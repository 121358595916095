import type { StoreApi } from 'zustand/vanilla';
import type { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { GlobalStateStore } from '../types';

export type EntityDetail = {
  assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
  assetId: string;
};

export type VersionUpdatesActionProps =
  | {
      operation: 'HIDE_VERSION_ERROR';
      payload: undefined;
    }
  | {
      operation: 'ADD_STALE_ENTITIES';
      payload: EntityDetail;
    }
  | {
      operation: 'REMOVE_STALE_ENTITIES';
      payload: {
        entityDetails: EntityDetail[];
      };
    }
  | {
      operation: 'ADD_TO_IGNORED_ENTITIES';
      payload: {
        entityDetails: EntityDetail[];
      };
    }
  | {
      operation: 'REMOVE_FROM_IGNORED_ENTITIES';
      payload: {
        entityDetails: EntityDetail[];
      };
    }
  | {
      operation: 'CLEAN_STALE_ENTITIES';
      payload: object;
    };

const getVersionUpdatesAction =
  (set: StoreApi<GlobalStateStore>['setState']) =>
  ({ operation, payload }: VersionUpdatesActionProps) => {
    const updateState = (
      stateUpdater: (
        updates: GlobalStateStore['live']['versionUpdates'],
      ) => GlobalStateStore['live']['versionUpdates'],
    ) => {
      set((state) => {
        const updatedVersionUpdates = stateUpdater(state.live.versionUpdates);

        return {
          ...state,
          live: {
            ...state.live,
            versionUpdates: updatedVersionUpdates,
          },
        };
      });
    };

    switch (operation) {
      case 'ADD_STALE_ENTITIES': {
        updateState((updates) => ({
          ...updates,
          showVersionError: true,
          outdatedEntities: [...updates.outdatedEntities, payload],
        }));
        break;
      }

      case 'REMOVE_STALE_ENTITIES': {
        updateState((updates) => {
          const outdatedEntities = updates.outdatedEntities.filter(
            (entity) =>
              !payload.entityDetails.some(
                (entityDetail) =>
                  entityDetail.assetClass === entity.assetClass &&
                  entityDetail.assetId === entity.assetId,
              ),
          );

          return {
            ...updates,
            showVersionError: outdatedEntities.length > 0,
            outdatedEntities,
          };
        });
        break;
      }
      case 'CLEAN_STALE_ENTITIES': {
        updateState((updates) => ({
          showVersionError: false,
          outdatedEntities: [],
          entitiesToIgnore: updates.entitiesToIgnore,
        }));

        break;
      }
      case 'HIDE_VERSION_ERROR': {
        updateState((updates) => ({
          ...updates,
          showVersionError: false,
        }));
        break;
      }

      case 'ADD_TO_IGNORED_ENTITIES': {
        updateState((updates) => ({
          ...updates,
          entitiesToIgnore: [...updates.entitiesToIgnore, ...payload.entityDetails],
        }));
        break;
      }

      case 'REMOVE_FROM_IGNORED_ENTITIES': {
        updateState((updates) => {
          const entitiesToIgnore = updates.entitiesToIgnore.filter(
            (entity) =>
              !payload.entityDetails.some(
                (entityDetail) =>
                  entityDetail.assetClass === entity.assetClass &&
                  entityDetail.assetId === entity.assetId,
              ),
          );

          return {
            ...updates,
            entitiesToIgnore,
          };
        });
        break;
      }
    }
  };

export default getVersionUpdatesAction;
