import { useCallback, useMemo } from 'react';
import DataSourceHelper from '../../../../../helper/DataSourceHelper';
import { getIsDataSourceAnEntityType } from '../../../../../utils/entityDataSource';
import { useInterfaceStore } from '../../../../../stores/InterfaceStore';
import { useGlobalStateStore } from '../../../../../stores/GlobalStateStore';
import {
  useDataSourceRecords,
  useDataSourceRecordStore,
} from '../../../../../stores/DataSourceRecordStore';
import { getLookupRequestsForDataSources } from '../../../utils/getLookupRequestsForDataSources';
import ApplicationDataSourceQueryNode from '../../ApplicationDataSourceQueryNode';
import type { ApplicationDataSourceQueryNodeProps, QueryNode } from '../../../types';
import { createIdFromQueryKeyPrefix } from '../../../utils/queryKeyPrefix';
import useManualDataSourceKeys from './useManualDataSourceTriggerIds';

function ManualDataSourceController() {
  const dataSources = useDataSourceRecords();
  const dependencies = useInterfaceStore().use.page.metadata.dependencies();
  const dependencyById = useInterfaceStore().use.page.metadata.dependencyById();
  const { setManualDataSourceState, setDataSourceState, removeManualDataSourceState } =
    useGlobalStateStore().use.actions();
  const { triggerIds, requests } = useManualDataSourceKeys();
  const { removeManuallyTriggeredDataSources } = useGlobalStateStore().use.actions();
  const getGlobalStateStoreState = useGlobalStateStore().getState;

  const { removeDataSource } = useDataSourceRecordStore().use.actions();

  const onManuallyTriggeredDataSourceCompletion: ApplicationDataSourceQueryNodeProps['onQueryCompletion'] =
    useCallback(
      ({ id, requestId }) => {
        requests[requestId].callback?.();
        if (getIsDataSourceAnEntityType(id)) {
          removeDataSource(id);
        }
        removeManualDataSourceState(requestId);
        removeManuallyTriggeredDataSources([requestId]);
      },
      [removeDataSource, removeManualDataSourceState, removeManuallyTriggeredDataSources, requests],
    );

  const lookupRequestsByDataSourceId = useMemo(
    () => getLookupRequestsForDataSources(dependencies, dependencyById),
    [dependencies, dependencyById],
  );

  const getCurrentQueryResult: QueryNode['getCurrentQueryResult'] = useCallback(
    ({ requestId }) => {
      return getGlobalStateStoreState().manualDataSources[requestId];
    },
    [getGlobalStateStoreState],
  );

  const onSetDataSourceState: ApplicationDataSourceQueryNodeProps['setDataSourceState'] =
    useCallback(
      ({ id, requestId, result }) => {
        // id is the where we would have stored the things in the global state store
        setDataSourceState(id, result);
        // requestId is the key we would have stored the things in the manualDataSources entry in the global state store
        setManualDataSourceState(requestId, result);
      },
      [setDataSourceState, setManualDataSourceState],
    );

  return (
    <>
      {triggerIds.map((requestId) => {
        const manuallyTriggeredDSDetails = requests[requestId];
        const dataSource = dataSources[manuallyTriggeredDSDetails.dataSourceId];
        const lookupRequests =
          lookupRequestsByDataSourceId[manuallyTriggeredDSDetails.dataSourceId];

        if (!dataSource) return null;

        const newId = createIdFromQueryKeyPrefix({
          originalId: manuallyTriggeredDSDetails.dataSourceId,
          queryKeyPrefix: manuallyTriggeredDSDetails.queryKeyPrefix,
        });

        if (DataSourceHelper.isApplicationTypeDataSource(dataSource)) {
          return (
            <ApplicationDataSourceQueryNode
              dataSource={dataSource.properties}
              getCurrentQueryResult={getCurrentQueryResult}
              id={newId}
              key={`manualDataSource-${requestId}`}
              lookupRequests={lookupRequests}
              onQueryCompletion={onManuallyTriggeredDataSourceCompletion}
              originalDataSourceId={
                dataSource.properties.standardDataSourceId ??
                manuallyTriggeredDSDetails.dataSourceId
              }
              refetchOnPayloadChange={false}
              requestId={requestId}
              setDataSourceState={onSetDataSourceState}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default ManualDataSourceController;
