import { useCallback, useMemo } from 'react';
import type { AutocompleteValue } from '@mui/base';
import type { AutocompleteProps } from '../Autocomplete';
import type { SelectProps, SelectValue } from './types';

export function useSelectHandlers<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  Value = string,
>({
  value: _value,
  options,
  onChange: _onChange,
  areValueEqual = Object.is,
  valueAccessor,
  freeSolo,
}: Pick<
  SelectProps<Option, Multiple, DisableClearable, Value>,
  'multiple' | 'value' | 'options' | 'onChange' | 'areValueEqual' | 'valueAccessor' | 'freeSolo'
>) {
  const createFreeStyleOption = useCallback(
    (value: Value) => {
      // Only create a freestyle option if freeSolo is enabled and value isn't found in options
      if (!freeSolo) return null;

      const existingOption = options.find((option) => areValueEqual(valueAccessor(option), value));

      if (existingOption) return existingOption;

      // Create a new option object that matches the Option type
      // The exact structure will depend on your Option type
      const freeStyleOption = {
        label: typeof value === 'string' ? value : String(value),
        value,
        // Add any other required fields for your Option type
      } as Option;

      return freeStyleOption;
    },
    [freeSolo, options, areValueEqual, valueAccessor],
  );

  // mui component uses referential equality of options by default
  const value = useMemo(() => {
    if (Array.isArray(_value)) {
      return (_value as Value[])
        .map((val) => {
          const existingOption = options.find((option) =>
            areValueEqual(valueAccessor(option), val),
          );
          return existingOption ?? createFreeStyleOption(val);
        })
        .filter((option): option is Option => option !== null);
    }

    // when value is undefined joyui will assume the component to be uncontrolled and when it is null joyui will assume the component to be controlled
    if (_value === undefined || _value === null) return null;

    // when value is undefined joyui will assume the component to be uncontrolled and when it is null joyui will assume the component to be controlled
    //https://github.com/mui/material-ui/blob/8aef9ad3fcf89d8061b8beeb04aba18be0510cce/packages/mui-utils/src/useControlled/useControlled.js#L7
    const existingOption = options.find((option) =>
      areValueEqual(valueAccessor(option), _value as Value),
    );

    return existingOption ?? createFreeStyleOption(_value as Value);
  }, [_value, areValueEqual, options, valueAccessor, createFreeStyleOption]) as AutocompleteValue<
    Option,
    Multiple,
    DisableClearable,
    false
  >;

  const handleChange: AutocompleteProps<Option, Multiple, DisableClearable, false>['onChange'] =
    useCallback(
      (
        _: React.SyntheticEvent,
        nextOption: AutocompleteValue<Option, Multiple, DisableClearable, false>,
      ) => {
        let updatedValue;

        if (Array.isArray(nextOption)) {
          updatedValue = nextOption.map((option) => {
            if (typeof option === 'string') {
              // Free-form input
              return option as Value;
            }
            return valueAccessor(option as Option);
          }) as SelectValue<Value, Multiple, DisableClearable>;
        } else if ((nextOption as Option | null) !== null) {
          if (typeof nextOption === 'string') {
            // from freesolo input
            updatedValue = nextOption;
          } else {
            updatedValue = valueAccessor(nextOption as Option);
          }
        }

        _onChange?.(updatedValue as SelectValue<Value, Multiple, DisableClearable>, nextOption);
      },
      [_onChange, valueAccessor],
    );

  return {
    value,
    handleChange,
  };
}
