import React, { useState, useMemo, useEffect } from 'react';
import _map from 'lodash/map';
import { MenuList } from '@unifyapps/ui/components/Menu';
import { Avatar } from '@unifyapps/ui/components/Avatar';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { Box } from '@unifyapps/ui/components/Box';
import type { Editor } from '@unifyapps/editor/tiptap/core';
import _isEmpty from 'lodash/isEmpty';
import { usePrevious } from '@react-hookz/web';
import { useKey } from 'react-use';
import { EmptyState } from '@unifyapps/ui/components/EmptyState';
import SvgSearchMd from '@unifyapps/icons/outline/SearchMd';
import { useTranslation } from '@unifyapps/i18n/client';

export interface MentionItem {
  id: string;
  label: string;
}

interface MentionListProps {
  editor: Editor;
  items: MentionItem[];
  command: (item: MentionItem | undefined) => void;
}

function MentionsList({ editor, items, command }: MentionListProps) {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const previousItem = usePrevious(items);

  useEffect(() => {
    if (previousItem?.length !== items.length) {
      setSelectedIndex(0);
    }
  }, [items.length, previousItem?.length]);

  // Replacing useEffect with useKey for key handling
  useKey(
    'ArrowDown',
    (event) => {
      setSelectedIndex((prev) => (prev + 1) % items.length);
      event.preventDefault();
    },
    { event: 'keydown' },
    [items.length],
  );

  useKey(
    'ArrowUp',
    (event) => {
      setSelectedIndex((prev) => (prev - 1 + items.length) % items.length);
      event.preventDefault();
    },
    { event: 'keydown' },
    [items.length],
  );

  useKey(
    'Enter',
    (event) => {
      event.preventDefault(); // Prevent default Enter behavior
      event.stopPropagation(); // Stop the event from propagating to the editor
      command(items[selectedIndex]);
    },
    { event: 'keydown' },
    [items, selectedIndex, command],
  );

  useKey(
    'Escape',
    () => {
      editor.commands.blur(); // Optionally close the suggestion popup
    },
    { event: 'keydown' },
    [editor],
  );

  const menuItems = useMemo(
    () =>
      _map(items, (item, index) => ({
        id: item.id,
        title: item.label,
        type: 'label' as const,
        startDecoratorNode: <Avatar name={item.label} size="xs" />,
        className: `!m-0 !min-h-[34px] !max-h-[34px] !h-[34px] flex item-center !py-0 !px-sm ${index === selectedIndex ? 'bg-secondary' : ''}`,
      })),
    [items, selectedIndex],
  );

  const onAction = useEventCallback((id: string) => {
    const item = items.find((suggestionItem) => suggestionItem.id === id);
    command(item);
  });

  if (_isEmpty(items)) {
    return (
      <Box className="bg-primary border-secondary flex min-h-[200px] min-w-[290px] max-w-[290px] items-center justify-center rounded-3xl border shadow-md">
        <EmptyState
          Icon={SvgSearchMd}
          size="sm"
          title={t('common:EmptyState.SearchResultsTitle')}
        />
      </Box>
    );
  }

  return (
    <Box className="bg-primary border-secondary max-h-[320px] min-w-[300px] max-w-[300px] overflow-y-auto rounded-3xl border shadow-md">
      <MenuList className="!p-md" items={menuItems} onAction={onAction} size="md" />
    </Box>
  );
}

export default MentionsList;
