import type {
  BlockStateDefinition,
  ComponentType,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { CopilotMessageComponentType } from '@unifyapps/defs/blocks/CopilotMessage/types';
import { lazy } from 'react';

const importCopilotMessageComponent = () =>
  import(
    /* webpackChunkName: "copilot-message-block" */
    './CopilotMessage'
  );

const CopilotMessage = lazy(importCopilotMessageComponent);

class CopilotMessageBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'CopilotMessage';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }
  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as CopilotMessageComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return CopilotMessage as ComponentType;
  }

  preload() {
    return {
      blockPreload: importCopilotMessageComponent as () => Promise<{ default: ComponentType }>,
    };
  }
}

export default CopilotMessageBlockStateDefinition;
