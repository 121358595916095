'use client';

import React, { useState, useMemo } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';

export function HeadingWrapper({ children }: { children: React.ReactNode }) {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);

  const isDarkMode = useMemo(() => {
    return (
      containerEl?.closest('[data-color-scheme]')?.getAttribute('data-color-scheme') === 'dark'
    );
  }, [containerEl]);

  return (
    <Stack
      className={clsx('min-w-0 flex-1', { 'gap-xs': !isDarkMode })}
      direction="column"
      ref={setContainerEl}
    >
      {children}
    </Stack>
  );
}
