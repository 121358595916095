import isString from 'lodash/isString';
import { cappedMemoize } from '../../utils/memoize';

// Reference: https://github.com/appsmithorg/appsmith/blob/release/app/client/src/utils/DynamicBindingUtils.ts
// eslint-disable-next-line optimize-regex/optimize-regex -- fine
export const DATA_BIND_REGEX = /{{(?:[\s\S]*?)}}/;

export const DATA_BIND_NON_NESTED_REGEX = /{{\s*(?<temp1>[^{}]*)\s*}}/g;

export const DATA_BIND_GLOBAL_REGEX = new RegExp(DATA_BIND_REGEX.source, 'g');

export const EXTRACT_DATA_BIND_REGEX = /{{\s*(?<temp1>.*?)\s*}}/;

export function removeDataBindingBraces(str: string) {
  return str.replaceAll(DATA_BIND_GLOBAL_REGEX, (match) => match.slice(2, -2).trim());
}

const isDynamicValuePlain = (value: string): boolean => DATA_BIND_REGEX.test(value);

export const isDynamicValue = cappedMemoize(isDynamicValuePlain, 1500);

function getDynamicStringSegments(dynamicString: string): string[] {
  let stringSegments: string[] = [];
  const doubleCurlyBraceStartIndex = dynamicString.indexOf('{{');

  if (doubleCurlyBraceStartIndex === -1) {
    return [dynamicString];
  }

  const firstString = dynamicString.substring(0, doubleCurlyBraceStartIndex);
  firstString && stringSegments.push(firstString);
  let rest = dynamicString.substring(doubleCurlyBraceStartIndex, dynamicString.length);

  let sum = 0;
  for (let i = 0; i <= rest.length - 1; i++) {
    const char = rest[i];
    const prevChar = rest[i - 1];

    if (char === '{') {
      sum++;
    } else if (char === '}') {
      sum--;
      if (prevChar === '}' && sum === 0) {
        stringSegments.push(rest.substring(0, i + 1));
        rest = rest.substring(i + 1, rest.length);
        if (rest) {
          stringSegments = stringSegments.concat(getDynamicStringSegments(rest));
          break;
        }
      }
    }
  }
  if (sum !== 0 && dynamicString !== '') {
    return [dynamicString];
  }
  return stringSegments;
}

export const getDynamicBindings = (dynamicString: string) => {
  // Protect against bad string parse
  if (!dynamicString || !isString(dynamicString)) {
    return { stringSegments: [], jsSnippets: [] };
  }
  const sanitisedString = dynamicString.trim();

  // Get the {{binding}} bound values
  const stringSegments = getDynamicStringSegments(sanitisedString);
  // Get the "binding" path values
  const paths = stringSegments.map((segment) => {
    const length = segment.length;
    const matches = isDynamicValue(segment);
    if (matches) {
      return segment.substring(2, length - 2);
    }
    return '';
  });

  return { stringSegments, jsSnippets: paths };
};
const NEW_LINE_REGEX = /[\n\r]+/g;

// Reference: https://github.com/appsmithorg/appsmith/blob/release/app/client/src/components/propertyControls/utils.ts#L8
const stringToJSPlain = (string: string): string => {
  const { jsSnippets, stringSegments } = getDynamicBindings(string);
  return stringSegments
    .map((segment, index) => {
      if (jsSnippets[index] && jsSnippets[index].length > 0) {
        // remove "\n" from the expression before evaluating
        return jsSnippets[index].replaceAll(NEW_LINE_REGEX, '');
      }
      return `'${segment}'`;
    })
    .join(' + ');
};

export const stringToJS = cappedMemoize(stringToJSPlain, 1500);
