import { forwardRef, useMemo } from 'react';
import {
  default as BaseCheckbox,
  type CheckboxProps as BaseCheckboxProps,
} from '@mui/joy/Checkbox';
import Check from '@unifyapps/icons/outline/Check';
import Minus from '@unifyapps/icons/outline/Minus';
import noop from 'lodash/noop';
import type { CheckboxProps } from './types';
import {
  checkboxRootVariants,
  checkboxCheckboxVariants,
  checkboxActionVariants,
  checkboxLabelVariants,
} from './styles';
import { UA_CHECKBOX } from './const';
import './checkbox.vars.css';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { className, size = 'sm', onChange, labelClassName, ...restProps } = props;

  const iconSize = size === 'sm' ? 12 : 14;

  const checkboxSlotProps = useMemo<BaseCheckboxProps['slotProps']>(
    () => ({
      root: ({ checked, indeterminate, disabled, focusVisible }) => ({
        className: checkboxRootVariants({
          className,
          checked,
          indeterminate,
          disabled,
          focusVisible,
          size,
        }),
      }),
      checkbox: ({ checked, indeterminate, disabled, focusVisible }) => ({
        className: checkboxCheckboxVariants({
          checked,
          indeterminate,
          disabled,
          focusVisible,
          size,
          className: UA_CHECKBOX,
        }),
        'data-size': size,
      }),
      action: ({ checked, indeterminate, disabled, focusVisible }) => ({
        className: checkboxActionVariants({
          checked,
          indeterminate,
          disabled,
          focusVisible,
          size,
        }),
      }),
      label: ({ checked, indeterminate, disabled, focusVisible }) => ({
        className: checkboxLabelVariants({
          checked,
          indeterminate,
          disabled,
          focusVisible,
          size,
          className: labelClassName,
        }),
      }),
      input: {
        ref,
      },
    }),
    [className, ref, size, labelClassName],
  );

  return (
    <BaseCheckbox
      checkedIcon={<Check height={iconSize} strokeWidth={2} width={iconSize} />}
      data-size={size}
      disabled={props.disabled || props.readOnly}
      indeterminateIcon={<Minus height={iconSize} width={iconSize} />}
      onChange={props.readOnly ? noop : onChange}
      ref={ref}
      size={size}
      slotProps={checkboxSlotProps}
      {...restProps}
    />
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
