import type { BeforeErrorHook } from 'ky';
import { getResponseData } from '../../utils';
import { UAFetchError } from '../../UAFetchError';
import type { ErrorPayload } from '../../../consts/error';

async function handleError({ response, url }: { response: Response; url: string }) {
  const responseData = (await getResponseData(response)) as Partial<ErrorPayload> | string;

  let messageTitle = 'Failed to fetch',
    errorId = '';
  if (typeof responseData === 'object') {
    messageTitle = responseData.errorMessage || responseData.message || messageTitle;
    errorId = responseData.errorId ?? errorId;
  }

  const error = new UAFetchError({
    message: `[UAFetchError] ${messageTitle} ${url} : [${errorId}]`,
    status: response.status,
    statusText: response.statusText,
    url,
    payload: typeof responseData === 'object' ? responseData : { message: responseData },
  });

  throw error;
}

export const getLogErrorHook = (): BeforeErrorHook => {
  const beforeErrorHook: BeforeErrorHook = async (error) => {
    const { response, request } = error;
    await handleError({ response, url: request.url });
    return error;
  };
  return beforeErrorHook;
};
