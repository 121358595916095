import type { Options as KyOptions } from 'ky';
import ky from 'ky';
import { CONTENT_TYPE_HEADER } from '../../auth/const';
import handleMobileCookies from '../native/handleMobileCookies/handleMobileCookies';
import { UAFetchError } from './UAFetchError';
import { getBaseURL, getQueryParams, getResponseData, isServer } from './utils';
import type { FetchArgs, FetchOptions } from './types';
import { getServerAuthHeaders } from './hooks/beforeRequest/serverAuth';
import { getDeltaMatrixHeaders } from './hooks/beforeRequest/deltaMatrix';
import { getProjectHeaders } from './hooks/beforeRequest/project';
import { handleLogout } from './hooks/afterResponse/logout';
import { handleExpiredPassword } from './hooks/afterResponse/expiredPassword';
import { getLogErrorHook } from './hooks/beforeError/logError';
import { getMetadataHeaders } from './hooks/beforeRequest/metadata';
import { getInterfaceHeaders } from './hooks/beforeRequest/interface';
import { handleLogging } from './hooks/afterResponse/logger';
import getMobileAuthHeaders from './hooks/beforeRequest/mobileAuth';
import { getInternalSvcRequest } from './hooks/beforeRequest/internalSvc';

// references:
// https://github.com/suhaotian/xior/blob/e46dfab26f5a4ddb73928d9e1b15b7f83576d930/src/xior.ts#L225
// https://github.com/elbywan/wretch/blob/d2cb52235026c3a68f8a9949f08d99234c6675be/src/core.ts#L95

export const executeFetch = async <T>(
  fetchArgs: FetchArgs,
  options: FetchOptions = {},
): Promise<T> => {
  const { url, method, params, headers = {}, data, signal } = fetchArgs;
  if (!isServer() && '__unifyBaseUrl' in window) {
    options.credentials = 'include';
  }

  const baseURL = await getBaseURL({ url });
  const queryParams = getQueryParams({ data, params }, options);

  const hookArgs = { fetchArgs, options, baseURL };

  const kyOptions: KyOptions = {
    method,
    credentials: options.credentials,
    headers: {
      ...headers,
      ...options.headers,
    },
    searchParams: queryParams,
    json: data instanceof FormData ? undefined : data,
    body: data instanceof FormData ? data : undefined,
    signal,
    timeout: false,
    retry: 0,
    cache: options.cache,
    hooks: {
      beforeRequest: [
        getMetadataHeaders(hookArgs),
        getServerAuthHeaders(hookArgs),
        getMobileAuthHeaders(hookArgs),
        getDeltaMatrixHeaders(hookArgs),
        getProjectHeaders(hookArgs),
        getInterfaceHeaders(hookArgs),
        getInternalSvcRequest,
      ],
      afterResponse: [
        handleLogout(hookArgs),
        handleExpiredPassword(hookArgs),
        handleLogging(hookArgs),
      ],
      beforeError: [getLogErrorHook()],
    },
  };

  const response = await ky(baseURL, kyOptions).catch((e: unknown) => {
    // UAFetchError is already handled by beforeError hook
    if (!(e instanceof UAFetchError)) {
      console.error('[fetch internal] error', { url });
    }
    throw e;
  });

  if (response.ok && response.status === 204) {
    return {} as Promise<T>;
  }

  try {
    if (response.headers.get('set-cookie')) {
      handleMobileCookies(response.headers.get('set-cookie'));
    }
    const responseData = await getResponseData(response);
    return responseData as T;
  } catch (e) {
    const contentType = response.headers.get(CONTENT_TYPE_HEADER) ?? '';
    console.error(`Failed to parse response for ${contentType}`, {
      url,
      statusText: response.statusText,
      status: response.status,
    });

    return response as unknown as Promise<T>;
  } finally {
    // enable on demand
    // if (process.env.NODE_ENV === 'development') {
    //   console.debug('Response', {
    //     // duration: measure.duration,
    //     url,
    //     status: response.status,
    //     statusText: response.statusText,
    //     params,
    //     payload: data,
    //   });
    // }
  }
};

export type ErrorType<T> = UAFetchError<T>;
