import type { BusinessHourValueType } from '@unifyapps/defs/blocks/BusinessHours/types';

export const defaultBusinessHourValue: BusinessHourValueType = {
  applyMondayValuesToAllDays: false,
  timezone: 'Asia/Kolkata',
  businessHour: [
    {
      isWorkingDay: true,
      dayOfWeek: 'MONDAY',
      startTime: '09:00',
      endTime: '17:00',
    },
    {
      isWorkingDay: true,
      dayOfWeek: 'TUESDAY',
      startTime: '09:00',
      endTime: '17:00',
    },
    {
      isWorkingDay: true,
      dayOfWeek: 'WEDNESDAY',
      startTime: '09:00',
      endTime: '17:00',
    },
    {
      isWorkingDay: true,
      dayOfWeek: 'THURSDAY',
      startTime: '09:00',
      endTime: '17:00',
    },
    {
      isWorkingDay: true,
      dayOfWeek: 'FRIDAY',
      startTime: '09:00',
      endTime: '17:00',
    },
  ],
};
