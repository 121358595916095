import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { useFindDeployedEntityById } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import { InterfaceClient } from '../../../../../stores/InterfaceStore';
import getHost from '../../../standardEntities/data/useFetchInterfaceResources/host';
import { useGetAuthDeployedInterface } from '../useAuthDeployedInterface';
import { useFetchDeployedDataSourcesByComponentId } from '../useFetchDeployedDataSourcesByComponentId';
import { useDeployedInterfaceModules } from '../useFetchDeployedInterfaceModules';
import { useFetchDeployedInterfaceNamespaces } from '../useFetchDeployedInterfaceNamespaces';
import { useFetchDeployedInterfacePage } from './useFetchDeployedInterfacePage';
import type { UseFetchResourcesHookType } from './type';

const useFetchDeployedInterfaceResources: UseFetchResourcesHookType = ({
  interfaceId,
  interfaceComponent,
  client,
  mode,
}) => {
  /**
   * Below two queries are ran conditionally based on client prop
   * Now lets say in a parent component(eg: InterfacePageComponent.tsx) we want useFindDeployedEntityById
   * hence enabled is true for useFindDeployedEntityById and false for useGetAuthDeployedInterface
   *
   * Now lets say in an app there is a module being rendered which will use the useGetAuthDeployedInterface query
   * Hence in that case enabled is true for useGetAuthDeployedInterface and false for useFindDeployedEntityById
   * Now when useGetAuthDeployedInterface query is in flight as the module requested it, the same query mounted in parent component
   * will also have isLoading as true. Now because of this the parent component will render the loader and not the child component.
   * This will lead to state reset as the store providers will unmount and mount again.
   *
   * Hence, to avoid this, the effective isLoading should be only be true if that query is enabled.
   */

  const shouldQueryAuthDeployedInterface =
    Boolean(getHost()) && client === InterfaceClient.UA_MATRIX;
  const authEntityInterface = useGetAuthDeployedInterface<InterfaceEntity>({
    // UA_MATRIX is for matrix app, so there we fetch from auth/interface endpoint that uses domain
    query: {
      enabled: shouldQueryAuthDeployedInterface,
      retry: 3,
    },
  });
  const isLoadingAuthDeployedInterfaceQuery =
    authEntityInterface.isLoading && shouldQueryAuthDeployedInterface;

  const shouldQueryDeployedInterfaceById =
    Boolean(interfaceId) && client === InterfaceClient.UA_PLATFORM;
  const { data, isLoading } = useFindDeployedEntityById<InterfaceEntity>(
    UAEntityTypes.Interface,
    interfaceId,
    {
      query: {
        enabled: shouldQueryDeployedInterfaceById,
        retry: 3,
      },
    },
  );
  const isLoadingDeployedInterfaceByIdQuery = isLoading && shouldQueryDeployedInterfaceById;

  const interfaceRecord = authEntityInterface.data || data;
  const isLoadingDeployedInterfaceQuery =
    isLoadingAuthDeployedInterfaceQuery || isLoadingDeployedInterfaceByIdQuery;
  const errorInterfaceRecord = authEntityInterface.error;

  const {
    interfacePage,
    isLoading: isLoadingPage,
    error: errorPage,
  } = useFetchDeployedInterfacePage({
    interfaceId,
    interfaceComponent,
    defaultPageId: interfaceRecord?.properties.navigation.defaultPageId,
  });

  const interfacePageId = interfacePage?.id ?? '';

  const {
    isLoading: isLoadingInterfaceNamespaces,
    error: errorInterfaceNamespaces,
    commonNamespace,
    interfaceComponentNamespace,
  } = useFetchDeployedInterfaceNamespaces({
    interfaceId,
    interfacePageId,
    mode,
    interfaceRecord,
  });

  const { dataSources, errorDS, isLoadingDS } =
    useFetchDeployedDataSourcesByComponentId(interfacePageId);

  const {
    interfaceModulesMap,
    isLoading: isLoadingInterfaceModules,
    error: interfaceModulesError,
  } = useDeployedInterfaceModules({
    interfaceId,
  });

  const _isLoading =
    isLoadingDeployedInterfaceQuery ||
    isLoadingPage ||
    isLoadingDS ||
    isLoadingInterfaceModules ||
    isLoadingInterfaceNamespaces;

  // Uncomment this line to debug ssr -- they all should be prefetched i.e. all should be false
  // console.log(
  //   `isLoading:${_isLoading} isLoadingDeployedInterfaceQuery:${isLoadingDeployedInterfaceQuery} ; isLoadingDeployedComponentQuery:${isLoadingDeployedComponentQuery}; isLoadingDS:${isLoadingDS}; isLoadingInterfaceModules:${isLoadingInterfaceModules} `,
  // );

  return {
    interfaceModulesMap,
    isLoading: _isLoading,
    error:
      errorInterfaceRecord ||
      errorPage ||
      errorDS ||
      interfaceModulesError ||
      errorInterfaceNamespaces,
    data: interfaceRecord,
    dataSources,
    interfacePage,
    interfaceComponentNamespace,
    commonNamespace,
  };
};

export default useFetchDeployedInterfaceResources;
