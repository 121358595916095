import type {
  QueryKey,
  UseInfiniteQueryResult,
  UseQueryResult,
} from '@unifyapps/network/react-query';
import type { LocationSensorState } from 'react-use/lib/useLocation';
import type { BlockStateUnionType, BlockType } from '@unifyapps/defs/types/block';
import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import type {
  DesktopVariant,
  DeviceVariantType,
  MobileVariant,
} from '@unifyapps/defs/types/deviceVariant';
import type { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import type { MutableRefObject, RefObject } from 'react';
import type { UserPermissionsMap } from '../../access-control/types';
import type createObjectSetters from '../../../utils/setters/createObjectSetters';
import type { RemountFunction } from '../../../components/Remount/Remount';
import type { InterfaceClient, InterfaceModes } from '../InterfaceStore';
import type { BuildDependencyGraph } from '../../../dependency/manager/types';
import type { BuildDependencyGraphItem } from '../../../dependency/utils/buildDependencyGraph';
import type { DeviceVariantActionProps } from './actions/deviceVariantAction';
import type { InterfaceSessionStorageActionProps } from './actions/getInterfaceSessionStorageAction';
import type { VersionUpdatesActionProps } from './actions/versionUpdatesAction';
import type { ParallelUpdatesActionProps } from './actions/parallelUpdatesActions';

type QueryResult = UseQueryResult<Awaited<unknown>, unknown>;

export type DeviceDetailsType = {
  device: DeviceVariantType;
  mobileVariant?: MobileVariant;
  desktopVariant?: DesktopVariant;
};

export type QueryRequestResult = {
  queryKey: QueryKey;
  objects?: unknown[];
} & Pick<QueryResult, 'error' | 'isLoading' | 'isFetching' | 'data'> &
  Partial<
    Pick<UseInfiniteQueryResult, 'isFetchingNextPage' | 'hasNextPage' | 'fetchNextPage' | 'refetch'>
  >;

type DataSourcesResultsSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'dataSources', QueryRequestResult>
>;

type ManualDataSourcesResultsSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'manualDataSources', QueryRequestResult>
>;

type BlockSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'blocks', BlockStateUnionType>
>;

type PageVariableSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'pageVariables', PageVariableState>
>;

type PageFunctionSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'pageFunctions', PageFunctionState>
>;

type BlocksRefSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'blockRefs', RefObject<unknown>>
>;

export type ManuallyTriggeredDataSourcesSetterType = ReturnType<
  typeof createObjectSetters<
    GlobalStateStoreType,
    'manuallyTriggeredDataSources',
    ManuallyTriggeredDataSourceType
  >
>;

type LocationInStore = Pick<
  LocationSensorState,
  'href' | 'hostname' | 'pathname' | 'search' | 'hash'
> & {
  pathParams?: Record<string, string | string[]>;
};

type LocationSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'location', string>
>;

type ContextualDialogForBlocksSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'contextualDialogForBlocks', string>
>;

type UserContextInStore = {
  id?: string;
  locale: string;
};

type UserContextSetterType = ReturnType<
  typeof createObjectSetters<GlobalStateStoreType, 'userContext', string>
>;

export type DataSourceMetaType = {
  aggregationMetadata?: QueryRequestResult | undefined;
  previewResult?: QueryRequestResult | undefined;
};

export type GlobalStateStoreFlows = {
  importObjectRecords?: {
    onSuccess?: (...args: unknown[]) => unknown;
    onError?: (...args: unknown[]) => unknown;
    onClose?: (...args: unknown[]) => unknown;
    objectId?: string;
    objectInputs?: {
      key: string;
      value: string;
    }[];
  };
};

export type SetPreBuiltFlowParams = {
  type: 'importObjectRecords';
  data: GlobalStateStoreFlows['importObjectRecords'];
};

export type ManuallyTriggeredDataSourceType = {
  callback: ((...args: unknown[]) => unknown) | undefined;
  dataSourceId: string;
  queryKeyPrefix?: string;
};

export const enum DependencyFlowStatus {
  Staging = 'staging',
  Commit = 'commit',
  Finished = 'finished',
}

export type DependencyFlowInstanceType = Record<
  string,
  { status: DependencyFlowStatus } | undefined
>;

type DependencyFlowManagerType = {
  dataSources: DependencyFlowInstanceType;
};

export type GlobalStateStoreInitialValue = Pick<
  GlobalStateStore,
  | 'deviceDetails'
  | 'pageInputs'
  | 'userContext'
  | 'manuallyTriggeredDataSources'
  | 'location'
  | 'blocks'
  | 'pageVariables'
  | 'permissions'
  | 'pageFunctions'
  | 'contextualDialogForBlocks'
>;

export type GlobalStateStoreRefType = {
  updateBlocks: ({
    added,
    deleted,
    changed,
  }: {
    added: BlockType[];
    deleted: BlockType[];
    changed: BlockType[];
  }) => void;
};

export type BaseGlobalStateStoreProviderProps = {
  blocks?: InterfacePageEntity['properties']['blocks'];
  pageVariables?: InterfacePageEntity['properties']['pageVariables'];
  deviceDetails: DeviceDetailsType;
  dataSources?: Record<string, DataSourceEntity | undefined>;
  pageInputs?: Record<string, unknown>;
  mode?: InterfaceModes;
  interfaceId: string;
  pageFunctions?: InterfacePageEntity['properties']['pageFunctions'];
  globalStateStoreRef?: MutableRefObject<GlobalStateStoreRefType | undefined>;
  client: InterfaceClient;
  remount: () => void;
  permissions: UserPermissionsMap;
};

export type WithRemountProps = Omit<BaseGlobalStateStoreProviderProps, 'remount' | 'permissions'>;
export type WithPermissionsProps = Omit<BaseGlobalStateStoreProviderProps, 'permissions'>;

export type GlobalStateStore = {
  dataSources: Record<string, QueryRequestResult | undefined>;
  // tentative manual registry, where we keep the inflight manual data sources response, once the response is received, we move it to dataSources, here the key will be the requestId
  manualDataSources: Record<string, QueryRequestResult | undefined>;
  dataSourcesMeta: Record<string, DataSourceMetaType | undefined>;
  blocks: Record<string, BlockStateUnionType | undefined>;
  pageVariables: Record<string, PageVariableState | undefined>;
  pageFunctions: Record<string, PageFunctionState | undefined>;
  manuallyTriggeredDataSources: Record<string, ManuallyTriggeredDataSourceType>;
  previewTriggeredDataSources: Record<string, boolean | undefined>;
  pageInputs: Partial<Record<string, unknown>>;
  location: LocationInStore;
  userContext: UserContextInStore;
  blockRefs: Record<string, RefObject<unknown> | undefined>;
  flows: GlobalStateStoreFlows;
  deviceDetails: DeviceDetailsType;
  permissions: UserPermissionsMap;
  interfaceSessionStorage: Partial<
    Pick<QueryResult, 'error' | 'isLoading' | 'isFetching' | 'data'>
  >;
  //  a map of blockId and associated contextual dialog block id
  contextualDialogForBlocks: Record<string, string | undefined>;
  /**
   * intra dependencies are the dependencies that track within the same entity -
   * for example, a data source can have dependencies on other data sources
   */
  intraDependencies: {
    dataSources?: BuildDependencyGraph;
  };
  /**
   * dependency flow manager is used to manage an id, and it's flow status, whether it's in staging, commit or finished
   */
  dependencyFlowManager: DependencyFlowManagerType;

  live: {
    /**
     * interface updates are used to show the version error in the interface
     */
    versionUpdates: {
      showVersionError: boolean;
      outdatedEntities: {
        assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
        assetId: string;
      }[];
      entitiesToIgnore: {
        assetClass: UAEntityTypes.Interface | UAEntityTypes.Component | UAEntityTypes.DataSource;
        assetId: string;
      }[];
    };

    /**
     * it stores information about the parallel updates in the interface made by other users
     */
    parallelUpdates: {
      skip: boolean;
      updates: Record<string, { userId: string; userName: string } | undefined>;
    };
  };
};

export type GlobalStateStoreType = GlobalStateStore & {
  actions: {
    setDataSourceState: DataSourcesResultsSetterType['setSingle'];
    setManualDataSourceState: ManualDataSourcesResultsSetterType['setSingle'];
    removeManualDataSourceState: ManualDataSourcesResultsSetterType['remove'];
    setBlockState: BlockSetterType['setSingle'];
    setBlocksState: BlockSetterType['setMultiple'];
    removeBlocksState: BlockSetterType['removeMultiple'];
    updateBlockState: BlockSetterType['updateSingle'];
    setPageVariableState: PageVariableSetterType['setSingle'];
    setPageVariablesState: PageVariableSetterType['setMultiple'];
    setPageFunctionState: PageFunctionSetterType['setSingle'];
    setUserContext: UserContextSetterType['setMultiple'];
    setLocation: LocationSetterType['setMultiple'];
    setContextualDialogForBlocks: ContextualDialogForBlocksSetterType['setMultiple'];
    removeManuallyTriggeredDataSources: ManuallyTriggeredDataSourcesSetterType['removeMultiple'];
    setAggregationMetadataInDSMeta: (
      dataSourceId: string,
      aggregationMetadata: QueryRequestResult,
    ) => void;
    setPreviewDataInDSMeta: (dataSourceId: string, previewData: QueryRequestResult) => void;
    setBlockRef: BlocksRefSetterType['setSingle'];
    resetBlockRefs: () => void;
    // reset: () => void;
    removeBlockRef: BlocksRefSetterType['remove'];
    setPreBuiltFlow: (params: SetPreBuiltFlowParams) => void;
    removePreBuiltFlow: (key: keyof GlobalStateStoreFlows) => void;
    deviceVariantActions: (args: DeviceVariantActionProps) => void;
    setInterfaceSessionStorage: (args: InterfaceSessionStorageActionProps) => void;
    datasource: {
      triggerManualDataSource: ManuallyTriggeredDataSourcesSetterType['setSingle'];
      previewDataSource: (id: string, value: boolean) => void;
      removeDataSourceFromStore: (id: string) => void;
      addIntraDependency: (entityId: string, data: BuildDependencyGraphItem<unknown>) => void;
      addIntraDependencies: (entities: BuildDependencyGraph) => void;
      addDependencyFlow: (entityId: string, status: DependencyFlowStatus) => void;
      addDependencyFlows: (entities: DependencyFlowInstanceType) => void;
    };
    setIsInitialized: () => void;
    reset: () => void;
    /*
     * this action is used to show the version errors in the interface
     */
    live: {
      versionUpdatesActions: (args: VersionUpdatesActionProps) => void;
      parallelUpdatesActions: (args: ParallelUpdatesActionProps) => void;
    };
    remount: RemountFunction;
  };
};
