import { useEffect, useMemo, useRef, useImperativeHandle } from 'react';
import type { MutableRefObject } from 'react';
import type { EditorRef } from '@unifyapps/editor/types';
import { clsx } from 'clsx';
import BasicExtensions from '@unifyapps/editor/extensions/basic';
import type { RichEditorProps } from '@unifyapps/editor/editors/RichTextEditor';
import { RichTextEditor as BaseRichTextEditor } from '@unifyapps/editor/editors/RichTextEditor';

const DEFAULT_ROOT_CLASS_NAME = 'border-none ';

export function RichTextEditor({
  ownerState,
  className,
  onInputChange,
  value,
  inputRef,
  placeholder,
  hideToolbar = true,
  extensions: propsExtensions,
  autofocus = false,
  minNumberOfLines,
  inputClassName,
  rootClassName = DEFAULT_ROOT_CLASS_NAME,
}: {
  onInputChange: (value: string) => void;
  ownerState: {
    placeholder?: string;
    onKeyDown?: () => void;
    disabled?: boolean;
    onFocus?: (event: FocusEvent) => void;
  };
  className: string;
  value?: string;
  inputRef?: MutableRefObject<{ insertHtml?: (html: string) => void } | undefined>;
  placeholder?: string;
  hideToolbar?: boolean;
  extensions?: RichEditorProps['extensions'];
  autofocus?: boolean;
  minNumberOfLines?: number;
  inputClassName?: string;
  rootClassName?: string;
}) {
  const editorRef = useRef<EditorRef>(null);
  const extensions = useMemo(
    () => [
      BasicExtensions.configure({
        placeholder: { placeholder: ownerState.placeholder },
      }),
      ...(propsExtensions || []),
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps -- extensions should not update on runtime
    [propsExtensions],
  );

  useEffect(() => {
    const editorContent = editorRef.current?.getContent();

    if (!value) {
      editorRef.current?.clearContent();
      return;
    }

    //if the value changes from outside (i.e. if the messageInput is controlled) then update the editor content
    if (value !== editorContent) {
      editorRef.current?.setContent(value);
    }
  }, [value]);

  const hasMultipleLines = Boolean(value?.includes('<br>'));

  useImperativeHandle(inputRef, () => ({
    insertHtml: (html: string) => {
      editorRef.current?.insertHtml(html);
    },
    isEmpty: () => {
      return editorRef.current?.isEmpty() || false;
    },
    clearContent: () => {
      editorRef.current?.clearContent();
    },
    focus: editorRef.current?.focus,
  }));

  return (
    <BaseRichTextEditor
      autofocus={autofocus}
      className={clsx('break-anywhere overflow-auto', className)}
      editable={!ownerState.disabled}
      extensions={extensions}
      hideToolbar={hideToolbar}
      initialContent={value}
      inputClassName={clsx('grow !p-0 !min-h-0', { 'w-full': hasMultipleLines }, inputClassName)}
      minNumberOfLines={minNumberOfLines}
      onChangeHTML={onInputChange}
      onFocus={ownerState.onFocus}
      onKeyDown={ownerState.onKeyDown}
      placeholder={placeholder}
      ref={editorRef}
      rootClassName={clsx('text-sm !w-auto grow', rootClassName)}
    />
  );
}
