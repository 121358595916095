/* eslint-disable @typescript-eslint/no-extraneous-class -- needed*/
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { fallbackLng } from '@unifyapps/i18n/settings';
import { EMPTY_ARRAY } from '../../../../consts/empty';
import { InterfaceModes } from '../types';

interface GetApplicationLocaleParams {
  interfaceRecord?: InterfaceEntity;
  userLocale?: string;
  mode?: InterfaceModes;
}

class InterfaceHelper {
  static getType = (interfaceRecord: InterfaceEntity | undefined) => {
    return interfaceRecord?.properties.manifest?.type || 'WEB';
  };

  static getDefaultLocale = (interfaceRecord: InterfaceEntity | undefined) => {
    return interfaceRecord?.properties.locale?.defaultLocale;
  };

  static getLocale = (params: GetApplicationLocaleParams) => {
    const { userLocale, interfaceRecord, mode = InterfaceModes.RUNNER } = params;
    const defaultLocale = this.getDefaultLocale(interfaceRecord);

    // no default locale, return fallback
    if (!defaultLocale) {
      return fallbackLng;
    }

    // if mode is builder or user locale is not set, return default locale
    if (mode === InterfaceModes.BUILDER || !userLocale) {
      return defaultLocale;
    }

    const allLocales = this.getAllLocalesSet(interfaceRecord);

    // if user locale is supported, return it
    if (allLocales.has(userLocale)) {
      return userLocale;
    }

    // return default locale
    return defaultLocale;
  };

  static getBuilderLocale = (userLocale?: string, interfaceRecord?: InterfaceEntity) => {
    const defaultLocale = this.getDefaultLocale(interfaceRecord);
    return defaultLocale ?? userLocale ?? fallbackLng;
  };

  static getSupportedLocales = (interfaceRecord: InterfaceEntity | undefined) => {
    return (interfaceRecord?.properties.locale?.supportedLocales || EMPTY_ARRAY) as string[];
  };

  static getAllLocalesSet = (interfaceRecord: InterfaceEntity | undefined) => {
    const interfaceLocales = new Set<string>();
    const locale = interfaceRecord?.properties.locale;

    if (!locale) {
      return interfaceLocales;
    }

    interfaceLocales.add(locale.defaultLocale);

    this.getSupportedLocales(interfaceRecord).forEach((supportedLocale) => {
      interfaceLocales.add(supportedLocale);
    });

    return interfaceLocales;
  };
}

export default InterfaceHelper;
