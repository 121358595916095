import { useEffect } from 'react';
import { InterfaceModes, useInterfaceStore } from '../../../../stores/InterfaceStore';
import { useInteractiveModeFromLocalStorage } from '../../../../hooks/local-storage/useInteractiveModeFromLocalStorage';

function useSetInteractiveModeInStore() {
  const { getState } = useInterfaceStore();

  const mode = useInterfaceStore().use.mode();
  const interfaceRecordId = useInterfaceStore().use.record.id();

  const { value, loading } = useInteractiveModeFromLocalStorage({
    interfaceRecordId,
    enabled: mode === InterfaceModes.BUILDER,
  });

  const { toggleInteractiveMode } = useInterfaceStore().use.actions();

  useEffect(() => {
    if (loading || value === undefined || value === getState().isInteractiveModeEnabled) {
      return;
    }

    toggleInteractiveMode();
  }, [getState, loading, toggleInteractiveMode, value]);
}

export default useSetInteractiveModeInStore;
