import Modal from './Modal';
import ModalHeader from './Headers/ModalHeader';
import ModalContent from './Content/Content';
import ModalFooter from './Footers/Footer';
import ModalHeaderBase from './Headers/ModalHeaderBase';
import ModalContentBase from './Content/ContentBase';
import ModalFooterBase from './Footers/FooterBase';

const ModalNamespace = Object.assign(Modal, {
  Header: ModalHeader,
  Content: ModalContent,
  Footer: ModalFooter,

  // Unstyled
  HeaderBase: ModalHeaderBase,
  ContentBase: ModalContentBase,
  FooterBase: ModalFooterBase,
});

export default ModalNamespace;
