import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { useMemo } from 'react';
import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { getIconFromFileType } from '@unifyapps/carbon/components/FileUpload/UploadStatus';
import CitationsHelper from '../CitationsHelper';

type Props = {
  citation: CitationType;
  className: string;
  displayName?: string;
};

export function CitationIcon({ citation, className, displayName }: Props) {
  const iconUrl = CitationsHelper.getIconUrl(citation);

  const Icon = useMemo(() => {
    if (citation.knowledgeSourceType === 'WEBSITE') {
      return getIconFromRegistry('Globe01');
    }
    if (citation.knowledgeSourceType === 'FILE') {
      return getIconFromFileType(citation.mimeType ?? '');
    }

    return undefined;
  }, [citation.knowledgeSourceType, citation.mimeType]);

  return Icon ? (
    <Icon className={className} />
  ) : (
    <img alt={displayName} className={className} src={iconUrl} />
  );
}
