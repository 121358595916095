import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type { QRCodeComponentType } from '@unifyapps/defs/blocks/QRCode/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importQRCodeComponent = () =>
  import(
    /* webpackChunkName: "qrcode-block" */
    './QRCode'
  );

const QRCode = lazy(importQRCodeComponent);

class QRCodeBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'QRCode';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as QRCodeComponentType, ['content', 'appearance', 'componentType']),
      data: [],
    });
  }

  getComponent() {
    return QRCode;
  }

  preload() {
    return {
      blockPreload: importQRCodeComponent,
    };
  }
}

export default QRCodeBlockStateDefinition;
