import { cva } from 'class-variance-authority';
import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { Box } from '@unifyapps/ui/components/Box';
import { useTranslation } from '@unifyapps/i18n/client';
import { Loader } from '@unifyapps/ui/components/Loader';
import type { MediaType } from '../../MediaViewer/types';
import type { MediaItemProps, MediaOverrides } from '../types';
import { isImage } from '../utils';
import { MediaItem } from './MediaItem';
import './style.css';
import { DeleteMediaItemButton } from './DeleteMediaItemButton';

const itemVariants = cva(
  'flex-none w-full bg-primary border-secondary group/media-item relative items-center rounded-sm border',
  {
    variants: {
      size: {
        sm: 'gap-sm p-sm h-8 min-w-[7.5rem] max-w-[10rem]',
        md: 'gap-lg py-md px-lg h-12 max-w-[17.5rem]',
        lg: 'gap-lg p-md px-lg h-16 max-w-[20rem]',
      },
      disabled: {
        true: '',
        false: 'hover:bg-primary_hover cursor-pointer',
      },
    },
  },
);

const textVariants = cva('', {
  variants: {
    size: {
      sm: 'text-xs',
      md: 'text-xs',
      lg: 'text-sm',
    },
  },
});

const iconClassName = cva('', {
  variants: {
    size: {
      sm: 'size-full',
      md: 'size-6',
      lg: 'size-8',
    },
  },
});

const imageClassName = cva('rounded-xs aspect-square', {
  variants: {
    size: {
      sm: 'size-[22px]',
      md: 'size-full',
      lg: 'size-full',
    },
  },
});

const detailsRootClassName = cva('h-full ', {
  variants: {
    size: {
      sm: 'justify-center',
      md: '',
      lg: 'gap-xs',
    },
  },
});

export const getMediaItem = (item: MediaType, overrides?: MediaOverrides) => {
  switch (true) {
    case isImage(item):
      return overrides?.ImageItem ?? null;
    default:
      return null;
  }
};

export function CompactMediaItem({
  item,
  onRemoveItem,
  className,
  onClick,
  size,
  overrides,
  slotProps,
}: MediaItemProps) {
  const { t } = useTranslation('common');
  const { isLoading, name, fileType, caption, id } = item;

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = useEventCallback((event) => {
    event.stopPropagation();
    onRemoveItem?.(id);
  });

  const { style, weight } = slotProps?.MediaLabel ?? {};

  const _iconClassName = clsx(iconClassName({ size }), slotProps?.icon?.className);

  const OverriddenMediaItem = getMediaItem(item, overrides);
  if (OverriddenMediaItem) {
    return (
      <OverriddenMediaItem
        className={className}
        item={item}
        onClick={onClick}
        onRemoveItem={onRemoveItem}
        size={size}
      />
    );
  }

  return (
    <Stack
      className={clsx(itemVariants({ size, disabled: isLoading }), className)}
      direction="row"
      onClick={isLoading ? undefined : onClick}
    >
      <Box className="flex h-full items-center">
        {isLoading ? (
          <Loader size="xs" variant="circle" />
        ) : (
          <MediaItem
            iconClassName={_iconClassName}
            imgClassName={clsx(imageClassName({ size }), slotProps?.image?.className)}
            item={item}
          />
        )}
      </Box>
      <Stack className={detailsRootClassName({ size })}>
        <Typography
          className="grow"
          maxNumberOfLines={1}
          style={style}
          variant={textVariants({ size }) as TypographyVariants}
          weight={weight ?? 'semi-bold'}
        >
          {name || caption || t('common:Attachment')}
        </Typography>
        {size !== 'sm' ? (
          <Typography variant="text-xxs" weight="light">
            {(fileType ?? 'File').toUpperCase()}
          </Typography>
        ) : null}
      </Stack>
      <DeleteMediaItemButton handleDelete={onRemoveItem ? handleDelete : undefined} />
    </Stack>
  );
}
