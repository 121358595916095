import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type {
  ContextualDialogBlockStateType,
  ContextualDialogComponentType,
} from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const importContextualDialogComponent = () =>
  import(
    /* webpackChunkName: "contextual-dialog-block" */
    './ContextualDialog'
  );

const ContextualDialog = lazy(importContextualDialogComponent);

class ContextualDialogBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'ContextualDialog';
  }

  getEventTargetIds() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ContextualDialogComponentType, [
        'content',
        'appearance',
        'componentType',
        'slots',
      ]),
      state: {
        open: false,
      },
    });
  }

  getBlockControlMethods() {
    return [
      {
        id: 'toggleContextualDialog',
        label: 'Toggle',
        method: (blockState: ContextualDialogBlockStateType, action: Action) => {
          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
            },
            state: {
              open: !blockState.state.open,
              targetBlockId: blockState.state.open ? undefined : action.payload?.blockStateId,
            },
          } as ContextualDialogBlockStateType;
        },
      },
      {
        id: 'showContextualDialog',
        label: 'Show',
        method: (blockState: ContextualDialogBlockStateType, _: Action) => {
          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
            },
            state: {
              open: true,
            },
          } as ContextualDialogBlockStateType;
        },
      },
      {
        id: 'hideContextualDialog',
        label: 'Hide',
        method: (blockState: ContextualDialogBlockStateType, _: Action) => {
          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
            },
            state: {
              open: false,
            },
          } as ContextualDialogBlockStateType;
        },
      },
    ];
  }

  getComponent() {
    return ContextualDialog;
  }

  preload() {
    return {
      blockPreload: importContextualDialogComponent,
    };
  }
}

export default ContextualDialogBlockStateDefinition;
