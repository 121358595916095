import { useCallback } from 'react';
import type { GlobalStateStoreFlows } from '../../../../stores/GlobalStateStore';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

function useImportObjects() {
  const { setPreBuiltFlow } = useGlobalStateStore().use.actions();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const importObjects: ActionHandlerType = useCallback(
    ({ action, actionContext }: OnActionArgs) => {
      const payload = action.payload as GlobalStateStoreFlows['importObjectRecords'];
      const computedPayload = getNoCodeComputedData<GlobalStateStoreFlows['importObjectRecords']>(
        payload ?? {},
        actionContext,
      );

      if (payload?.objectId) {
        return new Promise((resolve, reject) => {
          setPreBuiltFlow({
            type: 'importObjectRecords',
            data: {
              ...computedPayload,
              onSuccess: () => resolve(),
              onClose: () => resolve(),
              onError: () => reject(),
            },
          });
        });
      }
      return Promise.resolve();
    },
    [getNoCodeComputedData, setPreBuiltFlow],
  );

  return { importObjects };
}

export default useImportObjects;
