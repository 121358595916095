import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { SmallIconButton } from '@unifyapps/ui/components/SmallIconButton';
import SvgX from '@unifyapps/icons/outline/X';
import { useTranslation } from '@unifyapps/i18n/client';

export function DeleteMediaItemButton({
  handleDelete,
}: {
  handleDelete?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { t } = useTranslation();
  if (!handleDelete) return null;

  return (
    <Tooltip placement="top" title={t('common:Actions.RemoveItem')}>
      <SmallIconButton
        Icon={SvgX}
        className="btnClassName absolute -end-2 -top-2 hidden !size-4 justify-self-end group-hover/media-item:flex"
        iconClassName="!size-3"
        onClick={handleDelete}
        size="sm"
        strokeWidth={2}
        variant="solid"
      />
    </Tooltip>
  );
}
