import noop from 'lodash/noop';

const safeGlobalContext = {
  alert: noop,
  prompt: noop,
  confirm: noop,
  window:
    typeof window === 'undefined'
      ? {}
      : {
          location: window.location,
          open: (...args: Parameters<typeof window.open>) => window.open(...args),
          opener: window.opener as Window | null,
          addEventListener: (...args: Parameters<typeof window.addEventListener>) =>
            window.addEventListener(...args),
          removeEventListener: (...args: Parameters<typeof window.removeEventListener>) =>
            window.removeEventListener(...args),
        },
};

// To eval a single JavaScript expression or statement and get its resolved value use indirectEvalExpression
export const indirectEvalExpression = (script: string, globalContext: object): unknown => {
  const context = { ...safeGlobalContext, ...globalContext };
  const keys = Object.keys(context);
  const values: unknown[] = Object.values(context);
  // escape newline characters to avoid "Invalid or unexpected token" error
  // we need to preserve the newline characters in the script to maintain formatting, eg. markdown default value
  // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
  const evalFunction = new Function(
    ...keys,
    `"use strict"; return ${script.replace(/\n/g, '\\n')}`,
  );
  return evalFunction(...values);
};

export const createExpressionEvaluator = (
  globalContext: Record<string, unknown>,
): ((script: string) => unknown) => {
  // Cache the merged context object
  const context = { ...safeGlobalContext, ...globalContext };
  // Return a memoized version of the evaluation function
  const memoCache = new Map<string, unknown>();

  const keys = Object.keys(context);
  const values: unknown[] = Object.values(context);

  return (script: string): unknown => {
    if (typeof script !== 'string') {
      throw new TypeError('Script must be a string');
    }

    if (memoCache.has(script)) {
      return memoCache.get(script);
    }

    try {
      // Execute with direct parameter passing instead of using 'call'
      // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
      const evalFunction = new Function(
        ...keys,
        `"use strict"; return ${script.replace(/\n/g, '\\n')}`,
      );

      const result = evalFunction(...values) as unknown;
      memoCache.set(script, result);
      return result;
    } catch (error) {
      // Clear cache entry if evaluation fails
      memoCache.delete(script);
      throw error;
    }
  };
};

// To execute any JavaScript code, which doesn't return anything with global context use indirectExecuteScript
export const indirectExecuteScript = (
  script: string,
  globalContext: object,
  exposedWindowVariables?: string[],
) => {
  const context = { ...safeGlobalContext, ...globalContext };

  if (exposedWindowVariables) {
    exposedWindowVariables.forEach((key) => {
      // omitting keys that are present in safeglobalcontext because
      // we want our implementation
      if (typeof window[key] !== 'undefined' && !safeGlobalContext.window[key]) {
        context.window[key] = window[key] as unknown;
      }
    });
  }

  const keys = Object.keys(context);
  const values: unknown[] = Object.values(context);
  // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
  const evalFunction = new Function(...keys, `"use strict"; ${script}`);
  evalFunction(...values);
};
