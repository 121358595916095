import { keyBy } from 'lodash';

export const NoCodeTemplates: Record<
  | 'login'
  | 'publicLogin'
  | 'updatePassword'
  | 'userActivity'
  | 'mfaVerification'
  | 'forgotPassword'
  | 'resetPassword',
  { interfaceId: string; interfacePageId: string; interfacePageSlug: string }
> = {
  login: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'login',
    interfacePageId: 'login',
  },
  publicLogin: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'public-login',
    interfacePageId: 'public_login',
  },
  updatePassword: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'update-password',
    interfacePageId: 'update_password',
  },
  mfaVerification: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'mfa-verification',
    interfacePageId: 'mfa_verification',
  },
  userActivity: {
    interfaceId: 'ua-settings',
    interfacePageSlug: 'ua-settings-user-activity',
    interfacePageId: 'ua-settings-user-activity',
  },
  forgotPassword: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'forgot-password',
    interfacePageId: 'ua_governance_forgot_password',
  },
  resetPassword: {
    interfaceId: 'ua-governance',
    interfacePageSlug: 'reset-password',
    interfacePageId: 'ua_governance_reset_password',
  },
};

export const NO_CODE_TEMPLATES_BY_ID = keyBy(
  Object.values(NoCodeTemplates),
  'interfacePageId',
) as Record<string, { interfaceId: string; interfacePageSlug: string } | undefined>;
