import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import _forEach from 'lodash/forEach';
import _startCase from 'lodash/startCase';
import _isEmpty from 'lodash/isEmpty';
import {
  type InterfacePageEntity,
  InterfacePageEntityComponentType,
} from '@unifyapps/defs/types/page';
import _keys from 'lodash/keys';
import { ID_SEPARATOR } from '@unifyapps/form/const';
import type BlocksRegistry from '../../components/RegistryProvider/BlocksRegistry';
import { FOOTER_ID, HEADER_ID, ROOT_ID } from '../../const';
import type { FlattenedItem } from '../../types/flattenedItem';
import { FlattenedItemType } from '../../types/flattenedItem';
import { BlockHelper } from '../../../helpers/BlockHelper';

function getBlockDisplayName(
  blocks: Partial<Record<string, BlockType>>,
  parentFlattenedItem: FlattenedItem,
) {
  if (parentFlattenedItem.type !== FlattenedItemType.Slot) {
    return parentFlattenedItem.title;
  }

  const blockId = parentFlattenedItem.componentType
    ? parentFlattenedItem.parentBlockId
    : parentFlattenedItem.blockId;

  if (parentFlattenedItem.blockId === FOOTER_ID) {
    return `Footer`;
  } else if (parentFlattenedItem.blockId === HEADER_ID) {
    return `Header`;
  } else if (parentFlattenedItem.blockId === ROOT_ID) {
    return `Root`;
  }

  const parentDisplayName = blocks[blockId ?? '']?.displayName ?? '';

  return `${parentDisplayName}'s ${parentFlattenedItem.title}`;
}

function insertWithEmptyBlock(args: {
  blockId: BlockId;
  positionInBlock: string | number | null;
  parentFlattenedItem: FlattenedItem;
  blocks: Partial<Record<string, BlockType>>;
  flattenedItems: FlattenedItem[];
  registry: BlocksRegistry;
  options?: Partial<FlattenedItem>;
}) {
  const {
    blockId,
    blocks,
    parentFlattenedItem,
    positionInBlock,
    flattenedItems,
    registry,
    options,
  } = args;

  if (BlockHelper.shouldAddEmptyComponent(blockId)) {
    // adding add component button if blockId is null
    const parentDisplayName = getBlockDisplayName(blocks, parentFlattenedItem);
    flattenedItems.push({
      visualPath: `${parentFlattenedItem.visualPath}${ID_SEPARATOR}${positionInBlock}`,
      blockId: parentFlattenedItem.blockId,
      type: FlattenedItemType.AddComponent,
      level: parentFlattenedItem.level + 1,
      title: parentDisplayName,
      parentBlockId: parentFlattenedItem.parentBlockId,
      hasChildren: false,
      insertPos: positionInBlock?.toString() ?? null,
      newIndex: null,
      deletePos: null,
    });
    return;
  }

  flattenBlockIntoItems({
    flattenedItems,
    blocks,
    visualPath: `${parentFlattenedItem.visualPath}`,
    blockId,
    positionInBlock,
    level: parentFlattenedItem.level,
    parentBlockId: parentFlattenedItem.blockId,
    registry,
    options,
  });
}

export function flattenBlockIntoItems(args: {
  flattenedItems: FlattenedItem[];
  blocks: Partial<Record<string, BlockType>>;
  visualPath: string;
  blockId: BlockId;
  positionInBlock: string | number | null;
  level: number;
  parentBlockId: BlockId;
  registry: BlocksRegistry;
  options?: Partial<FlattenedItem>;
}) {
  const {
    flattenedItems,
    blocks,
    blockId,
    positionInBlock,
    visualPath,
    level,
    parentBlockId,
    registry,
    options,
  } = args;

  const block = blocks[blockId ?? ''];
  if (!block) return;

  const blockIds = BlockHelper.getComponentBlockIds(block);
  const slots = BlockHelper.getBlockSlots(block);
  const items = BlockHelper.getBlockItems(block);

  const rootBlock: FlattenedItem = {
    visualPath: `${visualPath}${ID_SEPARATOR}${blockId}`,
    blockId,
    type: FlattenedItemType.Block,
    level: level + 1,
    title: block.displayName || block.id,
    parentBlockId,
    componentType: block.component.componentType,
    hasChildren: false, // mutating this later if it has children, to keep all children related logic in one place
    insertPos: null,
    deletePos: positionInBlock?.toString() ?? null,
    newIndex: typeof positionInBlock === 'number' && parentBlockId ? positionInBlock : null,
    ...options,
  };

  // Adding current block
  flattenedItems.push(rootBlock);

  _forEach(blockIds, (childBlockId, index) => {
    insertWithEmptyBlock({
      blockId: childBlockId,
      positionInBlock: index,
      parentFlattenedItem: rootBlock,
      blocks,
      flattenedItems,
      registry,
    });
  });

  // Adding slots of current block
  if (!_isEmpty(slots)) rootBlock.hasChildren = true;
  const slotOrderFromRegistry = registry.getSlotOrder(block);

  const { order } =
    !slotOrderFromRegistry || _isEmpty(slotOrderFromRegistry.order)
      ? {
          order: _keys(slots),
        }
      : slotOrderFromRegistry;

  _forEach(order, (slotId) => {
    if (slots?.[slotId]) {
      const slot = slots[slotId];

      // if the slot is not wrapped in layout, we need to add it as a separate block
      // for backward compatibility.
      const slotFlattenBlock: FlattenedItem | undefined = !slot.wrappedInLayout
        ? {
            visualPath: `${rootBlock.visualPath}${ID_SEPARATOR}${slotId}`,
            blockId: rootBlock.blockId,
            type: FlattenedItemType.Slot,
            level: rootBlock.level + 1,
            title: _startCase(slotId),
            parentBlockId,
            hasChildren: true,
            insertPos: null,
            newIndex: null,
            deletePos: null,
          }
        : {
            ...rootBlock,
            // all slots are wrapped in Stack
            componentType: 'Stack',
            visualPath: `${rootBlock.visualPath}${ID_SEPARATOR}${slotId}`,
          };

      if (!slot.wrappedInLayout) {
        flattenedItems.push(slotFlattenBlock);
      }

      insertWithEmptyBlock({
        blockId: slot.blockId,
        positionInBlock: slotId,
        parentFlattenedItem: slotFlattenBlock,
        blocks,
        flattenedItems,
        registry,
        options: slot.wrappedInLayout
          ? {
              type: FlattenedItemType.Slot,
              deletePos: null,
              wrappedInLayout: true,
            }
          : {},
      });
    }
  });

  // Adding items.blockId of current block
  //items refers to component.content.items
  if (items?.length) rootBlock.hasChildren = true;
  _forEach(items, (item) => {
    const itemFlattenBlock: FlattenedItem = {
      visualPath: `${rootBlock.visualPath}${ID_SEPARATOR}${item.value}`,
      blockId: rootBlock.blockId,
      type: FlattenedItemType.Slot,
      level: rootBlock.level + 1,
      title: item.label,
      parentBlockId,
      hasChildren: true,
      insertPos: null,
      newIndex: null,
      deletePos: null,
    };

    flattenedItems.push(itemFlattenBlock);

    // because of mapped tabs, blockId can be undefined
    if (item.blockId === undefined) return;

    insertWithEmptyBlock({
      blockId: item.blockId,
      positionInBlock: item.value,
      parentFlattenedItem: itemFlattenBlock,
      blocks,
      flattenedItems,
      registry,
    });
  });

  // Adding blockIds of current block
  if (blockIds?.length) {
    rootBlock.hasChildren = true;
    if (block.component.componentType === 'Stack') {
      rootBlock.newIndex = 0;
    }
  }
}

export const PAGE_HEADER = 'header';
export const PAGE_BODY = 'body';
export const PAGE_FOOTER = 'footer';

function getInitialFlattenedItem({ title, visualPath }: { title: string; visualPath: string }) {
  return {
    visualPath,
    blockId: null,
    type: FlattenedItemType.Slot,
    level: 0,
    title: _startCase(title),
    parentBlockId: null,
    hasChildren: true,
    insertPos: null,
    newIndex: null,
    deletePos: null,
  };
}

function insertHeaderBlock({
  blocks,
  flattenedItems,
  layout,
  registry,
}: {
  flattenedItems: FlattenedItem[];
  blocks: Partial<Record<string, BlockType>>;
  layout: InterfacePageEntity['properties']['layout'];
  registry: BlocksRegistry;
}) {
  // NOTE: adding header blocks
  const headerItem: FlattenedItem = getInitialFlattenedItem({
    title: PAGE_HEADER,
    visualPath: PAGE_HEADER,
  });

  const block = blocks[HEADER_ID];

  if (layout?.header !== HEADER_ID) {
    flattenedItems.push(headerItem);
  }

  insertWithEmptyBlock({
    blockId: layout?.header ?? null,
    positionInBlock: PAGE_HEADER,
    parentFlattenedItem: headerItem,
    blocks,
    flattenedItems,
    registry,
    options: {
      deletePos: null,
      type: FlattenedItemType.Slot,
      isRootBlock: Boolean(block?.additional?.isRootBlock),
    },
  });

  return flattenedItems;
}

function insertBodyBlock({
  blocks,
  flattenedItems,
  layout,
  registry,
}: {
  flattenedItems: FlattenedItem[];
  blocks: Partial<Record<string, BlockType>>;
  layout: InterfacePageEntity['properties']['layout'];
  registry: BlocksRegistry;
}) {
  // NOTE: adding body blocks
  const bodyItem: FlattenedItem = getInitialFlattenedItem({
    title: PAGE_BODY,
    visualPath: PAGE_BODY,
  });

  const rootBlock = blocks[ROOT_ID];

  if (!rootBlock?.additional?.isRootBlock) {
    flattenedItems.push(bodyItem);
  }

  insertWithEmptyBlock({
    blockId: layout?.body ?? null,
    positionInBlock: PAGE_BODY,
    parentFlattenedItem: bodyItem,
    blocks,
    flattenedItems,
    registry,
    options: {
      deletePos: null,
      type: FlattenedItemType.Slot,
      isRootBlock: Boolean(rootBlock?.additional?.isRootBlock),
    },
  });
}

function insertFooterBlock({
  blocks,
  flattenedItems,
  layout,
  registry,
}: {
  flattenedItems: FlattenedItem[];
  blocks: Partial<Record<string, BlockType>>;
  layout: InterfacePageEntity['properties']['layout'];
  registry: BlocksRegistry;
}) {
  // NOTE: adding footer blocks
  const footerItem: FlattenedItem = getInitialFlattenedItem({
    title: PAGE_FOOTER,
    visualPath: PAGE_FOOTER,
  });

  const block = blocks[FOOTER_ID];

  if (layout?.footer !== FOOTER_ID) {
    flattenedItems.push(footerItem);
  }

  insertWithEmptyBlock({
    blockId: layout?.footer ?? null,
    positionInBlock: PAGE_FOOTER,
    parentFlattenedItem: footerItem,
    blocks,
    flattenedItems,
    registry,
    options: {
      deletePos: null,
      type: FlattenedItemType.Slot,
      isRootBlock: Boolean(block?.additional?.isRootBlock),
    },
  });
}

export function getFlattenBlocks({
  blocks,
  registry,
  layout,
  componentType,
}: {
  blocks: Record<string, BlockType>;
  registry: BlocksRegistry;
  layout: InterfacePageEntity['properties']['layout'];
  componentType?: InterfacePageEntityComponentType;
}) {
  const flattenedItems: FlattenedItem[] = [];
  const isModuleType = componentType === InterfacePageEntityComponentType.MODULE;

  if (_isEmpty(blocks)) {
    return flattenedItems;
  }

  if (!isModuleType) {
    insertHeaderBlock({ blocks, flattenedItems, layout, registry });
  }

  insertBodyBlock({ blocks, flattenedItems, layout, registry });

  if (!isModuleType) {
    insertFooterBlock({ blocks, flattenedItems, layout, registry });
  }

  return flattenedItems;
}
