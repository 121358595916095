import type {
  BlockControlMethodHelpers,
  BlockStateDefinition,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { CardBlockStateType, CardComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { BlockDataAttributes } from '@unifyapps/carbon/no-code/hooks/useBlockDataAttributes';
import Card from './Card';

// No need to lazy load Card, since it's used a lot and is a layout block
class CardBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Card';
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as CardComponentType, ['content', 'componentType']),
        appearance: {
          ...(block.component as CardComponentType).appearance,
          isCollapsed: !(
            (block.component as CardComponentType).content.collapsible?.isInitiallyExpanded ?? true
          ),
        },
      }) as BlockStateUnionType;
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'toggleCollapse' as const,
        label: 'Toggle Collapse State',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
              isCollapsed: !(blockState as CardBlockStateType).appearance?.isCollapsed,
            },
          } as BlockStateUnionType;
        },
      },
      {
        id: 'collapse' as const,
        label: 'Collapse Card',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
              isCollapsed: true,
            },
          } as BlockStateUnionType;
        },
      },
      {
        id: 'expand' as const,
        label: 'Expand Card',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
              isCollapsed: false,
            },
          } as BlockStateUnionType;
        },
      },
      {
        id: 'generatePreview' as const,
        label: 'Generate Preview',
        method: async (
          blockState: BlockStateUnionType,
          action: Action,
          blockRef: unknown,
          helpers: BlockControlMethodHelpers,
        ) => {
          try {
            const blockEl = document.querySelector<HTMLElement>(
              `[${BlockDataAttributes.BlockId}="${blockState.id}"]`,
            );

            if (!blockEl) {
              helpers.showSnackbar({
                title: 'Something went wrong while generating preview',
                color: 'error',
              });

              console.error('Error generating preview: ', 'Block element not found');
              return blockState;
            }

            const html2canvas = await import(/* webpackChunkName: "html2canvas" */ 'html2canvas');
            const canvas = await html2canvas.default(blockEl);

            const image = canvas.toDataURL('image/png');

            return {
              ...blockState,
              preview: image,
            };
          } catch (error) {
            helpers.showSnackbar({
              title: 'Something went wrong while generating preview',
              color: 'error',
            });

            console.error('Error generating preview: ', error);
            return blockState;
          }
        },
      },
    ];
  }

  getComponent() {
    return Card;
  }
}

export default CardBlockStateDefinition;
