'use client';
import type { ContextType, PropsWithChildren } from 'react';
import React, { useImperativeHandle, useRef } from 'react';
import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import { useRegistryContext } from '../../components/RegistryProvider';
import { useDeviceDetailsGetter, useGlobalStateStore } from '../GlobalStateStore';
import { useDataSourceExists, useDataSourceRecordStore } from '../DataSourceRecordStore';
import { createInterfaceStore } from './createInterfaceStore';
import type {
  AdditionalArgs,
  InterfaceStoreRefType,
  InterfaceStoreStateGetterAndSetter,
  InterfaceStoreStateInitialValue,
} from './types';
import { createInterfaceStoreSelectors } from './selectors';
import { InterfaceStoreContext, useInterfaceStore } from './context';

type InterfaceStoreProviderProps = {
  value: InterfaceStoreStateInitialValue;
  onChangeActiveBlockId?: (blockId: BlockId) => void;
  interfaceStoreRef: React.MutableRefObject<InterfaceStoreRefType | undefined>;
  getDependencyGraphActions: InterfaceStoreStateGetterAndSetter['getDependencyGraphActions'];
};
export { useInterfaceStore };

export function InterfaceStoreProvider({
  value,
  onChangeActiveBlockId,
  children,
  interfaceStoreRef,
  getDependencyGraphActions,
}: PropsWithChildren<InterfaceStoreProviderProps>) {
  const storeRef = useRef<ContextType<typeof InterfaceStoreContext>>();
  const { registry } = useRegistryContext();
  const { dataSourceExists } = useDataSourceExists();
  const getDataSourceRecordsStore = useDataSourceRecordStore().getState;
  const getGlobalStateStore = useGlobalStateStore().getState;

  const {
    setBlockState,
    updateBlockState,
    setPageVariableState,
    setPageFunctionState,
    setBlocksState,
    resetBlockRefs,
    deviceVariantActions: handleDeviceVariantChange,
  } = useGlobalStateStore().use.actions();

  const { getDeviceDetails } = useDeviceDetailsGetter();

  if (!storeRef.current) {
    const additionalArgs: AdditionalArgs = {
      dataSourceExists,
      registry,
      setBlockState,
      getDataSourceRecordsStore,
      getGlobalStateStore,
      updateBlockState,
      setBlocksState,
      setPageVariableState,
      setPageFunctionState,
      resetBlockRefs,
      getDeviceDetails,
      getDependencyGraphActions,
      handleDeviceVariantChange,
      onChangeActiveBlockId,
    };

    const interfaceStoreHook = createInterfaceStore(value, additionalArgs);
    storeRef.current = createInterfaceStoreSelectors(interfaceStoreHook);
  }

  useImperativeHandle(interfaceStoreRef, () => {
    return {
      updateBlocks: ({
        added,
        changed,
        deleted = [],
      }: {
        added: BlockType[];
        deleted: BlockType[];
        changed: BlockType[];
      }) => {
        const blocksToUpdate = [...added, ...changed];
        const keyByBlocks = _keyBy(blocksToUpdate, 'id');

        const interfaceStoreState = storeRef.current?.getState();

        const interfacePage =
          interfaceStoreState?.interfacePages[interfaceStoreState.activeInterfacePageId];

        if (interfacePage) {
          const newBlocks = _omit(
            {
              ...interfacePage.properties.blocks,
              ...keyByBlocks,
            },
            deleted.map((block) => block.id),
          );

          storeRef.current?.getState().actions.updateInterfacePageAction({
            interfacePage: {
              ...interfacePage,
              properties: {
                ...interfacePage.properties,
                blocks: newBlocks,
              },
            },
          });
        }
      },
    };
  }, []);

  return (
    <InterfaceStoreContext.Provider value={storeRef.current}>
      {children}
    </InterfaceStoreContext.Provider>
  );
}
