import { useEffect, useLayoutEffect, useState } from 'react';
// import BaseTableContainer, {
//   type TableContainerProps as BaseTableContainerProps,
// } from '@mui/material/TableContainer';
import Box, { type BoxProps } from '@mui/material/Box';
import { clsx } from 'clsx';
import fastdom from 'fastdom';
import useForkRef from '@unifyapps/ui/hooks/useForkRef';
import useToggle from '@unifyapps/hooks/useToggle';
import useEventCallback from '@mui/material/utils/useEventCallback';
import { useResizeObserver } from '@react-hookz/web';
import { type MRT_RowData, type MRT_TableInstance } from '../../types';
import { parseFromValuesOrFunc } from '../../utils/utils';
import { Table } from './Table';
// import { MRT_TableLoadingOverlay } from './MRT_TableLoadingOverlay';
// import { MRT_CellActionMenu } from '../menus/MRT_CellActionMenu';
// import { MRT_EditRowModal } from '../modals/MRT_EditRowModal';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

interface TableContainerProps<TData extends MRT_RowData> extends BoxProps {
  table: MRT_TableInstance<TData>;
}

function TableContainer<TData extends MRT_RowData>({ table, ...rest }: TableContainerProps<TData>) {
  const {
    getState,
    options: {
      // createDisplayMode,
      // editDisplayMode,
      // enableCellActions,
      enableColumnPinning,
      enableStickyHeader,
      muiTableContainerProps,
      renderOverlay,
    },
    refs: { bottomToolbarRef, tableContainerRef, topToolbarRef },
  } = table;
  const { isFullScreen, isLoading, showLoadingOverlay } = getState();
  const loading = showLoadingOverlay && (isLoading || showLoadingOverlay);

  const [totalToolbarHeight, setTotalToolbarHeight] = useState(0);
  // state to trigger a rerender when the tableContainerRef is set - issue was when table was inside a card with enableRowVirtualization
  const [_, toggleActions] = useToggle(false);

  // to track if the table is scrolled to the left or right used to show shadows
  const updateShadowState = useEventCallback(() => {
    if (!enableColumnPinning || !tableContainerRef.current) {
      return;
    }

    fastdom.measure(() => {
      const container = tableContainerRef.current;

      if (!container) {
        return;
      }

      const { scrollLeft, scrollWidth, clientWidth } = container;
      const left = scrollLeft > 0;
      const right = scrollLeft + clientWidth < scrollWidth;

      fastdom.mutate(() => {
        container.classList.toggle('ua-table-shadow-left', left);
        container.classList.toggle('ua-table-shadow-right', right);
      });
    });
  });

  const onResizeCallback = useEventCallback((entry: ResizeObserverEntry) => {
    const containerWidth = entry.contentRect.width;

    fastdom.mutate(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.style.setProperty(
          '--ua-table-container-width',
          containerWidth.toString(),
        );
      }
    });

    updateShadowState();
  });

  useResizeObserver(tableContainerRef, onResizeCallback);

  const tableContainerProps = {
    ...parseFromValuesOrFunc(muiTableContainerProps, { table }),
    ...rest,
  };

  const ref = useForkRef(
    tableContainerProps.ref,
    tableContainerRef,
    updateShadowState,
    toggleActions.toggle,
  );

  useIsomorphicLayoutEffect(() => {
    const topToolbarHeight =
      typeof document !== 'undefined' ? (topToolbarRef.current?.offsetHeight ?? 0) : 0;

    const bottomToolbarHeight =
      typeof document !== 'undefined' ? (bottomToolbarRef.current?.offsetHeight ?? 0) : 0;

    setTotalToolbarHeight(topToolbarHeight + bottomToolbarHeight);
  }, [bottomToolbarRef, topToolbarRef]);

  // const createModalOpen = createDisplayMode === 'modal' && creatingRow;
  // const editModalOpen = editDisplayMode === 'modal' && editingRow;
  const onScroll = useEventCallback((event: React.UIEvent<HTMLDivElement>) => {
    tableContainerProps.onScroll?.(event);
    updateShadowState();
  });

  return (
    <Box
      aria-busy={loading}
      aria-describedby={loading ? 'mrt-progress' : undefined}
      {...tableContainerProps}
      className={clsx(
        'ua-table-container relative max-w-full overflow-auto',
        tableContainerProps.className,
      )}
      onScroll={onScroll}
      ref={ref}
      style={{
        maxHeight: isFullScreen ? `calc(100vh - ${totalToolbarHeight}px)` : undefined,
        ...tableContainerProps.style,
      }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- needed as we have casted the sx prop
      sx={(_theme) => ({
        maxHeight: enableStickyHeader
          ? `clamp(350px, calc(100% - ${totalToolbarHeight}px), 9999px)`
          : undefined,
        // maxWidth: '100%',
        // overflow: 'auto',
        // position: 'relative',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this sx prop should only be SystemStyleObject and cannot be ((theme: Theme) => SystemStyleObject<Theme>)
        ...(tableContainerProps.sx as any),
        // ...(parseFromValuesOrFunc(tableContainerProps.sx, _theme) as any),
      })}
    >
      {/*{loading ? <MRT_TableLoadingOverlay table={table} /> : null}*/}
      <Table table={table} />
      {/*{createModalOpen || editModalOpen ? <MRT_EditRowModal open table={table} /> : null}*/}
      {/*{enableCellActions && actionCell && <MRT_CellActionMenu table={table} />}*/}
      {renderOverlay ? renderOverlay({ table }) : null}
    </Box>
  );
}

export { TableContainer };
