import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { CameraBlockStateType, CameraComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const importCameraComponent = () =>
  import(
    /* webpackChunkName: "camera-block" */
    './Camera'
  );

const Camera = lazy(importCameraComponent);

class CameraBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Camera';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'switchCamera' as const,
        label: 'Switch Camera',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { camera: 'user' | 'environment' };
          };

          const { camera } = methodPayload;

          return {
            ...blockState,
            content: {
              ...blockState.content,
              facingMode: camera,
            },
          } as CameraBlockStateType;
        },
      },
      {
        id: 'startRecording' as const,
        label: 'Start Recording',
        method: (blockState: BlockStateUnionType) => {
          return {
            ...blockState,
            recordingAction: 'start',
          } as CameraBlockStateType;
        },
      },
      {
        id: 'stopRecording' as const,
        label: 'Stop Recording',
        method: (blockState: BlockStateUnionType) => {
          return {
            ...blockState,
            recordingAction: 'stop',
          } as CameraBlockStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as CameraComponentType, ['content', 'appearance', 'componentType']),
    });
  }

  getComponent() {
    return Camera;
  }

  preload() {
    return {
      blockPreload: importCameraComponent,
    };
  }
}

export default CameraBlockStateDefinition;
