import type { BlockId } from '@unifyapps/defs/types/block';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { InterfaceStoreState, InterfaceStoreStateGetterAndSetter } from '../types';

export type NestedPropertyData = {
  propertyPath: string;
  headerLabel: string;
  formContext?: Record<string, unknown>;
};

export type PropertyPanelHistoryActionProps =
  | {
      operation: 'PUSH';
      payload:
        | {
            blockId: BlockId;
            viewType: 'FORM_DETAILS';
            data: { path: string[] };
          }
        | {
            blockId: BlockId;
            viewType: 'BLOCK_DETAILS' | 'TABLE_PAGE_DETAILS';
            data: undefined;
          }
        | { blockId: BlockId; viewType: 'CHART_SERIES_FIELD_DETAILS'; data: { id: string } }
        | { blockId: BlockId; viewType: 'STAT_CARD_DETAILS'; data: { id: string } }
        | {
            blockId: BlockId;
            viewType: 'BULK_ACTION_DETAILS';
            data: { id: string; interfacePages: InterfacePageEntity[]; headerLabel?: string };
          }
        | {
            blockId: BlockId;
            viewType:
              | 'FIELD_DETAILS'
              | 'CHART_SUMMARY_FIELD_DETAILS'
              | 'TABLE_TOOLBAR_DETAILS'
              | 'TABLE_SAVE_ACTION_DETAILS'
              | 'TABLE_TOOLBAR_FILTER_DETAILS'
              | 'TABLE_TOOLBAR_SORT_DETAILS'
              | 'ROW_ACTION_DETAILS'
              | 'TOOLBAR_CUSTOM_ACTION_DETAILS'
              | 'COLUMN_DETAILS'
              | 'MAPPED_COLUMN_DETAILS'
              | 'MAPPED_TAB_DETAILS'
              | 'MAPPED_FORM_FIELD_DETAILS'
              | 'NESTED_TABLE_COLUMN'
              | 'NAVIGATION_MENU_ITEM'
              | 'FILTER_DETAILS'
              | 'AI_FILTER_DETAILS'
              | 'SORT_FILTER_DETAILS'
              | 'DATE_FILTER_DETAILS'
              | 'QUICK_FILTER_DETAILS'
              | 'FIELD_FILTER_DETAILS'
              | 'SEARCH_FILTER_DETAILS'
              | 'FILE_UPLOAD_TEMPLATE_DETAILS'
              | 'RICH_TEXT_TOOLBAR_DETAILS'
              | 'TEXT_INPUT_LABEL_DETAILS'
              | 'ICON_DETAILS'
              | 'TAB_DETAILS'
              | 'SLIDE_DETAILS'
              | 'TIMELINE_AUDIT'
              | 'STEP_DETAILS'
              | 'BUTTON_DETAILS'
              | 'MENU_ITEM_DETAILS'
              | 'COMMENT_ACTION_DETAILS'
              | 'TREND_DETAILS'
              | 'STAT_CARD_PREFIX_SUFFIX_TEXT_DETAILS'
              | 'CALENDER_VALIDATION'
              | 'TYPOGRAPHY_TRUNCATION'
              | 'TOOLTIP_DETAILS'
              | 'RECAPTCHA_VALIDATION'
              | 'KEY_VALUE_FIELD_DECORATOR_DETAILS'
              | 'KEY_VALUE_VALUE_DECORATOR_DETAILS'
              | 'AUDIO_WAVEFORM_MARKER_DETAILS'
              | 'DATETIME_DETAILS'
              | 'GROUPED_COLUMN_DETAILS'
              | 'COLUMN_ACTION_DETAILS'
              | 'PDF_VIEWER_TOOLBAR_DETAILS'
              | 'MESSAGE_INPUT_CUSTOM_ACTIONS'
              | 'MESSAGE_INPUT_ADD_ATTACHMENT_ACTION'
              | 'MESSAGE_INPUT_SEND_MESSAGE_ACTION'
              | 'DROPDOWN_OPTIONS_DETAILS'
              | 'PROGRESS_ITEM_DETAILS'
              | 'MAPPED_COMMENTS_REPLY_ACTION_DETAILS'
              | 'MAPPED_COMMENTS_DELETE_ACTION_DETAILS'
              | 'COMMENT_INPUT_ADD_COMMENT_ACTION'
              | 'COMMENT_LIST_ADD_REPLY_ACTION'
              | 'MESSAGE_INPUT_EMOJI_PICKER_ACTION'
              | 'COMMENT_DATETIMEFORMAT_DETAILS';
            data: NestedPropertyData;
          };
    }
  | {
      operation: 'POP';
      payload?: undefined;
    }
  | {
      operation: 'CLEAR';
      payload?: undefined;
    };

const getPropertyPanelHistoryActions =
  ({ set, get }: InterfaceStoreStateGetterAndSetter) =>
  (args: PropertyPanelHistoryActionProps) => {
    const { propertyPanel } = get();
    const { operation } = args;

    switch (operation) {
      case 'PUSH': {
        const { blockId, viewType } = args.payload;

        let newPropertyPanelHistoryEl;

        switch (viewType) {
          case 'NAVIGATION_MENU_ITEM':
          case 'STEP_DETAILS':
          case 'MENU_ITEM_DETAILS':
          case 'SLIDE_DETAILS':
          case 'TAB_DETAILS':
          case 'FIELD_DETAILS':
          case 'COLUMN_DETAILS':
          case 'MAPPED_COLUMN_DETAILS':
          case 'MAPPED_TAB_DETAILS':
          case 'MAPPED_FORM_FIELD_DETAILS':
          case 'NESTED_TABLE_COLUMN':
          case 'CHART_SERIES_FIELD_DETAILS':
          case 'CHART_SUMMARY_FIELD_DETAILS':
          case 'STAT_CARD_DETAILS':
          case 'TREND_DETAILS':
          case 'ROW_ACTION_DETAILS':
          case 'COLUMN_ACTION_DETAILS':
          case 'BULK_ACTION_DETAILS':
          case 'TOOLBAR_CUSTOM_ACTION_DETAILS':
          case 'TABLE_TOOLBAR_DETAILS':
          case 'TABLE_SAVE_ACTION_DETAILS':
          case 'TABLE_TOOLBAR_FILTER_DETAILS':
          case 'TABLE_TOOLBAR_SORT_DETAILS':
          case 'DATE_FILTER_DETAILS':
          case 'FILTER_DETAILS':
          case 'SORT_FILTER_DETAILS':
          case 'QUICK_FILTER_DETAILS':
          case 'FIELD_FILTER_DETAILS':
          case 'FORM_DETAILS':
          case 'SEARCH_FILTER_DETAILS':
          case 'AI_FILTER_DETAILS':
          case 'FILE_UPLOAD_TEMPLATE_DETAILS':
          case 'TEXT_INPUT_LABEL_DETAILS':
          case 'CALENDER_VALIDATION':
          case 'TYPOGRAPHY_TRUNCATION':
          case 'TOOLTIP_DETAILS':
          case 'RECAPTCHA_VALIDATION':
          case 'ICON_DETAILS':
          case 'TIMELINE_AUDIT':
          case 'BUTTON_DETAILS':
          case 'RICH_TEXT_TOOLBAR_DETAILS':
          case 'COMMENT_ACTION_DETAILS':
          case 'STAT_CARD_PREFIX_SUFFIX_TEXT_DETAILS':
          case 'KEY_VALUE_FIELD_DECORATOR_DETAILS':
          case 'KEY_VALUE_VALUE_DECORATOR_DETAILS':
          case 'DATETIME_DETAILS':
          case 'AUDIO_WAVEFORM_MARKER_DETAILS':
          case 'GROUPED_COLUMN_DETAILS':
          case 'PDF_VIEWER_TOOLBAR_DETAILS':
          case 'MESSAGE_INPUT_CUSTOM_ACTIONS':
          case 'MESSAGE_INPUT_ADD_ATTACHMENT_ACTION':
          case 'MESSAGE_INPUT_SEND_MESSAGE_ACTION':
          case 'DROPDOWN_OPTIONS_DETAILS':
          case 'PROGRESS_ITEM_DETAILS':
          case 'MAPPED_COMMENTS_REPLY_ACTION_DETAILS':
          case 'MAPPED_COMMENTS_DELETE_ACTION_DETAILS':
          case 'COMMENT_INPUT_ADD_COMMENT_ACTION':
          case 'COMMENT_LIST_ADD_REPLY_ACTION':
          case 'MESSAGE_INPUT_EMOJI_PICKER_ACTION':
          case 'COMMENT_DATETIMEFORMAT_DETAILS': {
            newPropertyPanelHistoryEl = {
              blockId,
              viewDetails: {
                viewType,
                data: args.payload.data,
              },
            };
            break;
          }
          case 'BLOCK_DETAILS':
          case 'TABLE_PAGE_DETAILS': {
            newPropertyPanelHistoryEl = {
              blockId,
              viewDetails: {
                viewType,
                data: undefined,
              },
            };
            break;
          }
          default: {
            break;
          }
        }

        const updatedPropertyPanelHistory = [
          ...propertyPanel.history,
          newPropertyPanelHistoryEl,
        ] as InterfaceStoreState['propertyPanel']['history'];

        set({ propertyPanel: { ...propertyPanel, history: updatedPropertyPanelHistory } });
        break;
      }
      case 'POP': {
        const updatedPropertyPanelHistory = propertyPanel.history.slice(0, -1);

        set({
          propertyPanel: {
            ...propertyPanel,
            history: updatedPropertyPanelHistory,
          },
          activeBlockId:
            updatedPropertyPanelHistory[updatedPropertyPanelHistory.length - 1].blockId,
        });
        break;
      }
      case 'CLEAR': {
        set({
          propertyPanel: {
            ...propertyPanel,
            history: [] as InterfaceStoreState['propertyPanel']['history'],
          },
        });
        break;
      }
    }
  };

export default getPropertyPanelHistoryActions;
