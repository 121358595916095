import React, { useCallback, useMemo } from 'react';
import { SnackbarProvider } from '@unifyapps/ui/components/Snackbar';
import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import { parseAsString, useQueryState } from 'nuqs';
import type { BlockId } from '@unifyapps/defs/types/block';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import _noop from 'lodash/noop';
import useScreen from '@unifyapps/hooks/useScreen';
import { useTranslation } from '@unifyapps/i18n/client';
import { ComputeContextProvider } from '../../context/ComputeContextProvider';
import { DrawerProvider } from '../../../components/Drawer';
import { useGetDeviceDetailsFromLocalStorage } from '../../hooks/local-storage/useDeviceDetailsFromLocalStorage';
import type { WithPartial } from '../../../types';
import { getBaseDeviceOfThePage } from '../../stores/InterfaceStore/utils/block';
import { UppyProvider } from '../../../components/UppyProvider';
import { DataSourceRecordStoreProvider } from '../../stores/DataSourceRecordStore';
import {
  InterfaceClient,
  InterfaceModes,
  InterfaceStoreProvider,
  useInterfaceStore,
} from '../../stores/InterfaceStore';
import type { DeviceDetailsType } from '../../stores/GlobalStateStore';
import { GlobalStateStoreProvider, useGlobalStateStore } from '../../stores/GlobalStateStore';
import DataSourceController from '../DataSourceController';
import useGlobalBlockActions from '../../hooks/useGlobalBlockActions';
import RegistryProvider from '../RegistryProvider';
import ActionsProvider from '../ActionsProvider';
import { InterfaceSideEffects } from '../InterfaceSideEffects';
import { PreBuiltFlows } from '../PreBuiltFlows';
import AsyncView from '../../../components/AsyncView';
import InterfacePageHelper from '../../helper/InterfacePage';
import { NotificationsActionsProvider } from '../NotificationsActionsProvider';
import { useInjectHTML } from '../../hooks/useInjectHTML';
import userStore from '../../../auth/userStore';
import type { OnActionType } from '../ActionsProvider/context';
import { PageFunctionController } from '../PageFunctionController';
import { InterfaceEntitiesMethodsProvider } from '../InterfaceEntitiesMethodsProvider';
import { ResetBlockRuntimeStateProvider } from '../BlockRuntimeStateProvider';
import { InterfaceDrawerStoreProvider } from '../../stores/InterfaceDrawerStore';
import { DependencyGraphProvider } from '../../context/DependencyGraphContext/DependencyGraphContext';
import useNoCodeDependencyGraphManager from '../../context/DependencyGraphContext/hooks/useNoCodeDependencyGraphManager';
import { RuntimeBlockPreComputationProvider } from '../RuntimeBlockPreComputation';
import type { InterfaceResourceProviderProps } from './types';
import useInterfaceResourceProvider from './useInterfaceResourceProvider';
import { useStoreRefs } from './hooks/useStoreRefs';
import { adaptDataWithTranslations } from './utils/adaptDataWithTranslations';

function InterfaceActionProvider({
  children,
  emitPageEvent,
}: {
  children: React.ReactNode;
  emitPageEvent?: EmitEventType;
}) {
  const { onAction } = useGlobalBlockActions({ emitPageEvent });
  const isInteractiveModeEnabled = useInterfaceStore().use.isInteractiveModeEnabled();
  const previewTriggeredDataSourceIds = useGlobalStateStore().use.previewTriggeredDataSources();

  const isAnyDataSourcePreviewTriggered = useMemo(
    () =>
      Object.keys(previewTriggeredDataSourceIds).some(
        (key) => previewTriggeredDataSourceIds[key] === true,
      ),
    [previewTriggeredDataSourceIds],
  );

  return (
    <ActionsProvider
      isGlobalActionsProvider
      onAction={
        isInteractiveModeEnabled || isAnyDataSourcePreviewTriggered
          ? onAction
          : (_noop as OnActionType)
      }
    >
      {children}
    </ActionsProvider>
  );
}

// We assume the interface is being used in desktop if the device is not provided
function BaseInterfaceResourceProvider(props: InterfaceResourceProviderProps) {
  const {
    page: initialPage,
    interfaceRecord: initialInterfaceRecord,
    commonNamespace,
    interfaceComponentNamespace,
    registry,
    children,
    onOutputChange,
    mode,
    deviceDetails,
    dataSources,
    emitPageEvent,
    onChangeActiveBlockId,
    interfaceResourceProviderRef,
  } = props;

  useTranslation('blocks');
  const page = useMemo(() => {
    if (mode === InterfaceModes.BUILDER) {
      return initialPage;
    }
    const blocks = adaptDataWithTranslations({
      data: initialPage.properties.blocks,
      translations: interfaceComponentNamespace?.properties.translations,
    });

    return {
      ...initialPage,
      properties: { ...initialPage.properties, blocks },
    } as InterfacePageEntity;
  }, [initialPage, interfaceComponentNamespace?.properties.translations, mode]);

  const interfaceRecord = useMemo(() => {
    if (mode === InterfaceModes.BUILDER) {
      return initialInterfaceRecord;
    }

    const properties = adaptDataWithTranslations({
      data: initialInterfaceRecord.properties,
      translations: commonNamespace?.properties.translations,
    });

    return { ...initialInterfaceRecord, properties } as typeof initialInterfaceRecord;
  }, [commonNamespace?.properties.translations, initialInterfaceRecord, mode]);

  const { device } = deviceDetails;
  const { dataSourceRecordStoreInitialValue, finalPageInputs, interfaceStoreInitialValue } =
    useInterfaceResourceProvider({ ...props, interfaceRecord, page });

  const { globalStateStoreRef, interfaceStoreRef, dependencyGraphStoreRef } = useStoreRefs({
    interfaceResourceProviderRef,
  });

  const dependencyManager = useNoCodeDependencyGraphManager();

  const blocks = useMemo(() => {
    return {
      ...InterfacePageHelper.getBaseDeviceBlocks(page),
      ...InterfacePageHelper.getDeviceBlocks(page, device),
    };
  }, [device, page]);

  const getDependencyGraphActions = useCallback(() => {
    if (!dependencyGraphStoreRef.current) {
      throw new Error('Dependency Graph Store Ref is not available');
    }
    return {
      ...dependencyGraphStoreRef.current,
      ...dependencyManager.actions,
    };
  }, [dependencyGraphStoreRef, dependencyManager.actions]);

  return (
    <RegistryProvider registry={registry}>
      <DataSourceRecordStoreProvider initialValue={dataSourceRecordStoreInitialValue}>
        <GlobalStateStoreProvider
          blocks={blocks}
          client={props.client}
          dataSources={dataSources}
          deviceDetails={deviceDetails}
          globalStateStoreRef={globalStateStoreRef}
          interfaceId={interfaceStoreInitialValue.interfaceRecord.id}
          mode={mode}
          pageFunctions={page.properties.pageFunctions}
          pageInputs={finalPageInputs}
          pageVariables={page.properties.pageVariables}
        >
          <InterfaceStoreProvider
            getDependencyGraphActions={getDependencyGraphActions}
            interfaceStoreRef={interfaceStoreRef}
            onChangeActiveBlockId={onChangeActiveBlockId}
            value={interfaceStoreInitialValue}
          >
            <DependencyGraphProvider
              dependencyGraphStoreRef={dependencyGraphStoreRef}
              {...dependencyManager}
            >
              <InterfaceDrawerStoreProvider>
                <ResetBlockRuntimeStateProvider>
                  <InterfaceActionProvider emitPageEvent={emitPageEvent}>
                    <InterfaceEntitiesMethodsProvider>
                      <ComputeContextProvider>
                        <InterfaceSideEffects
                          interfaceId={interfaceStoreInitialValue.interfaceRecord.id}
                          onOutputChange={onOutputChange}
                        >
                          <DataSourceController>
                            <PageFunctionController>
                              <SnackbarProvider>
                                <UppyProvider>
                                  <PreBuiltFlows>
                                    <NotificationsActionsProvider>
                                      <RuntimeBlockPreComputationProvider>
                                        <DrawerProvider>{children}</DrawerProvider>
                                      </RuntimeBlockPreComputationProvider>
                                    </NotificationsActionsProvider>
                                  </PreBuiltFlows>
                                </UppyProvider>
                              </SnackbarProvider>
                            </PageFunctionController>
                          </DataSourceController>
                        </InterfaceSideEffects>
                      </ComputeContextProvider>
                    </InterfaceEntitiesMethodsProvider>
                  </InterfaceActionProvider>
                </ResetBlockRuntimeStateProvider>
              </InterfaceDrawerStoreProvider>
            </DependencyGraphProvider>
          </InterfaceStoreProvider>
        </GlobalStateStoreProvider>
      </DataSourceRecordStoreProvider>
    </RegistryProvider>
  );
}

type ModeInterfaceResourceProviderProps = WithPartial<
  InterfaceResourceProviderProps,
  'deviceDetails'
> & {
  disableInteractiveMode?: boolean;
};

function RunnerInterfaceResourceProvider(props: ModeInterfaceResourceProviderProps) {
  const { screen } = useScreen();
  const deviceDetailsData = useMemo(() => ({ device: screen }), [screen]);

  return (
    <BaseInterfaceResourceProvider
      {...props}
      deviceDetails={deviceDetailsData}
      isInteractiveModeEnabled
    />
  );
}

function BuilderAndPreviewInterfaceResourceProvider(props: ModeInterfaceResourceProviderProps) {
  const { interfaceRecord, mode, disableInteractiveMode } = props;
  const currentUserDetails = userStore.use.currentUserDetails();
  const [initialActiveBlockId, setBlockIdInParams] = useQueryState<BlockId>(
    'blockId',
    parseAsString,
  );

  const { deviceDetails, loading: deviceLoading } = useGetDeviceDetailsFromLocalStorage({
    interfaceRecordId: interfaceRecord.id,
    baseDevice: getBaseDeviceOfThePage(interfaceRecord),
    enabled: !props.deviceDetails,
  });

  // In runner mode (matrix) we inject custom code in root layout directly
  useInjectHTML(interfaceRecord.properties.customCode?.header, currentUserDetails, 'head');
  useInjectHTML(interfaceRecord.properties.customCode?.footer, currentUserDetails, 'body');

  const isInteractiveModeEnabled = useMemo(() => {
    // If interactive mode is disabled, we should not enable it in preview mode
    //this is done for /overview path
    if (disableInteractiveMode) {
      return false;
    }
    return mode === InterfaceModes.PREVIEW;
  }, [disableInteractiveMode, mode]);

  const onChangeActiveBlockId = useCallback(
    (blockId: string) => {
      void setBlockIdInParams(blockId);
    },
    [setBlockIdInParams],
  );

  const renderData = useCallback(
    (_deviceDetails: DeviceDetailsType) => (
      <BaseInterfaceResourceProvider
        {...props}
        deviceDetails={_deviceDetails}
        initialActiveBlockId={initialActiveBlockId}
        isInteractiveModeEnabled={isInteractiveModeEnabled}
        onChangeActiveBlockId={onChangeActiveBlockId}
      />
    ),
    [initialActiveBlockId, isInteractiveModeEnabled, onChangeActiveBlockId, props],
  );

  return (
    <AsyncView
      data={props.deviceDetails || deviceDetails}
      isLoading={deviceLoading}
      renderData={renderData}
      withSuspense={false}
    />
  );
}

export function InterfaceResourceProvider(
  props: WithPartial<InterfaceResourceProviderProps, 'mode' | 'deviceDetails' | 'client'> & {
    disableInteractiveMode?: boolean;
  },
) {
  const {
    page,
    mode = InterfaceModes.RUNNER,
    client = mode === InterfaceModes.RUNNER
      ? InterfaceClient.UA_MATRIX
      : InterfaceClient.UA_PLATFORM,
  } = props;
  const currentUserDetails = userStore.use.currentUserDetails();

  useInjectHTML(page.properties.customCode?.header, currentUserDetails, 'head');
  useInjectHTML(page.properties.customCode?.footer, currentUserDetails, 'body');

  return mode === InterfaceModes.RUNNER ? (
    <RunnerInterfaceResourceProvider {...props} client={client} mode={mode} />
  ) : (
    <BuilderAndPreviewInterfaceResourceProvider {...props} client={client} mode={mode} />
  );
}

export default InterfaceResourceProvider;
