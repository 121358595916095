import { useCallback } from 'react';
import { useRouter } from '../../../useRouter';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

/**
 * Matches a path with optional query params
 * ex. `example`, `path/to`, `path/to/`, `path/to/something-with-dashes/`,
 * `path/to/something-with-dashes?query=another-thing`, `path/to/something-with-dashes?query=another&foo=bar`
 *
 * Does not match urls
 * ex. `example.com`, `google.com/something`,
 */
// eslint-disable-next-line prefer-named-capture-group -- its okay
export const PATH_REGEX = /^([\w-]+\/)*[\w-]+\/?(\?[\w&=-]*)?$/;

/**
 * 1. if the url begins with `http://` or `https://` or `//`, url is complete, do nothing
 * 2. url begins with `../` or `./` or `/`, url is just path, do nothing
 * 3. if the url is a path, treat as relative path and do nothing
 * 4. otherwise append `//` to the url,
 */
export function adaptComputedPath(path: string | undefined) {
  if (!path) return path;

  if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('//')) {
    return path;
  }

  if (path.startsWith('../') || path.startsWith('./') || path.startsWith('/')) {
    return path;
  }

  if (PATH_REGEX.test(path)) {
    return path;
  }

  return `//${path}`;
}

function getComputedPathAndSearchParams(path: string) {
  const index = path.includes('?') ? path.indexOf('?') : path.length;
  const urlSearch = path.slice(index);

  const searchParams = new URLSearchParams(urlSearch);
  const computedPath = path.slice(0, index);

  return { computedPath, searchParams };
}

export default function useNavigate() {
  const router = useRouter();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const navigate: ActionHandlerType = useCallback(
    ({ action, actionContext, domEvent }: OnActionArgs) => {
      const path = action.payload?.path as string | undefined;
      const { path: computedPath } = getNoCodeComputedData<{
        path: string | undefined;
      }>(
        {
          path,
        },
        actionContext,
      );

      const adaptedPath = adaptComputedPath(computedPath?.trim());

      if (adaptedPath) {
        const { computedPath: computedPathWithoutSearchParams, searchParams } =
          getComputedPathAndSearchParams(adaptedPath);

        const windowSearchParams = new URLSearchParams(
          action.payload?.preserveSearchParams ? window.location.search : '',
        );

        const finalSearchParams = new URLSearchParams({
          ...Object.fromEntries(windowSearchParams),
          ...Object.fromEntries(searchParams),
        }).toString();

        const finalPath =
          finalSearchParams.length > 0 // final search params if empty, meaning no search params from either window or action
            ? `${computedPathWithoutSearchParams}?${finalSearchParams}`
            : computedPathWithoutSearchParams;

        if (action.payload?.target === '_blank') {
          window.open(finalPath, '_blank', 'noreferrer noopener');
        } else if (action.payload?.history === 'replace') {
          domEvent?.stopPropagation();
          router.replace(finalPath, true);
        } else {
          domEvent?.stopPropagation();
          router.push(finalPath, true);
        }
      }
      return Promise.resolve();
    },
    [getNoCodeComputedData, router],
  );

  const back: ActionHandlerType = useCallback(() => {
    router.back();
    return Promise.resolve();
  }, [router]);

  return { navigate, back };
}
