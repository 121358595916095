import { useCallback } from 'react';
import { useSessionStorage as useReactUseSessionStorage } from 'react-use';

type SetValue<T> = (value: T) => Promise<void>;
type GetValue<T> = () => Promise<T | undefined>;

//global-object for iframe and session storage disabled use case
const sessionStorageInMemoryMap = new Map();

//this is made asynchronous because in future this will be replaced with
//user preference service, so for easy transition we are making it async
//and not directly using react-use useSessionStorage
export const useSessionStorage = <T>(key: string, initialValue?: T): [SetValue<T>, GetValue<T>] => {
  const [_value, _setValue] = useReactUseSessionStorage(key, initialValue);

  const setValue = useCallback(
    async (value: T): Promise<void> => {
      await Promise.resolve();
      _setValue(value);
      sessionStorageInMemoryMap.set(key, value);
    },
    [_setValue, key],
  );

  const getValue = useCallback(async () => {
    await Promise.resolve();
    return _value ?? (sessionStorageInMemoryMap.get(key) as T);
  }, [_value, key]);

  return [setValue, getValue];
};
