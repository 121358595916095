import { isBoolean, isEqual, isNumber, isObject, toString } from 'lodash';
import isNil from 'lodash/isNil';
import type { Value } from './Value';
import { DataType } from './Op';

export class ComparableValue<T> implements Value {
  private readonly value: T;
  private readonly dataType: DataType;

  constructor(value: T, dataType: DataType) {
    this.value = value;
    this.dataType = dataType;
  }

  equals(obj: ComparableValue<T>): boolean {
    if (obj instanceof ComparableValue) {
      const other = obj;
      if (other.dataType !== this.dataType) {
        return false;
      }
      return this.equality(other.value);
    }
    return this.equality(obj);
  }

  compareValues({
    contextValue1,
    contextValue2,
  }: {
    contextValue1: unknown;
    contextValue2: unknown;
  }): boolean {
    try {
      switch (this.dataType) {
        case DataType.STRING: {
          if (typeof contextValue1 !== 'string' || typeof contextValue2 !== 'string') {
            return false;
          }
          return contextValue1 === contextValue2;
        }
        case DataType.BOOLEAN:
          return contextValue1 === contextValue2;
        case DataType.NUMBER:
          return contextValue1 === contextValue2;
        case DataType.OBJECT:
          return isEqual(contextValue1, contextValue2);
        case DataType.ANY:
          return contextValue1 === contextValue2;
        default:
          return false;
      }
    } catch (e) {
      return false;
    }
  }

  equality(value: unknown): boolean {
    return this.compareValues({
      contextValue1: this.value,
      contextValue2: value,
    });
  }

  lessThan(obj: ComparableValue<T>, equal: boolean): boolean {
    const num1 = Number(this.value);
    const num2 = Number(obj.value);
    if (Number.isNaN(num1) || Number.isNaN(num2)) {
      return false;
    }
    if (equal) {
      return num1 <= num2;
    }
    return num1 < num2;
  }

  greaterThan(obj: ComparableValue<T>, equal: boolean): boolean {
    const num1 = Number(this.value);
    const num2 = Number(obj.value);
    if (Number.isNaN(num1) || Number.isNaN(num2)) {
      return false;
    }
    if (equal) {
      return num1 >= num2;
    }
    return num1 > num2;
  }

  minLength(obj: ComparableValue<T>): boolean {
    const num = Number(obj.value);
    if (typeof this.value !== 'string' || Number.isNaN(num)) {
      return false;
    }

    return this.value.length >= num;
  }

  maxLength(obj: ComparableValue<T>): boolean {
    const num = Number(obj.value);
    if (typeof this.value !== 'string' || Number.isNaN(num)) {
      return false;
    }

    return this.value.length <= num;
  }

  matchedRegex(obj: ComparableValue<T>, flag: string): boolean {
    if (typeof this.value === 'number' && typeof obj.value === 'string') {
      return new RegExp(obj.value, flag).test(toString(this.value));
    } else if (typeof this.value !== 'string' || typeof obj.value !== 'string') {
      return false;
    }
    return new RegExp(obj.value, flag).test(this.value);
  }

  isBefore: (obj: ComparableValue<T>, on: boolean) => boolean = (obj, on) => {
    const date1 = new Date(this.toSafeNumberOrString(this.value));
    const date2 = new Date(this.toSafeNumberOrString(obj.value));
    if (Number.isNaN(date1.getTime()) || Number.isNaN(date2.getTime())) {
      return false;
    }
    if (on) {
      return date1 <= date2;
    }
    return date1 < date2;
  };

  isAfter: (obj: ComparableValue<T>, on: boolean) => boolean = (obj, on) => {
    const date1 = new Date(this.toSafeNumberOrString(this.value));
    const date2 = new Date(this.toSafeNumberOrString(obj.value));

    if (Number.isNaN(date1.getTime()) || Number.isNaN(date2.getTime())) {
      return false;
    }

    if (on) {
      return date1 >= date2;
    }
    return date1 > date2;
  };

  isBeforeTime: (obj: ComparableValue<T>, on: boolean) => boolean = (obj, on) => {
    if (typeof this.value !== 'string' || typeof obj.value !== 'string') {
      return false;
    }

    if (on) {
      return obj.value >= this.value;
    }
    return obj.value > this.value;
  };

  isAfterTime: (obj: ComparableValue<T>, on: boolean) => boolean = (obj, on) => {
    if (typeof this.value !== 'string' || typeof obj.value !== 'string') {
      return false;
    }

    if (on) {
      return this.value >= obj.value;
    }
    return this.value > obj.value;
  };

  convertValueToType(value: unknown, datatype: string): T {
    switch (datatype) {
      case 'string':
        return (isNil(value) ? '' : String(value)) as unknown as T;

      case 'number':
        return isNumber(value) ? (value as T) : ((Number(value) || 0) as unknown as T);

      case 'boolean':
        if (isBoolean(value)) {
          return value as T;
        }
        // Converts typical truthy/falsy values to boolean
        return (value === 'true' || value === '1' || Boolean(value)) as unknown as T;

      case 'object':
        try {
          return isObject(value) ? (value as T) : (JSON.parse(String(value)) as T);
        } catch (e) {
          return {} as T; // Fallback to empty object on parse failure
        }

      default:
        return value as T;
    }
  }

  in: (obj: ComparableValue<T>) => boolean = (obj) => {
    if (Array.isArray(obj.value)) {
      let isPresent = false;
      for (const value of obj.value) {
        const typeOfValue = typeof value;
        const convertedValue = this.convertValueToType(this.value, typeOfValue);
        if (
          this.compareValues({
            contextValue1: convertedValue,
            contextValue2: value,
          })
        ) {
          isPresent = true;
          break;
        }
      }
      return isPresent;
    }
    return false;
  };

  toString(): string {
    return toString(this.value);
  }

  // Date can be represented as string or number, like '2021-01-01' or 1609459200000, but to generate date from timestamp, it has to be a number NOT STRING, so we need to convert the string to number
  toSafeNumberOrString(value: unknown): number | string {
    const parsedNumber = Number(value);
    if (!Number.isNaN(parsedNumber)) {
      return parsedNumber;
    }
    return toString(value);
  }

  getValue(): T {
    return this.value;
  }
}
