import { useMountEffect } from '@react-hookz/web';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import useBlockEvents from '../../../hooks/useBlockEvents';
import { useInterfaceStore } from '../../../stores/InterfaceStore';

export function usePageEventEffects() {
  const interactions = useInterfaceStore().use.page.interactions();
  const { emitEvent } = useBlockEvents(interactions?.events ?? []);

  useMountEffect(() => {
    void emitEvent({ eventType: EventTypeEnum.OnPageLoad });
  });
}
