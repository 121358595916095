import { getSelectedDataSourcePath } from '../../../utils/getSelectedDataSourcePath';
import { ENTITY_DATA_SOURCE_PREFIX, ENTITY_DATA_SOURCE_SEPARATOR } from '../../const';

class EntityDataSourceBuilder {
  private prefix = ENTITY_DATA_SOURCE_PREFIX;
  private separator = ENTITY_DATA_SOURCE_SEPARATOR;
  private blockId?: string;
  private entityType?: string;
  private dataSourceId?: string;

  setBlockId(blockId: string): this {
    this.blockId = blockId;
    return this;
  }

  setEntityType(entityType: string): this {
    this.entityType = entityType;
    return this;
  }

  setDataSourceId(dataSourceId: string): this {
    this.dataSourceId = dataSourceId;
    return this;
  }

  buildExpression(): string {
    return `{{ ${this.prefix}${this.separator}${this.blockId}${this.separator}${this.entityType}.data.objects }}`;
  }

  buildId(): string {
    return `${this.prefix}${this.separator}${this.blockId}${this.separator}${this.entityType}`;
  }

  isEntityType(): boolean {
    return this.dataSourceId?.startsWith(`${this.prefix}${this.separator}`) ?? false;
  }

  buildTotalCountExpression(): string | undefined {
    if (this.dataSourceId && this.isEntityType()) {
      return `{{ ${this.dataSourceId}.data.total }}`;
    }
    return undefined;
  }

  extractEntityType(): string | undefined {
    return this.dataSourceId?.split(this.separator)[2];
  }
}

// Functions to generate the required strings and check data source types

export const getIsDataSourceAnEntityType = (dataSourceId: string): boolean => {
  return new EntityDataSourceBuilder().setDataSourceId(dataSourceId).isEntityType();
};

export const getIsDataSourcePathAnEntityType = (data?: string): boolean => {
  const { dataSourceId } = getSelectedDataSourcePath({ data });
  return dataSourceId ? getIsDataSourceAnEntityType(dataSourceId) : false;
};

export const getEntityDataSourceExpression = (blockId: string, entityType: string): string => {
  return new EntityDataSourceBuilder()
    .setBlockId(blockId)
    .setEntityType(entityType)
    .buildExpression();
};

export const getEntityDataSourceId = (blockId: string, entityType: string): string => {
  return new EntityDataSourceBuilder().setBlockId(blockId).setEntityType(entityType).buildId();
};

export const getEntityDataSourceTotalCountExpression = (
  dataSourceId?: string,
): string | undefined => {
  return new EntityDataSourceBuilder()
    .setDataSourceId(dataSourceId || '')
    .buildTotalCountExpression();
};

export const getEntityTypeFromDataSourceId = (dataSourceId: string): string | undefined => {
  return new EntityDataSourceBuilder().setDataSourceId(dataSourceId).extractEntityType();
};
