import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

function useSetInterfaceSessionStorage() {
  const { setInterfaceSessionStorage } = useGlobalStateStore().use.actions();

  const interfaceId = useInterfaceStore().use.record.id();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const onSetInterfaceSessionStorage: ActionHandlerType = ({
    action,
    actionContext,
  }: OnActionArgs) => {
    const { payload } = action;

    const { value: computedPayload } = getNoCodeComputedData<{
      value: unknown;
    }>(
      {
        value: payload,
      },
      actionContext,
    );

    const { path, value, operation } = computedPayload as {
      path: string;
      value: unknown;
      operation: 'SET_IN';
    };

    setInterfaceSessionStorage({
      operation,
      payload: {
        interfaceId,
        path,
        value,
      },
    });

    return Promise.resolve();
  };

  return { onSetInterfaceSessionStorage };
}

export default useSetInterfaceSessionStorage;
