import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeScanner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 38"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path fill="#F4F5F7" d="M0 0h40v38H0z" />
    <path fill="#fff" d="M-65-4241h1221V132H-65z" />
    <rect width={145} height={4150} x={-19.5} y={-4091.5} strokeDasharray="10 5" rx={4.5} />
    <g
      style={{
        mixBlendMode: 'luminosity',
      }}
    >
      <path
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M25.914 1.602h6.524a5.075 5.075 0 0 1 5.075 5.074v6.525m0 11.598v6.525a5.075 5.075 0 0 1-5.075 5.074h-6.524M2.716 13.2V6.676A5.075 5.075 0 0 1 7.79 1.602h6.525m0 34.796H7.79a5.075 5.075 0 0 1-5.074-5.074v-6.525"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 19.457v-6.184m4.935 0h.012m-4.96-4.948H20m11.132 4.948h-1.856m1.856-4.948h-6.185m-11.873 0h-2.227c-.692 0-1.039 0-1.303.135a1.24 1.24 0 0 0-.54.54c-.136.265-.136.612-.136 1.304v2.227c0 .692 0 1.039.135 1.303.119.233.308.422.54.54.265.136.612.136 1.304.136h2.227c.692 0 1.039 0 1.303-.135.233-.119.422-.308.54-.54.136-.265.136-.612.136-1.304v-2.227c0-.692 0-1.039-.135-1.303a1.24 1.24 0 0 0-.54-.54c-.265-.136-.612-.136-1.304-.136"
      />
      <rect
        width={25.038}
        height={12.712}
        x={32.519}
        y={30.77}
        fill="#B7B4FE"
        rx={2}
        transform="rotate(-180 32.52 30.77)"
      />
      <path strokeLinecap="round" d="m38.813 18.839-37.625.074" />
    </g>
  </svg>
);
export default SvgNoCodeScanner;
