import type { DependsOnType } from '@unifyapps/defs/types/page';
import type { BuildDependencyGraphItem } from '../utils/buildDependencyGraph';
import type { EntityDependencyNode, EntityId } from './types';

export class DependencyGraphManager {
  // a node corresponds to an entity, and it stores the dependencies of that entity
  private nodes = new Map<EntityId, EntityDependencyNode>();
  private cache = new Map<EntityId, Set<EntityId>>();

  /**
   * Add a node to the graph if it doesn't already exist.
   * @param id - The unique identifier of the node to add.
   */
  private invalidateCache(id: EntityId): void {
    this.cache.delete(id);
  }

  createNode(id: EntityId): void {
    if (!this.nodes.has(id)) {
      this.nodes.set(id, {
        _affectedBy: new Set(),
        _impacts: new Set(),
        dependsOn: [],
      });
      this.invalidateCache(id);
    }
  }

  /**
   * Add multiple nodes to the graph in bulk.
   * @param ids - An array of node identifiers to add.
   */
  createNodes(ids: EntityId[]): void {
    ids.forEach((id) => {
      this.createNode(id);
    });
  }

  /**
   * Add a dependency relationship between two nodes.
   * @param nodeId - The node that depends on another node.
   * @param dependsOnId - The node being depended upon.
   */
  private addDependency(nodeId: EntityId, dependsOnId: EntityId): void {
    if (nodeId === dependsOnId) {
      throw new Error('A node cannot depend on itself');
    }

    const node = this.nodes.get(nodeId);
    let dependsOnNode = this.nodes.get(dependsOnId);

    if (!node) {
      throw new Error(
        `Dependent node not found, before adding any dependency, create the dependent node ${nodeId}`,
      );
    }

    if (!dependsOnNode) {
      this.createNode(dependsOnId);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- creating on a line above
      dependsOnNode = this.nodes.get(dependsOnId)!;
    }

    node._affectedBy.add(dependsOnId);
    dependsOnNode._impacts.add(nodeId);

    // Invalidate cache for both nodes and their dependents
    this.invalidateCache(nodeId);
    this.invalidateCache(dependsOnId);
  }

  /**
   * Update the items of a node's dependency.
   * @param id - The identifier of the node to update.
   * @param dependsOn - The dependency items to update.
   */
  updateNode(id: EntityId, dependsOn?: DependsOnType[]): void {
    let node = this.nodes.get(id);

    if (!dependsOn) {
      return;
    }

    // it is mandatory to have a node here
    if (!node) {
      this.createNode(id);
    }

    node = this.nodes.get(id);

    if (!node) {
      throw new Error('Node not found');
    }

    // remove myself from all other nodes where I am present as _impacts, because dependsOn will make new impacts entries
    node._affectedBy.forEach((dependsOnItem) => {
      const dependsOnNode = this.nodes.get(dependsOnItem);
      if (dependsOnNode) {
        dependsOnNode._impacts.delete(id);
      }
    });

    node._affectedBy.clear();

    dependsOn.forEach((dependsOnItem) => {
      this.addDependency(id, dependsOnItem.id);
    });

    node.dependsOn = dependsOn;

    this.invalidateCache(id);
  }

  /**
   * Update dependency items for multiple nodes.
   * @param items - A record of node IDs and their corresponding dependency items.
   */
  updateNodes(items: Record<EntityId, BuildDependencyGraphItem<unknown> | undefined>): void {
    for (const [id, item] of Object.entries(items)) {
      if (!item?.dependsOn) {
        console.error('No dependsOn found for id', id);
        continue;
      }
      this.updateNode(id, item.dependsOn);
    }
  }

  /**
   * Get all impacted nodes when a node changes, including transitive dependencies.
   * Results are cached for improved performance.
   * @param changedId - The identifier of the node that has changed.
   * @returns A set of all impacted node identifiers.
   */
  getImpactedNodes(changedId: EntityId): Set<EntityId> {
    // Check cache first
    const cachedResult = this.cache.get(changedId);
    if (cachedResult) {
      return cachedResult;
    }

    // If not in cache, compute the result
    const affected = new Set<EntityId>();
    const queue: EntityId[] = [changedId];

    while (queue.length > 0) {
      const currentId = queue.shift();
      if (!currentId) continue;
      const node = this.nodes.get(currentId);

      if (!node) continue;
      affected.add(currentId);

      // Add all the impacts of the current node to the queue
      node._impacts.forEach((dependentId) => {
        if (!affected.has(dependentId)) {
          queue.push(dependentId);
        }
      });
    }

    // Cache the result before returning
    this.cache.set(changedId, affected);
    return affected;
  }

  /**
   * Delete a node from the graph and update dependencies.
   * @param id - The identifier of the node to delete.
   */
  deleteNode(id: EntityId): void {
    const node = this.nodes.get(id);
    if (!node) return;

    // Invalidate cache for the node and all its relationships
    this.invalidateCache(id);
    node._affectedBy.forEach((dependencyId) => {
      const dependency = this.nodes.get(dependencyId);
      if (dependency) {
        dependency._impacts.delete(id);
        this.invalidateCache(dependencyId);
      }
    });
    node._impacts.forEach((dependentId) => {
      const dependent = this.nodes.get(dependentId);
      if (dependent) {
        dependent._affectedBy.delete(id);
        this.invalidateCache(dependentId);
      }
    });
    this.nodes.delete(id);
  }

  /**
   * Retrieve a node from the graph.
   * @param id - The identifier of the node to retrieve.
   * @returns The node if found, otherwise null.
   */
  getNode(id?: EntityId | null): EntityDependencyNode | null {
    if (!id) return null;
    return this.nodes.get(id) || null;
  }

  /**
   * Reset the graph by clearing all nodes.
   * This should be called when the graph is no longer needed.
   */
  reset(): void {
    this.nodes.clear();
    this.cache.clear();
  }
}
