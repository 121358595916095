import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import { Op } from '@unifyapps/network/generated/models/op';
import {
  useSearchDeployedEntities,
  prefetchSearchDeployedEntities,
} from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import type { QueryClient } from '@unifyapps/network/react-query';
import DataSourceHelper from '../../../../../helper/DataSourceHelper';

export const getDataSourceFilters = (interfaceComponentId: string) => {
  return {
    filter: {
      field: 'properties.interfacePageId',
      op: Op.EQUAL,
      values: [interfaceComponentId],
    },
    page: {
      limit: 100,
      offset: 0,
    },
  };
};

function useFetchDeployedDataSourcesByComponentId(interfaceComponentId: string) {
  const {
    data: pageDataSources,
    isLoading: isLoadingDS,
    error: errorDS,
  } = useSearchDeployedEntities(
    DataSourceHelper.entity,
    getDataSourceFilters(interfaceComponentId),
    {
      query: {
        enabled: Boolean(interfaceComponentId),
        retry: 3,
      },
    },
  );

  const dataSources = pageDataSources?.objects?.reduce((acc, v) => {
    if (!v.id) return acc;
    acc[v.id] = v as DataSourceEntity;
    return acc;
  }, {}) as Record<string, DataSourceEntity | undefined>;

  return {
    dataSources,
    isLoadingDS,
    errorDS,
  };
}

export async function prefetchDeployedDataSourcesByComponentId({
  interfaceComponentId,
  queryClient,
}: {
  interfaceComponentId: string;
  queryClient: QueryClient;
}) {
  await prefetchSearchDeployedEntities(
    queryClient,
    DataSourceHelper.entity,
    getDataSourceFilters(interfaceComponentId),
    {
      query: {
        enabled: Boolean(interfaceComponentId),
      },
    },
  );

  return queryClient;
}

export default useFetchDeployedDataSourcesByComponentId;
