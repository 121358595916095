import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { FocusEventHandler } from 'react';
import { useState } from 'react';

export const useMessageInputFocusEvents = ({
  input,
  onFocus,
}: {
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  input?: string;
}) => {
  const [showMinimalisticState, setShowMinimalisticState] = useState(() => !input);

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = useEventCallback((event) => {
    setShowMinimalisticState(false);
    onFocus?.(event);
  });

  const onClickAway = useEventCallback(({ hasAttachments }: { hasAttachments?: boolean }) => {
    if (!input && !hasAttachments) {
      setShowMinimalisticState(true);
    }
  });

  return {
    handleFocus,
    onClickAway,
    showMinimalisticState,
  };
};
