import { Box } from '@unifyapps/ui/components/Box';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';
import { cva } from 'class-variance-authority';
import { useTranslation } from '@unifyapps/i18n/client';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { Loader } from '@unifyapps/ui/components/Loader';
import type { MediaItemProps, SlotPropsType } from '../types';
import { MediaItem } from './MediaItem';
import './style.css';
import { getMediaItem } from './CompactMediaItem';
import { DeleteMediaItemButton } from './DeleteMediaItemButton';

const iconVariants = cva('', {
  variants: {
    size: {
      sm: 'size-6',
      md: 'size-10',
      lg: 'size-16',
    },
  },
});

const typographyVariants = cva('', {
  variants: {
    size: {
      sm: 'text-xxs',
      md: 'text-xs',
      lg: 'text-xs',
    },
  },
});

const captionVariants = cva('flex items-center border-secondary border-t flex-shrink-0', {
  variants: {
    size: {
      sm: 'p-xs h-4',
      md: 'p-md h-6',
      lg: 'p-md h-8',
    },
  },
});

const sizeVariants = cva('', {
  variants: {
    size: {
      sm: 'size-[5.5rem]',
      md: 'h-[120px] w-[120px]',
      lg: 'h-[174px] w-[174px]',
    },
  },
});

const imgClassNameVariants = cva('', {
  variants: {
    size: {
      sm: 'size-full',
      md: 'size-full',
      lg: 'size-full',
    },
    showCaption: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      showCaption: true,
      className: 'flex-1 max-h-12',
    },
    {
      size: 'md',
      showCaption: true,
      className: 'flex-1 max-h-24',
    },
    {
      size: 'lg',
      showCaption: true,
      className: 'flex-1 max-h-[142px]',
    },
  ],
});

export function SpaciousMediaItem({
  item,
  className,
  onClick,
  size,
  showCaption: _showCaption,
  onRemoveItem,
  overrides,
  slotProps,
}: MediaItemProps & { slotProps?: SlotPropsType }) {
  const { t } = useTranslation('common');
  const { isLoading, name, caption, id } = item;

  const iconClassName = clsx(iconVariants({ size }), slotProps?.icon?.className);
  const variant = typographyVariants({ size }) as TypographyVariants;

  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = useEventCallback((event) => {
    event.stopPropagation();
    onRemoveItem?.(id);
  });

  const containerClassName = clsx(sizeVariants({ size }), className);

  const OverriddenMediaItem = getMediaItem(item, overrides);
  if (OverriddenMediaItem) {
    return (
      <OverriddenMediaItem
        className={containerClassName}
        item={item}
        onClick={onClick}
        onRemoveItem={onRemoveItem}
        size={size}
      />
    );
  }
  const showCaption = _showCaption || item.showCaption;

  return (
    <Tooltip
      arrow={false}
      disabled={slotProps?.tooltip?.disabled ?? showCaption}
      offset={4}
      title={name || t('common:Attachment')}
    >
      <Stack
        className={clsx(
          'bg-primary border-secondary group/media-item relative rounded-sm border',
          { 'hover:bg-primary-hover cursor-pointer': !isLoading },
          containerClassName,
        )}
        onClick={isLoading ? undefined : onClick}
      >
        <Box
          className={clsx(
            'flex items-center justify-center',
            imgClassNameVariants({ size, showCaption }),
          )}
        >
          {isLoading ? (
            <Loader size="xs" variant="circle" />
          ) : (
            <MediaItem
              iconClassName={iconClassName}
              imgClassName={clsx(
                showCaption ? 'rounded-t-sm' : 'rounded-sm',
                'size-full',
                slotProps?.image?.className,
              )}
              item={item}
            />
          )}
        </Box>
        {showCaption ? (
          <Box className={captionVariants({ size })}>
            <Typography maxNumberOfLines={1} variant={variant}>
              {caption ?? name ?? t('common:Attachment')}
            </Typography>
          </Box>
        ) : null}
        <DeleteMediaItemButton handleDelete={onRemoveItem ? handleDelete : undefined} />
      </Stack>
    </Tooltip>
  );
}
