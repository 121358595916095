import { lazy } from 'react';
import pick from 'lodash/pick';
import type { BlockStateUnionType, BlockType } from '@unifyapps/defs/types/block';
import type {
  OrganizationChartBlockRef,
  OrganizationChartBlockStateType,
} from '@unifyapps/defs/blocks/OrganizationChart/types';
import type {
  BlockStateDefinition,
  ComponentType,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { Action } from '@unifyapps/defs/types/action';

const importOrganizationChartComponent = () =>
  import(
    /* webpackChunkName: "organization-chart-block" */
    './OrganizationChart'
  ) as Promise<{ default: ComponentType }>;

const OrganizationChart = lazy(importOrganizationChartComponent) as ComponentType;

class OrganizationChartBlockStateDefinition implements BlockStateDefinition {
  get type(): 'OrganizationChart' {
    return 'OrganizationChart';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'highlightNode',
        label: 'Highlight Node',
        method: (_: BlockStateUnionType, action: Action, blockRef?: OrganizationChartBlockRef) => {
          const payload = action.payload as { methodPayload: { nodeId?: string } };

          if (!blockRef?.highlightNode) {
            console.error('No highlightNode method found on blockRef', blockRef);
          }

          blockRef?.highlightNode?.(payload.methodPayload.nodeId);
          return Promise.resolve();
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType<OrganizationChartBlockStateType>) =>
      ({
        id: block.id,
        ...pick(block.component, ['content', 'appearance', 'componentType']),
      }) as OrganizationChartBlockStateType;
  }

  getComponent() {
    return OrganizationChart;
  }

  preload() {
    return {
      blockPreload: importOrganizationChartComponent,
    };
  }
}

export default OrganizationChartBlockStateDefinition;
