import Sheet, { type SheetProps } from '@unifyapps/ui/_components/Sheet';
import { clsx } from 'clsx';
import useForkRef from '@unifyapps/ui/hooks/useForkRef';
import { type MRT_RowData, type MRT_TableInstance } from '../../types';
import { parseFromValuesOrFunc } from '../../utils/utils';
import { TableContainer } from './TableContainer';

interface TableSheetProps<TData extends MRT_RowData> extends SheetProps {
  sheetProps?: Record<string, unknown>;
  table: MRT_TableInstance<TData>;
}

function TableSheet<TData extends MRT_RowData>({
  table,
  sheetProps,
  className,
  ...rest
}: TableSheetProps<TData>) {
  const {
    getState,
    options: {
      enableBottomToolbar,
      enableTopToolbar,
      muiTablePaperProps,
      renderBottomToolbar,
      renderTopToolbar,
      tableStateStore,
      renderTableSheetOverlay,
    },
    refs: { tablePaperRef },
  } = table;
  const { isFullScreen } = getState();

  const paperProps = {
    ...parseFromValuesOrFunc(muiTablePaperProps, { table }),
    ...rest,
  };

  const ref = useForkRef(paperProps.ref, tablePaperRef);
  const isBulkSelectionActive = tableStateStore?.use.isBulkSelectionActive();

  return (
    <Sheet
      // elevation={2}
      //{...paperProps}
      className={clsx('bg-primary text-tertiary w-full rounded-lg text-sm', className, {
        'max-w-screen z-1000 fixed bottom-0 end-0 start-0 top-0 m-0 h-screen max-h-screen w-screen p-0':
          isFullScreen,
        'h-full': !isFullScreen,
        'select-none': isBulkSelectionActive,
      })}
      ref={ref}
      {...sheetProps}
      // style={{
      //   ...(isFullScreen
      //     ? {
      //         bottom: 0,
      //         height: '100dvh',
      //         left: 0,
      //         margin: 0,
      //         maxHeight: '100dvh',
      //         maxWidth: '100dvw',
      //         padding: 0,
      //         position: 'fixed',
      //         right: 0,
      //         top: 0,
      //         width: '100dvw',
      //         zIndex: 999,
      //       }
      //     : {}),
      // ...paperProps.style,
      // }}
      // sx={(theme) => ({
      //   backgroundColor: baseBackgroundColor,
      //   backgroundImage: 'unset',
      //   overflow: 'hidden',
      //   transition: 'all 100ms ease-in-out',
      //   ...parseFromValuesOrFunc(paperProps.sx, theme),
      // })}
    >
      {enableTopToolbar ? parseFromValuesOrFunc(renderTopToolbar, { table }) : null}
      <TableContainer table={table} />
      {enableBottomToolbar ? parseFromValuesOrFunc(renderBottomToolbar, { table }) : null}
      {renderTableSheetOverlay?.({ table })}
    </Sheet>
  );
}

export { TableSheet };
