import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { Op } from '@unifyapps/network/generated/models/op';
import InterfaceHelper from '../../../../../stores/InterfaceStore/helper/InterfaceHelper';

interface CommonNamespaceParams {
  interfaceId: string;
  locale: string;
}

export const getInterfaceCommonNamespaceQueryFilters = (params: CommonNamespaceParams) => {
  const { interfaceId, locale } = params;

  return {
    filter: {
      op: Op.AND,
      values: [
        { field: 'properties.interfaceId', op: Op.EQUAL, values: [interfaceId] },
        { field: 'properties.interfaceComponentId', op: Op.MISSING },
        { field: 'properties.locale', op: Op.EQUAL, values: [locale] },
      ],
    },
    page: { limit: 100, offset: 0 },
  };
};

interface ComponentNamespaceParams extends CommonNamespaceParams {
  interfaceComponentId: string;
}

export const getInterfaceComponentNamespaceQueryFilters = (params: ComponentNamespaceParams) => {
  const { interfaceId, interfaceComponentId, locale } = params;

  return {
    filter: {
      op: Op.AND,
      values: [
        { field: 'properties.interfaceId', op: Op.EQUAL, values: [interfaceId] },
        { field: 'properties.interfaceComponentId', op: Op.EQUAL, values: [interfaceComponentId] },
        { field: 'properties.locale', op: Op.EQUAL, values: [locale] },
      ],
    },
    page: { limit: 100, offset: 0 },
  };
};

export const getInterfaceNamespaceQueryFilters = (params: ComponentNamespaceParams) => {
  if (params.interfaceComponentId === 'common') {
    return getInterfaceCommonNamespaceQueryFilters(params);
  }
  return getInterfaceComponentNamespaceQueryFilters(params);
};

// fetch namespace only if interfaceDefaultLocale is available and different from current locale
export const getShouldFetchNamespace = (
  userLocale: string,
  interfaceRecord: InterfaceEntity | undefined,
) => {
  const interfaceDefaultLocale = InterfaceHelper.getDefaultLocale(interfaceRecord);
  return Boolean(interfaceDefaultLocale && interfaceDefaultLocale !== userLocale);
};
