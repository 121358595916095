import { useEffect } from 'react';
import { usePrevious } from '@react-hookz/web';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore';
import { SessionStorageKeys } from '../../../../consts/sessionStorageKeys';
import { useSessionStorageEntry } from '../../../../hooks/useSessionStorageEntry';

export function useSetSessionStorageInGlobalStateStore({ interfaceId }: { interfaceId: string }) {
  const globalStateStore = useGlobalStateStore();
  const response = useSessionStorageEntry<Record<string, unknown>>(SessionStorageKeys.Interfaces);

  const previousIsLoading = usePrevious(response.isLoading);

  useEffect(() => {
    const { data, isLoading, isFetching, error } = response;
    if (previousIsLoading !== isLoading) {
      globalStateStore.setState({
        interfaceSessionStorage: {
          data: data?.[interfaceId] as Record<string, unknown>,
          isLoading,
          isFetching,
          error,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should only happen for the 1st time when loading becomes false
  }, [globalStateStore, interfaceId, previousIsLoading, response.isLoading]);
}
