import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { useQuery } from '@unifyapps/network/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
  QueryClient,
} from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { executeFetch } from '@unifyapps/network/utils/fetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- same as in the generated hooks
type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAuthDeployedComponent = (
  slug: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<(InterfacePageEntity | DataSourceEntity)[]>(
    {
      url: `/auth/component/deployed/${encodeURIComponent(String(slug))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getAuthDeployedComponentQueryKey = (slug) => {
  return [`/auth/component/deployed`, slug] as const;
};

export const getAuthDeployedComponentQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthDeployedComponent>>,
  TError = ErrorType<unknown>,
>(
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAuthDeployedComponent>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthDeployedComponentQueryKey(slug);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthDeployedComponent>>> = ({
    signal,
  }) => getAuthDeployedComponent(slug, requestOptions, signal);

  return { queryKey, queryFn, staleTime: 5000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuthDeployedComponent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuthDeployedComponentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuthDeployedComponent>>
>;
export type GetAuthDeployedComponentQueryError = ErrorType<unknown>;

export const useGetAuthDeployedComponent = <
  TData = Awaited<ReturnType<typeof getAuthDeployedComponent>>,
  TError = ErrorType<unknown>,
>(
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAuthDeployedComponent>>, TError, TData>
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthDeployedComponentQueryOptions(slug, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetAuthDeployedComponent = async <
  TData = Awaited<ReturnType<typeof getAuthDeployedComponent>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  slug: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAuthDeployedComponent>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getAuthDeployedComponentQueryOptions(slug, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
