import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBug = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 20.731V7.03m0 13.701a5.91 5.91 0 0 1-5.596-4.004M12 20.731a5.91 5.91 0 0 0 5.612-4.052M3.134 4.879c.195 2.045.806 2.956 2.769 3.897l.684.335M3 21c.195-2.045.806-3.09 2.768-4.03l.636-.243M20.866 4.88c-.195 2.044-.806 2.955-2.769 3.896l-.684.349M21 21c-.195-2.045-.806-3.09-2.768-4.03l-.62-.291M12 7.029h-1.88a4.03 4.03 0 0 0-4.03 4.03v1.881M12 7.03h1.88a4.03 4.03 0 0 1 4.03 4.03v1.88M6.404 16.727a5.9 5.9 0 0 1-.314-1.906v-1.88m11.522 3.738a5.9 5.9 0 0 0 .298-1.858v-1.88M9.045 7.174 8.99 6.33V6.09c0-.874.322-1.64.887-2.203A3 3 0 0 1 12 3c.793 0 1.558.309 2.122.887.565.564.887 1.33.887 2.122v.336l-.077.824m2.978 5.771H21m-14.91 0H3"
    />
  </svg>
);
export default SvgBug;
