import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  CitationBlockStateType,
  CitationComponentType,
  CitationType,
} from '@unifyapps/defs/blocks/Citation/types';
import { memo } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { VisibleCitationSources } from './components/VisibleCitationSources';

function Citation({
  updateBlockState,
  events,
  computedBlockState,
}: BlockComponentProps<CitationComponentType, CitationBlockStateType, CitationBlockStateType>) {
  const {
    content: { citations, size, maxVisibleCitations, enableImplicitCitationClick = false },
    appearance,
  } = computedBlockState;
  const { emitEvent } = useBlockEvents(events);

  const onCitationClick = useEventCallback((citation?: CitationType) => {
    updateBlockState((draft) => {
      draft.selectedCitation = citation;
    });

    void emitEvent({ eventType: EventTypeEnum.OnClick });
  });

  return (
    <VisibleCitationSources
      citations={citations}
      className={appearance?.className}
      enableImplicitCitationClick={enableImplicitCitationClick}
      maxVisibleCitations={maxVisibleCitations}
      onCitationClick={onCitationClick}
      size={size}
    />
  );
}

const MemoizedCitation = memo(Citation);

export default MemoizedCitation;
