import { useMemo } from 'react';
import type { BlockType } from '@unifyapps/defs/types/block';
import { useRegistryContext } from '../../components/RegistryProvider';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import { getFlattenBlocks } from './flattenBlocks';

export function useFlattenBlocks(blocks?: Record<string, BlockType>) {
  const { registry } = useRegistryContext();
  const componentType = useInterfaceStore().use.page.componentType();
  const layout = useInterfaceStore().use.page.layout();

  return useMemo(
    () =>
      getFlattenBlocks({
        blocks: blocks ?? {},
        registry,
        layout,
        componentType,
      }),
    [blocks, componentType, layout, registry],
  );
}
