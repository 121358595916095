import type { UpdateBlockStateType } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { MessageInputAction } from '@unifyapps/defs/blocks/MessageInput/types';
import {
  MessageInputActionType,
  StandardActionsEnum,
} from '@unifyapps/defs/blocks/MessageInput/types';
import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import { useMemo, useRef } from 'react';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import type { MessageInputAction as MessageInputComponentAction } from '@unifyapps/carbon/components/MessageInput/types';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import isEqual from 'react-fast-compare';
import type { CommentInputBlockStateType } from '@unifyapps/defs/blocks/CommentInput/types';
import _set from 'lodash/set';

export function useCommentInputActions({
  actions,
  updateBlockState,
  emitEvent,
  onChange,
}: {
  actions: CommentInputBlockStateType['content']['actions'];
  updateBlockState: UpdateBlockStateType<CommentInputBlockStateType>;
  emitEvent: EmitEventType;
  onChange: (value: string) => void;
}) {
  const actionsRef = useRef<MessageInputAction[]>([]);
  const adaptedActionsRef = useRef<MessageInputComponentAction[]>([]);
  const adaptedActions = useMemo(() => {
    // computedBlock state always returns new reference for the actions here.
    // Therefore, using fast-compare.
    if (isEqual(actionsRef.current, actions)) {
      return adaptedActionsRef.current;
    }
    actionsRef.current = actions;

    const _actions = _map(actionsRef.current, (action) => {
      if (action.type === MessageInputActionType.Custom) {
        return {
          ...action,
          onClick: () => {
            void emitEvent({
              eventType: EventTypeEnum.OnClick,
              targetId: action.id,
            });
          },
        };
      }

      switch (action.action) {
        case StandardActionsEnum.AddAttachments: {
          return {
            ...action,
            onClick: (files: FileType[]) => {
              updateBlockState((draft) => {
                _set(draft, 'attachments', files);
              });
            },
          };
        }
        case StandardActionsEnum.SendMessage: {
          return {
            ...action,
            onClick: async () => {
              await emitEvent({
                eventType: EventTypeEnum.OnAddComment,
                targetId: action.id,
              });
            },
          };
        }
        default: {
          return undefined;
        }
      }
    });

    adaptedActionsRef.current = _filter(_actions, Boolean) as MessageInputComponentAction[];
    return adaptedActionsRef.current;
  }, [actions, emitEvent, updateBlockState]);

  return {
    adaptedActions,
  };
}
