import type { CommentInputVariant } from '@unifyapps/defs/blocks/CommentList/types';
import { enterKeyBehaviorEnum } from '@unifyapps/defs/blocks/common/CommentMessageInputSchemas/schema';
import type { RichEditorProps } from '@unifyapps/editor/editors/RichTextEditor';
import { HardBreak } from '@unifyapps/editor/extensions/basic';
import { useMemo, useRef } from 'react';
import { Emoji } from '@tiptap-pro/extension-emoji';

export default function useExtensions({
  variant,
  enterKeyBehavior,
}: {
  variant: CommentInputVariant | undefined;
  enterKeyBehavior: enterKeyBehaviorEnum;
}) {
  const isMentionListActiveRef = useRef<boolean>(false);

  const extensions = useMemo(() => {
    const baseExtensions: RichEditorProps['extensions'] = [
      Emoji.configure({
        HTMLAttributes: {
          style:
            'font-size: calc(var(--typography-line-height, 1rem) - 2px); display: inline-block; height: calc(var(--typography-line-height, 1rem) - 2px); vertical-align: top; overflow: hidden;',
        },
      }),
    ];

    /**
     * Default behavior of the rich text editor:
     * - 'Shift-Enter' : Inserts a hard break (newline) using this.editor.commands.setHardBreak().
     * - 'Enter': https://github.com/ueberdosis/tiptap/blob/develop/packages/core/src/extensions/keymap.ts.
     *
     * Desired behavior:
     * - 'Shift-Enter': Want same behavior as when Enter in pressed in default case.
     * - 'Enter': Will manually handle submitting of Text.
     */

    if (variant === 'richText' && enterKeyBehavior === enterKeyBehaviorEnum.SendMessage) {
      baseExtensions.push(
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              'Shift-Enter': () =>
                this.editor.commands.first(({ commands }) => [
                  () => commands.newlineInCode(),
                  () => commands.createParagraphNear(),
                  () => commands.liftEmptyBlock(),
                  () => commands.splitListItem('listItem'),
                  () => commands.splitBlock(),
                ]),
              Enter: () => true,
            };
          },
        }),
      );
    }
    return baseExtensions;
  }, [enterKeyBehavior, variant]);

  return { extensions, isMentionListActiveRef };
}
