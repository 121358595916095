import useFetchDeployedInterfaceResourcesByVersion from '../../../hooks/fetchAndInvalidation/deployedEntities/dataByVersion/useFetchDeployedInterfaceResourcesByVersion';
import useFetchDeployedInterfaceResources from '../../../hooks/fetchAndInvalidation/deployedEntities/data/useFetchDeployedInterfaceResources';
import useFetchAuthDeployedInterfaceResources from '../../../hooks/fetchAndInvalidation/deployedEntities/data/useFetchAuthDeployedInterfaceResources';
import useFetchInterfaceResources from '../../../hooks/fetchAndInvalidation/standardEntities/data/useFetchInterfaceResources';
import { InterfaceResourceStrategies } from '../context/constants';

/**
 * Mapping object that associates each interface resource strategy with its corresponding fetch hook
 * The mapping includes:
 *
 * Authorization Strategy:
 * - Used in governance pages
 * - Fetches resources with authorization/permission data
 * - Hook: useFetchAuthDeployedInterfaceResources
 *
 * Standard Strategy:
 * - Used in interface builder, preview, and overview screens
 * - Fetches non-deployed interface resources
 * - Hook: useFetchInterfaceResources
 *
 * Deployed Strategy:
 * - Used in deployed interface views, matrix views, delta matrix, and platform-integrated interfaces
 * - Fetches currently deployed interface resources
 * - Hook: useFetchDeployedInterfaceResources
 *
 * DeployedByVersion Strategy:
 * - Used in version-specific screens of interfaces
 * - Fetches interface resources for a specific version
 * - Hook: useFetchDeployedInterfaceResourcesByVersion
 */

export const RESOURCE_STRATEGY_VS_HOOK = {
  [InterfaceResourceStrategies.Authorization]: useFetchAuthDeployedInterfaceResources,
  [InterfaceResourceStrategies.Standard]: useFetchInterfaceResources,
  [InterfaceResourceStrategies.Deployed]: useFetchDeployedInterfaceResources,
  [InterfaceResourceStrategies.DeployedByVersion]: useFetchDeployedInterfaceResourcesByVersion,
};
