import type { MutableRefObject } from 'react';
import type { TextareaProps } from '@unifyapps/ui/components/Textarea';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import type { enterKeyBehaviorEnum } from '@unifyapps/defs/blocks/common/CommentMessageInputSchemas/schema';
import type { RichEditorProps } from '@unifyapps/editor/editors/RichTextEditor';
import type { KeyBindingsType } from '../CommentsWidget/component/CommentInput/types';

export enum MessageInputActionType {
  Standard = 'STANDARD',
  Custom = 'CUSTOM',
}

export enum MessageInputActionAlignment {
  Start = 'start',
  End = 'end',
}

export enum StandardActionsEnum {
  AddAttachments = 'addAttachments',
  SendMessage = 'sendMessage',
  EmojiPicker = 'emojiPicker',
}

type ActionAppearanceConfig = {
  color: 'brand' | 'neutral' | 'danger';
  size: 'sm' | 'md' | 'lg';
  variant: 'solid' | 'outline' | 'soft';
};

interface BaseAction {
  id: string;
  label: string;
  appearance: ActionAppearanceConfig;
  alignment: MessageInputActionAlignment;
}

export type CustomAction = BaseAction & {
  type: MessageInputActionType.Custom;
  icon: string;
  onClick: () => void;
};

export type AddAttachmentAction = BaseAction & {
  type: MessageInputActionType.Standard;
  action: StandardActionsEnum.AddAttachments;
  supportedFileTypes?: string[];
  maxFileSize?: number;
  multipleFiles?: boolean;
  onClick: (files: FileType[]) => void;
  icon: string;
};

export enum SendMessageActionVariant {
  Icon = 'icon',
  Label = 'label',
}

export type SendMessageAction =
  | (BaseAction & {
      variant: SendMessageActionVariant.Icon;
      type: MessageInputActionType.Standard;
      action: StandardActionsEnum.SendMessage;
      icon: string;
      allowDictation?: boolean;
      onClick: (props: {
        message: string | undefined;
        attachments?: FileType[];
      }) => void | Promise<void>;
    })
  | (BaseAction & {
      variant: SendMessageActionVariant.Label;
      type: MessageInputActionType.Standard;
      action: StandardActionsEnum.SendMessage;
      startIcon: string;
      displayLabel: string;
      allowDictation?: boolean;
      onClick: (props: {
        message: string | undefined;
        attachments?: FileType[];
      }) => void | Promise<void>;
    });

export type EmojiPickerAction = BaseAction & {
  type: MessageInputActionType.Standard;
  action: StandardActionsEnum.EmojiPicker;
  icon: string;
  appearance: ActionAppearanceConfig & {
    pickerTheme?: 'light' | 'dark';
    emojiPreviewPosition?: 'top' | 'bottom' | 'none';
    pickerSize?: 'sm' | 'lg';
  };
};

export type StandardAction = AddAttachmentAction | SendMessageAction | EmojiPickerAction;

export type MessageInputAction = CustomAction | StandardAction;

export type InputRef = MutableRefObject<
  | {
      insertHtml?: (html: string) => void;
      isEmpty?: () => boolean;
      clearContent?: () => void;
      focus?: () => void;
    }
  | undefined
>;

export type InputMethods = {
  onInputChange?: (newInput: string | undefined) => void;
};
export type InputMethodsRef = MutableRefObject<InputMethods | null>;

export type MessageInputProps = {
  actions: MessageInputAction[];
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
  isLoading?: boolean;
  allowAttachmentsUpload?: boolean;
  variant?: 'textarea' | 'richText';
  enterKeyBehavior?: enterKeyBehaviorEnum;
  keyBindings?: KeyBindingsType[];
  extensions?: RichEditorProps['extensions'];
  inputRef?: InputRef;
  startDecoratorNode?: React.ReactNode;
  isSubmitDisabled?: boolean;
  isMentionListActiveRef?: MutableRefObject<boolean>;
  mode?: 'compact' | 'default';
  editorKey?: string;
  onClickAway?: ({ hasAttachments }: { hasAttachments?: boolean }) => void;
  inlineActions?: boolean;
  slotProps?: TextareaProps['slotProps'] & {
    actions?: {
      className?: string;
    };
  };
  autoFocus?: boolean;
  textareaClassName?: string;
  textareaVariant?: TextareaProps['variant'];
  dataAttributes?: Record<string, string>;
} & Omit<TextareaProps, 'onChange' | 'value' | 'autoFocus' | 'variant'>;
