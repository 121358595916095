import { cva } from 'class-variance-authority';

export const getInputClassName = cva('', {
  variants: {
    size: {
      //5x the line height of the input
      sm: '!px-md !pt-sm',
      md: '!px-lg !pt-md',
    },
  },
});

export const getPlaceHolderClassName = cva('', {
  variants: {
    size: {
      //5x the line height of the input
      sm: 'px-md py-sm h-8',
      md: 'px-lg py-md h-9',
    },
  },
});

export const getRootClassName = cva('!min-h-fit', {
  variants: {
    size: {
      sm: 'px-md pt-sm',
      md: 'px-lg pt-md',
    },
    variant: {
      richText: '',
      textarea: '',
    },
  },
});
