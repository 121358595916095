import type { BlockComponentStateId, BlockComponentType } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type { MappedValue } from '../Chart/types';

export type MediaItemType = {
  url: string;
  fileType?: string;
  caption?: string;
  previewUrl?: string;
  name?: string;
  id: string;
  mimeType?: string;
  isLoading?: boolean;
};

export enum MediaDataType {
  Static = 'STATIC',
  Mapped = 'MAPPED',
}

type Content = {
  type: MediaDataType;
  src?: string | string[] | MediaItemType | MediaItemType[];
  mappings?: {
    id?: MappedValue;
    name?: MappedValue;
    url?: MappedValue;
    caption?: MappedValue;
    mimeType?: MappedValue;
    isLoading?: MappedValue;
  };
};

export type Appearance = {
  size: 'sm' | 'md' | 'lg';
  variant: 'compact' | 'spacious';
  display: 'grid' | 'list';
  showCaption?: boolean;
  cols?: number;
  maxUpfrontItems?: number;
  styles?: Pick<
    StyleObject,
    'padding' | 'margin' | 'height' | 'width' | 'minHeight' | 'minWidth' | 'maxHeight' | 'maxWidth'
  > & { justifyContent?: string };
};

export type MediaComponentType = BlockComponentType<'Media', Content, Appearance>;

export type MediaBlockStateType = Pick<
  MediaComponentType,
  'appearance' | 'content' | 'componentType'
> &
  BlockComponentStateId;
