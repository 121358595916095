import { InterfaceResourceStrategies } from '../context/constants';

export const AuthorizationResources = {
  strategy: InterfaceResourceStrategies.Authorization,
};

export const StandardResources = {
  strategy: InterfaceResourceStrategies.Standard,
};

export const DeployedResources = {
  strategy: InterfaceResourceStrategies.Deployed,
};

export const DeployedResourcesByVersion = {
  strategy: InterfaceResourceStrategies.DeployedByVersion,
};
