import { cva } from 'class-variance-authority';

export const getIconClassName = cva('', {
  variants: {
    size: {
      sm: 'h-3 w-3',
      md: 'h-4 w-4',
      lg: 'h-4 w-4',
    },
  },
});

export const getCurrentStepIndicatorClassName = cva('bg-primary rounded-full', {
  variants: {
    size: {
      sm: 'h-2 w-2',
      md: 'h-2 w-2',
      lg: 'h-3 w-3',
    },
    isPending: {
      true: '',
      false: '',
    },
    isSubStep: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [{ isPending: true, isSubStep: false, className: '!bg-disabled' }],
});
