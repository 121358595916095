import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentsUnionType,
  ComponentTypeUnionType,
  ComputedBlockStateUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import type { FormType } from '@unifyapps/form/types';
import { lazy, type MemoExoticComponent } from 'react';
import type { FormBlockStateType, FormComponentType } from '@unifyapps/defs/blocks/Form/types';
import type { JSONSchema7WithForiegnKey } from '@unifyapps/carbon/no-code/utils/dataSourceLookup/types';
import { getAllPaths } from '@unifyapps/carbon/widgets/rjsf/utils/getAllPathsFromSchema';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import { produce } from 'immer';
import _set from 'lodash/set';
import _get from 'lodash/get';
import isEqual from 'react-fast-compare';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { shouldTraverseSchema } from './utils/shouldTraverseSchema';

const importFormComponent = () =>
  import(
    /* webpackChunkName: "form-block" */
    './Form'
  );

const Form = lazy(importFormComponent);

type LoadFormType = MemoExoticComponent<
  (
    props: BlockComponentProps<
      ComponentsUnionType,
      BlockStateUnionType,
      ComputedBlockStateUnionType
    >,
  ) => React.ReactNode
> | null;

class FormBlockStateDefinition implements BlockStateDefinition {
  private static loadedForm = null as LoadFormType | null;

  get type(): ComponentTypeUnionType {
    return 'Form';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds(block: BlockType): string[] {
    const component = block.component as FormComponentType;

    const allPaths = getAllPaths({
      schema:
        'schema' in component.content
          ? (
              component.content.schema as
                | { schema: JSONSchema7WithForiegnKey | undefined }
                | undefined
            )?.schema
          : {},
      shouldTraverseSchema,
    });
    if (!allPaths) {
      return [];
    }
    return _map(allPaths, (pathDetail) => pathDetail.key);
  }
  getBlockControlMethods() {
    return [
      {
        id: 'submitForm',
        label: 'Submit',
        method: (blockState: BlockStateUnionType, _: Action, blockRef?: FormType) => {
          if (blockRef?.submit) {
            blockRef.submit();
          }
          return Promise.resolve();
        },
      },
      {
        id: 'resetForm',
        label: 'Reset',
        method: (blockState: BlockStateUnionType, _: Action, blockRef?: FormType) => {
          if (blockRef?.reset) {
            blockRef.reset();
          }
          return Promise.resolve();
        },
      },
      {
        id: 'resetField',
        label: 'Reset Field',
        method: (blockState: BlockStateUnionType, action: Action, blockRef?: FormType) => {
          const { methodPayload } = action.payload as {
            methodPayload: { fieldId: string; resetToDefault: boolean };
          };

          const { fieldId, resetToDefault } = methodPayload;

          const content = blockState.content as FormBlockStateType['content'];

          const allPaths = getAllPaths({
            schema: (content.schema as { schema: JSONSchema7WithForiegnKey } | undefined)?.schema,
            shouldTraverseSchema,
          });

          const fieldPath = _find(allPaths, { key: fieldId })?.path;

          if (!fieldPath) {
            return blockState;
          }

          //fieldData is the value of the field
          const fieldData = _get((blockState as FormBlockStateType).data, fieldPath);

          //fieldVsDefaultValueMap is the default value of the field
          const fieldVsDefaultValueMap = (blockState as FormBlockStateType).fieldVsDefaultValueMap;
          const defaultFieldValue = resetToDefault
            ? _get(fieldVsDefaultValueMap, fieldPath)
            : undefined;

          if (isEqual(fieldData, defaultFieldValue)) {
            return blockState;
          }

          const updatedData = produce((blockState as FormBlockStateType).data ?? {}, (draft) => {
            _set(draft, fieldPath, defaultFieldValue);
          });

          if (fieldPath) {
            blockRef?.onChange(updatedData, undefined, `root.${fieldPath}`);
          }

          return produce(blockState as FormBlockStateType, (draft) => {
            draft.data = updatedData;
          });
        },
      },
      {
        id: 'setFieldValue',
        label: 'Set Field Value',
        method: (blockState: BlockStateUnionType, action: Action, blockRef?: FormType) => {
          const { methodPayload } = action.payload as {
            methodPayload: { fieldId: string; value: unknown };
          };

          const { fieldId, value } = methodPayload;

          const content = blockState.content as FormBlockStateType['content'];

          const allPaths = getAllPaths({
            schema: (content.schema as { schema: JSONSchema7WithForiegnKey } | undefined)?.schema,
            shouldTraverseSchema,
          });

          const fieldPath = _find(allPaths, { key: fieldId })?.path;

          if (!fieldPath) {
            return blockState;
          }

          const updatedData = produce((blockState as FormBlockStateType).data ?? {}, (draft) => {
            _set(draft, fieldPath, value);
          });

          if (fieldPath) {
            blockRef?.onChange(updatedData, undefined, `root.${fieldPath}`);
          }

          return produce(blockState as FormBlockStateType, (draft) => {
            draft.data = updatedData;
          });
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType', 'slots']),
      }) as BlockStateUnionType;
  }

  preload() {
    return {
      blockPreload: importFormComponent,
    };
  }

  getComponent() {
    return FormBlockStateDefinition.loadedForm ?? Form;
  }

  getSlotOrder(block: BlockType<FormComponentType>): { order: string[] } {
    const allSlots = block.component.slots;

    const allSlotsExceptHeaderAndFooter = Object.keys(allSlots ?? []).filter(
      (slotName) => slotName !== 'header' && slotName !== 'footer',
    );
    return {
      order: ['header', ...allSlotsExceptHeaderAndFooter, 'footer'],
    };
  }

  getKeysToOmitInComputation() {
    return ['data', 'fieldVsDefaultValueMap'];
  }
}

export default FormBlockStateDefinition;
