// white listing these routes as these are to executed in unifyapps context
const whitelistedRoutes = ['custom', 'standard', 'create', 'edit', 'share'];

export const extractInterfaceFromRoute = (route: string): string | null | undefined => {
  const params = route.split('/');
  const indexOfInterfaces = params.indexOf('interfaces');
  if (indexOfInterfaces === -1) {
    return null;
  }
  const interfaceIdFromRoute = params[indexOfInterfaces + 1];

  if (whitelistedRoutes.some((it) => it === interfaceIdFromRoute)) {
    return null;
  }
  return params[indexOfInterfaces + 1];
};
