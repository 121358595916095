/* eslint-disable @typescript-eslint/no-extraneous-class -- safe to ignore */

import type { LookupByKeysRequest } from '@unifyapps/network/generated/models/lookupByKeysRequest';
import { LookupByKeysRequestType } from '@unifyapps/network/generated/models/lookupByKeysRequestType';
import { LookupTypes } from '../../types/entities';

export const APPLICATION_BY_NAME = 'APPLICATION_BY_NAME';
export const ASSIGNEE_AGENT_DETAILS = 'ASSIGNEE_AGENT_DETAILS';

class LookupsHelper {
  static getLookupRequestForAppName = (keys: string[]): LookupByKeysRequest => ({
    keys,
    name: APPLICATION_BY_NAME,
    lookupType: LookupTypes.ApplicationByName,
    type: LookupByKeysRequestType.ByKeys,
  });

  static getLookupRequestForAssigneeDetails = (keys: string[]): LookupByKeysRequest => ({
    keys,
    name: ASSIGNEE_AGENT_DETAILS,
    lookupType: LookupTypes.CustomerProfile,
    type: LookupByKeysRequestType.ByKeys,
  });
}

export default LookupsHelper;
