import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { Action } from '@unifyapps/defs/types/action';
import type {
  BlockType,
  ComponentTypeUnionType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { VideoBlockStateType, VideoComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importVideoComponent = () =>
  import(
    /* webpackChunkName: "video-block" */
    './Video'
  );

const Video = lazy(importVideoComponent);

class VideoBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Video';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'changePlaybackSpeed' as const,
        label: 'Change playback speed',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { playbackSpeed: string };
          };
          const { playbackSpeed } = methodPayload;
          return {
            ...blockState,
            playbackSpeed,
          } as VideoBlockStateType;
        },
      },
      {
        id: 'playVideo' as const,
        label: 'Play Video',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            playVideo: true,
          };
        },
      },
      {
        id: 'pauseVideo' as const,
        label: 'Pause Video',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            pauseVideo: true,
          };
        },
      },
      {
        id: 'restartVideo' as const,
        label: 'Restart Video',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            restartVideo: true,
          };
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as VideoComponentType, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Video;
  }

  preload() {
    return {
      blockPreload: importVideoComponent,
    };
  }
}

export default VideoBlockStateDefinition;

