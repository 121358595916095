import { produce } from 'immer';
import _set from 'lodash/set';
import type { InterfaceDependencyType } from '@unifyapps/defs/types/page';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getLongId } from '../../../../utils/id';

const getInsertDependenciesAction =
  ({ get, set }: InterfaceStoreStateGetterAndSetter) =>
  (dependenciesToAdd: [InterfaceDependencyType, InterfaceDependencyType]) => {
    const currentPageId = get().activeInterfacePageId;

    const [pairKey, pairValue] = [getLongId(), getLongId()] as const;
    const metadataPath = [currentPageId, 'properties', 'metadata'];

    set({
      interfacePages: produce(get().interfacePages, (draftPages) => {
        _set(draftPages, [...metadataPath, 'dependencies', pairKey], pairValue);
        _set(draftPages, [...metadataPath, 'dependencyById', pairKey], dependenciesToAdd[0]);
        _set(draftPages, [...metadataPath, 'dependencyById', pairValue], dependenciesToAdd[1]);
      }),
    });
  };

export default getInsertDependenciesAction;
