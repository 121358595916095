import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import {
  type CommentInputComponentType,
  type CommentInputBlockStateType,
} from '@unifyapps/defs/blocks/CommentInput/types';
import { memo } from 'react';
import { CommentInput as BaseCommentInput } from '@unifyapps/carbon/components/CommentsWidget/component/CommentInput';
import _noop from 'lodash/noop';
import { useGetComputeContext } from '@unifyapps/carbon/no-code/hooks/computeContext';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { getSelectedDataSourcePath } from '@unifyapps/carbon/utils/getSelectedDataSourcePath';
import { useControlDataSource } from '@unifyapps/carbon/no-code/hooks/useGlobalBlockActions/actions/controlDataSource';
import { ActionType } from '@unifyapps/defs/types/action';
import { oldEvaluateExpression } from '@unifyapps/carbon/no-code/utils/evaluateExpression';
import { getFieldKey } from '@unifyapps/defs/utils/getFieldKey';
import _map from 'lodash/map';
import _get from 'lodash/get';
import type { MentionItem } from '@unifyapps/carbon/components/CommentsWidget/component/CommentInput/extension/UserMentionsList';
import { EMPTY_ARRAY } from '@unifyapps/carbon/consts/empty';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import _set from 'lodash/set';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import Stack from '@unifyapps/ui/_components/Stack';
import useExtensions from '@unifyapps/carbon/components/CommentsWidget/hooks/useExtensions';
import { enterKeyBehaviorEnum } from '@unifyapps/defs/blocks/common/CommentMessageInputSchemas/schema';
import { KEY_BINDING_BY_ENTER_BEHAVIOUR } from './constants/keyBindingsCommentInput';
import { useCommentInputActions } from './hooks/useCommentInputActions';

function CommentInput({
  dataAttributes,
  computedBlockState,
  component,
  events,
  updateBlockState,
}: BlockComponentProps<
  CommentInputComponentType,
  CommentInputBlockStateType,
  CommentInputBlockStateType
>) {
  const { onControlDataSource } = useControlDataSource();
  const { getComputeContext } = useGetComputeContext();

  const { content, appearance } = computedBlockState;
  const { variant, actions, enterKeyBehavior = enterKeyBehaviorEnum.StartNewLine } = content;

  const { emitEvent } = useBlockEvents(events);

  const userMentions = component.content.addOns?.userMentions;

  const { dataSourceId: userMentionDataSourceId } = getSelectedDataSourcePath({
    data: userMentions?.data,
  });

  const { extensions, isMentionListActiveRef } = useExtensions({ variant, enterKeyBehavior });

  const getMentionsItems = useEventCallback(async ({ query }: { query: string }) => {
    updateBlockState((draft) => {
      draft.userMentionSearchString = query;
    });

    const idFieldKey = getFieldKey(userMentions?.mappedUserDetails?.id);
    const labelFieldKey = getFieldKey(userMentions?.mappedUserDetails?.label);

    if (!userMentionDataSourceId || !userMentions?.data || !idFieldKey || !labelFieldKey) {
      return EMPTY_ARRAY as MentionItem[];
    }

    await onControlDataSource({
      action: {
        actionType: ActionType.ControlDataSource,
        payload: { method: 'trigger', dataSourceId: userMentionDataSourceId },
      },
      actionContext: {},
    });

    const context = getComputeContext();

    const result = oldEvaluateExpression<unknown[]>({
      context,
      expression: userMentions.data,
    });

    if (!result) {
      return EMPTY_ARRAY as MentionItem[];
    }

    return _map(result, (user) => {
      const id = _get(user, idFieldKey) as string;
      const label = _get(user, labelFieldKey) as string;

      return {
        id,
        label,
      } as MentionItem;
    });
  });

  const { className } = useBlockAppearanceStyles({
    appearanceStyles: computedBlockState.appearance?.styles,
  });

  const onChange = useEventCallback((newValue: string) => {
    updateBlockState((draft) => {
      _set(draft, 'value', newValue);
    });
  });

  const { adaptedActions } = useCommentInputActions({
    actions,
    updateBlockState,
    emitEvent,
    onChange,
  });

  const keyBindings = KEY_BINDING_BY_ENTER_BEHAVIOUR[enterKeyBehavior];

  return (
    <Stack className={className} {...dataAttributes}>
      <BaseCommentInput
        actions={adaptedActions}
        autofocus={false}
        // In CommentInput, when the `variant` or `EnterKeyBehavior` changes,
        // we need to update extensions pased to Textarea. However, Tiptap editor
        // doesn't automatically reinitialize with the new extensions.
        // To force a remount of the editor, we pass a unique `key`.
        // https://github.com/ueberdosis/tiptap/issues/1044
        editorKey={`${variant}-${enterKeyBehavior}`}
        extensions={extensions}
        getMentionsItems={userMentions ? getMentionsItems : undefined}
        input={computedBlockState.value}
        isMentionListActiveRef={isMentionListActiveRef}
        keyBindings={keyBindings}
        onInputChange={onChange}
        size={appearance?.size}
        variant={variant}
      />
    </Stack>
  );
}

export default memo(CommentInput);
