import { Autocomplete } from '../Autocomplete';
import type { SelectProps } from './types';
import { useSelectHandlers } from './useSelectHandlers';

function Select<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  Value = string,
>({
  multiple,
  value,
  disableCloseOnSelect = multiple, // do not close popover on select if multiple
  options,
  onChange,
  areValueEqual = Object.is,
  valueAccessor,
  disableSearch,
  variant,
  freeSolo,
  ...rest
}: SelectProps<Option, Multiple, DisableClearable, Value>) {
  // _value is either string or array of string when multi select is on
  // Create an option object for free solo values that don't exist in options

  const { value: computedValue, handleChange } = useSelectHandlers({
    value,
    options,
    onChange,
    areValueEqual,
    valueAccessor,
    freeSolo,
  });

  // In Select we're enabling search by default if options length is greater than 5. This can be overridden by passing disableSearch prop as true or false
  const _disableSearch = disableSearch ?? options.length < 5;

  return (
    <Autocomplete
      disableCloseOnSelect={disableCloseOnSelect}
      disableSearch={_disableSearch}
      freeSolo={freeSolo}
      multiple={multiple}
      onChange={handleChange}
      options={options}
      value={computedValue}
      variant={variant}
      {...rest}
    />
  );
}

export default Select;
