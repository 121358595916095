import { useCallback, useMemo, type CSSProperties } from 'react';
import { ButtonGroup } from '@unifyapps/ui/components/ButtonGroup';
import { Button as BaseButton } from '@unifyapps/ui/components/Button';
import type { Option } from '@unifyapps/defs/blocks/ButtonGroup/types';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';

type Props = {
  color?: 'brand' | 'neutral' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'solid' | 'outline' | 'soft' | 'ghost';
  className?: string;
  style?: CSSProperties;
  dataAttributes: Record<string, string> | undefined;
  options: Option[];
  onAction: (id: string, label: string) => void;
};

function Button({
  color,
  variant,
  option,
  index,
  size,
  numberOfOptions,
  tooltipContent,
  onAction,
  ...rest
}: Pick<Props, 'color' | 'size' | 'variant' | 'onAction'> & {
  option: Option;
  index: number;
  numberOfOptions: number;
  tooltipContent: string | undefined;
}) {
  const onClick = useCallback(() => {
    onAction(option.id, option.label);
  }, [onAction, option.id, option.label]);

  const style = useMemo(
    () => ({
      ...(index === 0
        ? { borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }
        : undefined),
      ...(index === numberOfOptions - 1
        ? { borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }
        : undefined),
    }),
    [index, numberOfOptions],
  );

  return tooltipContent ? (
    <Tooltip key={option.id} placement="top" title={tooltipContent}>
      <BaseButton
        className="grow"
        color={color ?? 'brand'}
        disabled={option.appearance?.disabled}
        endDecoratorComponent={getIconFromRegistry(option.appearance?.endDecorator)}
        loading={option.appearance?.loading}
        onClick={onClick}
        size={size ?? 'md'}
        startDecoratorComponent={getIconFromRegistry(option.appearance?.startDecorator)}
        style={style}
        variant={variant ?? 'solid'}
        {...rest}
      >
        {option.label}
      </BaseButton>
    </Tooltip>
  ) : (
    <BaseButton
      className="grow"
      color={color ?? 'brand'}
      disabled={option.appearance?.disabled}
      endDecoratorComponent={getIconFromRegistry(option.appearance?.endDecorator)}
      loading={option.appearance?.loading}
      onClick={onClick}
      size={size ?? 'md'}
      startDecoratorComponent={getIconFromRegistry(option.appearance?.startDecorator)}
      style={style}
      variant={variant ?? 'solid'}
      {...rest}
    >
      {option.label}
    </BaseButton>
  );
}

export function DefaultButtonGroup({
  size,
  color,
  variant,
  className,
  style,
  options,
  dataAttributes,
  onAction,
}: Props) {
  const slotProps = useMemo(() => ({ root: dataAttributes }), [dataAttributes]);

  return (
    <ButtonGroup className={className} slotProps={slotProps} style={style}>
      {options.map((option, index) => {
        const tooltipContent = option.addOns?.tooltip ? option.addOns.tooltip.content : undefined;

        return (
          <Button
            color={color}
            index={index}
            key={option.id}
            numberOfOptions={options.length}
            onAction={onAction}
            option={option}
            size={size}
            tooltipContent={tooltipContent}
            variant={variant}
          />
        );
      })}
    </ButtonGroup>
  );
}
