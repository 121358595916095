import type { Event } from '@unifyapps/defs/types/event';
import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import { useCallback, useMemo } from 'react';
import type { BlockId } from '@unifyapps/defs/types/block';
import { NoCodeEventHandler } from '../../event';
import { useActionsContext } from '../../components/ActionsProvider';
import { useGetComputeContext } from '../computeContext';
import { useComputeConditionEvaluationGetter } from '../useComputeConditionEvaluationGetter';

function useBlockEvents(events?: Event[], blockId?: BlockId) {
  const { getComputeContext } = useGetComputeContext();
  const getComputedFilters = useComputeConditionEvaluationGetter();
  const { doAction } = useActionsContext();

  const { triggerEvent } = useMemo(() => {
    return new NoCodeEventHandler({
      getComputeContext,
      getComputedFilters,
      events: events || [],
    });
  }, [events, getComputeContext, getComputedFilters]);

  const emitEvent = useCallback<EmitEventType>(
    async ({ eventType, domEvent, actionContext, targetId }) => {
      if (doAction) {
        await triggerEvent({
          eventType,
          doAction,
          actionContext,
          domEvent,
          targetId,
          blockId,
        });
      }
    },
    [blockId, doAction, triggerEvent],
  );

  return {
    emitEvent,
  };
}

export default useBlockEvents;
