import type { OnActionArgs } from '../../../../components/ActionsProvider/context';
import { oldEvaluateExpression } from '../../../../utils/evaluateExpression';

export const useEvalJS = () => {
  const onEvalJS = ({ action }: OnActionArgs) => {
    const script = action.payload?.script as string;
    if (script) {
      oldEvaluateExpression({
        context: {
          console: window.console,
        },
        expression: script,
      });
    }
    return Promise.resolve();
  };

  return { onEvalJS };
};
