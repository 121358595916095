import { cva } from 'class-variance-authority';
import type { PillColorsEnum } from './types';

export const pillVariants = cva(
  'border w-fit border-box flex flex-row shrink-0 justify-center items-center border-secondary',
  {
    variants: {
      variant: {
        soft: '',
        clear: 'border-secondary bg-primary',
        solid: 'text-white border-none',
      },
      color: {
        gray: '',
        brand: '',
        error: '',
        warning: '',
        success: '',
        blue: '',
        purple: '',
        fuchsia: '',
        cyan: '',
        lime: '',
        pink: '',
        violet: '',
      } satisfies Record<PillColorsEnum, string>,
      shape: {
        rounded: 'rounded-full py-xs',
        square: 'rounded-sm py-xs',
      },
      size: {
        md: '!p-md h-fit',
        sm: 'px-md h-6',
        xs: 'px-sm h-5',
      },
      disabled: {
        true: '!bg-disabled !text-disabled',
      },
    },
    compoundVariants: [
      {
        variant: 'soft',
        color: 'gray',
        className: 'border-utility-gray-100 bg-utility-gray-50 text-utility-gray-700',
      },
      {
        variant: 'soft',
        color: 'brand',
        className: 'border-utility-brand-100 bg-utility-brand-50 text-utility-brand-700',
      },
      {
        variant: 'soft',
        color: 'error',
        className: 'border-utility-error-100 bg-utility-error-50 text-utility-error-700',
      },
      {
        variant: 'soft',
        color: 'warning',
        className: 'border-utility-warning-100 bg-utility-warning-50 text-utility-warning-700',
      },
      {
        variant: 'soft',
        color: 'success',
        className: 'border-utility-success-100 bg-utility-success-50 text-utility-success-700',
      },
      {
        variant: 'soft',
        color: 'blue',
        className: 'border-utility-blue-100 bg-utility-blue-50 text-utility-blue-700',
      },
      {
        variant: 'soft',
        color: 'purple',
        className: 'border-utility-purple-100 bg-utility-purple-50 text-utility-purple-700',
      },
      {
        variant: 'soft',
        color: 'fuchsia',
        className: 'border-utility-fuchsia-100 bg-utility-fuchsia-50 text-utility-fuchsia-700',
      },
      {
        variant: 'soft',
        color: 'cyan',
        className: 'border-utility-cyan-100 bg-utility-cyan-50 text-utility-cyan-700',
      },
      {
        variant: 'soft',
        color: 'lime',
        className: 'border-utility-lime-100 bg-utility-lime-50 text-utility-lime-700',
      },
      {
        variant: 'soft',
        color: 'pink',
        className: 'border-utility-pink-100 bg-utility-pink-50 text-utility-pink-700',
      },
      {
        variant: 'soft',
        color: 'violet',
        className: 'border-utility-violet-100 bg-utility-violet-50 text-utility-violet-700',
      },
      {
        variant: 'clear',
        color: 'brand',
        className: 'text-utility-brand-700',
      },
      {
        variant: 'clear',
        color: 'gray',
        className: 'text-utility-gray-700',
      },
      {
        variant: 'clear',
        color: 'error',
        className: 'text-utility-error-700',
      },
      {
        variant: 'clear',
        color: 'warning',
        className: 'text-utility-warning-700',
      },
      {
        variant: 'clear',
        color: 'success',
        className: 'text-utility-success-700',
      },
      {
        variant: 'clear',
        color: 'blue',
        className: 'text-utility-blue-700',
      },
      {
        variant: 'clear',
        color: 'purple',
        className: 'text-utility-purple-700',
      },
      {
        variant: 'clear',
        color: 'fuchsia',
        className: 'text-utility-fuchsia-700',
      },
      {
        variant: 'clear',
        color: 'cyan',
        className: 'text-utility-cyan-700',
      },
      {
        variant: 'clear',
        color: 'lime',
        className: 'text-utility-lime-700',
      },
      {
        variant: 'clear',
        color: 'pink',
        className: 'text-utility-pink-700',
      },
      {
        variant: 'clear',
        color: 'violet',
        className: 'text-utility-violet-700',
      },
      {
        variant: 'solid',
        color: 'brand',
        className: 'bg-utility-brand-600',
      },
      {
        variant: 'solid',
        color: 'gray',
        className: 'bg-utility-gray-600',
      },
      {
        variant: 'solid',
        color: 'error',
        className: 'bg-utility-error-600',
      },
      {
        variant: 'solid',
        color: 'warning',
        className: 'bg-utility-warning-600',
      },
      {
        variant: 'solid',
        color: 'success',
        className: 'bg-utility-success-600',
      },
      {
        variant: 'solid',
        color: 'blue',
        className: 'bg-utility-blue-600',
      },
      {
        variant: 'solid',
        color: 'purple',
        className: 'bg-utility-purple-600',
      },
      {
        variant: 'solid',
        color: 'fuchsia',
        className: 'bg-utility-fuchsia-600',
      },
      {
        variant: 'solid',
        color: 'cyan',
        className: 'bg-utility-cyan-600',
      },
      {
        variant: 'solid',
        color: 'lime',
        className: 'bg-utility-lime-600',
      },
      {
        variant: 'solid',
        color: 'pink',
        className: 'bg-utility-cyan-600',
      },
      {
        variant: 'solid',
        color: 'violet',
        className: 'bg-utility-violet-600',
      },
    ],
    defaultVariants: {},
  },
);

export const labelVariants = cva('flex', {
  variants: {
    variant: {
      soft: '',
      clear: '',
      solid: '',
    },
  },
});

export const dotVariants = cva('w-md h-md rounded-full', {
  variants: {
    variant: {
      soft: '',
      clear: '',
      solid: '!bg-primary',
    },
    color: {
      gray: 'bg-utility-gray-500',
      brand: 'bg-utility-brand-500',
      error: 'bg-utility-error-500',
      warning: 'bg-utility-warning-500',
      success: 'bg-utility-success-500',
      blue: 'bg-utility-blue-500',
      purple: 'bg-utility-purple-500',
      fuchsia: 'bg-utility-fuchsia-500',
      cyan: 'bg-utility-cyan-500',
      lime: 'bg-utility-lime-500',
      pink: 'bg-utility-pink-500',
      violet: 'bg-utility-violet-500',
    } satisfies Record<PillColorsEnum, string>,
  },
  compoundVariants: [
    { variant: 'soft', color: 'gray', className: 'bg-utility-gray-500' },
    { variant: 'soft', color: 'brand', className: 'bg-utility-brand-500' },
    { variant: 'soft', color: 'error', className: 'bg-utility-error-500' },
    { variant: 'soft', color: 'warning', className: 'bg-utility-warning-500' },
    { variant: 'soft', color: 'success', className: 'bg-utility-success-500' },
    { variant: 'soft', color: 'blue', className: 'bg-utility-blue-500' },
    { variant: 'soft', color: 'purple', className: 'bg-utility-purple-500' },
    { variant: 'soft', color: 'fuchsia', className: 'bg-utility-fuchsia-500' },
    { variant: 'soft', color: 'cyan', className: 'bg-utility-cyan-500' },
    { variant: 'soft', color: 'lime', className: 'bg-utility-lime-500' },
    { variant: 'soft', color: 'pink', className: 'bg-utility-pink-500' },
    { variant: 'soft', color: 'violet', className: 'bg-utility-violet-500' },
    { variant: 'clear', color: 'gray', className: 'bg-utility-gray-500' },
    { variant: 'clear', color: 'brand', className: 'bg-utility-brand-500' },
    { variant: 'clear', color: 'error', className: 'bg-utility-error-500' },
    { variant: 'clear', color: 'warning', className: 'bg-utility-warning-500' },
    { variant: 'clear', color: 'success', className: 'bg-utility-success-500' },
    { variant: 'clear', color: 'blue', className: 'bg-utility-blue-500' },
    { variant: 'clear', color: 'purple', className: 'bg-utility-purple-500' },
    { variant: 'clear', color: 'fuchsia', className: 'bg-utility-fuchsia-500' },
    { variant: 'clear', color: 'cyan', className: 'bg-utility-cyan-500' },
    { variant: 'clear', color: 'lime', className: 'bg-utility-lime-500' },
    { variant: 'clear', color: 'pink', className: 'bg-utility-pink-500' },
    { variant: 'clear', color: 'violet', className: 'bg-utility-violet-500' },
  ],
});

export const decoratorVariants = cva('', {
  variants: {
    variant: {
      soft: '',
      clear: '',
      solid: 'text-white',
    },
    color: {
      gray: 'text-utility-gray-700',
      brand: 'text-utility-brand-700',
      error: 'text-utility-error-700',
      warning: 'text-utility-warning-700',
      success: 'text-utility-success-700',
      blue: 'text-utility-blue-700',
      purple: 'text-utility-purple-700',
      fuchsia: 'text-utility-fuchsia-700',
      cyan: 'text-utility-cyan-700',
      lime: 'text-utility-lime-700',
      pink: 'text-utility-pink-700',
      violet: 'text-utility-violet-700',
    } satisfies Record<PillColorsEnum, string>,
    size: {
      md: 'w-xl h-xl',
      sm: 'w-lg h-lg',
      xs: 'w-lg h-lg',
    },
  },
});

export const iconVariants = cva('', {
  variants: {
    color: {
      gray: 'stroke-utility-gray-500',
      brand: 'stroke-utility-brand-500',
      error: 'stroke-utility-error-500',
      warning: 'stroke-utility-warning-500',
      success: 'stroke-utility-success-500',
      blue: 'stroke-utility-blue-500',
      purple: 'stroke-utility-purple-500',
      fuchsia: 'stroke-utility-fuchsia-500',
      cyan: 'stroke-utility-cyan-500',
      lime: 'stroke-utility-lime-500',
      pink: 'stroke-utility-pink-500',
      violet: 'stroke-utility-violet-500',
    } satisfies Record<PillColorsEnum, string>,
  },
});
