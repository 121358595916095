import { useQueryClient } from '@unifyapps/network/react-query';
import useQuery from '@unifyapps/network/utils/useQuery';
import { useCallback, useEffect } from 'react';

interface StorageHookOptions<T> {
  queryKey: string[];
  getItem: () => Promise<T | undefined>;
  setItem: (value: T) => Promise<void>;
  onSuccess?: (data: T | undefined) => void;
  enabled?: boolean;
}

export const useStorageValue = <T>({
  queryKey,
  getItem,
  setItem,
  onSuccess,
  enabled = true,
}: StorageHookOptions<T>) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey,
    queryFn: async () => {
      const item = await getItem();
      return item ?? ({} as T);
    },
    enabled,
  });

  const setStorageValue = useCallback(
    async (value: T) => {
      await setItem(value);
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey });
      }, 100);
    },
    [queryClient, queryKey, setItem],
  ) as (value: T) => void;

  useEffect(() => {
    // onSuccess
    if (isLoading || isFetching || !enabled) {
      return;
    }

    onSuccess?.(data);
  }, [data, enabled, isFetching, isLoading, onSuccess]);

  return {
    data,
    isLoading,
    isFetching,
    error,
    setStorageValue,
  };
};
