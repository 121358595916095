import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  CommentInputBlockStateType,
  CommentInputComponentType,
} from '@unifyapps/defs/blocks/CommentInput/types';
import _map from 'lodash/map';
import type { Action } from '@unifyapps/defs/types/action';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import CommentInput from './CommentInput';

class CommentInputBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'CommentInput';
  }

  getEventTargetIds(block: BlockType<CommentInputComponentType>) {
    return _map(block.component.content.actions, (action) => action.id);
  }

  getBlockControlMethods() {
    return [
      {
        id: 'setContent' as const,
        label: 'Set Content',
        method: (blockState: CommentInputBlockStateType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { value: string | undefined; attachments: FileType[] | undefined };
          };

          return {
            ...blockState,
            value: methodPayload.value,
            attachments: methodPayload.attachments,
          };
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as CommentInputComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
        userMentionSearchString: '',
        value: '',
      }) as BlockStateUnionType;
  }

  getComponent() {
    return CommentInput;
  }

  getKeysToOmitInComputation() {
    return ['value'];
  }
}

export default CommentInputBlockStateDefinition;
