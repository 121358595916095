import { memo, type PropsWithChildren } from 'react';
import { cva } from 'class-variance-authority';
import { slowCn } from '@unifyapps/ui/lib/utils';
import type { ButtonColors } from '@unifyapps/ui/components/Button';
import Stack from '@unifyapps/ui/_components/Stack';
import ModalFooterHorizontalFull from './HorizontalFull';
import { ModalFooterHorizontalRight } from './HorizontalRight';

export type FooterProps = {
  className?: string;
  startDecoratorNode?: React.ReactNode;
  submitLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  //todo: rename this to secondaryActionDisabled
  secondaryDisabled?: boolean;
  isPending?: boolean;
  hidePrimaryAction?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  primaryColor?: ButtonColors;
  footerVariant?: 'full' | 'right';
  modalVariant?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | null | undefined;
};

const classnameVariants = cva('gap-lg border-tertiary bg-primary', {
  variants: {
    modalVariant: {
      xs: 'px-2xl pb-xl',
      sm: 'px-2xl pb-xl',
      md: 'px-2xl py-xl border-t',
      lg: 'px-2xl py-xl border-t',
      xl: 'px-2xl py-xl border-t',
    },
    footerVariant: {
      right: 'justify-end',
      full: 'justify-between',
    },
  },
});

function ModalFooterBase(props: PropsWithChildren<FooterProps>) {
  const {
    className,
    children,
    submitLabel,
    cancelLabel,
    disabled,
    startDecoratorNode,
    secondaryDisabled,
    isPending,
    hidePrimaryAction,
    onCancel,
    onSubmit,
    primaryColor,
    footerVariant = 'right',
    modalVariant = 'md',
  } = props;

  const baseClassName = slowCn(classnameVariants({ modalVariant, footerVariant }), className);

  if (children)
    return (
      <Stack className={baseClassName} direction="row">
        {children}
      </Stack>
    );

  return (
    <Stack className={baseClassName} direction="row">
      {footerVariant === 'full' ? (
        <ModalFooterHorizontalFull
          cancelLabel={cancelLabel}
          isPending={isPending}
          onCancel={onCancel}
          onSubmit={onSubmit}
          primaryColor={primaryColor}
          submitLabel={submitLabel}
        />
      ) : (
        <ModalFooterHorizontalRight
          cancelLabel={cancelLabel}
          disabled={disabled}
          hidePrimaryAction={hidePrimaryAction}
          isPending={isPending}
          onCancel={onCancel}
          onSubmit={onSubmit}
          primaryColor={primaryColor}
          secondaryDisabled={secondaryDisabled}
          startDecoratorNode={startDecoratorNode}
          submitLabel={submitLabel}
        />
      )}
    </Stack>
  );
}

export default memo(ModalFooterBase);
