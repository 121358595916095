import isEmptyFn from 'lodash/isEmpty';
import { EmptyState } from '@unifyapps/ui/components/EmptyState';
import SvgSearchMd from '@unifyapps/icons/outline/SearchMd';
import { useTranslation } from '@unifyapps/i18n/client';
import React, { useMemo, useRef } from 'react';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import { ErrorBoundary } from '../ErrorBoundary';
import { logError } from '../../utils/error-reporting/log';
import { useRemountProps } from './useRemountProps';
import ErrorComponent, { renderedErrorComponent } from './ErrorComponent';
import AsyncViewLoader from './AsyncViewLoader';
import type { AsyncViewProps } from './types';

function AsyncView<T, E extends Error>(props: AsyncViewProps<T, E>) {
  const {
    isLoading,
    error,
    data,
    isEmpty,
    isAccessDenied,
    renderLoader,
    renderError,
    renderEmptyState,
    EmptyStateProps,
    ErrorStateProps,
    renderAccessDenied,
    renderData,
    children,
    withSuspense = true,
    remountOnError,
  } = props;
  const { t } = useTranslation();
  const errorLogged = useRef(false);

  const { key: remountKey, ...rest } = useRemountProps({ remountOnError });

  const loader = useMemo(() => {
    return renderLoader ? renderLoader() : <AsyncViewLoader />;
  }, [renderLoader]);

  if (isLoading) {
    return loader;
  }

  if (isAccessDenied) {
    return renderAccessDenied ? (
      renderAccessDenied()
    ) : (
      <Box>
        <Typography>Access denied</Typography>
      </Box>
    );
  }

  if (error) {
    if (!errorLogged.current) {
      errorLogged.current = true;
      logError(error);
    }

    return renderError ? renderError(error) : <ErrorComponent {...ErrorStateProps} />;
  }

  if (isEmpty ?? isEmptyFn(data)) {
    return renderEmptyState ? (
      renderEmptyState()
    ) : (
      <EmptyState
        Icon={SvgSearchMd}
        size="sm"
        title={t('common:EmptyState.NoDataFound')}
        {...EmptyStateProps}
      />
    );
  }

  const renderEl = typeof renderData === 'function' ? renderData(data as NonNullable<T>) : children;
  const renderElWithSuspense = withSuspense ? (
    <React.Suspense fallback={loader}>{renderEl}</React.Suspense>
  ) : (
    renderEl
  );

  return (
    <ErrorBoundary fallback={renderedErrorComponent} key={remountKey} {...rest}>
      {renderElWithSuspense}
    </ErrorBoundary>
  );
}

export default AsyncView;
