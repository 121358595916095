import type {
  BlockComponentType,
  BlockComponentStateId,
  ComputedVisibilityPayloadType,
} from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type {
  TypographyColors,
  TypographyVariants,
  TypographyWeights,
} from '../../types/typography';

export enum StepperContentType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export type IconType =
  | string
  | {
      conditionalValueFilters: {
        propertyValue: string;
        conditions: boolean;
      }[];
      defaultValue: string;
    };

export type Step = {
  value: string;
  label?: string;
  icon?: IconType;
  description?: string;
  subSteps?: Omit<Step, 'subSteps'>[];
  visibility?: ComputedVisibilityPayloadType;
};

export type StaticContent = {
  type: StepperContentType.STATIC;
  steps?: Step[];
  defaultStepValue?:
    | string
    | {
        conditionalValueFilters: {
          propertyValue: string;
          conditions: boolean;
        }[];
        defaultValue?: string;
      };
};

export type DynamicContent = {
  type: StepperContentType.DYNAMIC;
  data?: unknown[];
  stepMappings?: Step;
};

type StepperContent = StaticContent | DynamicContent;

type StepperAppearance = {
  orientation: 'horizontal' | 'vertical';
  size: 'sm' | 'md' | 'lg';
  showStepNumber?: boolean;
  stepDetails: {
    clickable?: boolean;
    orientation: 'horizontal' | 'vertical';
    label?: {
      weight?: TypographyWeights;
      color?: TypographyColors;
      variant?: TypographyVariants;
    };
    description?: {
      weight?: TypographyWeights;
      color?: TypographyColors;
      variant?: TypographyVariants;
    };
  };
  styles?: Pick<
    StyleObject,
    | 'padding'
    | 'margin'
    | 'height'
    | 'maxHeight'
    | 'minHeight'
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'layout'
    | 'gap'
  >;
};

export type StepperComponentType = BlockComponentType<'Stepper', StepperContent, StepperAppearance>;

export type StepperComponentStateType = Pick<
  StepperComponentType,
  'appearance' | 'componentType' | 'content'
> & {
  activeStepId: string;
  currentStep?: { value: string; label: string; icon?: string };
  steps?: Step[];
} & BlockComponentStateId;
