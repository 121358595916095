import type { KeyBindingsType } from '@unifyapps/carbon/components/CommentsWidget/component/CommentInput/types';
import { CommentInputActions } from '@unifyapps/carbon/components/MessageInput/MessageInput';
import { enterKeyBehaviorEnum } from '@unifyapps/defs/blocks/common/CommentMessageInputSchemas/schema';

/**
 * On enter key press, the default behavior of Plain TextArea is to
 * move to newline. But when EnterKeyBehavior is SendMessage
 * we want to send message and prevent from moving to newLine.
 *
 */
export const KEY_BINDING_BY_ENTER_BEHAVIOUR = {
  [enterKeyBehaviorEnum.SendMessage]: [
    {
      key: 'Enter',
      uniqueKey: 'Enter',
      shiftKey: false,
      action: CommentInputActions.SubmitAndPreventDefault,
    },
  ],
  [enterKeyBehaviorEnum.StartNewLine]: [
    {
      key: 'Enter',
      shiftKey: false,
      uniqueKey: 'Enter-MetaKey',
      metaKey: true,
      action: CommentInputActions.Submit,
    },
  ],
} as Record<enterKeyBehaviorEnum, KeyBindingsType[]>;
