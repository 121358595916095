import _forEach from 'lodash/forEach';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { produce } from 'immer';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getBaseDeviceOfThePage } from '../utils/block';
import { getNextInterfacePage } from '../utils/getNextInterfacePage';
import InterfacePageHelper from '../../../helper/InterfacePage';
import type { DeviceDetailsType } from '../../GlobalStateStore';

export type OnDeviceSwitchActionProps = {
  newDeviceDetailsType: DeviceDetailsType;
  onSuccess: () => void;
};

const updateInterfacePage = ({
  set,
  interfacePageId,
  interfacePage,
}: {
  set: InterfaceStoreStateGetterAndSetter['set'];
  interfacePageId: string;
  interfacePage: InterfacePageEntity;
}) => {
  set((state) => {
    return produce(state, (draft) => {
      draft.touchedBlocks = new Set();
      draft.interfacePages[interfacePageId] = interfacePage;
    });
  });
};

const doubleRequestAnimationFrame = (callback: () => void) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback);
  });
};

const getDeviceSwitchAction =
  ({
    get,
    set,
    getDeviceDetails,
    setBlocksState,
    resetBlockRefs,
    registry,
    handleDeviceVariantChange,
  }: InterfaceStoreStateGetterAndSetter) =>
  ({
    newDeviceDetailsType,
    onSuccess,
  }: {
    newDeviceDetailsType: DeviceDetailsType;
    onSuccess: () => void;
  }) => {
    const { device: currentDevice } = getDeviceDetails();

    const interfaceRecord = get().interfaceRecord;
    const activeInterfacePageId = get().activeInterfacePageId;
    const touchedBlocks = get().touchedBlocks;
    const interfacePage = get().interfacePages[activeInterfacePageId];

    const destinationDevice = newDeviceDetailsType.device;

    const baseDevice = getBaseDeviceOfThePage(interfaceRecord);

    resetBlockRefs();
    // Now we have made changes in interfacePage
    // We need to reset the block states of the blocks in the interfacePage
    if (currentDevice === baseDevice) {
      // when going from base -> non-base, we will create the new interfacePage after doing
      // all the changes in the non-base devices
      const nextInterfacePage = getNextInterfacePage({
        currentDevice,
        interfacePage,
        baseDevice,
        registry,
        touchedBlocks,
      });

      // then for each destination device we will create new block states using their initial state
      const updatedBlockStates = {};
      _forEach(
        InterfacePageHelper.getDeviceBlocks(nextInterfacePage, destinationDevice),
        (block, blockId) => {
          const nextBlockState = registry.getBlockInitialState(
            block.component.componentType,
            block,
          );

          updatedBlockStates[blockId] = nextBlockState;
        },
      );

      setBlocksState(updatedBlockStates);

      handleDeviceVariantChange({
        operation: 'SWITCH_DEVICE',
        payload: {
          deviceDetails: newDeviceDetailsType,
        },
      });

      // then we will set the interfacePage to the new interfacePage
      updateInterfacePage({
        set,
        interfacePageId: activeInterfacePageId,
        interfacePage: nextInterfacePage,
      });

      // when going from non-base -> base, prepare the block state first
    } else if (destinationDevice === baseDevice) {
      const updatedBlockStates = {};

      _forEach(
        InterfacePageHelper.getDeviceBlocks(interfacePage, currentDevice),
        (block, blockId) => {
          const destinationBlock = InterfacePageHelper.getBaseDeviceBlock(interfacePage, blockId);

          if (!destinationBlock) {
            return;
          }

          const nextBlockState = registry.getBlockInitialState(
            block.component.componentType,
            destinationBlock,
          );

          updatedBlockStates[blockId] = nextBlockState;
        },
      );

      handleDeviceVariantChange({
        operation: 'SWITCH_DEVICE',
        payload: {
          deviceDetails: newDeviceDetailsType,
        },
      });

      doubleRequestAnimationFrame(() => {
        setBlocksState(updatedBlockStates);
        const nextInterfacePage = getNextInterfacePage({
          currentDevice,
          interfacePage,
          baseDevice,
          registry,
          touchedBlocks,
        });

        updateInterfacePage({
          set,
          interfacePageId: activeInterfacePageId,
          interfacePage: nextInterfacePage,
        });
      });

      // when going from non-base -> non-base, prepare the block state first
    } else {
      // prepare interface page
      const nextInterfacePage = getNextInterfacePage({
        currentDevice,
        interfacePage,
        baseDevice,
        registry,
        touchedBlocks,
      });

      // then for each destination device we will create new block states using their initial state
      const updatedBlockStates = {};

      // for each current device block, we will create new block states using their initial state
      _forEach(
        InterfacePageHelper.getDeviceBlocks(interfacePage, currentDevice),
        (block, blockId) => {
          const destinationBlock = InterfacePageHelper.getBaseDeviceBlock(
            nextInterfacePage,
            blockId,
          );

          if (!destinationBlock) {
            return;
          }

          const nextBlockState = registry.getBlockInitialState(
            block.component.componentType,
            destinationBlock,
          );

          updatedBlockStates[blockId] = nextBlockState;
        },
      );

      _forEach(
        InterfacePageHelper.getDeviceBlocks(interfacePage, destinationDevice),
        (block, blockId) => {
          const nextBlockState = registry.getBlockInitialState(
            block.component.componentType,
            block,
          );

          updatedBlockStates[blockId] = nextBlockState;
        },
      );

      doubleRequestAnimationFrame(() => {
        setBlocksState(updatedBlockStates);

        handleDeviceVariantChange({
          operation: 'SWITCH_DEVICE',
          payload: {
            deviceDetails: newDeviceDetailsType,
          },
        });

        updateInterfacePage({
          set,
          interfacePageId: activeInterfacePageId,
          interfacePage: nextInterfacePage,
        });
      });
    }

    onSuccess();
  };

export default getDeviceSwitchAction;
