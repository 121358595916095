'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { InterfaceResourceStrategyType } from './constants';
import { InterfaceResourceStrategies } from './constants';

type InterfaceResourceStrategyContextType = {
  strategy: InterfaceResourceStrategyType;
};

const InterfaceResourceStrategyContext = createContext<InterfaceResourceStrategyContextType>({
  strategy: InterfaceResourceStrategies.Standard,
});

export const useInterfaceResourceStrategy = () => {
  const context = useContext(InterfaceResourceStrategyContext);

  return context;
};

type Props = PropsWithChildren<{
  strategy: InterfaceResourceStrategyType;
}>;

export function InterfaceResourceStrategyProvider({ children, strategy }: Props) {
  const contextValue = useMemo(() => {
    return { strategy };
  }, [strategy]);

  return (
    <InterfaceResourceStrategyContext.Provider value={contextValue}>
      {children}
    </InterfaceResourceStrategyContext.Provider>
  );
}
