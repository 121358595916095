import { useLayoutEffect, useMemo, useRef } from 'react';
import { useBlocksFromPage, useInterfaceStore } from '../../../../stores/InterfaceStore';
import { BLOCK_STYLES } from '../../../../const';

const useCustomBlockStyles = () => {
  const blocks = useBlocksFromPage();
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();
  const previousCustomCSSRef = useRef('');

  const combinedCustomCSS = useMemo(() => {
    const _blocks = Object.values(blocks ?? {});
    return _blocks
      .map((block) => block.additional?.customCSS)
      .filter(Boolean)
      .join('\n');
  }, [blocks]);

  useLayoutEffect(() => {
    // Only proceed if the combined custom CSS has changed
    if (combinedCustomCSS !== previousCustomCSSRef.current) {
      previousCustomCSSRef.current = combinedCustomCSS;
      const id = `${BLOCK_STYLES}-${activeInterfacePageId}`;
      let styleElementFromDOM = document.querySelector(`#${id}`);

      // Create the style element if it doesn't exist
      if (!styleElementFromDOM) {
        const styleElement = document.createElement('style');
        styleElement.id = id;
        document.head.appendChild(styleElement);
        styleElementFromDOM = styleElement;
      }

      // Update the style element's content
      styleElementFromDOM.innerHTML = combinedCustomCSS;
    }
  });
};

export default useCustomBlockStyles;
