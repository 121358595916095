import { useDataSourceRecordStore } from '../../../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../../../helper/DataSourceHelper';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import DependencyFlowHelper from '../../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';

function usePreviewDataSourceIds() {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const getDataSourceRecordsState = useDataSourceRecordStore().getState;
  const previewTriggeredDataSources = useGlobalStateStore().use.previewTriggeredDataSources();
  const previewDataSourceIds = Object.entries(previewTriggeredDataSources).reduce<string[]>(
    (acc, [id, value]) => {
      if (value) acc.push(id);
      return acc;
    },
    [],
  );

  const freePreviewDataSourceIds = previewDataSourceIds.filter((id) => {
    const intraDependencies = DataSourceHelper.getPreviewDataSourceTentativeDependencies(
      id,
      getDataSourceRecordsState,
    );
    return DependencyFlowHelper.areAllDependenciesFinished({
      dependencies: intraDependencies[id],
      dependencyFlowInstance: dsDependencyFlow,
    });
  });

  return {
    ids: freePreviewDataSourceIds,
  };
}

export default usePreviewDataSourceIds;
