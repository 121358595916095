import { useCallback, type SyntheticEvent } from 'react';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { CardComponentType, CardBlockStateType } from '@unifyapps/defs/types/blocks';
import BlockRenderer from '@unifyapps/carbon/no-code/components/BlockRenderer';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import _set from 'lodash/set';
import { isEmptyBlock } from '@unifyapps/carbon/no-code/utils/block';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { memo } from 'react';
import { CardLayout, CardLayoutSlots } from './CardLayout';
import { useCollapsibleParams } from './hooks/useCollapsibleParams';

function Card({
  blockState,
  dataAttributes,
  events,
  component,
  updateBlockState,
  blockRef,
  computedBlockState,
}: BlockComponentProps<CardComponentType, CardBlockStateType, CardBlockStateType>) {
  const { appearance } = computedBlockState;
  const { slots } = component;

  const { emitEvent } = useBlockEvents(events);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnClick, domEvent: e });
    },
    [emitEvent],
  );

  const hasClickEvent = events?.some((event) => event.eventType === 'onClick');

  const { collapsibleParams } = useCollapsibleParams({
    blockState,
    updateBlockState,
  });

  return (
    <CardLayout
      appearance={appearance}
      blockRef={blockRef}
      collapsibleParams={collapsibleParams}
      dataAttributes={dataAttributes}
      hasClickEvent={hasClickEvent}
      onClick={onClick}
    >
      <CardLayout.Slot name={CardLayoutSlots.Header}>
        {slots?.header?.blockId && !isEmptyBlock(slots.header.blockId) ? (
          <BlockRenderer blockId={slots.header.blockId} key={slots.header.blockId} />
        ) : null}
      </CardLayout.Slot>
      <CardLayout.Slot name={CardLayoutSlots.Body}>
        {slots?.body?.blockId && !isEmptyBlock(slots.body.blockId) ? (
          <BlockRenderer blockId={slots.body.blockId} />
        ) : null}
      </CardLayout.Slot>
      <CardLayout.Slot name={CardLayoutSlots.Footer}>
        {slots?.footer?.blockId && !isEmptyBlock(slots.footer.blockId) ? (
          <BlockRenderer blockId={slots.footer.blockId} />
        ) : null}
      </CardLayout.Slot>
    </CardLayout>
  );
}

export default memo(Card);
