import type { DependsOnType } from '@unifyapps/defs/types/page';
import { DependencyHelper } from '../helpers/DependencyHelper';
import { extractPropertyPaths } from './extractPropertyPaths';

export type DependencyHandler<T> = {
  path: string;
  value: unknown;
  dependencies: BuildDependencyGraphItem<T>;
  key: string;
  mainKey: string;
};

export type BuildDependencyGraphItem<T> = {
  /**
   * output contains the value of handlers that are passed while building the dependency graph
   */
  output?: T;
  /**
   * A list of entity IDs that this entity depends on.
   * This is useful for tracking inter-entity dependencies.
   */
  dependsOn?: DependsOnType[];
};

function removeDuplicateDependencyPaths<T>(dependencies: BuildDependencyGraphItem<T>): void {
  if (!dependencies.dependsOn) return;

  dependencies.dependsOn = dependencies.dependsOn.map((dependsOn) => {
    return DependencyHelper.makePropertiesUnique(dependsOn);
  });
}

export type BuildDependencyGraphParams<T> = {
  objects: Record<string, unknown>;
  isValidEntity: (entityId: string) => boolean;
  handlers?: ((props: DependencyHandler<T>) => void)[];
  dedupeHandlers?: ((params: T) => T)[];
};

function processObject<T>({
  isValidEntity,
  handlers,
  mainKey,
  dependencies,
  obj,
  parentPath,
}: {
  obj: unknown;
  parentPath: string;
  mainKey: string;
  dependencies: BuildDependencyGraphItem<T>;
  isValidEntity: (entityId: string) => boolean;
  handlers: ((props: DependencyHandler<T>) => void)[];
}): void {
  // Handle cycles and invalid objects
  if (!obj || typeof obj !== 'object') return;

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      const currentPath = `${parentPath}[${index}]`;
      processValue({
        value: item,
        isValidEntity,
        key: '',
        mainKey,
        handlers,
        dependencies,
        path: currentPath,
      });
      processObject({
        obj: item,
        parentPath: currentPath,
        mainKey,
        dependencies,
        isValidEntity,
        handlers,
      });
    });
    return;
  }

  for (const [key, value] of Object.entries(obj)) {
    const currentPath = parentPath ? `${parentPath}.${key}` : key;
    processValue({
      value: value as unknown,
      isValidEntity,
      key,
      mainKey,
      dependencies,
      path: currentPath,
      handlers,
    });
    processObject({
      obj: value,
      parentPath: currentPath,
      mainKey,
      dependencies,
      isValidEntity,
      handlers,
    });
  }
}

function processValue<T>({
  dependencies,
  mainKey,
  key,
  isValidEntity,
  value,
  path,
  handlers,
}: {
  value: unknown;
  path: string;
  key: string;
  mainKey: string;
  dependencies: BuildDependencyGraphItem<T>;
  isValidEntity: (entityId: string) => boolean;
  handlers: ((props: DependencyHandler<T>) => void)[];
}): void {
  handlers.forEach((handler) => {
    handler({ path, value, dependencies, key, mainKey });
  });

  if (typeof value !== 'string') return;

  const { result } = extractPropertyPaths(value, isValidEntity);

  for (const [entityId, paths] of Object.entries(result)) {
    if (entityId === mainKey) continue;
    if (!dependencies.dependsOn) {
      dependencies.dependsOn = [];
    }
    if (!paths?.size) continue;

    let existingDependsOn = dependencies.dependsOn.find((dep) => dep.id === entityId);
    if (existingDependsOn) {
      existingDependsOn = DependencyHelper.updateDependsOn(existingDependsOn, paths);
    } else {
      const newDependsOn = DependencyHelper.createDependsOn(entityId, Array.from(paths));
      dependencies.dependsOn.push(newDependsOn);
    }
  }
}

export function buildDependencyGraph<T>({
  dedupeHandlers,
  handlers = [],
  objects,
  isValidEntity,
}: BuildDependencyGraphParams<T>): Record<string, BuildDependencyGraphItem<T> | undefined> {
  const dependencyGraph: Record<string, BuildDependencyGraphItem<T> | undefined> = {};

  for (const [key, value] of Object.entries(objects)) {
    const dependencies: BuildDependencyGraphItem<T> = { dependsOn: [] };
    processObject({
      obj: value,
      parentPath: '',
      mainKey: key,
      dependencies,
      isValidEntity,
      handlers,
    });

    dependencyGraph[key] = dependencies;
  }

  for (const key in dependencyGraph) {
    const dependencies = dependencyGraph[key];

    if (dependencies) {
      removeDuplicateDependencyPaths(dependencies);
    }

    if (dependencies?.output && dedupeHandlers) {
      dependencies.output = dedupeHandlers.reduce(
        (acc, dedupeHandler) => dedupeHandler(acc),
        dependencies.output,
      );
    }
  }

  return dependencyGraph;
}
