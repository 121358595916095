import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import { cva } from 'class-variance-authority';
import type { MediaType } from '../../MediaViewer/types';
import type { MediaOverrides, SlotPropsType } from '../types';
import { SpaciousMediaItem } from './SpaciousMediaItem';

const containerClassNameVariants = cva('size-full flex-wrap', {
  variants: {
    size: {
      sm: 'gap-md',
      md: 'gap-md',
      lg: 'gap-lg',
    },
  },
});

export function SpaciousMediaContainer({
  media,
  className,
  openMediaViewer,
  size,
  maxUpfrontItems,
  showCaption,
  onRemoveItem,
  overrides,
  slotProps,
  itemClassName,
}: {
  media: MediaType[];
  className?: string;
  openMediaViewer: (index: number) => void;
  size: 'sm' | 'md' | 'lg';
  maxUpfrontItems: number;
  showCaption: boolean;
  onRemoveItem?: (id: string) => void;
  overrides?: MediaOverrides;
  slotProps?: SlotPropsType;
  itemClassName?: string;
}) {
  const overflowCount = media.length - maxUpfrontItems;
  const maxLength = Math.min(media.length, maxUpfrontItems);

  const {
    className: typographyClassName,
    weight,
    variant,
    color,
  } = slotProps?.overflowTypography ?? {};

  return (
    <Stack className={clsx(containerClassNameVariants({ size }), className)} direction="row">
      {media.slice(0, maxLength).map((item, index) =>
        overflowCount <= 0 || index < maxLength - 1 ? (
          <SpaciousMediaItem
            className={itemClassName}
            item={item}
            key={item.id}
            onClick={() => {
              openMediaViewer(index);
            }}
            onRemoveItem={onRemoveItem}
            overrides={overrides}
            showCaption={showCaption}
            size={size}
            slotProps={slotProps}
          />
        ) : (
          <Box
            className="relative cursor-pointer"
            key={item.id}
            onClick={() => {
              openMediaViewer(index);
            }}
          >
            <SpaciousMediaItem
              className={clsx('bg-overlay', itemClassName)}
              item={item}
              onRemoveItem={onRemoveItem}
              overrides={overrides}
              showCaption={showCaption}
              size={size}
              slotProps={slotProps}
            />
            <Typography
              className={clsx(
                'bg-overlay text-primary_on-brand absolute inset-0 flex size-full items-center justify-center rounded-sm opacity-60',
                typographyClassName,
              )}
              color={color}
              variant={variant ?? 'display-xs'}
              weight={weight ?? 'medium'}
            >
              {`+${overflowCount + 1}`}
            </Typography>
          </Box>
        ),
      )}
    </Stack>
  );
}
