import type { AutomationInputsType, NodeContextType } from '@unifyapps/defs/types/dataSource';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import _pick from 'lodash/pick';
import { useExecuteDataSourceCallbacks } from '../../hooks/useExecuteDataSourceCallbacks';
import { useAddSystemParameters } from '../DataSourceWorkflowNode/useAddSystemParameters';
import { useGenerateQueryOptionsFromAdvancedOptions } from '../../hooks/useGenerateQueryOptionsFromAdvancedOptions';
import type { InfiniteDataSourceWorkflowNodeProps } from '../../types';
import { useComputeDataSourcePayload } from '../../hooks/useComputeDataSourcePayload';
import { INFINITE_KEYS_TO_MATCH } from '../../consts';
import useInfiniteDataSourceWorkflowNode from './useInfiniteDataSourceWorkflowNode';

function InfiniteDataSourceWorkflowNode({
  id,
  originalDataSourceId,
  requestId,
  inputs,
  context,
  lookupRequests,
  onQueryCompletion,
  name,
  callbacks,
  advancedOptions,
  setDataSourceState,
  getCurrentQueryResult,
  refetchOnPayloadChange,
  disabled,
}: InfiniteDataSourceWorkflowNodeProps) {
  const computedPayload = useComputeDataSourcePayload<{
    inputs: AutomationInputsType;
    context?: NodeContextType;
  }>({
    data: { inputs, context },
    refetchOnPayloadChange,
  });

  const decoratedInputs = useAddSystemParameters(computedPayload);
  const queryOptions = useGenerateQueryOptionsFromAdvancedOptions(advancedOptions);
  const queryResult = useInfiniteDataSourceWorkflowNode({
    payload: {
      context: computedPayload.context,
      id: originalDataSourceId,
      inputs: decoratedInputs,
      lookupRequests,
    },
    enabled: !disabled,
    debugParams: {
      name,
      requestId,
    },
    queryOptions,
  });

  useEffect(() => {
    const currentQueryResult = getCurrentQueryResult({ id, requestId });

    if (
      isEqual(
        _pick(queryResult, INFINITE_KEYS_TO_MATCH),
        _pick(currentQueryResult, INFINITE_KEYS_TO_MATCH),
      )
    )
      return;
    setDataSourceState({
      id,
      result: queryResult,
      requestId,
    });
  }, [getCurrentQueryResult, id, queryResult, requestId, setDataSourceState]);

  useExecuteDataSourceCallbacks({
    callbacks,
    id,
    onQueryCompletion,
    requestId,
    queryResult: {
      error: queryResult.error,
      isLoading: queryResult.isLoading,
      isFetching: queryResult.isFetching,
    },
  });

  return null;
}

export default InfiniteDataSourceWorkflowNode;
