import { memo } from 'react';
import type { ButtonColors } from '@unifyapps/ui/components/Button';
import { Button } from '@unifyapps/ui/components/Button';
import { useTranslation } from '@unifyapps/i18n/client';
import useEventCallback from '@unifyapps/hooks/useEventCallback';

export type ModalFooterHorizontalRightProps = {
  className?: string;
  startDecoratorNode?: React.ReactNode;
  submitLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  //todo: rename this to secondaryActionDisabled
  secondaryDisabled?: boolean;
  isPending?: boolean;
  hidePrimaryAction?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  primaryColor?: ButtonColors;
};

//todo: handle primary and secondary actions based on config. Remove explicit 'cancel' variable. Should be primary and secondary.
export function ModalFooterHorizontalRight({
  startDecoratorNode,
  submitLabel,
  cancelLabel,
  disabled,
  secondaryDisabled,
  isPending,
  onCancel,
  onSubmit,
  hidePrimaryAction,
  primaryColor,
}: ModalFooterHorizontalRightProps) {
  const { t } = useTranslation('common');
  const handleSubmit = useEventCallback(() => {
    onSubmit?.();
  });

  return (
    <>
      {startDecoratorNode}
      {onCancel ? (
        <Button
          color="neutral"
          disabled={secondaryDisabled}
          onClick={onCancel}
          size="md"
          variant="outline"
        >
          {cancelLabel ?? t('Actions.Cancel')}
        </Button>
      ) : null}
      {!hidePrimaryAction ? (
        <Button
          color={primaryColor ?? 'brand'}
          disabled={disabled || isPending}
          loading={isPending}
          onClick={handleSubmit}
          size="md"
          type="button"
          variant="solid"
        >
          {submitLabel}
        </Button>
      ) : null}
    </>
  );
}

export default memo(ModalFooterHorizontalRight);
