'use client';
import type { BlockType } from '@unifyapps/defs/types/block';
import { createContext, useContext } from 'react';

export type RuntimeBlockPreComputationValue = {
  runtimeSourceBlockIdVsChildrenBlocks?: Record<string, BlockType[] | undefined>;
  runtimeSourceBlockIds?: Set<string>;
  blockIdVsClosestRuntimeSourceBlockAncestor?: Map<string, string>;
};

export const RuntimeBlockPreComputationContext = createContext<RuntimeBlockPreComputationValue>({});

export const useRuntimeBlockPreComputationContext = () => {
  const context = useContext(RuntimeBlockPreComputationContext);
  return context;
};
