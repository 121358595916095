import type { TooltipProps as BaseTooltipProps } from '@mui/joy/Tooltip';
import { default as BaseTooltip } from '@mui/joy/Tooltip';
import { forwardRef, memo, useMemo } from 'react';
import { clsx } from 'clsx';
import { Typography } from '../Typography';
import Stack from '../../_components/Stack';
import { usePopoverGlobalAnchorContext } from '../Popover/PopoverGlobalContainer';
import { slowCn } from '../../lib/utils';
import type { TooltipProps } from './types';
import { tooltipArrowClassname, tooltipVariants } from './styles';
import { UA_TOOLTIP_CLASS } from './const';
import './tooltip.vars.css';

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const {
    className,
    arrow = true,
    enterDelay = 0,
    title,
    description,
    titleNode,
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorNode,
    offset = 10,
    modifiers,
    disabled = false,
    mode = 'light',
    slotProps,
    ...rest
  } = props;

  const globalContainerEl = usePopoverGlobalAnchorContext();
  const { className: titleClassName, style: titleStyle } = slotProps?.title ?? {};

  const tooltipContent = useMemo(() => {
    if (titleNode) return titleNode;

    return title || description ? (
      <>
        {title ? (
          <Stack alignItems="center" className="gap-sm text-fg-senary text-xs" direction="row">
            {StartDecoratorComponent ? (
              <StartDecoratorComponent height={12} width={12} />
            ) : (
              startDecoratorNode
            )}
            <Typography
              className={clsx('text-white dark:text-black', titleClassName)}
              style={titleStyle}
              variant="text-xs"
              weight={description ? 'semi-bold' : 'regular'}
            >
              {title}
            </Typography>
            {endDecoratorNode}
          </Stack>
        ) : null}
        {description ? (
          <Typography color="text-placeholder_subtle" variant="text-xs" weight="medium">
            {description}
          </Typography>
        ) : null}
      </>
    ) : (
      ''
    );
  }, [
    StartDecoratorComponent,
    description,
    endDecoratorNode,
    startDecoratorNode,
    title,
    titleClassName,
    titleNode,
    titleStyle,
  ]);

  // @ts-expect-error -- typescript wants us to provide root.open prop,
  // but setting it to true/false/undefined prevents tooltip from opening.
  const tooltipSlotProps = useMemo<BaseTooltipProps['slotProps']>(() => {
    return {
      root: {
        container: globalContainerEl,
        className: tooltipVariants({
          description: Boolean(description),
          className: clsx('isolate', UA_TOOLTIP_CLASS, className, slotProps?.rootClassName),
        }),
        'data-color-scheme': mode,
        ...slotProps?.root,
      },
      arrow: {
        className: slowCn(tooltipArrowClassname, slotProps?.arrowClassName),
      },
    };
  }, [
    globalContainerEl,
    description,
    className,
    slotProps?.rootClassName,
    slotProps?.root,
    slotProps?.arrowClassName,
    mode,
  ]);

  const finalModifiers = useMemo<BaseTooltipProps['modifiers']>(() => {
    return offset
      ? [{ name: 'offset', options: { offset: [0, offset] } }, ...(modifiers || [])]
      : modifiers;
  }, [modifiers, offset]);

  return (
    <BaseTooltip
      arrow={arrow}
      enterDelay={enterDelay}
      modifiers={finalModifiers}
      ref={ref}
      slotProps={tooltipSlotProps}
      title={disabled ? null : tooltipContent}
      variant="solid"
      {...rest}
    />
  );
});

Tooltip.displayName = 'Tooltip';

export default memo(Tooltip);
