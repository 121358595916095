import type { CSSProperties } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import { cva } from 'class-variance-authority';
import { useTranslation } from '@unifyapps/i18n/client';
import type { MediaType } from '../../MediaViewer/types';
import type { MediaOverrides, SlotPropsType } from '../types';
import { CompactMediaItem } from './CompactMediaItem';

const textVariants = cva('', {
  variants: {
    size: {
      sm: 'text-xxs',
      md: 'text-xs',
      lg: 'text-sm',
    },
  },
});

export function CompactMediaContainer({
  media,
  className,
  openMediaViewer,
  onRemoveItem,
  maxUpfrontItems,
  cols,
  size,
  style,
  overrides,
  itemClassName,
  slotProps,
}: {
  media: MediaType[];
  className?: string;
  openMediaViewer: (index: number) => void;
  maxUpfrontItems: number;
  onRemoveItem?: (id: string) => void;
  cols: number;
  size: 'sm' | 'md' | 'lg';
  style?: CSSProperties | undefined;
  overrides?: MediaOverrides;
  itemClassName?: string;
  slotProps?: SlotPropsType;
}) {
  const { t } = useTranslation('common');
  const overflowCount = media.length - maxUpfrontItems;
  const maxLength = Math.min(media.length, maxUpfrontItems);

  return (
    <Stack className={clsx(`gap-lg grid flex-1 grid-cols-${cols}`, className)} style={style}>
      {media.slice(0, maxLength).map((item, index) =>
        overflowCount <= 0 || index < maxLength - 1 ? (
          <CompactMediaItem
            className={clsx('flex-1', itemClassName)}
            item={item}
            key={item.id}
            onClick={() => {
              openMediaViewer(index);
            }}
            onRemoveItem={onRemoveItem}
            overrides={overrides}
            size={size}
            slotProps={slotProps}
          />
        ) : (
          <Box
            className="bg-primary_on-brand border-secondary p-sm flex min-h-[30px] w-full max-w-[17.5rem] items-center rounded-sm border"
            key={item.id}
            onClick={() => {
              openMediaViewer(index);
            }}
            role="button"
          >
            <Typography variant={textVariants({ size }) as TypographyVariants}>
              {t('common:AttachmentsOverflow', { overflowCount: overflowCount + 1 })}
            </Typography>
          </Box>
        ),
      )}
    </Stack>
  );
}
