import _reduce from 'lodash/reduce';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import { useMemo } from 'react';
import get from 'lodash/get';
import useDynamicMemo from '../useDynamicMemo';
import { useComputeConditionEvaluationGetter } from '../useComputeConditionEvaluationGetter';
import { optimisedEvaluateExpression } from '../../utils/evaluateExpression';
import { omitKeysFromEntity, pickKeysFromEntity } from '../../utils/block';
import { useDependencyGraphProvider } from '../../context/DependencyGraphContext/DependencyGraphContext';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import { createExpressionEvaluator } from '../../utils/indirectEval';
import { DependencyHelper } from '../../../dependency/helpers/DependencyHelper';
import { BlockHelper } from '../../../helpers/BlockHelper';
import { useRegistryContext } from '../../components/RegistryProvider';
import { useGetShouldEvaluateResult } from '../computeContext/useGetShouldEvaluateResult';
import { useGetNoCodeComputedData } from '../computeContext/useGetNoCodeComputedData';

interface UseComputeBlockStateProps {
  blockState: BlockStateUnionType;
  context: Record<string, unknown>;
}

// This hook is used to return a computed block from the blockState object
function useComputeBlockState<T>(props: UseComputeBlockStateProps) {
  const { blockState, context } = props;
  const { buildEntityDependency } = useDependencyGraphProvider();
  const shouldEvaluateResult = useGetShouldEvaluateResult();
  const { device } = useGlobalStateStore().use.deviceDetails();
  const block = useInterfaceStore().use.block.fromDevice({
    blockId: blockState.id,
    device,
  });
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const { registry } = useRegistryContext();
  const { isAllDepsCalculated } = useInterfaceStore().use.page.metadata.flags() ?? {};

  const keysToOmit = registry.getKeysToOmitInComputation(blockState.componentType);

  const blockStateToEvaluate = omitKeysFromEntity(blockState, ['events', ...keysToOmit]);
  // if a block has dPaths stored in it, use them to calculate dependencies
  // otherwise, create dependencies from the block state at runtime
  const blockDependencies = useMemo(() => {
    if (isAllDepsCalculated)
      return DependencyHelper.getPathsFromDynamicPaths(BlockHelper.getDynamicPaths(block));
    const blockDeps = buildEntityDependency(blockStateToEvaluate, 'blocks')[blockState.id];

    return DependencyHelper.getPathsFromDynamicPaths(DependencyHelper.getDynamicPaths(blockDeps));
  }, [block, blockState.id, blockStateToEvaluate, buildEntityDependency, isAllDepsCalculated]);

  const expressionEvaluator = createExpressionEvaluator(context);

  const dependentEntities = _reduce(
    blockDependencies,
    (acc, dependency) => {
      const expression = get(blockStateToEvaluate, dependency) as unknown;

      if (typeof expression !== 'string') return acc;

      const result = optimisedEvaluateExpression<unknown>({
        context,
        expression,
        expressionEvaluator,
        shouldEvaluateResult,
      });

      acc.push(result);

      return acc;
    },
    [] as unknown[],
  );

  const getComputedFilters = useComputeConditionEvaluationGetter();

  return useDynamicMemo(() => {
    const blockStateWithComputedFilters = getComputedFilters(blockStateToEvaluate, context);
    const blockId = blockState.id;

    const contextWithComputedFilters = {
      ...context,
      [blockId]: {
        ...(context[blockId] as Record<string, unknown>),
        ...(blockStateWithComputedFilters as Record<string, unknown>),
      },
    };

    const evaluatedBlockState = getNoCodeComputedData<T>(
      blockStateWithComputedFilters as Record<string, unknown>,
      contextWithComputedFilters,
    );

    return {
      ...evaluatedBlockState,
      ...pickKeysFromEntity(blockState, ['events', ...keysToOmit]),
    } as T;
  }, [blockState, ...dependentEntities, getComputedFilters]);
}

export default useComputeBlockState;
