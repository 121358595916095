import { produce } from 'immer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { ExecuteWorkflowNodeResponse } from '@unifyapps/network/generated/models/executeWorkflowNodeResponse';
import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';
import type { ExecuteWorkflowNodeResponseLookupReferences } from '@unifyapps/network/generated/models/executeWorkflowNodeResponseLookupReferences';
import { mutateDataWithLookupReferences } from './mutateDataWithLookupReferences';

const wildcard = `[*].`;

const adaptResponseRecursively = ({
  keys,
  data,
  lookupReferences,
  name,
}: {
  name: string;
  keys: string;
  data: Record<string, unknown>;
  lookupReferences: ExecuteWorkflowNodeResponseLookupReferences;
}) => {
  const hasWildcard = keys.includes(wildcard);

  if (hasWildcard) {
    // path-> should return the remaining key after finding the first wildCard
    // example-> key: messages[*].message[*].data.citations[*].appName-> list:messages, path:message[*].data.citations[*].appName
    const [list, ...temp] = keys.split(wildcard);
    const path: string = temp.join(wildcard);

    if (!list || !path) {
      return;
    }
    const listData = get(data, list) as unknown[];

    if (!Array.isArray(listData)) {
      return;
    }

    if (path.includes(wildcard)) {
      return listData.forEach((item: Record<string, unknown>) => {
        adaptResponseRecursively({ keys: path, data: item, lookupReferences, name });
      });
    }

    //leaf condition
    listData.forEach((item) => {
      mutateDataWithLookupReferences({
        data: item as Record<string, unknown>,
        path,
        name,
        lookupReferences,
      });
    });
  } else {
    mutateDataWithLookupReferences({
      data,
      path: keys,
      name,
      lookupReferences,
    });
  }
};

export const adaptResponseWithLookupReferences = (
  data?: ExecuteWorkflowNodeResponse,
  lookupRequests?: ExecuteWorkflowNodeRequest['lookupRequests'],
) => {
  if (!data?.response || !lookupRequests?.length || isEmpty(data.lookupReferences)) {
    return data?.response;
  }

  const lookupReferences = data.lookupReferences;

  return produce(data.response, (draft) => {
    lookupRequests.forEach((lookupRequest) => {
      const name = lookupRequest.name;
      // @ts-expect-error -- should be present when lookupRequest is byKeys
      let keys = lookupRequest.keys as string | string[];

      if (Array.isArray(keys)) {
        // backward compatibility for old lookupRequests - earlier it was a string
        keys = keys[0];
      }

      if (!name || typeof keys !== 'string') {
        return;
      }

      adaptResponseRecursively({ keys, data: draft, lookupReferences, name });
    });
  });
};
