import { useCallback, useMemo } from 'react';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { Draft } from 'immer';
import { getRuntimeBlockId } from '../../utils/runTimeBlocks';
import { useRuntimeBlockKey } from '../../components/BlockRuntimeStateProvider';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import { useGetAndSetRunTimeBlockState } from '../useGetAndSetRunTimeBlockState';
import { useInterfaceStore } from '../../stores/InterfaceStore';

function useSetBlockState(originalBlockId: string) {
  const runtimeBlockKey = useRuntimeBlockKey();
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();
  const interfaceId = useInterfaceStore().use.record.id();

  const blockId = useMemo(
    () =>
      runtimeBlockKey
        ? getRuntimeBlockId({
            originalBlockId,
            runtimeBlockKey,
          })
        : originalBlockId,
    [originalBlockId, runtimeBlockKey],
  );

  const { updateBlockState: updateBlockStateStore, setInterfaceSessionStorage } =
    useGlobalStateStore().use.actions();

  // in case of !runTimeBlockKey, originalBlockState is same as runTimeBlockState
  const originalBlockState = useGlobalStateStore().use.block.details(originalBlockId);

  const runTimeBlockState = useGetAndSetRunTimeBlockState({
    originalBlockState,
    blockId,
    runtimeBlockKey,
  });

  const updateBlockState = useCallback(
    (setter: (blockState: Draft<BlockStateUnionType>) => void) => {
      updateBlockStateStore(blockId, setter);
    },
    [blockId, updateBlockStateStore],
  );

  const updateBlockSessionState = useCallback(
    (state: unknown) => {
      setInterfaceSessionStorage({
        operation: 'SET_IN_BLOCK_STATE',
        payload: {
          path: [originalBlockId],
          value: state,
          interfaceId,
          interfacePageId: activeInterfacePageId,
        },
      });
    },
    [activeInterfacePageId, interfaceId, originalBlockId, setInterfaceSessionStorage],
  );

  return {
    blockState: runtimeBlockKey ? runTimeBlockState : originalBlockState,
    updateBlockState,
    updateBlockSessionState,
  };
}

export default useSetBlockState;

export type UseSetBlockStateReturnType = ReturnType<typeof useSetBlockState>;
