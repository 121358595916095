import { useStorageValue } from '../useStorageValue';
import { useLocalStorage } from '../useLocalStorage';

export const useLocalStorageEntry = <T>(
  key: string,
  options?: { onSuccess?: (data: T | undefined) => void },
  enabled = true,
) => {
  const [setItem, getItem] = useLocalStorage<T>(key);

  return useStorageValue({
    enabled,
    queryKey: ['localStorage', key],
    getItem,
    onSuccess: options?.onSuccess,
    setItem,
  });
};
