import type { StoreApi } from 'zustand/vanilla';
import { produce } from 'immer';
import _set from 'lodash/set';
import _isString from 'lodash/isString';
import { invariant } from 'ts-invariant';
import type { GlobalStateStore } from '../types';
import { INTERNALS_PARAMETERS_KEY } from '../../../const';

export type InterfaceSessionStorageActionProps = {
  operation: 'SET_IN' | 'SET_IN_BLOCK_STATE';
  payload: {
    path: string | string[] | undefined;
    value: unknown;
    interfaceId: string;
    interfacePageId?: string;
  };
};

const getInterfaceSessionStorageAction =
  (
    get: StoreApi<GlobalStateStore>['getState'],
    set: StoreApi<GlobalStateStore>['setState'],
    interfaceSessionStorage: Record<string, unknown> | undefined,
    setInterfaceSessionStorage: (value: Record<string, unknown>) => void,
  ) =>
  ({ operation, payload }: InterfaceSessionStorageActionProps) => {
    switch (operation) {
      case 'SET_IN':
        {
          const { interfaceId, path: propertyPath } = payload;

          const activeInterfaceSessionStorage = get().interfaceSessionStorage.data;

          const updatedActiveInterfaceSessionStorage = produce(
            activeInterfaceSessionStorage ?? {},
            (draft) => {
              const path = _isString(propertyPath) ? [propertyPath] : propertyPath;

              _set(draft, [...(path ?? [])], payload.value);
            },
          );

          setInterfaceSessionStorage({
            ...interfaceSessionStorage,
            [interfaceId]: updatedActiveInterfaceSessionStorage,
          });

          set((state) => {
            return {
              ...state,
              interfaceSessionStorage: {
                ...state.interfaceSessionStorage,
                data: updatedActiveInterfaceSessionStorage,
              },
            };
          });
        }
        break;
      case 'SET_IN_BLOCK_STATE': {
        const { interfaceId, interfacePageId, path: propertyPath } = payload;
        invariant(interfacePageId, 'interfacePageId is required for SET_IN_BLOCK_STATE operation');
        const activeInterfaceSessionStorage = get().interfaceSessionStorage.data;

        const updatedActiveInterfaceSessionStorage = produce(
          activeInterfaceSessionStorage ?? {},
          (draft) => {
            const path = _isString(propertyPath) ? [propertyPath] : propertyPath;
            _set(
              draft,
              // setting the blocks internal state inside __internals__ so we can omit this whole before sending to server for generating schema
              [INTERNALS_PARAMETERS_KEY, interfacePageId, ...(path ?? [])],
              payload.value,
            );
          },
        );

        setInterfaceSessionStorage({
          ...interfaceSessionStorage,
          [interfaceId]: updatedActiveInterfaceSessionStorage,
        });

        set((state) => {
          return {
            ...state,
            interfaceSessionStorage: {
              ...state.interfaceSessionStorage,
              data: updatedActiveInterfaceSessionStorage,
            },
          };
        });
      }
    }
  };

export default getInterfaceSessionStorageAction;
