'use client';
import { createContext, useContext } from 'react';
import type { createInterfaceStoreSelectors } from './selectors';

export const InterfaceStoreContext = createContext<ReturnType<
  typeof createInterfaceStoreSelectors
> | null>(null);

export const useInterfaceStore = () => {
  const _useStore = useContext(InterfaceStoreContext);
  if (!_useStore) {
    throw new Error('Missing StoreProvider');
  }
  return _useStore;
};
