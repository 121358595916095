import { useGetGlobalStateStoreState } from '../../../stores/GlobalStateStore';
import { getBlocksFromPage, useGetInterfaceStoreState } from '../../../stores/InterfaceStore';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';
import type { DependencyGraphManager } from '../../../../dependency/manager/DependencyGraphManager';
import {
  blockDependencyBuilder,
  dataSourceDependencyBuilder,
  pageFunctionDependencyBuilder,
} from '../builders';
import { BlockHelper } from '../../../../helpers/BlockHelper';
import { PageFunctionHelper } from '../../../../helpers/PageFunctionHelper';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import type { EntityListType } from './useBuildEntitiesForDependencies';

export function useHandleSavedDependencies() {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const getDataSourceRecordStoreState = useDataSourceRecordStore().getState;
  const activeInterfacePage =
    getInterfaceStoreState().interfacePages[getInterfaceStoreState().activeInterfacePageId];

  function handleSavedDependencies(graphManager: DependencyGraphManager) {
    // get the page blocks handling the current view device in mind
    const pageBlocks = getBlocksFromPage(
      getGlobalStateStoreState().deviceDetails.device,
      getInterfaceStoreState(),
    );

    Object.values(pageBlocks ?? {}).forEach((block) => {
      graphManager.updateNode(block.id, BlockHelper.getDependsOn(block));
    });

    const pageFunctions = activeInterfacePage.properties.pageFunctions;

    Object.entries(pageFunctions ?? {}).forEach(([pageFunctionId, pageFunction]) => {
      graphManager.updateNode(pageFunctionId, PageFunctionHelper.getDependsOn(pageFunction));
    });

    const dataSourceRecords = getDataSourceRecordStoreState().dataSources;
    Object.entries(dataSourceRecords).forEach(([dataSourceId, dataSource]) => {
      graphManager.updateNode(dataSourceId, DataSourceHelper.getDependsOn(dataSource));
    });
  }

  function buildNewDependencies({
    entityList,
    graphManager,
  }: {
    entityList: EntityListType;
    graphManager: DependencyGraphManager;
  }) {
    //3.1 build block dependency graph
    Object.entries(entityList.blocks).forEach(([, blockState]) => {
      if (!blockState) return;

      const blockDependencyGraph = blockDependencyBuilder({
        blockState,
      });

      graphManager.updateNodes(blockDependencyGraph);
    });

    //3.2 build page function dependency graph
    Object.entries(entityList.pageFunctions).forEach(([, pageFunction]) => {
      const pageFunctionDependencyGraph = pageFunctionDependencyBuilder({
        pageFunction,
      });

      graphManager.updateNodes(pageFunctionDependencyGraph);
    });

    //3.4 build datasource dependency graph
    Object.entries(entityList.dataSources).forEach(([, dataSource]) => {
      if (!dataSource) return;

      const dataSourceDependencyGraph = dataSourceDependencyBuilder({
        dataSource,
      });

      graphManager.updateNodes(dataSourceDependencyGraph);
    });
  }

  return { handleSavedDependencies, buildNewDependencies };
}
