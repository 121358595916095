import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 38"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      fill="#fff"
      strokeMiterlimit={10}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <rect width={19.786} height={15.107} x={4.941} y={15.893} fill="#CFD4DE" rx={2} />
    <path
      fill="#705AF8"
      d="M26.481 19.862a1.5 1.5 0 0 0-.981 1.407v4.488a1.5 1.5 0 0 0 .981 1.408l5.62 2.07a1.5 1.5 0 0 0 2.02-1.407v-8.63a1.5 1.5 0 0 0-2.02-1.407z"
    />
    <circle cx={17.5} cy={10.5} r={4.5} fill="#CFD4DE" />
    <circle cx={9.821} cy={11.35} r={3.543} fill="#CFD4DE" />
  </svg>
);
export default SvgNoCodeVideo;
