import { getComputedData } from '../../utils/getComputedData';

export class ExecutionInstance {
  readonly #_context: Record<string, unknown>;
  readonly getComputedValue: <T>(
    data: Record<string, unknown> | string | unknown[],
    context: Record<string, unknown>,
  ) => T;

  constructor(
    instance: Record<string, unknown>,
    _getComputedValue: <T>(
      data: Record<string, unknown> | string | unknown[],
      context: Record<string, unknown>,
    ) => T = getComputedData,
  ) {
    this.#_context = instance;
    this.getComputedValue = _getComputedValue;
  }

  resolve(
    value: string | Record<string, unknown>,
  ): string | object | number | boolean | null | undefined {
    if (typeof value === 'string') {
      const tempobject = { value };
      // take from outside
      const resolved = this.getComputedValue<typeof tempobject>(tempobject, this.#_context);
      return resolved.value as string | undefined;
    }

    return this.getComputedValue(value, this.#_context);
  }

  get context(): Record<string, unknown> {
    return this.#_context;
  }
}
