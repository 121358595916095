import { useMemo } from 'react';
import type { AutomationInputsType } from '@unifyapps/defs/types/dataSource';
import { useBlocksInActiveInterfacePage } from '../../../stores/InterfaceStore';
import type { ApplicationDataSourceQueryNodeProps } from '../types';
import {
  isInfiniteDataSourceWorkflowNode,
  shouldUseInfiniteDataSourceWorkflowNode,
} from './InfiniteDataSourceWorkflowNode/utils';
import { InfiniteDataSourceWorkflowNode } from './InfiniteDataSourceWorkflowNode';
import { DataSourceWorkflowNode } from './DataSourceWorkflowNode';

function ApplicationDataSourceQueryNode(props: ApplicationDataSourceQueryNodeProps) {
  const {
    id,
    dataSource,
    lookupRequests,
    onQueryCompletion,
    setDataSourceState,
    requestId,
    refetchOnPayloadChange,
    getCurrentQueryResult,
    disabled,
    originalDataSourceId,
  } = props;

  const blocks = useBlocksInActiveInterfacePage();

  const isInfinite = useMemo(() => {
    return (
      isInfiniteDataSourceWorkflowNode(dataSource) &&
      shouldUseInfiniteDataSourceWorkflowNode(id, blocks)
    );
  }, [blocks, dataSource, id]);

  const Component = isInfinite ? InfiniteDataSourceWorkflowNode : DataSourceWorkflowNode;

  return (
    <Component
      advancedOptions={dataSource.advancedOptions}
      callbacks={dataSource.callbacks}
      context={dataSource.context}
      disabled={disabled}
      getCurrentQueryResult={getCurrentQueryResult}
      id={id}
      inputs={dataSource.inputs as AutomationInputsType}
      lookupRequests={lookupRequests}
      name={dataSource.name}
      onQueryCompletion={onQueryCompletion}
      originalDataSourceId={dataSource.standardDataSourceId ?? originalDataSourceId}
      refetchOnPayloadChange={refetchOnPayloadChange}
      requestId={requestId}
      setDataSourceState={setDataSourceState}
    />
  );
}

export default ApplicationDataSourceQueryNode;
