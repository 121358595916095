import Stack from '@unifyapps/ui/_components/Stack';
import { useTranslation } from '@unifyapps/i18n/client';
import { Pill } from '@unifyapps/ui/components/Pill';
import { Button } from '@unifyapps/ui/components/Button';
import SvgChevronRight from '@unifyapps/icons/outline/ChevronRight';
import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { CitationComponentType, CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { Typography } from '@unifyapps/ui/components/Typography';
import { clsx } from 'clsx';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { Box } from '@unifyapps/ui/components/Box';
import CitationsHelper from '../CitationsHelper';
import { useCitationsPanelContext } from './CitationsPanelProvider';
import { CitationIcon } from './CitationIcon';
import {
  CITATION_WIDTH,
  SPACIOUS_PILL_CLASSNAME,
  SpaciousCitationPill,
} from './SpaciousCitationPill';

type Props = Pick<
  CitationComponentType['content'],
  'citations' | 'size' | 'enableImplicitCitationClick' | 'maxVisibleCitations'
> & {
  onCitationClick: (citation?: CitationType) => void;
  className?: string;
};

const openCitationUrl = (citation: CitationType) => {
  window.open(citation.referenceUrl, '_blank');
};

const VIEW_ALL_CITATION_WIDTH = 104;
const GAP = 6;

const CITATION_STYLE = { width: CITATION_WIDTH };
const VIEW_ALL_CITATION_STYLE = { width: VIEW_ALL_CITATION_WIDTH };

const AllCitationIcons = memo(function AllCitationsIcons({
  citations,
}: {
  citations: CitationType[];
}) {
  return (
    <Stack className="gap-xs flex-wrap" direction="row">
      {citations
        .toReversed()
        .slice(0, 6)
        .map((citation, index) => (
          <Box className="bg-primary rounded-xs size-6 p-[3px]" key={index}>
            <CitationIcon citation={citation} className="size-full" />
          </Box>
        ))}
    </Stack>
  );
});

function ViewAllCitationPill({
  size,
  onViewAllCitations,
  className,
  citations,
}: {
  size: 'sm' | 'lg';
  onViewAllCitations: () => void;
  className?: string;
  citations: CitationType[];
}) {
  const { t } = useTranslation();

  return size === 'lg' ? (
    <Stack
      className={clsx(SPACIOUS_PILL_CLASSNAME, '!p-md', className)}
      onClick={onViewAllCitations}
      role="button"
      style={VIEW_ALL_CITATION_STYLE}
    >
      <AllCitationIcons citations={citations} />
      <Typography color="text-quaternary" variant="text-xxs">
        {t('ai-agents:AllSources')}
      </Typography>
    </Stack>
  ) : (
    <Button
      className="text-xs"
      color="neutral"
      endDecoratorComponent={SvgChevronRight}
      onClick={onViewAllCitations}
      size="sm"
      variant="soft"
    >
      {t('ai-agents:ViewDetails')}
    </Button>
  );
}

export function VisibleCitationSources({
  citations,
  size = 'sm',
  onCitationClick,
  className,
  maxVisibleCitations: _maxVisibleCitations,
  enableImplicitCitationClick,
}: Props) {
  const { showPanel } = useCitationsPanelContext();
  const [maxVisibleCitations, setMaxVisibleCitations] = useState(0);

  const uniqueCitationSources = useMemo(() => {
    // We only want to show unique citation titles upfront.
    // Eg. If there are multiple sources from same slack channel, we only want to show that slack channel once upfront.
    //     In panel, we can show all sources.
    const citationsMap = {};
    return citations.reduce<CitationType[]>((acc, item) => {
      const title = CitationsHelper.getCitationTitle(item);
      if (!title) return acc;
      if (!citationsMap[title]) {
        acc.push(item);
        citationsMap[title] = true;
      }
      return acc;
    }, []);
  }, [citations]);

  const onViewAllCitations = useEventCallback(() => {
    showPanel(citations);
    onCitationClick();
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (_maxVisibleCitations !== undefined) {
      return setMaxVisibleCitations(_maxVisibleCitations);
    }

    const container = containerRef.current;
    if (container) {
      const { width } = container.getBoundingClientRect();
      const maxAvailableWidth = width - VIEW_ALL_CITATION_WIDTH;
      const visibleCitationsCount = Math.floor(maxAvailableWidth / (CITATION_WIDTH + GAP));

      if (citations.length - visibleCitationsCount > 1) {
        return setMaxVisibleCitations(visibleCitationsCount);
      }
      // if there is only one citation that the viewAllSources card contaions, then try rendering the citation rather than the viewAllCitation card.
      if (visibleCitationsCount * (CITATION_WIDTH + GAP) + CITATION_WIDTH <= width) {
        setMaxVisibleCitations(visibleCitationsCount + 1);
      } else setMaxVisibleCitations(visibleCitationsCount);
    }
  }, [citations.length, _maxVisibleCitations, size]);

  return (
    <Stack
      className="gap-sm w-full flex-wrap items-center self-start"
      direction="row"
      ref={containerRef}
    >
      {uniqueCitationSources.slice(0, maxVisibleCitations).map((citation, index) =>
        size === 'lg' ? (
          <SpaciousCitationPill
            citation={citation}
            className={className}
            key={index}
            onClick={() => {
              if (enableImplicitCitationClick) {
                return openCitationUrl(citation);
              }
              onCitationClick(citation);
              showPanel(citations);
            }}
          />
        ) : (
          <Pill
            key={index}
            label={CitationsHelper.getCitationTitle(citation)}
            labelClassName="!break-all"
            onClick={() => {
              if (enableImplicitCitationClick) {
                return openCitationUrl(citation);
              }
              onCitationClick(citation);
              showPanel(citations);
            }}
            pillClassName="!gap-xs flex !justify-start"
            startDecoratorNode={
              <CitationIcon citation={citation} className="size-3 flex-shrink-0" />
            }
            style={CITATION_STYLE}
          />
        ),
      )}
      {citations.length > maxVisibleCitations ? (
        <ViewAllCitationPill
          citations={uniqueCitationSources}
          className={className}
          onViewAllCitations={onViewAllCitations}
          size={size}
        />
      ) : null}
    </Stack>
  );
}
