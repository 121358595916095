import { useSearchEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import type { InterfaceNamespaceEntity } from '@unifyapps/defs/types/namespace';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import InterfaceHelper from '../../../../../stores/InterfaceStore/helper/InterfaceHelper';
import { useGetUserContext } from '../../../../useGetUserContext';
import {
  getInterfaceCommonNamespaceQueryFilters,
  getInterfaceComponentNamespaceQueryFilters,
} from './utils';
import type { FetchInterfaceNamespacesParams } from './types';

type Namespace = InterfaceNamespaceEntity | undefined;

export const useFetchInterfaceNamespaces = (props: FetchInterfaceNamespacesParams) => {
  const { userContext } = useGetUserContext();
  const { interfaceId, interfaceRecord, interfacePageId, mode } = props;

  const userLocale = userContext?.locale;
  const locale = InterfaceHelper.getLocale({ userLocale, interfaceRecord, mode });
  const interfaceDefaultLocale = InterfaceHelper.getDefaultLocale(interfaceRecord);

  const {
    data: commonNamespaceData,
    isLoading: isLoadingCommonNamespace,
    error: errorCommonNamespace,
  } = useSearchEntities(
    UAEntityTypes.InterfaceNamespace,
    getInterfaceCommonNamespaceQueryFilters({
      interfaceId,
      locale,
    }),
    {
      query: {
        enabled: Boolean(interfaceDefaultLocale),
        retry: 3,
      },
    },
  );

  const {
    data: componentNamespaceData,
    isLoading: isLoadingComponentNamespace,
    error: errorComponentNamespace,
  } = useSearchEntities(
    UAEntityTypes.InterfaceNamespace,
    getInterfaceComponentNamespaceQueryFilters({
      interfaceId,
      interfaceComponentId: interfacePageId ?? '',
      locale,
    }),
    {
      query: {
        // only fetch if interfacePageId and interfaceDefaultLocale are available
        // interfaceDefaultLocale -> for legacy interfaces interfaceDefaultLocale is not defined
        enabled: Boolean(interfacePageId && interfaceDefaultLocale),
        retry: 3,
      },
    },
  );

  return {
    isLoading: isLoadingCommonNamespace || isLoadingComponentNamespace,
    error: errorCommonNamespace || errorComponentNamespace,
    commonNamespace: commonNamespaceData?.objects?.[0] as Namespace,
    interfaceComponentNamespace: componentNamespaceData?.objects?.[0] as Namespace,
  };
};
