import React, { createContext, useContext, useMemo } from 'react';
import { INTERFACE_THEME, UA_THEME_VARS } from '@unifyapps/style/themes/consts';
import { StyleOverride } from '../../styles/StyleOverride';

const ThemeOverrideContext = createContext<{
  themeOverrideClassName: string;
}>({
  themeOverrideClassName: '',
});

export function useThemeOverrideContext() {
  return useContext(ThemeOverrideContext);
}
/**
 * Provides theme override provider for Interface Builder and Preview
 * Used to pass theme override style context to pop-overs originating from inside the canvas
 */
export function ThemeOverrideContextProvider({
  value: style,
  children,
}: {
  value: string | undefined;
  children: React.ReactNode;
}) {
  const themeOverrideClassName = style ? `${UA_THEME_VARS} ${INTERFACE_THEME}` : '';

  const contextValue = useMemo(() => ({ themeOverrideClassName }), [themeOverrideClassName]);

  return (
    <ThemeOverrideContext.Provider value={contextValue}>
      {style ? <StyleOverride style={style} /> : null}
      {children}
    </ThemeOverrideContext.Provider>
  );
}
