import type { CardBlockStateType } from '@unifyapps/defs/types/blocks';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useEffect } from 'react';
import type { UpdateBlockStateType } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';

export const useCollapsibleParams = ({
  blockState,
  updateBlockState,
}: {
  blockState: CardBlockStateType;
  updateBlockState: UpdateBlockStateType<CardBlockStateType>;
}) => {
  const isCollapsed = blockState.appearance?.isCollapsed ?? false;

  const setIsCollapsed = useEventCallback(() => {
    updateBlockState((draft) => {
      if (!draft.appearance)
        draft.appearance = { isCollapsed: blockState.appearance?.isCollapsed ?? false };
      draft.appearance.isCollapsed = !draft.appearance.isCollapsed;
    });
  });

  useEffect(() => {
    const newIsCollapsed = !(blockState.content.collapsible?.isInitiallyExpanded ?? true);

    if (blockState.appearance?.isCollapsed === newIsCollapsed) return;

    updateBlockState((draft) => {
      if (!draft.appearance) draft.appearance = { isCollapsed: false };
      draft.appearance.isCollapsed = newIsCollapsed;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only set state if isInitiallyExpanded is changed
  }, [blockState.content.collapsible?.isInitiallyExpanded]);

  return {
    collapsibleParams: {
      isCollapsed,
      setIsCollapsed,
      enabled: blockState.content.collapsible?.enabled ?? false,
    },
  };
};
