import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type { CalendarComponentType } from '@unifyapps/defs/blocks/Calendar/types';
import { isDynamicValue } from '@unifyapps/carbon/no-code/utils/dynamicBindings';

const importCalendarComponent = () =>
  import(
    /* webpackChunkName: "calendar-block" */
    './Calendar'
  );

const Calendar = lazy(importCalendarComponent);

class CalenderBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Calendar;
  }

  get type(): ComponentTypeUnionType {
    return 'Calendar';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        /**
         * We do not have the computed value of defaultDate here,
         * Hence when it is dynamic, the component will handle the setting of the selectedDate in an effect
         */
        selectedDate:
          (block.component as CalendarComponentType).content.defaultDate &&
          !isDynamicValue((block.component as CalendarComponentType).content.defaultDate)
            ? new Date((block.component as CalendarComponentType).content.defaultDate)
            : new Date(),
        ..._pick(block.component as CalendarComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      }) as BlockStateUnionType;
  }

  getSlotOrder(block: BlockType) {
    return {
      order: ['header', 'footer'],
    };
  }

  preload() {
    return {
      blockPreload: importCalendarComponent,
    };
  }
}

export default CalenderBlockStateDefinition;
