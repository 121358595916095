import has from 'lodash/has';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import { createDraft, finishDraft } from 'immer';
import type { InterfaceNamespaceProperties } from '@unifyapps/defs/types/namespace';

interface Params {
  translations: InterfaceNamespaceProperties['translations'] | undefined;
  data: Record<string, unknown> | undefined;
}

export const adaptDataWithTranslations = ({ translations, data }: Params) => {
  if (isEmpty(translations) || isEmpty(data)) {
    return data;
  }

  const draftData = createDraft(data);

  for (const key in translations) {
    if (has(draftData, key)) {
      set(draftData, key, translations[key].value);
    }
  }

  return finishDraft(draftData);
};
