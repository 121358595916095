import { useTranslation } from '@unifyapps/i18n/client';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { useCallback } from 'react';
import { useCopyToClipboard as useBaseCopyToClipboard } from 'react-use';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';
import type { Serializable } from './utils/getStringifiedCopyPath';
import { getCopyText } from './utils/getCopyText';

const useCopyToClipboardAction = () => {
  const { showSnackbar } = useSnackbar();
  const [copyClipboardState, copyToClipboard] = useBaseCopyToClipboard();
  const { t } = useTranslation();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const handleCopyAction: ActionHandlerType = useCallback(
    ({ action, actionContext }: OnActionArgs) => {
      const payload = action.payload as { value: string };

      const { value } = getNoCodeComputedData<{
        value: unknown;
      }>(
        {
          value: payload.value,
        },
        actionContext,
      );

      copyToClipboard(typeof value === 'string' ? value : getCopyText(value as Serializable));
      if (copyClipboardState.error) {
        showSnackbar({ color: 'error', title: t('common:ClipboardCopy.error') });
      } else {
        showSnackbar({ color: 'success', title: t('common:ClipboardCopy.success') });
      }
      return Promise.resolve();
    },
    [copyClipboardState.error, copyToClipboard, showSnackbar, t],
  );

  return { handleCopyAction };
};

export default useCopyToClipboardAction;
