import type {
  BlockStateDefinition,
  ComponentType,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { CommentListComponentType } from '@unifyapps/defs/blocks/CommentList/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import _map from 'lodash/map';
import type { Action } from '@unifyapps/defs/types/action';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';

const importCommentList = () =>
  import(
    /* webpackChunkName: "comment-list-block" */
    './CommentList'
  );

const CommentList = lazy(importCommentList) as ComponentType;

class CommentListBlockStateDefinition implements BlockStateDefinition {
  useBlockRef?: boolean | undefined = true;

  get type(): ComponentTypeUnionType {
    return 'CommentList';
  }

  getEventTargetIds(block: BlockType<CommentListComponentType>) {
    return [
      'deleteCommentAction',
      ..._map(block.component.content.addOns?.replyComment?.actions, (action) => action.id),
    ];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'addComment' as const,
        label: 'Add Comment',
        method: (
          blockState: BlockStateUnionType,
          action: Action,
          blockRef: { addComment: (args: { content?: string; attachments?: FileType[] }) => void },
        ) => {
          const { methodPayload } = action.payload as {
            methodPayload: { content?: string; attachments?: FileType[] };
          };

          blockRef.addComment(methodPayload);

          return blockState;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as CommentListComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return CommentList;
  }
}

export default CommentListBlockStateDefinition;
