import type { MutableRefObject } from 'react';
import { useImperativeHandle, useRef } from 'react';
import type { InterfaceResourceProviderRefType } from '../types';
import type { InterfaceStoreRefType } from '../../../stores/InterfaceStore';
import type { DependencyGraphStoreRefType } from '../../../context/DependencyGraphContext/types';
import type { GlobalStateStoreRefType } from '../../../stores/GlobalStateStore';

export const useStoreRefs = ({
  interfaceResourceProviderRef,
}: {
  interfaceResourceProviderRef?: MutableRefObject<InterfaceResourceProviderRefType | undefined>;
}) => {
  const globalStateStoreRef = useRef<GlobalStateStoreRefType | undefined>();
  const interfaceStoreRef = useRef<InterfaceStoreRefType | undefined>();
  const dependencyGraphStoreRef = useRef<DependencyGraphStoreRefType | undefined>();

  useImperativeHandle(interfaceResourceProviderRef, () => {
    return {
      updateBlocks: (params) => {
        // Call updateBlocks on both stores
        // TODO: when the blocks are added, they need to have the dependsOn etc updated
        globalStateStoreRef.current?.updateBlocks(params);
        interfaceStoreRef.current?.updateBlocks(params);
        dependencyGraphStoreRef.current?.shouldRebuildGraph();
      },
    };
  }, []);

  return {
    globalStateStoreRef,
    dependencyGraphStoreRef,
    interfaceStoreRef,
  };
};
