import type { StoreApi } from 'zustand/vanilla';
import type { DependencyFlowInstanceType, DependencyFlowStatus, GlobalStateStore } from '../types';
import DependencyFlowHelper from '../helpers/DependencyFlowHelper';

const getDependencyFlowActions = (
  set: StoreApi<GlobalStateStore>['setState'],
  get: StoreApi<GlobalStateStore>['getState'],
) => {
  const setDependencyFlowStatusMultiple = ({
    key,
    value,
  }: {
    key: keyof GlobalStateStore['dependencyFlowManager'];
    value: DependencyFlowInstanceType;
  }) => {
    set((state) => {
      return {
        ...state,
        dependencyFlowManager: {
          ...state.dependencyFlowManager,
          [key]: {
            ...state.dependencyFlowManager[key],
            ...value,
          },
        },
      };
    });
  };

  const setEntityIdDependencyFlow = ({
    entityId,
    key,
    status,
  }: {
    key: keyof GlobalStateStore['intraDependencies'];
    entityId: string;
    status: DependencyFlowStatus;
  }) => {
    const allStagedEntities = DependencyFlowHelper.getDependencyFlowStatus({
      dependencyFlowInstance: get().dependencyFlowManager[key],
      intraDependencies: get().intraDependencies[key],
      entities: { [entityId]: { status } },
    });

    setDependencyFlowStatusMultiple({
      key,
      value: allStagedEntities,
    });
  };

  const setEntitiesDependencyFlow = ({
    key,
    entities,
  }: {
    key: keyof GlobalStateStore['intraDependencies'];
    entities: DependencyFlowInstanceType;
  }) => {
    const allStagedEntities = DependencyFlowHelper.getDependencyFlowStatus({
      dependencyFlowInstance: get().dependencyFlowManager[key],
      entities,
      intraDependencies: get().intraDependencies[key],
    });
    setDependencyFlowStatusMultiple({
      key,
      value: allStagedEntities,
    });
  };

  const removeEntityIdDependencyFlow = ({
    entityId,
    key,
  }: {
    key: keyof GlobalStateStore['intraDependencies'];
    entityId: string;
  }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: {
            ...state.intraDependencies[key],
            [entityId]: undefined,
          },
        },
      };
    });
  };

  return {
    setEntityIdDependencyFlow,
    setEntitiesDependencyFlow,
    removeEntityIdDependencyFlow,
  };
};

export default getDependencyFlowActions;
