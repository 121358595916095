import type {
  BlockControlMethod,
  BlockStateDefinition,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  Step,
  StepperComponentStateType,
  StepperComponentType,
} from '@unifyapps/defs/blocks/Stepper/types';
import { StepperContentType } from '@unifyapps/defs/blocks/Stepper/types';
import _findIndex from 'lodash/findIndex';
import { lazy } from 'react';
import { getNextStep, getPreviousStep } from '@unifyapps/ui/components/Stepper/utils';
import { getBlockSteps } from './Stepper';
import { filterSteps } from './hooks/useVisibleSteps';

const importStepperComponent = () =>
  import(
    /* webpackChunkName: "stepper-block" */
    './Stepper'
  );

const Stepper = lazy(importStepperComponent);

class StepperBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Stepper';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    const methods: BlockControlMethod[] = [
      {
        id: 'moveToNextStep',
        label: 'Move to next step',
        method: (
          blockState: StepperComponentStateType,
          action,
          blockRef,
          { getComputeContext, getComputedFilters, getComputedData },
        ) => {
          const steps = getBlockSteps(blockState);

          const context = getComputeContext();

          const { computedSteps } = getComputedFilters(
            { computedSteps: getComputedData<Step[]>(steps, context) },
            context,
          ) as { computedSteps: Step[] };

          return {
            ...blockState,
            activeStepId: getNextStep({
              stepId: blockState.activeStepId,
              steps: filterSteps(computedSteps),
            }),
          } as StepperComponentStateType;
        },
      },
      {
        id: 'moveToPrevStep',
        label: 'Move to previous step',
        method: (
          blockState: StepperComponentStateType,
          action,
          blockRef,
          { getComputeContext, getComputedFilters, getComputedData },
        ) => {
          const steps = getBlockSteps(blockState);

          const context = getComputeContext();

          const { computedSteps } = getComputedFilters(
            { computedSteps: getComputedData<Step[]>(steps, context) },
            context,
          ) as { computedSteps: Step[] };

          return {
            ...blockState,
            activeStepId: getPreviousStep({
              stepId: blockState.activeStepId,
              steps: filterSteps(computedSteps),
            }),
          } as StepperComponentStateType;
        },
      },
    ];
    return methods;
  }

  get initialStateGetter() {
    return (block: BlockType) => {
      const component = block.component as StepperComponentType;

      let activeStepId: string | undefined;

      if (
        component.content.type === StepperContentType.STATIC &&
        component.content.defaultStepValue &&
        !(
          typeof component.content.defaultStepValue === 'object' &&
          'conditionalValueFilters' in component.content.defaultStepValue
        )
      ) {
        activeStepId = component.content.defaultStepValue;
      }

      return {
        id: block.id,
        ..._pick(block.component, ['appearance', 'componentType', 'content']),
        activeStepId,
      } as StepperComponentStateType;
    };
  }

  getComponent() {
    return Stepper;
  }

  preload() {
    return {
      blockPreload: importStepperComponent,
    };
  }
}

export default StepperBlockStateDefinition;
