import { AssetTabs } from '../views/AssetsManager/types';

export enum DataCatalogTabsEnum {
  Overview = 'overview',
  AllAssets = 'all-assets',
  DataQuality = 'data-quality',
}

export const DATA_CATALOG_BASE_ROUTE = '/data-catalog';

export const getDataCatalogTabRoute = (catalogId: string, tabId = DataCatalogTabsEnum.Overview) => {
  return `${DATA_CATALOG_BASE_ROUTE}/${catalogId}/${tabId}`;
};

export function getAssetSidebarTabRoute(
  catalogId: string,
  dataCatalogTab: DataCatalogTabsEnum,
  assetId: string,
  assetType: string,
) {
  return `${getDataCatalogTabRoute(catalogId, dataCatalogTab)}/asset/${assetType}/${assetId}/${AssetTabs.Overview}`;
}

export function getAssetDetailsTabRoute(
  catalogId: string,
  assetId: string,
  assetType: string,
  tabId: AssetTabs = AssetTabs.Overview,
) {
  const query = `?pageInputs={"objectId":"${assetId}"}`;
  return `${DATA_CATALOG_BASE_ROUTE}/${catalogId}/${assetType}/${assetId}/${tabId}${query}`;
}

export const getDataCatalogsManagerRoute = () => DATA_CATALOG_BASE_ROUTE;
export const getDataCatalogDetailsRoute = (dataCatalogSlug: string) =>
  `${DATA_CATALOG_BASE_ROUTE}/${dataCatalogSlug}`;
export const getCreateDataCatalogRoute = () => `${DATA_CATALOG_BASE_ROUTE}/create`;
export const getEditDataCatalogRoute = (dataCatalogId: string) =>
  `${DATA_CATALOG_BASE_ROUTE}/edit/${dataCatalogId}`;

export const getAddApplicationRoute = (catalogId: string, returnToUrl = '') =>
  `${DATA_CATALOG_BASE_ROUTE}/${catalogId}/add-application?returnTo=${returnToUrl}`;
