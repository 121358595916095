import type { BlockComponentType, BlockId, BlockComponentStateId } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type { ConditionalValueFilters } from '../../types/conditionalValueFilters';
import type { ItemsType } from '../../types/items';

export enum CarouselContentType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export type DynamicCarouselContent = {
  type: CarouselContentType.DYNAMIC;
  data?: string;
  primaryKey?: string;
  blockId?: BlockId;
  defaultSlideIndex?: number | ConditionalValueFilters<number>;
};

export type StaticCarouselContent = {
  type: CarouselContentType.STATIC;
  items?: ItemsType[];
  defaultSlideIndex?: number | ConditionalValueFilters<number>;
};

type CarouselContent = DynamicCarouselContent | StaticCarouselContent;

type CarouselAppearance = {
  enableLoop?: boolean;
  enableNavigationButtons?: boolean;
  autoPlay?: {
    enabled: boolean;
    duration?: number;
  };
  viewDetails?: {
    perView: number;
    gap: number;
  };
  styles?: Pick<
    StyleObject,
    | 'padding'
    | 'margin'
    | 'height'
    | 'maxHeight'
    | 'minHeight'
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'layout'
    | 'gap'
  >;
};

export type CarouselComponentType = BlockComponentType<
  'Carousel',
  CarouselContent,
  CarouselAppearance
>;

export type CarouselComponentStateType = Pick<
  CarouselComponentType,
  'appearance' | 'componentType'
> & {
  content: CarouselContent & {
    currentSlideIndex: number;
    totalSlidesCount: number;
  };
} & BlockComponentStateId;
