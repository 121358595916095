import {
  getConditionalIcon,
  getConditionalIconNode,
  getConditionalPropertyValue,
} from '@unifyapps/carbon/utils/conditionalValues';
import type {
  IconButtonBlockStateType,
  IconButtonComponentType,
} from '@unifyapps/defs/blocks/IconButton/types';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { SyntheticEvent } from 'react';
import { useCallback, memo } from 'react';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { IconButton as BaseIconButton } from '@unifyapps/ui/components/IconButton';
import { Tooltip as BaseTooltip } from '@unifyapps/ui/components/Tooltip';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { EventTypeEnum } from '@unifyapps/defs/types/event';

function IconButton({
  dataAttributes,
  events,
  computedBlockState,
}: BlockComponentProps<
  IconButtonComponentType,
  IconButtonBlockStateType,
  IconButtonBlockStateType
>) {
  const { content, appearance } = computedBlockState;
  const color = getConditionalPropertyValue({ value: appearance?.color }) ?? 'brand';

  const { icon, addOns } = content;

  const { className } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const { emitEvent } = useBlockEvents(events);
  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({
        eventType: EventTypeEnum.OnClick,
        domEvent: e,
      });
    },
    [emitEvent],
  );

  const Icon = getConditionalIcon(icon);
  const IconNode = getConditionalIconNode(icon, appearance?.size);

  if (!Icon && !IconNode) {
    return null;
  }

  const iconButtonEl = (
    <BaseIconButton
      Icon={Icon}
      IconNode={IconNode}
      className={className}
      color={color}
      disabled={appearance?.disabled}
      onClick={onClick}
      size={appearance?.size ?? 'md'}
      variant={appearance?.variant ?? 'solid'}
      {...dataAttributes}
    />
  );

  if (addOns?.tooltip?.content) {
    return <BaseTooltip title={addOns.tooltip.content}>{iconButtonEl}</BaseTooltip>;
  }

  return iconButtonEl;
}

export default memo(IconButton);
