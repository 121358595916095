'use client';
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { useFindDeployedEntityById } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import {
  InterfacePageEntityComponentType,
  type InterfacePageEntity,
} from '@unifyapps/defs/types/page';
import { useMemo } from 'react';
import { InterfaceClient } from '../../../../../stores/InterfaceStore';
import { useGetAuthDeployedInterface } from '../useAuthDeployedInterface';
import { useGetAuthDeployedComponent } from '../useAuthDeployedComponent';
import type { UseFetchResourcesHookType } from '../useFetchDeployedInterfaceResources/type';

const useFetchAuthDeployedInterfaceResources: UseFetchResourcesHookType = ({
  interfaceId,
  interfaceComponent,
  client,
}) => {
  const host = typeof window !== 'undefined' ? window.location.host : '';

  const authEntityInterface = useGetAuthDeployedInterface<InterfaceEntity>({
    query: {
      enabled: Boolean(host) && client === InterfaceClient.UA_MATRIX,
      // No need to refetch this data in lifetime of the app
      staleTime: Infinity,
    },
  });

  const { data, isLoading } = useFindDeployedEntityById<InterfaceEntity>(
    UAEntityTypes.Interface,
    interfaceId,
    {
      query: {
        enabled: Boolean(interfaceId) && client === InterfaceClient.UA_PLATFORM,
      },
    },
  );

  const interfaceRecord = authEntityInterface.data || data;
  const isLoadingInterfaceRecord = authEntityInterface.isLoading || isLoading;
  const errorInterfaceRecord = authEntityInterface.error || authEntityInterface.error;

  const interfaceComponentSlug =
    interfaceComponent.type === InterfacePageEntityComponentType.PAGE
      ? interfaceComponent.slug
      : '';

  const authComponent = useGetAuthDeployedComponent(interfaceComponentSlug, {
    query: {
      enabled: Boolean(interfaceComponentSlug),
    },
  });

  const fetchedComponent = authComponent.data?.find(
    (component) =>
      component.entityType === (UAEntityTypes.DeployedComponent as string) &&
      component.properties.componentType === InterfacePageEntityComponentType.PAGE,
  ) as InterfacePageEntity | undefined;

  const fetchedModules = authComponent.data?.filter(
    (component) =>
      component.entityType === (UAEntityTypes.DeployedComponent as string) &&
      component.properties.componentType === InterfacePageEntityComponentType.MODULE,
  ) as InterfacePageEntity[] | undefined;

  const fetchedDataSources = authComponent.data?.filter(
    (component) => component.entityType === (UAEntityTypes.DeployedDataSource as string),
  ) as DataSourceEntity[] | undefined;

  const _isLoading = authComponent.isLoading || isLoadingInterfaceRecord;
  const _error = authComponent.error || errorInterfaceRecord;

  const interfaceModulesMap = useMemo(() => {
    return fetchedModules?.reduce((acc, v) => {
      if (!v.id) return acc;
      acc[v.id] = v;
      return acc;
    }, {}) as Record<string, InterfacePageEntity>;
  }, [fetchedModules]);

  const dataSourcesMap = useMemo(() => {
    return fetchedDataSources?.reduce((acc, v) => {
      if (!v.id) return acc;
      acc[v.id] = v;
      return acc;
    }, {}) as Record<string, DataSourceEntity> | undefined;
  }, [fetchedDataSources]);

  return {
    interfaceModulesMap,
    isLoading: _isLoading,
    error: _error,
    data: interfaceRecord,
    dataSources: dataSourcesMap,
    interfacePage: fetchedComponent,
  };
};

export default useFetchAuthDeployedInterfaceResources;
