/* eslint-disable @typescript-eslint/no-extraneous-class -- safe to ignore */
import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { APPLICATION_BY_NAME } from '@unifyapps/defs/blocks/CopilotChat/LookupsHelper';

class CitationsHelper {
  static getCitationTitle = (citation: CitationType) => {
    const { appName, title: _title, referenceUrl, name } = citation;
    const title = _title ?? name ?? referenceUrl;

    // TODO: to be removed
    if (appName === 'slack') {
      return `#${title}`;
    }
    return title;
  };

  static getApplicationName = (citation: CitationType) => citation[APPLICATION_BY_NAME]?.name;

  static getIconUrl = (citation: CitationType) => citation[APPLICATION_BY_NAME]?.iconUrl;
}

export default CitationsHelper;
