import type { Step } from '@unifyapps/defs/blocks/Stepper/types';
import { isTargetVisible } from '@unifyapps/carbon/no-code/hooks/useIsBlockVisibility';
import _isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

export const filterSteps = (steps: Step[] = []) => {
  return steps.reduce<Step[]>((acc, step) => {
    if (!isTargetVisible(step.visibility)) {
      return acc;
    }

    if (!_isEmpty(step.subSteps)) {
      const filteredSubSteps = filterSteps(step.subSteps);
      if (_isEmpty(filteredSubSteps)) {
        return acc;
      }
      acc.push({ ...step, subSteps: filteredSubSteps });
      return acc;
    }

    acc.push(step);
    return acc;
  }, []);
};

export const useVisibleSteps = (steps?: Step[]) => {
  return useMemo(() => filterSteps(steps), [steps]);
};
