import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import type { CardAppearance } from '@unifyapps/defs/types/blocks';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

const STYLES_TO_APPLY_ON_COLLAPSIBLE_SECTION = ['gap'];

export const useCardAppearance = ({ appearance }: { appearance?: CardAppearance }) => {
  const { className: rootClassName, style: rootStyle } = useBlockAppearanceStyles({
    appearanceStyles: _omit(appearance?.styles),
  });

  const { className: rootAndChildrenClassName, style: rootAndChildrenStyles } =
    useBlockAppearanceStyles({
      appearanceStyles: _pick(appearance?.styles, STYLES_TO_APPLY_ON_COLLAPSIBLE_SECTION),
    });

  return {
    rootStyle: { ...rootStyle, ...rootAndChildrenStyles },
    rootClassName: `${rootClassName} ${rootAndChildrenClassName}`,
    rootAndChildrenStyles,
    rootAndChildrenClassName,
  };
};
