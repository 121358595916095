import { useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { isEmpty } from 'lodash';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';
import { useReactiveComputeContext } from '../../../../hooks/computeContext';
import { EMPTY_OBJECT } from '../../../../../consts/empty';
import { useGetNoCodeComputedData } from '../../../../hooks/computeContext/useGetNoCodeComputedData';

function useHandleOnPageOutputChange(onOutputChange?: (outputs: Record<string, unknown>) => void) {
  const pageOutput = useInterfaceStore().use.page.output();
  const previousComputedOutput = useRef<unknown>();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const { context } = useReactiveComputeContext();

  const computedOutput = pageOutput
    ? getNoCodeComputedData<Record<string, unknown>>(pageOutput, context)
    : EMPTY_OBJECT;

  useEffect(() => {
    if (isEmpty(pageOutput) || !onOutputChange) return;

    if (isEqual(computedOutput, previousComputedOutput.current)) return;

    previousComputedOutput.current = computedOutput;
    onOutputChange(computedOutput);
  }, [computedOutput, onOutputChange, pageOutput]);
}

export default useHandleOnPageOutputChange;
