import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import { deviceTypes } from '@unifyapps/defs/types/deviceVariant';
import { createDraft, finishDraft, original, produce, type WritableDraft } from 'immer';
import type { InterfaceStoreStateGetterAndSetter } from '../../types';
import { getDraftInterfacePage } from '../../utils/getDraftInterfacePage';

export type UpdateBlockForAllDevicesActionProps = {
  blockId: BlockId;
  updateBlock: (block: WritableDraft<BlockType>) => void;
};

export const getUpdateBlockForAllDevicesAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  (args: UpdateBlockForAllDevicesActionProps) => {
    const { set, get } = storeArgs;
    const { blockId, updateBlock } = args;

    if (!blockId) return;

    const draftInterfacePage = getDraftInterfacePage(get);
    const baseBlock = original(draftInterfacePage)?.properties.blocks?.[blockId];

    if (!baseBlock) {
      console.error(`updatePageBlockForAllDevices: Block with id ${blockId} not found`);
      return;
    }
    const draftBaseBlock = createDraft(baseBlock);

    // Update Base Block First
    updateBlock(draftBaseBlock);

    const finishedBaseBlock = finishDraft(draftBaseBlock);

    draftInterfacePage.properties.blocks = {
      ...original(draftInterfacePage)?.properties.blocks,
      [blockId]: finishedBaseBlock,
    };

    // Then update all the devices blocks
    deviceTypes.forEach((device) => {
      const nonBaseBlock =
        original(draftInterfacePage)?.properties.devices?.[device]?.blocks?.[blockId];

      if (!nonBaseBlock) {
        return;
      }

      const draftNonBaseBlock = createDraft(nonBaseBlock);

      updateBlock(draftNonBaseBlock);

      const finishedNonBaseBlock = finishDraft(draftNonBaseBlock);

      draftInterfacePage.properties.devices = {
        ...original(draftInterfacePage)?.properties.devices,
        [device]: {
          ...original(draftInterfacePage)?.properties.devices?.[device],
          blocks: {
            ...original(draftInterfacePage)?.properties.devices?.[device]?.blocks,
            [blockId]: finishedNonBaseBlock,
          },
        },
      };
    });

    const finishedPage = finishDraft(draftInterfacePage);

    set((state) => {
      return produce(state, (draftState) => {
        draftState.interfacePages[state.activeInterfacePageId] = finishedPage;
      });
    });
  };
