import type { BlockId } from '@unifyapps/defs/types/block';
import { invariant } from 'ts-invariant';
import type { InterfaceStoreStateGetterAndSetter } from '../../types';
import { getBlocksFromPage } from '../../hooks';
import { getSetBlockInInterfacePage } from '../../utils/block';
import type { BuildDependencyGraphItem } from '../../../../../dependency/utils/buildDependencyGraph';
import type { DynamicAndConditionalPathHandler } from '../../../../context/DependencyGraphContext/handlers';
import { BlockHelper } from '../../../../../helpers/BlockHelper';

export type UpdateBlockDependency = {
  blockId: BlockId;
  dependency?: BuildDependencyGraphItem<DynamicAndConditionalPathHandler>;
};

export const getUpdateBlockDependency =
  (storeArgs: InterfaceStoreStateGetterAndSetter) => (args: UpdateBlockDependency) => {
    const { get } = storeArgs;
    const { blockId, dependency } = args;
    if (!blockId) return;

    const activePageId = get().activeInterfacePageId;
    const blocks = getBlocksFromPage(storeArgs.getDeviceDetails().device, get());
    const block = blocks?.[blockId];

    invariant(block, `Block with id ${blockId} not found while updating block dependency`);

    const updateBlock = {
      ...block,
      ...BlockHelper.extractBlockDependencyKeys(dependency),
    };

    const setBlockInInterfacePage = getSetBlockInInterfacePage(storeArgs);

    setBlockInInterfacePage({ pageId: activePageId, block: updateBlock, blockId });
  };
