import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { ComponentTypeUnionType, BlockType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { StackBlockStateType, StackComponentType } from '@unifyapps/defs/types/blocks';
import Stack from './Stack';

class StackBlockStateDefinition implements BlockStateDefinition {
  get type(): 'Stack' {
    return 'Stack';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType<StackComponentType>) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as StackBlockStateType;
  }

  getComponent() {
    return Stack;
  }
}

export default StackBlockStateDefinition;
