'use client';
import type { TypographyColors } from '@unifyapps/defs/types/typography';
import { TypographyWithTooltip as BaseTypographyWithTooltip } from '@unifyapps/carbon/components/Typography/TypographyWithTooltip';
import { Typography as BaseTypography } from '@unifyapps/ui/components/Typography';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  TypographyBlockStateType,
  TypographyComponentType,
} from '@unifyapps/defs/blocks/Typography/types';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { clsx } from 'clsx';
import React, { lazy, memo, useMemo } from 'react';
import _isNil from 'lodash/isNil';
import { getConditionalPropertyValue } from '@unifyapps/carbon/utils/conditionalValues';
import omit from 'lodash/omit';
import { isEmptyBlock } from '@unifyapps/carbon/no-code/utils/block';
import BlockRenderer from '@unifyapps/carbon/no-code/components/BlockRenderer';
import { Link } from '@unifyapps/ui/components/Link';
import type {
  SlotProps,
  TypographyWithExpandCollapseProps,
} from '@unifyapps/carbon/components/Typography/TypographyWithExpandCollapse/types';
import { TypographyWithExpandCollapseSlots } from '@unifyapps/carbon/components/Typography/TypographyWithExpandCollapse/types';
import HtmlContent from '@unifyapps/carbon/components/HtmlContent';
import { getStringifiedValue } from '@unifyapps/carbon/no-code/utils/typography';
import { MARKDOWN_CLASSNAMES } from './const';

const Markdown = lazy(
  () =>
    import(
      /* webpackChunkName: "block-markdown" */
      './Markdown'
    ),
);

const TypographyWithExpandCollapse = lazy(
  () =>
    import(
      /* webpackChunkName: "block-TypographyWithExpandCollapse" */
      '@unifyapps/carbon/components/Typography/TypographyWithExpandCollapse/TypographyWithExpandCollapse'
    ),
) as unknown as React.FC<TypographyWithExpandCollapseProps> & { Slot: React.FC<SlotProps> };

const LINK_STYLES = { textDecorationColor: 'var(--palette-blue-dark-800)' };

function TypographyBlock({
  dataAttributes,
  computedBlockState,
  component,
}: BlockComponentProps<
  TypographyComponentType,
  TypographyBlockStateType,
  TypographyBlockStateType
>) {
  const {
    content: { value, type, addOns },
    appearance,
  } = computedBlockState;

  const { slots } = component;

  const {
    enableTruncation,
    truncateWith,
    maxNumberOfLines,
    showTooltipOnTextOverflow,
    tooltipPlacement = 'top',
  } = addOns?.truncation ?? {};

  const {
    weight,
    variant,
    styles,
    align,
    color: _color,
    preserveNewline = true,
  } = appearance ?? {};

  const color = useMemo(
    () => getConditionalPropertyValue<TypographyColors>({ value: _color }),
    [_color],
  );

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: styles,
  });

  const typographyProps = {
    align,
    maxNumberOfLines,
    style,
    variant,
    weight,
    ...dataAttributes,
  };

  const Typography =
    enableTruncation && showTooltipOnTextOverflow && maxNumberOfLines
      ? BaseTypographyWithTooltip
      : BaseTypography;

  if (type === 'MARKDOWN' && !_isNil(value)) {
    return (
      <Typography
        // temporary fix for prose styles, need to be mapped correctly
        className={clsx(
          MARKDOWN_CLASSNAMES,
          className,
          color,
          //class !prose-p:text-md does not work when the default class prose-p:text-sm is present, hence using the below condition
          variant === 'text-md' ? 'prose-p:text-md' : 'prose-p:text-sm',
          weight === 'medium' ? 'prose-p:font-medium' : 'prose-p:font-normal',
        )}
        {...omit(typographyProps, ['maxNumberOfLines'])}
      >
        <React.Suspense fallback={null}>
          <Markdown>{getStringifiedValue(value)}</Markdown>
        </React.Suspense>
      </Typography>
    );
  }

  if (type === 'HTML' && !_isNil(value)) {
    return (
      <HtmlContent
        className={clsx(className, color)}
        {...omit(typographyProps, ['maxNumberOfLines'])}
        autofocus={false}
        value={value}
      />
    );
  }

  if (enableTruncation && truncateWith === 'label' && maxNumberOfLines) {
    const text = getStringifiedValue(value);

    return (
      <TypographyWithExpandCollapse
        className={clsx(className, color, { 'whitespace-pre-line': preserveNewline })}
        text={text}
        {...typographyProps}
      >
        <TypographyWithExpandCollapse.Slot name={TypographyWithExpandCollapseSlots.Collapse}>
          {slots?.collapsedTypography?.blockId &&
          !isEmptyBlock(slots.collapsedTypography.blockId) ? (
            <Link style={LINK_STYLES}>
              <BlockRenderer
                blockId={slots.collapsedTypography.blockId}
                key={slots.collapsedTypography.blockId}
              />
            </Link>
          ) : null}
        </TypographyWithExpandCollapse.Slot>
        <TypographyWithExpandCollapse.Slot name={TypographyWithExpandCollapseSlots.Expand}>
          {slots?.expandedTypography?.blockId && !isEmptyBlock(slots.expandedTypography.blockId) ? (
            <Link style={LINK_STYLES}>
              <BlockRenderer blockId={slots.expandedTypography.blockId} />
            </Link>
          ) : null}
        </TypographyWithExpandCollapse.Slot>
      </TypographyWithExpandCollapse>
    );
  }

  return (
    <Typography
      // Added whitespace-pre-line so that the new lines are preserved, earlier this was added in Typography component in this PR - https://github.com/unify-apps/www/pull/3276
      // But was reverted due to some issues, adding this class in Typography block based on preserveNewline flag
      className={clsx(className, color, { 'whitespace-pre-line': preserveNewline })}
      {...typographyProps}
      placement={tooltipPlacement}
    >
      {getStringifiedValue(value)}
    </Typography>
  );
}

export default memo(TypographyBlock);
