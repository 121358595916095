import { useCallback, useMemo } from 'react';
import { DependencyGraphManager } from '../../../../dependency/manager/DependencyGraphManager';
import { DependencyGraphSubscriptions } from '../../../../dependency/subscription/DependencyGraphSubscriptions';

function useNoCodeDependencyGraphManager() {
  const graphManager = useMemo(() => new DependencyGraphManager(), []);
  const subscriptionManager = useMemo(
    () => new DependencyGraphSubscriptions(graphManager),
    [graphManager],
  );

  const deleteDependencies = useCallback(
    (id: string) => {
      graphManager.deleteNode(id);
      subscriptionManager.removeSubscriptions(id);
    },
    [graphManager, subscriptionManager],
  );

  const updateDependenciesAndNotify = useCallback<DependencyGraphManager['updateNodes']>(
    (params) => {
      graphManager.updateNodes(params);
      const allNodes = Object.keys(params);
      allNodes.forEach((nodeId) => {
        subscriptionManager.notifyChange({ id: nodeId });
      });
    },
    [graphManager, subscriptionManager],
  );

  return {
    graphManager,
    subscriptionManager,
    actions: {
      deleteDependencies,
      updateDependenciesAndNotify,
    },
  };
}

export default useNoCodeDependencyGraphManager;
export type UseNoCodeDependencyGraphManagerType = ReturnType<
  typeof useNoCodeDependencyGraphManager
>;
