import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import _reduce from 'lodash/reduce';
import type { Event } from '@unifyapps/defs/types/event';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import { FOOTER_ID, HEADER_ID, NEW_BLOCK_ID, ROOT_ID } from '../const';
import { BlockDataAttributes } from '../hooks/useBlockDataAttributes';
import { isRuntimeBlockId } from './runTimeBlocks';

/**
 * Checks if the block doesn't exist
 * @param blockId -- The block id
 *
 * stringified null is used when extracting blockId from data attributes
 *
 * `null` check is done for backward compatibility
 */
export const isEmptyBlock = (blockId: BlockId | undefined) =>
  blockId === null || blockId === NEW_BLOCK_ID || blockId === 'null';

/**
 * Returns key for react, we're using blockId and index to make it unique, since blockId can be null
 * @param blockId - block id to be rendered
 * @param index - index of the block
 */
export const getBlockKey = (blockId: BlockId, index: number) => `${blockId}_${index}`;

export function getIsRootBlock(blockId: BlockId): boolean {
  return blockId === ROOT_ID;
}

export const getIsRenameAllowed = (blockId: BlockId) =>
  blockId !== ROOT_ID && blockId !== HEADER_ID && blockId !== FOOTER_ID;

/**
 * Returns all the events from all the blocks
 * @param blocks -- The blocks
 */
export const getAllBlockEvents = (blocks: Record<string, BlockType>) =>
  _reduce(
    blocks,
    (acc, block) => {
      const { events } = block;
      if (events) {
        acc.push(...events);
      }
      return acc;
    },
    [] as Event[],
  );

export const getTargetBlockElement = ({
  targetBlockId = '',
}: {
  targetBlockId: string | undefined;
}) => {
  const isRuntimeBlock = isRuntimeBlockId(targetBlockId);

  return isRuntimeBlock
    ? document.querySelector(`[${BlockDataAttributes.BlockRunTimeBlockId}="${targetBlockId}"]`)
    : document.querySelector(`[${BlockDataAttributes.BlockId}="${targetBlockId}"]`);
};

type OmitKeys<T, K extends keyof T> = Omit<T, K>;
type PickKeys<T, K extends keyof T> = Pick<T, K>;

export const omitKeysFromEntity = <T extends Record<string, unknown>, K extends keyof T>(
  entity: object | undefined,
  keys: K[],
): OmitKeys<T, K> => {
  return _omit(entity, keys) as OmitKeys<T, K>;
};

export const pickKeysFromEntity = <T extends Record<string, unknown>, K extends keyof T>(
  entity: object | undefined,
  keys: K[],
): PickKeys<T, K> => {
  return _pick(entity, keys) as PickKeys<T, K>;
};

export const omitKeysFromEntities = <T extends Record<string, unknown>, K extends keyof T>(
  entities: Record<string, T | undefined>,
  keys: K[],
): Record<string, OmitKeys<T, K>> => {
  const result: Record<string, OmitKeys<T, K>> = {};

  for (const [key, entity] of Object.entries(entities)) {
    result[key] = omitKeysFromEntity(entity, keys);
  }

  return result;
};
