import { UAApps } from '@unifyapps/defs/types/apps';
import { UAResources } from '@unifyapps/defs/types/resources';
import type { BlockType } from '@unifyapps/defs/types/block';
import type { DataSource } from '@unifyapps/defs/types/dataSource';
import type { DataTableComponentType } from '@unifyapps/defs/blocks/Table/DataTable/types';
import type { EntityContentType } from '@unifyapps/defs/blocks/Comments/types';
import { StandardDataSourceIds } from '@unifyapps/defs/types/dataSourceIds';
import { getSelectedDataSourcePath } from '../../../../utils/getSelectedDataSourcePath';
import {
  getEntityTypeFromDataSourceId,
  getIsDataSourceAnEntityType,
} from '../../../utils/entityDataSource';

interface GetDataTableEntityDataSourceParams {
  blockId: string;
  dataSourceId: string;
  activeInterfacePageId: string;
  inputs?: DataTableComponentType['content']['inputs'];
}

export const getDataTableEntityDataSource = (
  params: GetDataTableEntityDataSourceParams,
): DataSource & { id: string } => {
  const { blockId, dataSourceId, activeInterfacePageId, inputs } = params;

  return {
    id: dataSourceId,
    type: 'APPLICATION',
    interfacePageId: activeInterfacePageId,
    name: `Entity Data Source: ${dataSourceId}`,
    context: {
      appName: UAApps.StorageByUA,
      resourceName: UAResources.StorageByUAFetchRecords,
    },
    inputs: {
      numberOfRecordsToFetch: 'MULTIPLE',
      includeTotalCount: true,
      sortBy: inputs?.sorts,
      triggerInputCondition: inputs?.filters,
      searchField: inputs?.searchField,
      object_type: getEntityTypeFromDataSourceId(dataSourceId),
      page: {
        offset: `{{ ${blockId}['content']['page']['offset'] }}`,
        limit: `{{ ${blockId}['content']['page']['size'] }}`,
      },
    },
  };
};

export const getEntityTypeDataSourceFromBlock = (
  block: BlockType,
  activeInterfacePageId: string,
): (DataSource & { id: string }) | null => {
  switch (block.component.componentType) {
    case 'Table': {
      const { data, inputs } = block.component.content;
      const { dataSourceId } = getSelectedDataSourcePath({ data });

      if (dataSourceId && getIsDataSourceAnEntityType(dataSourceId)) {
        return getDataTableEntityDataSource({
          blockId: block.id,
          dataSourceId,
          activeInterfacePageId,
          inputs,
        });
      }

      return null;
    }

    case 'Comments': {
      const { data, assetId } = block.component.content as EntityContentType;
      const { dataSourceId } = getSelectedDataSourcePath({ data });

      if (dataSourceId && getIsDataSourceAnEntityType(dataSourceId)) {
        return {
          id: dataSourceId,
          standardDataSourceId: StandardDataSourceIds.FetchEntityComments,
          type: 'APPLICATION',
          interfacePageId: activeInterfacePageId,
          name: `Entity Data Source: ${dataSourceId}`,
          context: {
            appName: UAApps.CommentsByUnifyApps,
            resourceName: UAResources.CommentsByUAFindComments,
          },
          inputs: {
            assetId,
            assetClass: getEntityTypeFromDataSourceId(dataSourceId),
          },
        };
      }

      return null;
    }

    default: {
      return null;
    }
  }
};
