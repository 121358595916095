import { useSearchDeployedEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import type { InterfaceNamespaceEntity } from '@unifyapps/defs/types/namespace';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import InterfaceHelper from '../../../../../stores/InterfaceStore/helper/InterfaceHelper';
import { useGetUserContext } from '../../../../useGetUserContext';
import {
  getInterfaceCommonNamespaceQueryFilters,
  getInterfaceComponentNamespaceQueryFilters,
  getShouldFetchNamespace,
} from '../../../standardEntities/data/useFetchInterfaceNamespaces/utils';
import type { FetchInterfaceNamespacesParams } from '../../../standardEntities/data/useFetchInterfaceNamespaces/types';

type Namespace = InterfaceNamespaceEntity | undefined;

export const useFetchDeployedInterfaceNamespaces = (props: FetchInterfaceNamespacesParams) => {
  const { userContext } = useGetUserContext();
  const { interfaceId, interfaceRecord, interfacePageId, mode } = props;

  const userLocale = userContext?.locale;
  const locale = InterfaceHelper.getLocale({ userLocale, interfaceRecord, mode });
  const shouldFetchNamespace = getShouldFetchNamespace(locale, interfaceRecord);

  const {
    data: commonNamespaceData,
    isLoading: isLoadingCommonNamespace,
    error: errorCommonNamespace,
  } = useSearchDeployedEntities(
    UAEntityTypes.InterfaceNamespace,
    getInterfaceCommonNamespaceQueryFilters({
      interfaceId,
      locale,
    }),
    {
      query: {
        enabled: shouldFetchNamespace,
        retry: 3,
      },
    },
  );

  const {
    data: componentNamespaceData,
    isLoading: isLoadingComponentNamespace,
    error: errorComponentNamespace,
  } = useSearchDeployedEntities(
    UAEntityTypes.InterfaceNamespace,
    getInterfaceComponentNamespaceQueryFilters({
      interfaceId,
      interfaceComponentId: interfacePageId ?? '',
      locale,
    }),
    {
      query: {
        enabled: Boolean(interfacePageId && shouldFetchNamespace),
        retry: 3,
      },
    },
  );

  return {
    isLoading: isLoadingCommonNamespace || isLoadingComponentNamespace,
    error: errorCommonNamespace || errorComponentNamespace,
    commonNamespace: commonNamespaceData?.objects?.[0] as Namespace,
    interfaceComponentNamespace: componentNamespaceData?.objects?.[0] as Namespace,
  };
};
