import type {
  ComponentType,
  BlockStateDefinition,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  CopilotBlockRef,
  CopilotBlockStateType,
  CopilotComponentType,
} from '@unifyapps/defs/blocks/Copilot/types';
import { CopilotBlockMethods } from '@unifyapps/defs/blocks/Copilot/types';
import type { Action } from '@unifyapps/defs/types/action';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { lazy } from 'react';

const importCopilotComponent = () =>
  import(
    /* webpackChunkName: "copilot-block" */
    './Copilot'
  );

const Copilot = lazy(importCopilotComponent);

class CopilotBlockStateDefinition implements BlockStateDefinition {
  useBlockRef?: boolean | undefined = true;

  get type(): ComponentTypeUnionType {
    return 'Copilot';
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as CopilotComponentType, ['content', 'appearance', 'componentType']),
      aiAgent: {},
    });
  }

  getComponent() {
    return Copilot as ComponentType;
  }

  getEventTargetIds() {
    return [];
  }

  getSlotOrder(): { order: string[] } {
    return {
      order: ['messageHistory', 'messageInputPanel'],
    };
  }

  getBlockControlMethods() {
    return [
      {
        id: CopilotBlockMethods.SendMessage,
        label: 'Send Message',
        method: (block: BlockStateUnionType, action: Action, blockRef?: CopilotBlockRef) => {
          const methodPayload = (action.payload?.methodPayload ?? {}) as {
            message?: string;
            attachments?: FileType[];
          };
          if ((block as CopilotBlockStateType).content.aiAgentId) {
            if (methodPayload.message || methodPayload.attachments?.length) {
              blockRef?.submitMessage?.({
                message: methodPayload.message,
                attachments: methodPayload.attachments,
              });
            }

            return Promise.resolve();
          }

          return {
            ...block,
            errorMessage: 'Please select an AI Agent ID',
          };
        },
      },
      {
        id: CopilotBlockMethods.CreateNewChat,
        label: 'Create New Chat',
        method: (_: BlockStateUnionType, __: Action, blockRef?: CopilotBlockRef) => {
          blockRef?.createNewChat?.();

          return Promise.resolve();
        },
      },
      {
        id: CopilotBlockMethods.GoToChat,
        label: 'Go to Chat',
        method: (_: BlockStateUnionType, action: Action, blockRef?: CopilotBlockRef) => {
          const methodPayload = action.payload?.methodPayload as { chatId: string };
          blockRef?.goToChat?.(methodPayload.chatId);

          return Promise.resolve();
        },
      },
    ];
  }
}

export default CopilotBlockStateDefinition;
