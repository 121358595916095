import _find from 'lodash/find';
import { invariant } from 'ts-invariant';
import type { Action } from '@unifyapps/defs/types/action';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import type { BlockId } from '@unifyapps/defs/types/block';
import { useRegistryContext } from '../../../../components/RegistryProvider';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../stores/GlobalStateStore';
import { useGetInterfaceStoreState } from '../../../../stores/InterfaceStore';
import { isRuntimeBlockId } from '../../../../utils/runTimeBlocks';
import { useComputeConditionEvaluationGetter } from '../../../useComputeConditionEvaluationGetter';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

type ControlComponentPayloadType = {
  blockId: string;
  methodName: string;
  methodPayload: Record<string, unknown>;
};

function useControlBlockMethod() {
  const { showSnackbar, closeSnackbar } = useSnackbar();

  const { registry } = useRegistryContext();
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const { setBlockState } = useGlobalStateStore().use.actions();
  const getComputedFilters = useComputeConditionEvaluationGetter();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const onCallBlockMethod = useEventCallback(
    async ({
      actionContext,
      blockId,
      action,
      methodName,
      targetBlockId,
    }: {
      blockId: string;
      methodName: string;
      action: Action;
      actionContext: OnActionArgs['actionContext'];
      targetBlockId?: BlockId;
    }) => {
      const isRunTimeBlock = targetBlockId ? isRuntimeBlockId(targetBlockId) : false;
      const blocksState = getGlobalStateStoreState().blocks;
      const activeInterfacePageId = getInterfaceStoreState().activeInterfacePageId;
      const interfacePages = getInterfaceStoreState().interfacePages;
      const blocks = interfacePages[activeInterfacePageId].properties.blocks;

      const computedAction = getNoCodeComputedData<Action>(
        action as unknown as Record<string, unknown>,
        actionContext,
      );

      const blockRefs = getGlobalStateStoreState().blockRefs;

      const block = _find(blocks, { id: blockId });

      invariant(block, `Block with id ${blockId} not found`);

      const controlMethods = registry.getBlockControlMethods(block.component.componentType, block);
      const method = _find(controlMethods, { id: methodName })?.method;

      if (method) {
        const blockState = blocksState[isRunTimeBlock && targetBlockId ? targetBlockId : blockId];
        const nextBlockState = await method(
          blockState,
          computedAction,
          blockRefs[blockId]?.current,
          {
            showSnackbar,
            closeSnackbar,
            getComputeContext: () => actionContext,
            getComputedFilters,
            getComputedData: getNoCodeComputedData,
          },
        );
        if (nextBlockState) {
          setBlockState(isRunTimeBlock && targetBlockId ? targetBlockId : blockId, nextBlockState);
        }
      }
      return Promise.resolve();
    },
  );

  const onControlBlockMethod: ActionHandlerType = useEventCallback(
    async ({ action, actionContext, blockId }: OnActionArgs) => {
      const payload = action.payload as ControlComponentPayloadType;

      await onCallBlockMethod({
        blockId: payload.blockId,
        targetBlockId: blockId,
        methodName: payload.methodName,
        actionContext,
        action,
      });
    },
  );

  return { onControlBlockMethod, onCallBlockMethod };
}

export default useControlBlockMethod;
