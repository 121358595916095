import { optimisedEvaluateExpression } from './evaluateExpression';
import type { Obj } from './recursiveUpdate';
import { recursiveUpdate } from './recursiveUpdate';
import { createExpressionEvaluator } from './indirectEval';

export const getComputedData = <T>(
  data: Record<string, unknown> | string | unknown[],
  context: Record<string, unknown>,
  shouldEvaluateResult?: (result: string) => boolean,
) => {
  // we create a closure of new function for the context, for it to be used for all evaluations in the data
  // saving us from the overhead of creating a new function for each evaluation
  const expressionEvaluator = createExpressionEvaluator(context);
  return recursiveUpdate(data, (value) => {
    if (typeof value !== 'string') return value as Obj;

    return optimisedEvaluateExpression({
      context,
      expression: value,
      shouldEvaluateResult,
      expressionEvaluator,
    });
  }) as T;
};
