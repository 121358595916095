import compact from 'lodash/compact';
import omit from 'lodash/omit';
import { formatDate } from '../../../utils/date';
import { formatTime } from '../../../utils/time';
import { formatNumber } from '../../../utils/number';
import { adaptSearchToFilter } from '../../../hooks/useAggregationQueryBuilder';
import { safeJsonParse, safeJsonStringify } from '../../../utils/json';

export const ComputeContextKeys = {
  PAGE_VARIABLES: '__PAGE_VARS__',
  PAGE_FUNCTIONS: '__PAGE_FUNCTIONS__',
  SESSION_STORAGE: '__SESSION_STORAGE__',
  LOCATION: 'location',
  UTILS: 'utils',
  PAGE_INPUTS: 'pageInputs',
  USER_CONTEXT: 'userContext',
  INTERFACE_RECORD: 'interfaceRecord',
  RUNTIME_BLOCKS: 'runtimeBlocks',
};

/**
 * When creating dependency graph, we have more entityId {{ <entityId>.something }} that we reference in blocks, but
 * these are added to context at runtime and not stored in the block state.
 */
export const RESERVED_COMPUTE_CONTEXT_KEYS = Object.values(omit(ComputeContextKeys, 'UTILS'));
export const BLOCKS_STATE_WITH_METHOD = 'blocksStateWithMethod';

export const contextUtils = {
  formatDate,
  formatTime,
  compact,
  adaptSearchToFilter,
  parseJson: safeJsonParse,
  stringifyJson: safeJsonStringify,
  formatNumber,
};
