import type { BlockType } from '@unifyapps/defs/types/block';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import DependencyFlowHelper from '../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore';

export function useIsEntitySuspended({
  dataSourceIds = [],
}: {
  dataSourceIds: BlockType['dataSourceIds'];
}) {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const dataSourceRecords = useDataSourceRecordStore().use.dataSources();
  const dataSources = useGlobalStateStore().use.dataSources();

  const automaticDataSourceIds = useMemo(() => {
    // it is possible that we might have includes manual data source id in dataSourceIds
    return dataSourceIds.filter((id) => {
      return !DataSourceHelper.isManualDataSource(dataSourceRecords[id]);
    });
  }, [dataSourceIds, dataSourceRecords]);

  const areAllQueriesReady = automaticDataSourceIds.every((id) => {
    return DependencyFlowHelper.isDependencyFinished(dsDependencyFlow, id);
  });

  const isWaitingForRequestsToBeMade = automaticDataSourceIds.some((id) => {
    return isEmpty(dataSources[id]);
  });

  const isLoadingBlockingQueries = automaticDataSourceIds.some((id) => {
    const value = dataSources[id];
    return value?.isLoading !== false;
  });

  return {
    isSuspended: isLoadingBlockingQueries || isWaitingForRequestsToBeMade || !areAllQueriesReady,
  };
}
