import _mapKeys from 'lodash/mapKeys';
import { mergeAttributes, Node } from '@unifyapps/editor/tiptap/core';
import type { SuggestionOptions } from '@unifyapps/editor/tiptap/suggestion';
import { Suggestion } from '@unifyapps/editor/tiptap/suggestion';
import { PluginKey } from '@unifyapps/editor/tiptap/pm';
import './mention.css';

export type VariableMentionOptions = {
  suggestion: Omit<SuggestionOptions, 'editor'>;
};

export const UserMentionPluginKey = new PluginKey('user-mention');

export const UserMentionExtension = Node.create<VariableMentionOptions>({
  name: 'user-mention',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions() {
    return {
      suggestion: {
        char: '@',
        pluginKey: UserMentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [{ type: this.name, attrs: props as Record<string, string> }])
            .run();

          window.getSelection()?.collapseToEnd();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = Boolean($from.parent.type.contentMatch.matchType(type));

          // Get the character after the @ mention trigger
          const nodeAfter = state.doc.textBetween(range.from, range.to + 1, undefined, '\uFFFC');
          const nextChar = nodeAfter.charAt(range.to - range.from);

          // Cancel the suggestion if the next character is a space
          if (nextChar === ' ') {
            return false;
          }

          return allow;
        },
      },
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id as string,
          };
        },
      },
      label: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML(params) {
    const { node, HTMLAttributes } = params;
    return [
      'a',
      mergeAttributes(
        {
          'data-type': this.name,
          'data-mention-id': `${node.attrs.id}`,
          class: 'user-mention',
          ..._mapKeys(node.attrs, (value, key) => `data-${key}`),
        },
        HTMLAttributes,
      ),
      `@${node.attrs.label} `,
    ];
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
