import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  FileUploadBlockStateType,
  FileUploadComponentType,
} from '@unifyapps/defs/blocks/FileUpload/types';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const importFileUploadComponent = () =>
  import(
    /* webpackChunkName: "file-upload-block" */
    './FileUpload'
  );

const FileUpload = lazy(importFileUploadComponent);

class FileUploadBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'FileUpload';
  }

  getEventTargetIds() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType): FileUploadBlockStateType => ({
      id: block.id,
      ..._pick(block.component as FileUploadComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
      files: [],
    });
  }

  getBlockControlMethods() {
    return [
      {
        id: 'removeFile' as const,
        label: 'Remove File',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { fileId?: string };
          };

          const { fileId } = methodPayload;

          return {
            ...blockState,
            removeFileId: fileId,
          } as FileUploadBlockStateType;
        },
      },
    ];
  }

  getComponent() {
    return FileUpload;
  }

  preload() {
    return {
      blockPreload: importFileUploadComponent,
    };
  }
}

export default FileUploadBlockStateDefinition;
