import type { BlockType } from '@unifyapps/defs/types/block';
import { useEffect, useMemo } from 'react';
import DependencyFlowHelper from '../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore';

export function useTriggerAutomaticDataSources({
  dataSourceIds = [],
}: {
  dataSourceIds: BlockType['dataSourceIds'];
}) {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const dataSourceRecords = useDataSourceRecordStore().use.dataSources();
  const { datasource } = useGlobalStateStore().use.actions();

  const automaticDataSourceIds = useMemo(() => {
    // it is possible that we might have includes manual data source id in dataSourceIds
    return dataSourceIds.filter((id) => {
      return !DataSourceHelper.isManualDataSource(dataSourceRecords[id]);
    });
  }, [dataSourceIds, dataSourceRecords]);

  const nonStagedDataSourceIds = useMemo(() => {
    return automaticDataSourceIds.filter((id) => {
      return DependencyFlowHelper.isDependencyUnstaged(dsDependencyFlow, id);
    });
  }, [automaticDataSourceIds, dsDependencyFlow]);

  useEffect(() => {
    if (nonStagedDataSourceIds.length) {
      datasource.addDependencyFlows(
        DependencyFlowHelper.createStagingDependencyFlow({
          ids: nonStagedDataSourceIds,
        }),
      );
    }
  }, [datasource, nonStagedDataSourceIds]);
}
