import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { PageFunction } from '@unifyapps/defs/types/pageFunction';
import omit from 'lodash/omit';
import type { UnsavedDataSource } from '../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../helper/DataSourceHelper';
import noCodeBuildDependencyGraph from '../../graph/noCodeBuildDependencyGraph';
import { isNoCodeEntityId } from '../../utils/isNoCodeEntityId';
import {
  dedupeDynamicAndConditionalPathsHandler,
  dynamicAndConditionalPathsHandler,
} from './handlers';

const noCodeBuildDependencyGraphParams = {
  isValidEntity: isNoCodeEntityId,
  handlers: [dynamicAndConditionalPathsHandler],
  dedupeHandlers: [dedupeDynamicAndConditionalPathsHandler],
};

// no need to remove dependsOn etc. here because we don't pick them in block state
function blockDependencyBuilder({ blockState }: { blockState: NonNullable<BlockStateUnionType> }) {
  return noCodeBuildDependencyGraph({
    objects: { [blockState.id]: blockState },
    ...noCodeBuildDependencyGraphParams,
  });
}

function pageFunctionDependencyBuilder({ pageFunction }: { pageFunction: PageFunction }) {
  const adaptedPageFunction = omit(pageFunction, ['dependsOn']);
  return noCodeBuildDependencyGraph({
    objects: { [pageFunction.id]: adaptedPageFunction },
    ...noCodeBuildDependencyGraphParams,
  });
}

function dataSourceDependencyBuilder({ dataSource }: { dataSource: UnsavedDataSource }) {
  const { adaptedDataSources } = DataSourceHelper.getAdaptedDataSourcesForDependencyGraph({
    [dataSource.id]: dataSource,
  });

  return noCodeBuildDependencyGraph({
    objects: { [dataSource.id]: adaptedDataSources[dataSource.id] },
    ...noCodeBuildDependencyGraphParams,
  });
}

export { blockDependencyBuilder, pageFunctionDependencyBuilder, dataSourceDependencyBuilder };
