import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import _reduce from 'lodash/reduce';
import { useMemo } from 'react';
import { MODULE_COMPONENT_FILTER } from '../../../../../utils/interfaceQueryFilters';
import { useInterfaceComponent } from '../useFetchInterfaceComponent';

function useInterfaceModules({ interfaceId }: { interfaceId: string }) {
  const {
    interfaceComponents: interfaceModules,
    isLoading,
    error,
  } = useInterfaceComponent({
    interfaceId,
    searchFilters: MODULE_COMPONENT_FILTER,
  });

  const interfaceModulesMap = useMemo(
    () =>
      _reduce(
        interfaceModules,
        (acc, module: InterfacePageEntity) => {
          acc[module.id] = module;
          return acc;
        },
        {} as Record<string, InterfacePageEntity>,
      ),
    [interfaceModules],
  );

  return {
    interfaceModulesMap,
    isLoading,
    error,
  };
}

export { useInterfaceModules };
