import { useMemo, useRef } from 'react';
import getSuggestions from '@unifyapps/editor/extensions/mention/suggestions/getSuggestions';
import _map from 'lodash/map';
import type { RichEditorProps } from '@unifyapps/editor/editors/RichTextEditor';
import { UserMentionExtension } from '../UserMentionExtension';
import MentionsList, { type MentionItem } from '../UserMentionsList';

const ARROW_SVG = `<svg width="40" height="54" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill="var(--bg-primary)" stroke="var(--border-secondary)" stroke-width="1px" d="M18 15L12 9L6 15" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const useExtensions = ({
  getMentionsItems,
  _extensions,
  isMentionListActiveRef,
}: {
  getMentionsItems?: ({ query }: { query: string }) => Promise<MentionItem[]>;
  _extensions?: RichEditorProps['extensions'];
  isMentionListActiveRef?: React.MutableRefObject<boolean>;
}) => {
  const extensions = useMemo(() => {
    const baseExtensions: RichEditorProps['extensions'] = [];
    if (getMentionsItems) {
      baseExtensions.push(
        UserMentionExtension.extend().configure({
          suggestion: getSuggestions<MentionItem>({
            items: getMentionsItems,
            SuggestionList: MentionsList,
            placement: 'bottom-start',
            arrow: ARROW_SVG,
            isMentionListActiveRef,
          }),
        }),
      );
    }

    if (_extensions?.length) {
      baseExtensions.push(..._extensions);
    }

    return baseExtensions;
  }, [_extensions, getMentionsItems, isMentionListActiveRef]);

  return { extensions };
};
