import type { FC } from 'react';
import type { BlockComponentProps } from '../BlockRenderer/types';
import useGetFallbackNode from '../DataSourceController/hooks/useGetFallbackComponent';
import { useIsEntitySuspended } from '../DataSourceController/hooks/useIsEntitySuspended';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';

function withSuspense<T, P, R>(Component: FC<BlockComponentProps<T, P, R>>) {
  return function SuspenseWrapper(props: BlockComponentProps<T, P, R>) {
    const { fallback, dataSourceIds } = props;

    const { isSuspended } = useIsEntitySuspended({
      dataSourceIds,
    });

    const { isLoading: isLoadingSessionStorage } =
      useGlobalStateStore().use.interfaceSessionStorage();

    const { fallbackNode } = useGetFallbackNode({
      fallback,
      isSuspended,
    });

    if (
      isSuspended ||
      // when undefined, that means things are not yet loaded
      isLoadingSessionStorage === undefined ||
      isLoadingSessionStorage
    )
      return fallbackNode;

    return <Component {...props} />;
  };
}

export default withSuspense;
