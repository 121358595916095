import { default as BaseTextarea } from '@mui/joy/Textarea';
import { forwardRef, useCallback, useMemo } from 'react';
import { clsx } from 'clsx';
import { getInputState } from '../../lib/getInputState';
import { INPUT_VARS_CLASSNAME } from '../Input/styles';
import type { TextareaProps } from './types';
import '../Input/input.vars.css';
import './textarea.css';

const getTextareaClassnames = ({ size }: { size: TextareaProps['size'] }) =>
  clsx('flex flex-row items-center p-lg', {
    'text-xs': size === 'sm',
  });

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {
    className,
    slotProps,
    error,
    onChange,
    onChangeValue,
    size = 'md',
    variant = 'outlined',
    ...rest
  } = props;

  const state = getInputState(props);

  const slotPropsWithRef = useMemo(
    () => ({
      ...slotProps,
      textarea: {
        ...slotProps?.textarea,
        ref,
      },
    }),
    [ref, slotProps],
  );

  const onChangeHandler = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (onChangeValue) onChangeValue(e.target.value);
      if (onChange) onChange(e);
    },
    [onChange, onChangeValue],
  );

  const textAreaClassName = getTextareaClassnames({ size });

  return (
    <BaseTextarea
      {...rest}
      className={clsx(INPUT_VARS_CLASSNAME, textAreaClassName, className)}
      data-size={size} // if sizes are added to textarea, set this prop to the size
      data-state={state}
      data-variant={variant}
      error={error}
      onChange={onChangeHandler}
      slotProps={slotPropsWithRef}
      variant={variant}
    />
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;
