import type { DependencyGraphManager } from '../manager/DependencyGraphManager';
import type { EntityId } from '../manager/types';
import type { DependencyUpdate } from './types';

export class DependencyGraphSubscriptions {
  private subscriptions = new Map<EntityId, Record<string, () => void>>();
  private graphManager: DependencyGraphManager;

  /**
   * Create an instance of DependencyGraphSubscriptions.
   * @param graphManager - An instance of DependencyGraphManager to interact with.
   */
  constructor(graphManager: DependencyGraphManager) {
    this.graphManager = graphManager;
  }

  /**
   * Subscribe to changes for a specific node.
   * @param params - An object containing id, callback, and key.
   */
  subscribe({ callback, id, key }: { id: EntityId; callback: () => void; key: string }): void {
    const nodeSubscriptions = this.subscriptions.get(id) ?? {};
    nodeSubscriptions[key] = callback;
    this.subscriptions.set(id, nodeSubscriptions);
  }

  /**
   * Unsubscribe from changes for a specific node.
   * @param params - An object containing id and key.
   */
  unsubscribe({ id, key }: { id: EntityId; key: string }): void {
    const nodeSubscriptions = this.subscriptions.get(id);
    if (!nodeSubscriptions) return;
    const { [key]: _, ...rest } = nodeSubscriptions;
    this.subscriptions.set(id, rest);
  }

  /**
   * Unsubscribe all callbacks for a specific node.
   * @param id - The identifier of the node to remove subscriptions for.
   */
  removeSubscriptions(id: EntityId) {
    this.subscriptions.delete(id);
  }

  /**
   * Notify all affected nodes of a change.
   * @param update - An object containing the id of the changed node.
   */
  notifyChange(update: DependencyUpdate): void {
    const affected = this.graphManager.getImpactedNodes(update.id);

    // we are adding set timeout to make sure that all the affected nodes are updated in the next tick,
    // but remember we are not breaking every notification in set timeout, because it causes too much delay
    setTimeout(() => {
      affected.forEach((id) => {
        const callbacks = this.subscriptions.get(id);
        if (callbacks) {
          Object.values(callbacks).forEach((callback) => callback());
        } else {
          // No callbacks found for this node
        }
      });
    }, 0);
  }

  /**
   * Notify all subscribed nodes.
   */
  notifyAll(): void {
    this.subscriptions.forEach((callbacks) => {
      Object.values(callbacks).forEach((callback) => callback());
    });
  }
}
