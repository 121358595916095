import type { DependsOnType } from '@unifyapps/defs/types/page';
import type {
  BuildDependencyGraphItem,
  BuildDependencyGraphParams,
} from '../../dependency/utils/buildDependencyGraph';
import { buildDependencyGraph } from '../../dependency/utils/buildDependencyGraph';
import type { DynamicAndConditionalPathHandler } from '../context/DependencyGraphContext/handlers';
import { parseRuntimeBlockPathId } from '../utils/parseRuntimeBlockPathId';
import { isRuntimeBlockId } from '../utils/runTimeBlocks';
import { DependencyHelper } from '../../dependency/helpers/DependencyHelper';

// these are all the dependency related keys used in no-code entities
// dependsOn is generated by buildDependencyGraph and others are generated by handlers
export const NoCodeDependencyKeys = {
  DependsOn: 'dpOn',
  dynamicPaths: 'dP',
  conditionalPaths: 'cP',
};

function noCodeBuildDependencyGraph(
  params: BuildDependencyGraphParams<DynamicAndConditionalPathHandler>,
) {
  const dependencies = buildDependencyGraph<DynamicAndConditionalPathHandler>(params);

  // Transform the dependencies to handle runtime block references
  return Object.entries(dependencies).reduce<
    Record<string, BuildDependencyGraphItem<DynamicAndConditionalPathHandler> | undefined>
  >((acc, [entityId, depGraph]) => {
    if (!depGraph) {
      return acc;
    }

    const newDependsOn = depGraph.dependsOn?.reduce<DependsOnType[]>((accDependsOn, dependsOn) => {
      const id = DependencyHelper.getDependsOnId(dependsOn);
      const properties = DependencyHelper.getDependsOnProperties(dependsOn);

      let newId = id;
      let newProperties = properties;

      if (isRuntimeBlockId(id)) {
        const parsedId = parseRuntimeBlockPathId(id);

        if (!parsedId) {
          // Not a runtime block reference, return as is
          accDependsOn.push(dependsOn);
          return accDependsOn;
        }

        newId = parsedId.targetBlockId;

        // transform paths to use the correct block ID format
        newProperties = properties.map((property) => {
          const parsedPath = parseRuntimeBlockPathId(property);
          if (!parsedPath) {
            return property; // not a runtime block path, return as is
          }

          // create the new path using the target block ID and any remaining path components
          return `${parsedPath.targetBlockId}${parsedPath.remainingPathString}`;
        });
      }

      accDependsOn.push({
        id: newId,
        p: newProperties,
      });

      return accDependsOn;
    }, []);

    acc[entityId] = {
      ...depGraph,
      dependsOn: newDependsOn,
    };

    return acc;
  }, {});
}

export default noCodeBuildDependencyGraph;
