import type { JSONSchema7 } from 'json-schema';
import _includes from 'lodash/includes';
import type { KeyValuePairs } from '../../rjsf/fields/KeyValuePairField/utils';
import { adaptToKeyValuePairList } from '../../rjsf/fields/KeyValuePairField/utils';
import { isHashed, isMasked, isSecure, isSkipped } from '../../../utils/schema/utils';
import type {
  AutoGeneratedFieldDefinition,
  Option,
  SchemaAddModalFormFields,
  SchemaToFormDataUiSchema,
} from './types';
import { VariableType } from './types';

type JSONSchema = JSONSchema7 & {
  help?: string;
  searchable?: boolean;
  clientSearchable?: boolean;
  enableSelectAll?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  primaryKey?: boolean;
  nameField?: boolean;
  autoGeneratedFieldDefinition?: AutoGeneratedFieldDefinition;
  foreignKey?: { reference: string };
  foreignKeyConstraintEnforced?: boolean;
  dateFormat?: string;
};

// reverse of formTypeToSchema to show in edit mode, check if we can use any rjsf utils
export function schemaToFormType(schema: JSONSchema): VariableType {
  // before we have added type: [string, number, integer, boolean] in schema to handle single-select dropdown
  // but were removed now, so we are adding this to have backward compatibility
  // ping @pradeet for queries
  if (Array.isArray(schema.type)) {
    return VariableType.SingleSelectDropdown;
  }

  switch (schema.type) {
    case 'string': {
      if (schema.format === 'radio') {
        return VariableType.Radio;
      }
      if (schema.format === 'single-select' || schema.oneOf || schema.foreignKey) {
        return VariableType.SingleSelectDropdown;
      }
      if (schema.format === 'date') {
        return VariableType.Date;
      }
      if (schema.format === 'date-time') {
        return VariableType.DateTime;
      }
      if (schema.format === 'time') {
        return VariableType.Time;
      }
      if (schema.format === 'file-input') {
        return VariableType.File;
      }
      if (schema.format === 'auto-generate') {
        return VariableType.AutoNumber;
      }
      if (schema.format === 'textarea') {
        return VariableType.TextArea;
      }
      if (schema.format === 'rich-text') {
        return VariableType.RichText;
      }
      if (schema.format === 'email') {
        return VariableType.Email;
      }
      if (schema.format === 'password') {
        return VariableType.Password;
      }
      if (schema.format === 'json-schema') {
        return VariableType.JsonSchema;
      }
      if (schema.format === 'otp') {
        return VariableType.Otp;
      }
      if (schema.format === 'code-editor') {
        return VariableType.CodeEditor;
      }
      if (schema.format === 'slot') {
        return VariableType.Slot;
      }
      return VariableType.String;
    }
    case 'array': {
      if (
        typeof schema.items === 'object' &&
        ((schema.items as JSONSchema).oneOf || schema.foreignKey)
      ) {
        return VariableType.MultiSelectDropdown;
      }
      // before we have added type: [string, number, integer, boolean] in schema to handle multi-select dropdown
      // but were removed now, so we are adding this to have backward compatibility
      // ping @pradeet for queries
      if (
        schema.format === 'multi-select' ||
        Array.isArray((schema.items as JSONSchema7 | undefined)?.type)
      ) {
        return VariableType.MultiSelectDropdown;
      }
      if (schema.format === 'vector') {
        return VariableType.Vector;
      }
      if (schema.format === 'file-uploader') {
        return VariableType.FileUploader;
      }
      if (schema.format === 'multi-select') {
        return VariableType.MultiSelectDropdown;
      }
      if (schema.format === 'checkbox') {
        return VariableType.Checkbox;
      }
      if (schema.format === 'key-value-pair') {
        return VariableType.KeyValuePair;
      }
      return VariableType.Array;
    }
    case 'object':
      if (schema.format === 'file-uploader') {
        return VariableType.FileUploader;
      }
      if (schema.format === 'key-value-pair') {
        return VariableType.KeyValuePair;
      }
      if (schema.format === 'phone-number') {
        return VariableType.PhoneNumber;
      }
      return VariableType.Object;
    case 'number': {
      if (schema.oneOf || schema.foreignKey) {
        return VariableType.SingleSelectDropdown;
      }
      if (schema.format === 'date') {
        return VariableType.DateEpoch;
      }
      if (schema.format === 'date-time') {
        return VariableType.DateTimeEpoch;
      }
      if (schema.format === 'auto-generate') {
        return VariableType.AutoNumber;
      }
      if (schema.format === 'slider') {
        return VariableType.Slider;
      }
      return VariableType.Number;
    }
    case 'boolean':
      if (schema.oneOf || schema.foreignKey) {
        return VariableType.SingleSelectDropdown;
      }
      if (schema.format === 'checkbox') {
        return VariableType.Checkbox;
      }
      if (schema.format === 'switch') {
        return VariableType.Switch;
      }
      return VariableType.Boolean;
    case 'integer':
      if (schema.oneOf || schema.foreignKey) {
        return VariableType.SingleSelectDropdown;
      }
      if (schema.format === 'date' && schema.dateFormat === 'epoch') {
        return VariableType.DateEpoch;
      }
      if (schema.format === 'date-time' && schema.dateFormat === 'epoch') {
        return VariableType.DateTimeEpoch;
      }
      return VariableType.Integer;
    default:
      throw new Error(`Unknown schema type: ${schema.type?.toString()}`);
  }
}

export const schemaToFormData = ({
  fieldKey,
  schema,
  isRequired,
  useLegacyOptions = true,
}: {
  fieldKey?: string;
  schema: JSONSchema &
    Pick<
      SchemaAddModalFormFields,
      | 'dimension'
      | 'methodName'
      | 'engine'
      | 'spaceType'
      | 'efConstructionSize'
      | 'efSearchSize'
      | 'mBidirectionalLinks'
      | 'countryCode'
      | 'maxFileSize'
      | 'allowDownload'
      | 'maxFiles'
      | 'timeStepSize'
      | 'minNumberOfLines'
      | 'maxNumberOfLines'
      | 'multipleFiles'
      | 'supportedFileTypes'
      | 'disablePast'
      | 'additionalData'
      | 'visibility'
      | 'showNumberInput'
      | 'otp'
      | 'optionsDropdownStyles'
      | 'dateTimeAddOns'
    > & {
      encoder?: { type?: string; params?: KeyValuePairs };
    };
  uiSchema?: SchemaToFormDataUiSchema;
  isRequired?: boolean;
  /**
   * This flag is used to determine if options are to be transformed to the new format or not.
   * New Format: `{ const: string; title: string }`
   * Old Format: string[]
   */
  useLegacyOptions?: boolean;
}) => {
  const _fieldType = schemaToFormType(schema);
  const data: SchemaAddModalFormFields = {
    field_name: fieldKey ?? schema.title ?? '',
    field_label: fieldKey ? schema.title : '',
    field_type: _fieldType,
    array_type: schema.items ? schemaToFormType(schema.items as JSONSchema7) : undefined,
  };

  if (schema.help !== 'undefined' && schema.help) {
    data.help = schema.help;
  }

  data.primaryKey = schema.primaryKey;
  data.description = schema.description;
  data.nameField = schema.nameField;
  data.is_optional = !isRequired;
  data.multipleFiles = schema.multipleFiles;
  data.maxFiles = schema.maxFiles;
  data.maxFileSize = schema.maxFileSize;
  data.allowDownload = schema.allowDownload;
  data.supportedFileTypes = schema.supportedFileTypes;
  data.disablePast = schema.disablePast;
  data.is_secure = isSecure(schema);
  data.is_masked = isMasked(schema);
  data.is_hashed = isHashed(schema);
  data.is_skipped = isSkipped(schema);
  data.searchable = schema.searchable;
  data.clientSearchable = schema.clientSearchable;
  data.enableSelectAll = schema.enableSelectAll;
  data.sortable = schema.sortable;
  data.filterable = schema.filterable;
  data.additionalData = schema.additionalData;

  if (schema.visibility?.value === 'conditions') {
    data.visibility = { value: true, conditions: schema.visibility.conditions.payload };
  }

  if (schema.format === 'json-schema') {
    data.allowedFieldTypes = (
      (schema.properties?.['ua:modal'] as JSONSchema7).properties?.field_type as JSONSchema7
    ).oneOf?.map((fieldType) => (fieldType as JSONSchema7).const as string);
  }

  if (schema.default !== undefined) {
    data.default_value = schema.default;
  }

  if (data.field_type === VariableType.Slider) {
    data.minimum = schema.minimum;
    data.maximum = schema.maximum;
    data.multipleOf = schema.multipleOf;
    data.showNumberInput = schema.showNumberInput;
  }

  if (data.field_type === VariableType.PhoneNumber) {
    data.countryCode = schema.countryCode;
    data.is_optional = !_includes(schema.required, 'phoneNumber');
  }

  if (data.field_type === VariableType.FileUploader) {
    if (schema.type === 'array') {
      data.is_optional = !_includes((schema.items as JSONSchema7).required, 'url');
    } else {
      data.is_optional = !_includes(schema.required, 'url');
    }
  }

  if (data.field_type === VariableType.Time) {
    data.timeStepSize = schema.timeStepSize;
  }
  if (data.field_type === VariableType.Date) {
    data.dateTimeAddOns = schema.dateTimeAddOns;
  }

  if (data.field_type === VariableType.RichText) {
    data.minNumberOfLines = schema.minNumberOfLines;
    data.maxNumberOfLines = schema.maxNumberOfLines;
  }

  if (data.field_type === VariableType.Vector) {
    data.dimension = schema.dimension;
    data.methodName = schema.methodName;
    data.engine = schema.engine;
    data.spaceType = schema.spaceType;
    data.efConstructionSize = schema.efConstructionSize;
    data.efSearchSize = schema.efSearchSize;
    data.mBidirectionalLinks = schema.mBidirectionalLinks;
    if (schema.encoder) {
      data.encoder = {
        type: schema.encoder.type,
        params: adaptToKeyValuePairList(schema.encoder.params),
      };
    }
  }

  const dropdownSchema = (schema.type === 'array' ? schema.items : schema) as
    | JSONSchema7
    | undefined;

  if (Array.isArray(dropdownSchema?.oneOf)) {
    data.optionsType = 'STATIC';
    if (useLegacyOptions) {
      data.options = dropdownSchema.oneOf.map((option: Option) => option.title);
    } else {
      data.options = dropdownSchema.oneOf as Option[];
    }
  }

  if (
    data.field_type === VariableType.SingleSelectDropdown ||
    data.field_type === VariableType.MultiSelectDropdown
  ) {
    data.optionsDropdownStyles = schema.optionsDropdownStyles;
  }

  if (schema.autoGeneratedFieldDefinition) {
    data.autoGeneratedFieldDefinition = schema.autoGeneratedFieldDefinition;
  }

  if (schema.foreignKey) {
    data.optionsType = 'LOOKUP';
    // @ts-expect-error -- adding foreignKeyReference to data only for LOOKUP
    data.foreignKeyReference = schema.foreignKey.reference;
    data.foreignKeyConstraintEnforced = schema.foreignKeyConstraintEnforced;
  }

  if (_fieldType === VariableType.Otp) {
    data.is_optional = false;
  }

  return data;
};
