import type { UIFilter } from '@unifyapps/defs/types/uiFilter';
import _find from 'lodash/find';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import type { ButtonAppearance } from '@unifyapps/defs/types/blocks';
import { iconSizeVariants } from '@unifyapps/ui/components/Button/styles';
import { getInlineSvg } from './getInlineSvg';

export type ConditionalValueFilters<T = unknown> = {
  conditionalValueFilters: {
    propertyValue: T;
    conditions: boolean;
  }[];
  defaultValue?: T;
};

export type UnComputedConditionalValueFilters<T = unknown> = {
  conditionalValueFilters?: {
    propertyValue: T;
    conditions: { type: 'filter'; payload: UIFilter };
  }[];
  defaultValue?: T;
};

export const getConditionalPropertyValue = <T extends string | number>({
  value,
}: {
  value: T | ConditionalValueFilters<T> | undefined;
}): T | undefined => {
  if (typeof value === 'object' && 'conditionalValueFilters' in value) {
    return (
      _find(value.conditionalValueFilters, ({ conditions }) => conditions)?.propertyValue ??
      value.defaultValue
    );
  }

  return value;
};

export const getConditionalIcon = (value: string | ConditionalValueFilters<string> | undefined) => {
  const iconName = getConditionalPropertyValue({ value });
  return getIconFromRegistry(iconName);
};

export const getConditionalIconNode = (
  value: string | ConditionalValueFilters<string> | undefined,
  size: ButtonAppearance['size'] = 'md',
) => {
  const iconName = getConditionalPropertyValue({ value });
  const iconClassName = iconSizeVariants({ size });

  return getInlineSvg(iconName, iconClassName);
};
