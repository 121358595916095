import _omit from 'lodash/omit';
import { produce } from 'immer';
import { invariant } from 'ts-invariant';
import type { BlockId } from '@unifyapps/defs/types/block';
import { deviceTypes } from '@unifyapps/defs/types/deviceVariant';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { markUnsavedChanges } from '../utils/markUnsavedChanges';

export type DeleteBlockActionProps = {
  blockIds: string[];
};

export type DeleteBlockActionReturnType = {
  success: boolean;
  blockId: BlockId[];
};

/**
 * Block Deletion Logic:
 *
 * Deleting from the base variant:
 *   - Delete the block in the base variant.
 *   - Delete the block in all the required variants.
 *   - Delete the diff of that blockId in the required variants.
 *
 * Deleting from a non-base variant:
 *   - The deletion process is the same as deleting from the base variant.
 */
const getDeleteBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ blockIds }: DeleteBlockActionProps): DeleteBlockActionReturnType => {
    const { get, set, setBlocksState, getDependencyGraphActions } = storeArgs;
    const state = get();
    const activePageId = state.activeInterfacePageId;

    const newState = produce(state, (draftState) => {
      const currentPage = draftState.interfacePages[activePageId];
      invariant(currentPage, `getDeleteBlockAction: Page with id ${activePageId} not found`);
      // STEP 1: mark pageHasUnsavedChanges true when ever a block is updated
      markUnsavedChanges(activePageId, draftState);

      // STEP 2: remove the block from the page
      currentPage.properties.blocks = _omit(currentPage.properties.blocks, blockIds);

      // STEP 3: remove the block state from other devices
      deviceTypes.forEach((device) => {
        const deviceDetails = currentPage.properties.devices?.[device];
        if (deviceDetails?.blocks) {
          deviceDetails.blocks = _omit(deviceDetails.blocks, blockIds);
        }

        if (deviceDetails?.diffs?.blocks) {
          deviceDetails.diffs.blocks = _omit(deviceDetails.diffs.blocks, blockIds);
        }

        if (currentPage.properties.devices?.[device]) {
          currentPage.properties.devices[device] = deviceDetails;
        }
      });
    });

    set(newState);

    // STEP 3: remove the block state
    setBlocksState(
      blockIds.reduce((acc, blockId) => {
        acc[blockId] = undefined;
        return acc;
      }, {}),
    );

    blockIds.forEach((blockId) => {
      getDependencyGraphActions().deleteDependencies(blockId);
    });

    return { success: true, blockId: blockIds };
  };

export default getDeleteBlockAction;
