import { useMemo } from 'react';
import merge from 'lodash/merge';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import { keepPreviousData, useInfiniteQuery } from '@unifyapps/network/react-query';
import type { QueryRequestResult } from '../../../../stores/GlobalStateStore';
import type { DebugParams } from '../DataSourceWorkflowNode/api';
import {
  executeWorkflowNode,
  useExecuteWorkflowNodeQueryKeyMemo,
} from '../DataSourceWorkflowNode/api';
import { adaptDataSourceWorkflowNode } from '../../utils/adaptDataSourceWorkflowNode';
import type { QueryOptions } from '../../hooks/useGenerateQueryOptionsFromAdvancedOptions';
import {
  getInfiniteDataSourceWorkflowNodePageParams,
  getInitialPageParams,
  getNextPageParams,
} from './utils';

interface ExecuteWorkflowNodeResponseType {
  response: {
    // todo: normalise once fixed from backend -- https://unifyapps.monday.com/boards/1434760518/pulses/1518127115
    totalHits?: number;
    total?: number;
    hasMore?: unknown;
    objects?: unknown[];
  };
}

function useInfiniteDataSourceWorkflowNode({
  payload: initialPayload,
  debugParams,
  queryOptions,
  enabled,
}: {
  payload: ExecuteWorkflowNodeRequest;
  debugParams: DebugParams;
  queryOptions?: Partial<QueryOptions>;
  enabled?: boolean;
}): QueryRequestResult {
  const { offset, limit, payload, paginateBy } = useMemo(
    () => getInfiniteDataSourceWorkflowNodePageParams(initialPayload),
    [initialPayload],
  );
  const modifiedQueryKey = useExecuteWorkflowNodeQueryKeyMemo(payload, debugParams);

  const {
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    error: fetchError,
    data: initialData,
    refetch,
  } = useInfiniteQuery({
    queryKey: modifiedQueryKey,
    queryFn: async ({ pageParam }) => {
      return executeWorkflowNode(merge(payload, pageParam), debugParams);
    },
    getNextPageParam: (lastPage: ExecuteWorkflowNodeResponseType | undefined, _, lastPageParam) => {
      // Assumption: response has `hasMore` field
      if (lastPage?.response.hasMore === true) {
        return getNextPageParams(lastPageParam, paginateBy);
      }
      return null;
    },
    initialPageParam: getInitialPageParams({ offset, limit, paginateBy }),
    placeholderData: keepPreviousData,
    enabled,
    ...queryOptions,
  });

  const data = useMemo(() => {
    if (!initialData?.pages.length) {
      return undefined;
    }

    const adaptedPages = initialData.pages.map((page) =>
      adaptDataSourceWorkflowNode(page, payload.lookupRequests),
    ) as ExecuteWorkflowNodeResponseType['response'][];

    return {
      hasMore: hasNextPage,
      objects: adaptedPages.flatMap((page) => page.objects),
      total: adaptedPages[0].total,
      totalHits: adaptedPages[0].totalHits,
    };
  }, [hasNextPage, initialData?.pages, payload.lookupRequests]);

  return {
    error: fetchError,
    isLoading,
    isFetching,
    data,
    queryKey: modifiedQueryKey,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  };
}

export default useInfiniteDataSourceWorkflowNode;
