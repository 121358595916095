import type Step from '@mui/joy/Step';
import type React from 'react';
import _isUndefined from 'lodash/isUndefined';
import type { TypographyVariants, TypographyWeight } from '../Typography';
import type { IconComponentType } from '../../types';

export type Step = {
  value: string;
  label?: string;
  description?: string;
  Icon?: IconComponentType;
  subSteps?: Omit<Step, 'subSteps'>[];
};

export type StepperProps = {
  steps: Step[];
  orientation?: 'horizontal' | 'vertical';
  style?: React.CSSProperties;
  className?: string;
  stepDescriptionClassName?: string;
  stepLabelClassName?: string;
  size?: 'sm' | 'md' | 'lg';
  controlledState?: {
    activeStepId: string;
    onStepChange: (activeStepId: string) => void;
  };
  stepDetails?: {
    showCompleteIndicator?: boolean;
    clickable?: boolean;
    orientation?: 'horizontal' | 'vertical';
    showStepNumber?: boolean;
    label?: {
      weight?: TypographyWeight;
      variant?: TypographyVariants;
    };
    description?: {
      weight?: TypographyWeight;
      variant?: TypographyVariants;
    };
  };
};

export enum StepStatus {
  Pending = 'pending',
  Active = 'active',
  Completed = 'completed',
}
