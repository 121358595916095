import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import { BLOCK_PREFIX } from '../../../const';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getCreateBlock } from '../utils/block';
import { getIdWithPrefix } from '../../../../utils/id';

export type CreateBlockActionProps = {
  block: Omit<BlockType, 'id'> & {
    displayName?: string;
  };
};

export type CreateBlockActionReturnType =
  | {
      success: false;
      blockId: null;
    }
  | {
      success: true;
      blockId: BlockId;
    };

const getCreateBlockAction =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ block }: CreateBlockActionProps): CreateBlockActionReturnType => {
    const { get, registry, setBlockState, getDependencyGraphActions } = storeArgs;
    // Step 1: Get the active page id, where the block will be inserted
    const activePageId = get().activeInterfacePageId;
    if (!activePageId) {
      console.debug('getCreateBlockAction: No active page found');
      return { success: false, blockId: null };
    }

    const componentType = block.component.componentType;
    const createBlock = getCreateBlock(storeArgs);

    // Step 2: Create a block with a unique id
    const newCount = get().actions.incrementBlockCounter(componentType);
    const id = getIdWithPrefix(BLOCK_PREFIX);
    const displayName = block.displayName ?? `${componentType}_${newCount}`;
    const blockWithId: BlockType = { ...block, displayName, id };

    // Step 3: Insert the block in the blockDefinitions map
    createBlock({
      block: blockWithId,
      blockId: id,
      pageId: activePageId,
    });

    const initialBlockState = registry.getBlockInitialState(
      block.component.componentType,
      blockWithId,
    );

    // Step 3.1: optional for blocks not having definition
    if (initialBlockState) {
      setBlockState(id, initialBlockState);
    }

    const deps = getDependencyGraphActions().buildEntityDependency(initialBlockState, 'blocks');
    const blockDeps = deps[id];

    get().actions.updateBlock.dependency({
      blockId: id,
      dependency: blockDeps,
    });

    getDependencyGraphActions().updateDependenciesAndNotify(deps);

    return { success: true, blockId: id };
  };

export default getCreateBlockAction;
