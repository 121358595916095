import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import type { ComputedBlockStateUnionType } from '@unifyapps/defs/types/block';
import _noop from 'lodash/noop';

const DUMMY_BLOCK_STATE = {
  id: 'dummy_block_id',
};

export const DUMMY_INTERFACE_RECORD: InterfaceEntity = {
  name: 'dummy-interface-name',
  entityType: 'dummy-entity-type',
  id: 'dummy-id',
  properties: {
    name: 'dummy-name',
    standard: false,
    metadata: {
      logo: {
        small: 'https://assets.unifyapps.com/theme/unifyapps/unify.svg',
        large: 'https://assets.unifyapps.com/theme/unifyapps/unify.svg',
      },
    },
    navigation: {
      defaultPageId: 'dummy-default-page-id',
      primary: [
        {
          type: 'GROUP' as const,
          id: 'dummy-nav-primary-id',
          name: 'dummy-nav-primary-name',
          icon: 'dummy-nav-primary-icon',
          items: [],
        },
      ],
    },
  },
};

const DUMMY_REGISTRY = {
  getBlockRenderer: () => () => null,
};

export const dummyBlockProps = {
  blockState: DUMMY_BLOCK_STATE,
  computedBlockState: DUMMY_BLOCK_STATE as ComputedBlockStateUnionType,
  registry: DUMMY_REGISTRY,
  updateBlockSessionState: _noop,
  updateBlockState: _noop,
};

export const getDummyPage = (properties: Record<string, object>) => {
  return {
    id: 'page-id',
    entityType: 'e_component',
    deleted: false,
    version: 1,
    properties: {
      ...properties,
    },
  };
};
