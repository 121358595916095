import type { BlockId, ComponentTypeUnionType } from '@unifyapps/defs/types/block';

export const enum FlattenedItemType {
  Block = 'block',
  Slot = 'slot',
  AddComponent = 'add-component',
}

export type FlattenedItem = {
  /**
   * uses dot notation to represent the path of the block in the hierarchy
   */
  visualPath: string;
  /**
   * id of the current block / the block containing the slot or the tab
   */
  blockId: BlockId;
  type: FlattenedItemType;
  level: number;
  title: string;
  parentBlockId: BlockId;
  hasChildren: boolean;
  /**
   * insert context is only required for a type add-component
   */
  insertPos: string | null;
  /**
   * deletePos is used in remove context is only required for type block
   */
  deletePos: string | null;
  /**
   * if the slot is a stack or a dummy item
   */
  wrappedInLayout?: boolean;
  /**
   * newIndex is used in reorder context is only required for type block which is contained inside parent block's blockIds
   */
  newIndex: number | null;
  /**
   *  the type of the component
   */
  componentType?: ComponentTypeUnionType;
  /**
   * if the block is a root block, i.e. generated first block in the hierarchy
   */
  isRootBlock?: boolean;
};

export interface HierarchyItemProps extends FlattenedItem {
  className?: string;
  expanded: boolean;
  toggle: (visualPath: string) => void;
  /**
   * Whether the item is in the active block's path
   */
  isInsideActiveBlock: boolean;
  /**
   * if the item is the active block
   */
  isActiveBlock: boolean;
  /**
   *  if the item is droppable zone for the dragged block
   */
  isDroppable: boolean;

  /**
   *  when the item is parent of item being hovered over
   */
  highlightAsParent?: boolean;

  /**
   * isVisible is used to show the item in the hierarchy
   */
  isVisible: boolean;
  /*
   * isAddingNewBlock is used to show that a block is being added inside this block
   */
  isAddingNewBlock: boolean;
  /**
   * childrenNum is used to show the number of children in the block
   */
  childrenNum: number;
}
