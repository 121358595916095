import isEqual from 'react-fast-compare';
import _reduce from 'lodash/reduce';
import { memo, useMemo } from 'react';
import _pick from 'lodash/pick';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import _throttle from 'lodash/throttle';
import type { DependsOnType } from '@unifyapps/defs/types/page';
import { useReactiveComputeContext } from '../../../hooks/computeContext';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore';
import { evaluateResult } from '../../../utils/evaluateExpression';
import useDynamicEffect from '../../../hooks/useDynamicEffect';
import { removeDataBindingBraces } from '../../../utils/dynamicBindings';
import { useTriggerAutomaticDataSources } from '../../DataSourceController/hooks/useTriggerAutomaticDataSources';
import { useIsEntitySuspended } from '../../DataSourceController/hooks/useIsEntitySuspended';
import { useInterfaceStore } from '../../../stores/InterfaceStore';
import { useDependencyGraphProvider } from '../../../context/DependencyGraphContext/DependencyGraphContext';
import { DependencyHelper } from '../../../../dependency/helpers/DependencyHelper';
import { useGetShouldEvaluateResult } from '../../../hooks/computeContext/useGetShouldEvaluateResult';

function evaluatePageFunction({
  context,
  functionBody,
  setPageFunctionState,
  pageFunctionState,
}: {
  context: Record<string, unknown>;
  functionBody: string;
  setPageFunctionState: (id: string, state: PageFunctionState) => void;
  pageFunctionState: PageFunctionState;
}) {
  if (!pageFunctionState) {
    return;
  }

  try {
    const keys = Object.keys(context);
    const values: unknown[] = Object.values(context);
    const funcBody = removeDataBindingBraces(functionBody);

    // escape newline characters to avoid "Invalid or unexpected token" error
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
    const evalFunction = new Function(...keys, funcBody);

    const value = evalFunction(...values) as unknown;

    if (!isEqual(value, pageFunctionState.value)) {
      setPageFunctionState(pageFunctionState.id, {
        ...pageFunctionState,
        value,
        error: undefined,
      });
    }
  } catch (e: unknown) {
    setPageFunctionState(pageFunctionState.id, {
      ...pageFunctionState,
      error: _pick(e, ['message', 'stack']) as { message: string; stack: string },
    });
  }
}

const throttledEvaluatePageFunction = _throttle(evaluatePageFunction, 300);

function PageFunctionExecutor({
  functionBody,
  id,
  dependsOn,
}: {
  functionBody: string;
  id: string;
  dependsOn?: DependsOnType[];
}) {
  const { context } = useReactiveComputeContext();
  const shouldEvaluateResult = useGetShouldEvaluateResult();

  const { buildEntityDependency } = useDependencyGraphProvider();
  const pageFunctionState = useGlobalStateStore().use.pageFunction.details(id);

  const { isAllDepsCalculated } = useInterfaceStore().use.page.metadata.flags() ?? {};

  const pageFncDeps = useMemo(() => {
    // if we are capturing the deps correctly and dependsOn.length is present, then we use those paths
    if (isAllDepsCalculated) return DependencyHelper.getCombinedDependsOnProperties(dependsOn);
    // otherwise, we create deps on runtime and use that
    const deps = buildEntityDependency({ id, functionBody }, 'pageFunctions')[id];
    return DependencyHelper.getCombinedDependsOnProperties(DependencyHelper.getDependsOn(deps));
  }, [buildEntityDependency, dependsOn, functionBody, id, isAllDepsCalculated]);

  useTriggerAutomaticDataSources({
    dataSourceIds: pageFunctionState?.dataSourceIds,
  });

  const { isSuspended } = useIsEntitySuspended({
    dataSourceIds: pageFunctionState?.dataSourceIds,
  });

  const dependentProperties = _reduce(
    pageFncDeps,
    (acc, path) => {
      const result = evaluateResult<unknown>({
        context,
        resolvedStringToEvaluate: path,
        shouldEvaluateResult,
      });

      acc.push(result);

      return acc;
    },
    [] as unknown[],
  );

  const { setPageFunctionState } = useGlobalStateStore().use.actions();

  useDynamicEffect(() => {
    if (isSuspended) {
      return;
    }

    throttledEvaluatePageFunction({
      context,
      functionBody,
      setPageFunctionState,
      pageFunctionState,
    });
  }, [functionBody, isSuspended, ...dependentProperties]);

  return null;
}

export default memo(PageFunctionExecutor);
