import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorQrCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <path
      fill="#fff"
      stroke="#CFD4DE"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M31.75 1H6.25A5.25 5.25 0 0 0 1 6.25v25.5C1 34.65 3.35 37 6.25 37h25.5c2.9 0 5.25-2.35 5.25-5.25V6.25C37 3.35 34.65 1 31.75 1Z"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.895 25.36h-.013m5.23 5.217H19.1M7.361 25.36h1.957M7.36 30.577h6.521m12.52-16.954h2.348c.73 0 1.096 0 1.375-.143.245-.125.445-.324.57-.57.142-.278.142-.644.142-1.374V9.188c0-.73 0-1.095-.142-1.374a1.3 1.3 0 0 0-.57-.57c-.28-.142-.644-.142-1.375-.142h-2.347c-.73 0-1.096 0-1.375.142a1.3 1.3 0 0 0-.57.57c-.142.279-.142.644-.142 1.374v2.348c0 .73 0 1.096.142 1.375.125.245.325.444.57.57.28.142.644.142 1.375.142M24.316 26.143c0-.73 0-1.095.142-1.374.125-.246.325-.445.57-.57.279-.142.644-.142 1.375-.142h2.347c.73 0 1.096 0 1.375.142.245.125.445.324.57.57.142.279.142.644.142 1.374v2.348c0 .73 0 1.096-.142 1.375a1.3 1.3 0 0 1-.57.57c-.28.142-.644.142-1.375.142h-2.347c-.73 0-1.096 0-1.375-.142a1.3 1.3 0 0 1-.57-.57c-.142-.28-.142-.645-.142-1.375z"
    />
    <path
      stroke="#705AF8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.578 18.84H19.1v6.36M7.374 18.84h-.013m23.49 0h-.014m-7.173 0h2.608M19.1 6.919v6.704"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.361 9.188c0-.73 0-1.095.142-1.374.126-.246.325-.445.57-.57.28-.142.645-.142 1.375-.142h2.348c.73 0 1.095 0 1.374.142.246.125.445.324.57.57.142.279.142.644.142 1.374v2.348c0 .73 0 1.096-.142 1.375a1.3 1.3 0 0 1-.57.57c-.279.142-.644.142-1.374.142H9.448c-.73 0-1.096 0-1.375-.143a1.3 1.3 0 0 1-.57-.57c-.142-.278-.142-.644-.142-1.374z"
    />
  </svg>
);
export default SvgNoCodeColorQrCode;
