import type { FileType } from '../FileUpload/types';
import type { BlockComponentStateId, BlockComponentType, BlockType } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type { SlotType } from '../../types/slot';
import type { Appearance as TypographyAppearance } from '../Typography/types';
import type { ChartColours, ChartTypes } from '../Chart/types';
import type { Appearance as MediaBlockAppearance } from '../Media/types';
import type { StackComponentType } from '../Stack/types';
import type { ButtonComponentType } from '../Button/types';
import type { FormAppearance } from '../Form/types';
import type { APPLICATION_BY_NAME } from '../CopilotChat/LookupsHelper';

export enum Channel {
  Email = 'email',
}

export type TableData = {
  rowData: Record<string, string | number>[];
  columns: { type: string; label: string; id?: string; addOns?: Record<string, unknown> }[];
};

export type StatCardData = {
  type: 'NUMBER' | 'CURRENCY' | 'PERCENT' | 'TIME' | 'STRING';
  label: string;
  description?: string;
  value: string;
  thresholdValue?: number;
  currencyCode?: string;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact' | 'text';
  trend?: {
    value: string;
    type: 'CURRENCY' | 'PERCENT' | 'NUMBER';
    decimalPlaces: number;
    signDisplay: 'POSITIVE_AND_NEGATIVE';
    notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
    style?: 'decimal' | 'unit';
    unit?: string;
    currencyCode?: string;
  };
};

export type TypographyData = { type?: 'PLAIN_TEXT' | 'MARKDOWN' | 'HTML'; text: string };

export type WorkflowInputFormData = { value: Record<string, unknown> };

export type KeyValueData = {
  fields: {
    id?: string;
    type:
      | 'STRING'
      | 'DATE'
      | 'TIME'
      | 'CURRENCY'
      | 'LINK'
      | 'DATETIME'
      | 'PERCENT'
      | 'NUMBER'
      | 'BUTTON';
    value: string;
    label: string;
    description?: string;
    currencyCode?: string;
    dateFormat?: string;
    precision?: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds';
    notation?: 'compact' | 'comfortable';
    maxSignificantUnits?: string;
    displayText?: string;
    decimalPlaces?: string;
    unit?: string;
    style?: 'decimal' | 'unit';
    blockId?: string;
  }[];
};

export type TypographyBlockParams = {
  blockType: 'Typography';
  data: TypographyData;
  appearance?: TypographyAppearance;
};

export type KeyValueBlockParams = {
  blockType: 'KeyValue';
  data: KeyValueData;
};

export type StatCardBlockParams = {
  blockType: 'StatCard';
  data: StatCardData;
};

export type TableBlockParams = {
  blockType: 'Table';
  data: TableData;
};

export type ChartBlockData = {
  chartType: ChartTypes;
  xAxisValue: string;
  groupBy?: string;
  series?: { key: string; label?: string }[];
  xAxis?: {
    label?: string;
    scale: 'time' | 'linear' | 'log';
    tickLabels?: {
      enabled: boolean;
      decimalPlaces?: number;
      format?: string;
      notation?: 'compact' | 'standard';
    };
  };
  yAxis?: {
    series: { key: string; label?: string }[];
    label?: string;
    scale: 'time' | 'linear' | 'log';
    tickLabels?: {
      enabled: boolean;
      decimalPlaces?: number;
      format?: string;
      notation?: 'compact' | 'standard';
    };
  };
  yAxisRight?: {
    series: { key: string; label?: string }[];
    label?: string;
    scale: 'time' | 'linear' | 'log';
    tickLabels?: {
      enabled: boolean;
      decimalPlaces?: number;
      format?: string;
      notation?: 'compact' | 'standard';
    };
  };
  chartData: Record<string, string | number>[];
  showLegends?: boolean;
  showTotal?: boolean;
  visibleDataPoints?: number;
};

export type ChartBlockAppearance = {
  chartColours?: ChartColours;
};

export type ChartBlockParams = {
  blockType: 'Chart';
  data: ChartBlockData;
  appearance?: ChartBlockAppearance;
};

export type MediaBlockParams = {
  blockType: 'Media';
  data: (FileType & Pick<MediaBlockAppearance, 'showCaption'>)[];
};

export type CitationBlockParams = {
  blockType: 'Citation';
  data: {
    citations: {
      referenceUrl?: string;
      knowledgeSourceType?: string;
      mimeType?: string;
      title?: string;
      appName?: string;
      //todo: adding temporarily till BE changes are merged
      name?: string;
      chunkContents?: string[];
      author?: string;
      date?: number;
      id: string;
      [APPLICATION_BY_NAME]?: {
        iconUrl?: string;
        name?: string;
      };
    }[];
  };
};

export type StackBlockParams = {
  blockType: StackComponentType['componentType'];
  appearance: StackComponentType['appearance'];
  data: Record<string, BlockMessageContentType>;
};

export type ButtonBlockParams = {
  blockType: ButtonComponentType['componentType'];
  appearance: ButtonComponentType['appearance'];
  content: ButtonComponentType['content'];
  events?: Event[];
};

export type WorkflowInputFormBlockParams = {
  blockType: 'Form';
  data: WorkflowInputFormData;
  appearance?: FormAppearance;
};

export type CardBlockParams = {
  blockType: 'Card';
  data: Record<string, BlockType>;
};

export type BlockMessageContentType =
  | TypographyBlockParams
  | KeyValueBlockParams
  | StatCardBlockParams
  | TableBlockParams
  | ChartBlockParams
  | WorkflowInputFormBlockParams
  | CitationBlockParams
  | ButtonBlockParams
  | StackBlockParams
  | MediaBlockParams
  | CardBlockParams;

export type ChatBlockRef = {
  sendMessage?: (input: { message: string; attachments?: FileType[] }) => void;
};

type Content = {
  chatId?: string;
};

export type Layout = 'FLAT' | 'BUBBLE';

type Appearance = {
  layout?: Layout;
  styles?: Pick<
    StyleObject,
    | 'height'
    | 'minHeight'
    | 'maxHeight'
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'margin'
    | 'padding'
    | 'borderWidth'
    | 'borderColor'
    | 'borderRadius'
    | 'backgroundColor'
  >;
};

type Slots = {
  messageInputPanel?: SlotType;
};

export const ChatActions = {
  CreateCase: 'publishMessageAutomation',
  FetchMessageById: 'fetchMessageAutomation',
  FetchConversation: 'fetchCaseConversationAutomation',
};

export type AutomationConfig = Record<string, string>;

export type ChatComponentType = BlockComponentType<'Chat', Content, Appearance, Slots>;

export type ChatBlockStateType = Pick<
  ChatComponentType,
  'appearance' | 'content' | 'componentType'
> &
  BlockComponentStateId & {
    lastReceivedUserMessageDetails?: {
      userId: string | undefined;
      userName: string | undefined;
      messageId: string;
      channel: Channel | null;
    } | null;
  };

export const enum ChatBlockMethods {
  SubmitMessage = 'submitMessage',
}

export const enum MessageType {
  User = 'Fan',
  Bot = 'Bot',
  Brand = 'Brand',
}

export enum InternalMessageType {
  Response = 'RESPONSE',
  Thought = 'THOUGHT',
}

export type Message = {
  messageId: string;
  caseId?: string;
  createdTime: number;
  messageType: MessageType;
  message: BlockMessageContentType[];
  attachments?: MediaBlockParams['data'];
  interfaceDetails?: {
    interfaceId: string;
    pageId: string;
    slug: string;
    pageInputs?: Record<string, unknown>;
  };
  additional?: {
    platformUserId?: string;
    internalMessageType?: InternalMessageType;
  };
  fromCustomerUserId?: string;
  userName?: string;
};
