import { cva } from 'class-variance-authority';
import type { SmallIconButtonSizes } from './types';

export const smallIconButtonVariants = cva('focus-visible:ring-gray', {
  variants: {
    size: {
      xs: 'size-3 p-xxs rounded-xxs',
      sm: 'size-5 p-xxs rounded-xxs',
      md: 'size-6 p-xxs rounded-xxs',
      lg: 'size-7 p-xxs rounded-sm',
    } satisfies Record<SmallIconButtonSizes, string>,
    variant: {
      solid: '',
      outline: 'border border-secondary',
    },
  },
});

export const smallIconButtonIconVariants = cva('shrink-0', {
  variants: {
    size: {
      xs: 'size-2',
      sm: 'size-4',
      md: 'size-5',
      lg: 'size-5',
    } satisfies Record<SmallIconButtonSizes, string>,
  },
});
