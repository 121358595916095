import type { BlockType } from '@unifyapps/defs/types/block';
import type { DataTableComponentType } from '@unifyapps/defs/blocks/Table/DataTable/types';
import { TABLE_SAVE_ACTION_TARGET_ID } from '@unifyapps/defs/blocks/Table/DataTable/tableSaveActionSchema';
import { MAPPED_COLUMN_EVENT_TARGET_ID } from '@unifyapps/defs/blocks/Table/DataTable/mappedColumnSchema';
import { DataTableColumnHelper } from '../DataTable/helpers/DataTableColumnHelper';

const getNestedTableTargetIds = (
  columns: DataTableComponentType['content']['columns'],
  depth = 0,
): string[] => {
  const eventTargetIds: string[] = [];

  columns.forEach((column) => {
    // root columns are already handled in getEventTargetIds
    if (depth) {
      eventTargetIds.push(column.id);

      column.columnActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });
    }

    if (column.type === 'NESTED_TABLE') {
      column.rowActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });

      if (column.columns) {
        eventTargetIds.push(...getNestedTableTargetIds(column.columns, depth + 1));
      }
    }
  });

  return eventTargetIds;
};

export const getTableEventTargetIds = (block: BlockType): string[] => {
  const eventTargetIds: string[] = [];

  if (
    block.component.componentType === 'Table' ||
    block.component.componentType === 'NestedTable'
  ) {
    block.component.content.addOns?.topToolbar?.customActions?.forEach((action) => {
      eventTargetIds.push(action.id);
    });

    // removed adding TABLE_SAVE_ACTION_TARGET_ID as default - as event was not getting removed
    if (block.component.content.addOns?.saveAction) {
      eventTargetIds.push(TABLE_SAVE_ACTION_TARGET_ID);
    }

    if (block.component.content.columnType === 'mapped') {
      eventTargetIds.push(MAPPED_COLUMN_EVENT_TARGET_ID);

      block.component.content.mappedColumn?.columnActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });
    }

    block.component.content.rowActions?.forEach((action) => {
      eventTargetIds.push(action.id);
    });

    block.component.content.bulkActions?.forEach((action) => {
      eventTargetIds.push(action.id);
    });

    DataTableColumnHelper.getColumns(block.component.content.columns).forEach((column) => {
      eventTargetIds.push(column.id);

      column.columnActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });
    });

    eventTargetIds.push(...getNestedTableTargetIds(block.component.content.columns));
  }

  return eventTargetIds;
};
