import type {
  BlockStateDefinition,
  ComponentType,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { RepeatableBlockStateType } from '@unifyapps/defs/blocks/Repeatable/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type { RepeatableComponentType } from '@unifyapps/defs/types/blocks';
import type { Action } from '@unifyapps/defs/types/action';

const importRepeatableComponent = () =>
  import(
    /* webpackChunkName: "repeatable-block" */
    './Repeatable'
  ) as Promise<{ default: ComponentType }>;

const Repeatable = lazy(importRepeatableComponent) as ComponentType;

class RepeatableBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Repeatable';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'selectNextItem' as const,
        label: 'Select Next Item',
        method: (blockState: BlockStateUnionType, action: Action, _blockRef: unknown) => {
          const primaryKey = (blockState as RepeatableBlockStateType).content.primaryKey;
          const blockRef = _blockRef as {
            selectNextItem: () => {
              nextItem: unknown;
              nextItemKey: string | undefined;
              nextItemIndex: number;
            };
          };

          if (primaryKey) {
            const { nextItem, nextItemKey, nextItemIndex } = blockRef.selectNextItem();

            return {
              ...blockState,
              selectedItemKey: nextItemKey,
              selectedItem: nextItem,
              selectedItemIndex: nextItemIndex,
            } as BlockStateUnionType;
          }

          return blockState;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
        pagination: {
          currentPage: (block.component as RepeatableComponentType).content.addOns?.page?.current,
          offset: (block.component as RepeatableComponentType).content.addOns?.page?.offset,
          pageSize: (block.component as RepeatableComponentType).content.addOns?.page?.size,
          total: (block.component as RepeatableComponentType).content.addOns?.page?.total,
        },
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Repeatable;
  }

  preload() {
    return {
      blockPreload: importRepeatableComponent,
    };
  }

  getKeysToOmitInComputation() {
    return ['items'];
  }
}

export default RepeatableBlockStateDefinition;
