import { cva } from 'class-variance-authority';

export const tooltipVariants = cva(
  'ua-tooltip flex flex-col gap-xs bg-primary-solid shadow-lg dark:border dark:bg-[var(--palette-common-white)] dark:border-[var(--palette-gray-100)]',
  {
    variants: {
      description: {
        false: 'p-sm',
        true: 'p-md',
      },
    },
  },
);

export const tooltipArrowClassname =
  'w-3 h-3 -z-10 before:!border-[var(--bg-primary-solid)] dark:before:!border-[var(--palette-common-white)]';
