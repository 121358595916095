import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { LinkComponentType, LinkBlockStateType } from '@unifyapps/defs/types/blocks';
import { Link } from '@unifyapps/ui/components/Link';
import { type SyntheticEvent, useCallback } from 'react';
import { getConditionalIcon } from '@unifyapps/carbon/utils/conditionalValues';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { Typography } from '@unifyapps/ui/components/Typography';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { memo } from 'react';
import { decoratorVariants } from './styles';

function LinkBlock({
  dataAttributes,
  events,
  computedBlockState,
}: BlockComponentProps<LinkComponentType, LinkBlockStateType, LinkBlockStateType>) {
  const { content, appearance } = computedBlockState;

  const { variant = 'text-sm', weight, styles, color = 'brand' } = appearance ?? {};

  const { emitEvent } = useBlockEvents(events);
  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({
        eventType: EventTypeEnum.OnClick,
        domEvent: e,
      });
    },
    [emitEvent],
  );

  const { className } = useBlockAppearanceStyles({
    appearanceStyles: styles,
  });

  const externalLinkProps = content.openInNewTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <Link
      className={className}
      disabled={appearance?.disabled}
      endDecoratorComponent={getConditionalIcon(appearance?.endDecorator)}
      href={content.url}
      onClick={onClick}
      startDecoratorComponent={getConditionalIcon(appearance?.startDecorator)}
      underline={appearance?.underline}
      {...externalLinkProps}
      {...dataAttributes}
      color={color}
      decoratorClassName={decoratorVariants({ variant })}
    >
      <Typography variant={variant} weight={weight}>
        {content.label ?? content.url}
      </Typography>
    </Link>
  );
}

export default memo(LinkBlock);
