import { memo } from 'react';
import type { ButtonColors } from '@unifyapps/ui/components/Button';
import { Button } from '@unifyapps/ui/components/Button';

export type ModalFooterHorizontalFullProps = {
  className?: string;
  submitLabel?: string;
  cancelLabel?: string;
  isPending?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  primaryColor?: ButtonColors;
};

function ModalFooterHorizontalFull({
  submitLabel,
  cancelLabel,
  isPending,
  onCancel,
  onSubmit,
  primaryColor,
}: ModalFooterHorizontalFullProps) {
  return (
    <>
      {cancelLabel && onCancel ? (
        <Button className="flex-1" color="neutral" onClick={onCancel} size="md" variant="outline">
          {cancelLabel}
        </Button>
      ) : null}
      <Button
        className="flex-1"
        color={primaryColor ?? 'brand'}
        disabled={isPending}
        loading={isPending}
        onClick={() => onSubmit?.()}
        size="md"
        type="button"
        variant="solid"
      >
        {submitLabel}
      </Button>
    </>
  );
}

export default memo(ModalFooterHorizontalFull);
