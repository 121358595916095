import { useCallback } from 'react';
import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import type { EventTypeEnum } from '@unifyapps/defs/types/event';
import type { OnActionType } from '../../../../components/ActionsProvider/context';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

type PageEventPayload = {
  eventType: EventTypeEnum;
  eventPayload?: unknown;
};

function useEmitPageEvent({ emitPageEvent }: { emitPageEvent?: EmitEventType }) {
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const onEmitPageEvent: OnActionType = useCallback(
    async ({ action, actionContext }) => {
      const payload = action.payload as PageEventPayload;
      const { eventType, eventPayload: unComputedEventPayload } = payload;
      const { value: eventPayload } = getNoCodeComputedData<{
        value: unknown;
      }>(
        {
          value: unComputedEventPayload,
        },
        actionContext,
      );
      await emitPageEvent?.({
        eventType,
        eventPayload,
      });
    },
    [getNoCodeComputedData, emitPageEvent],
  );
  return { onEmitPageEvent };
}

export default useEmitPageEvent;
