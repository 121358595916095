'use client';
import { ThemeOverrideContextProvider } from '@unifyapps/ui/providers/ThemeOverrideContext';
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import type { InterfaceComponentPage } from '../../types/interfaceComponent';
import { InterfaceProvider } from '../InterfaceProvider';
import { InterfacePage } from '../InterfacePage';
import type BlocksRegistry from '../RegistryProvider/BlocksRegistry';
import { AuthorizationResources } from '../InterfaceProvider/constants/strategies';

type GovernancePageProps = {
  interfaceComponent: InterfaceComponentPage;
  interfaceRecord: InterfaceEntity;
  searchParams?: {
    returnTo?: string;
  };
  registry: BlocksRegistry;
};

function GovernancePage(props: GovernancePageProps) {
  const { interfaceRecord, interfaceComponent, searchParams, registry } = props;

  return (
    <InterfaceProvider
      interfaceComponent={interfaceComponent}
      interfaceSlug={interfaceRecord.id}
      pageInputs={searchParams}
      registry={registry}
      resourceStrategy={AuthorizationResources}
    >
      <InterfacePage />
    </InterfaceProvider>
  );
}

function ThemedGovernancePage(props: GovernancePageProps) {
  const { interfaceRecord } = props;
  const style = interfaceRecord.properties.theme?.output;

  return (
    <ThemeOverrideContextProvider value={style}>
      <GovernancePage {...props} />
    </ThemeOverrideContextProvider>
  );
}

export { GovernancePage, ThemedGovernancePage };
