import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <rect width={5} height={16} x={5} y={4} strokeWidth={1.5} rx={1} />
    <rect width={5} height={16} x={14} y={4} strokeWidth={1.5} rx={1} />
  </svg>
);
export default SvgPause;
