/* eslint-disable @typescript-eslint/no-extraneous-class -- it is okay */
import type { DependencyFlowInstanceType } from '../types';
import { DependencyFlowStatus } from '../types';
import type { BuildDependencyGraphItem } from '../../../../dependency/utils/buildDependencyGraph';
import type { BuildDependencyGraph } from '../../../../dependency/manager/types';
import { DependencyHelper } from '../../../../dependency/helpers/DependencyHelper';

class DependencyFlowHelper {
  static areAllDependenciesFinished({
    dependencies,
    dependencyFlowInstance,
  }: {
    dependencyFlowInstance?: DependencyFlowInstanceType;
    dependencies?: BuildDependencyGraphItem<unknown>;
  }) {
    let areAllDependenciesFinished = true;

    for (const dependency of DependencyHelper.getDependsOn(dependencies) ?? []) {
      const dependencyStatus = dependencyFlowInstance?.[dependency.id]?.status;

      if (dependencyStatus !== DependencyFlowStatus.Finished) {
        areAllDependenciesFinished = false;
        break;
      }
    }

    return areAllDependenciesFinished;
  }

  static getCommittedDependencies({
    dependencyFlowInstance,
  }: {
    dependencyFlowInstance: DependencyFlowInstanceType;
  }) {
    return Object.keys(dependencyFlowInstance).reduce<string[]>((acc, key) => {
      if (dependencyFlowInstance[key]?.status === DependencyFlowStatus.Commit) {
        acc.push(key);
      }
      return acc;
    }, []);
  }

  static isDependencyStaging(
    dependencyFlowInstance: DependencyFlowInstanceType,
    dependency: string,
  ) {
    return dependencyFlowInstance[dependency]?.status === DependencyFlowStatus.Staging;
  }

  static isDependencyFinished(
    dependencyFlowInstance: DependencyFlowInstanceType,
    dependency: string,
  ) {
    return dependencyFlowInstance[dependency]?.status === DependencyFlowStatus.Finished;
  }

  static isDependencyUnstaged(
    dependencyFlowInstance: DependencyFlowInstanceType,
    dependency: string,
  ) {
    return dependencyFlowInstance[dependency]?.status === undefined;
  }

  static getStagedDependencies({
    dependencyFlowInstance,
  }: {
    dependencyFlowInstance: DependencyFlowInstanceType;
  }) {
    return Object.keys(dependencyFlowInstance).reduce<string[]>((acc, key) => {
      if (DependencyFlowHelper.isDependencyStaging(dependencyFlowInstance, key)) {
        acc.push(key);
      }
      return acc;
    }, []);
  }

  static getDependencyFlowStatus({
    dependencyFlowInstance,
    entities,
    intraDependencies,
  }: {
    entities: DependencyFlowInstanceType;
    intraDependencies?: BuildDependencyGraph;
    dependencyFlowInstance: DependencyFlowInstanceType;
  }): DependencyFlowInstanceType {
    // collection staging ids in this
    const collectedEntityIds = new Set<string>();
    // collect non-staging entity ids in this
    const nonStagedEntities: DependencyFlowInstanceType = {};

    for (const [entityId, entity] of Object.entries(entities)) {
      if (!entity) continue;
      const { status } = entity;
      if (status === DependencyFlowStatus.Staging) {
        collectedEntityIds.add(entityId);
        DependencyFlowHelper.collectUnstagedDependencies({
          entityId,
          collected: collectedEntityIds,
          intraDependencies,
          dependencyFlowInstance,
        });
      } else {
        nonStagedEntities[entityId] = { status };
      }
    }

    const stagedEntities = Array.from(collectedEntityIds).reduce<
      Record<string, { status: DependencyFlowStatus }>
    >((acc, id) => {
      acc[id] = {
        status: DependencyFlowStatus.Staging,
      };
      return acc;
    }, {});

    return {
      ...nonStagedEntities,
      ...stagedEntities,
    };
  }

  static createStagingDependencyFlow({ ids }: { ids: string[] }): DependencyFlowInstanceType {
    return ids.reduce<DependencyFlowInstanceType>((acc, entityId) => {
      acc[entityId] = { status: DependencyFlowStatus.Staging };
      return acc;
    }, {});
  }

  static createCommitDependencyFlow({ ids }: { ids: string[] }): DependencyFlowInstanceType {
    return ids.reduce<DependencyFlowInstanceType>((acc, entityId) => {
      acc[entityId] = { status: DependencyFlowStatus.Commit };
      return acc;
    }, {});
  }

  static createFinishedDependencyFlow({ ids }: { ids: string[] }): DependencyFlowInstanceType {
    return ids.reduce<DependencyFlowInstanceType>((acc, entityId) => {
      acc[entityId] = { status: DependencyFlowStatus.Finished };
      return acc;
    }, {});
  }

  static collectUnstagedDependencies({
    collected,
    dependencyFlowInstance,
    entityId,
    intraDependencies,
  }: {
    entityId: string;
    collected: Set<string>;
    intraDependencies?: BuildDependencyGraph;
    dependencyFlowInstance: DependencyFlowInstanceType;
  }) {
    const entityDeps = intraDependencies?.[entityId];

    for (const dependsOn of DependencyHelper.getDependsOn(entityDeps) ?? []) {
      const dependsOnId = dependsOn.id;
      // Only add the dependency if it hasn't been processed already
      if (
        !collected.has(dependsOnId) &&
        DependencyFlowHelper.isDependencyUnstaged(dependencyFlowInstance, dependsOnId)
      ) {
        collected.add(dependsOnId);

        // Recursively collect dependencies for this child
        DependencyFlowHelper.collectUnstagedDependencies({
          collected,
          entityId: dependsOnId,
          intraDependencies,
          dependencyFlowInstance,
        });
      }
    }
  }
}

export default DependencyFlowHelper;
