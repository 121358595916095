import { forwardRef, memo } from 'react';
import { clsx } from 'clsx';
import { Typography } from '../Typography';
import type { PillProps } from './types';
import { decoratorVariants, dotVariants, labelVariants, pillVariants } from './styles';

function Dot({ color, variant }: PillProps) {
  const divClassnames = dotVariants({ color, variant });

  return <div className={divClassnames} />;
}

const getLabelVariant = (_size: 'md' | 'sm' | 'xs' | undefined) => {
  switch (_size) {
    case 'md':
      return 'text-sm';
    case 'sm':
      return 'text-xs';
    default:
      return 'text-xxxs';
  }
};

const Label = memo(function Label(
  props: Pick<PillProps, 'label' | 'variant' | 'labelClassName' | 'size'> & {
    typography?: PillProps['labelTypography'];
  },
) {
  const { label, variant, typography, labelClassName, size } = props;
  const labelClassNames = labelVariants({ variant });

  return (
    <Typography
      as="p"
      className={clsx(labelClassNames, labelClassName)}
      maxNumberOfLines={1}
      variant={typography?.variant ?? getLabelVariant(size)}
      weight={typography?.weight ?? 'medium'}
    >
      {label}
    </Typography>
  );
});

const Pill = forwardRef<HTMLDivElement, PillProps>(function Pill(props, ref) {
  const {
    className,
    startDecoratorComponent: StartDecoratorComponent,
    endDecoratorComponent: EndDecoratorComponent,
    startDecoratorNode,
    endDecoratorNode,
    variant = 'clear',
    shape = 'square',
    color = 'gray',
    label,
    isDot,
    pillClassName,
    labelClassName,
    labelTypography,
    disabled,
    size = 'sm',
    ...rest
  } = props;

  const pillClassNames = pillVariants({ variant, color, className, shape, disabled, size });
  const decoratorClassNames = decoratorVariants({ color, size, variant });

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent className={decoratorClassNames} />
  ) : (
    startDecoratorNode
  );

  const endDecorator = EndDecoratorComponent ? (
    <EndDecoratorComponent className={decoratorClassNames} />
  ) : (
    endDecoratorNode
  );

  if (!label) {
    return (
      <div className={clsx(pillClassNames, size !== 'md' ? '!p-sm' : '')} ref={ref} {...rest}>
        {startDecorator}
      </div>
    );
  }

  return (
    <div
      className={clsx(
        pillClassNames,
        { 'cursor-pointer': Boolean(props.onClick) },
        { 'gap-x-xs': isDot },
        { 'gap-x-xs': Boolean(endDecorator) },
        { 'gap-x-xs ps-sm': Boolean(startDecorator) },
        pillClassName,
      )}
      ref={ref}
      {...rest}
    >
      {isDot ? <Dot {...props} /> : startDecorator}
      <Label
        label={label}
        labelClassName={labelClassName}
        size={size}
        typography={labelTypography}
        variant={variant}
      />
      {endDecorator}
    </div>
  );
});

Pill.displayName = 'Pill';

const MemoizedPill = memo(Pill) as typeof Pill;
export default MemoizedPill;
