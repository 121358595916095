import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type { FiltersBlockStateType, FiltersComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importFiltersComponent = () =>
  import(
    /* webpackChunkName: "filters-block" */
    './Filters'
  );

const Filters = lazy(importFiltersComponent);

class FiltersBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Filters;
  }

  get type(): ComponentTypeUnionType {
    return 'Filters';
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => {
      const blockState: FiltersBlockStateType = {
        id: block.id,
        ..._pick(block.component as FiltersComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      };
      return blockState;
    };
  }

  getEventTargetIds() {
    return [];
  }

  preload() {
    return {
      blockPreload: importFiltersComponent,
    };
  }
}

export default FiltersBlockStateDefinition;
