import StepButton from '@mui/joy/StepButton';
import StepIndicator from '@mui/joy/StepIndicator';
import BaseStepper from '@mui/joy/Stepper';
import BaseStep from '@mui/joy/Step';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { clsx } from 'clsx';
import _isUndefined from 'lodash/isUndefined';
import SvgCheck from '@unifyapps/icons/outline/Check';
import { isEmpty } from 'lodash';
import { Typography } from '../Typography';
import { Box } from '../Box';
import Stack from '../../_components/Stack';
import { getIconClassName, getCurrentStepIndicatorClassName } from './styles';
import { findStep, getFirstStepId, getStepNumberLabel, getStepStatus } from './utils';
import { StepStatus, type Step, type StepperProps } from './types';

const getStepperStyles = ({
  stepsCount,
  orientation,
}: {
  stepsCount: number;
  orientation: 'horizontal' | 'vertical';
}) => {
  switch (orientation) {
    case 'horizontal':
      return {
        // Calculate the width of the step connector line
        // (100% - stepsCount * StepIndicator-size):
        //   - Subtracts the total width occupied by the step indicators from the total width.
        // ((2 * stepsCount) / (2 * stepsCount - 2)):
        //   - Adjusts the width proportionally based on the number of steps to ensure proper spacing between steps.
        width: `calc((100% - ${stepsCount} * var(--StepIndicator-size)) * ${(2 * stepsCount) / (2 * stepsCount - 2)})`,

        // Calculate the margin-left to position the connector line correctly
        // (width calculation) * (-1 / (2 * stepsCount)):
        //   - Offsets the connector line to align it correctly with the step indicators.
        // var(--StepIndicator-size):
        //   - Adds the size of the step indicator to fine-tune the starting position.
        marginLeft: `calc(((100% - ${stepsCount} * var(--StepIndicator-size)) * ${(2 * stepsCount) / (2 * stepsCount - 2)}) * ${-1 / (2 * stepsCount)} + var(--StepIndicator-size))`,
      };
    default:
      return undefined;
  }
};

const STEPPER_SX = {
  '--Stepper-verticalGap': '18px',
};

const STEP_SX = {
  '--Step-connectorThickness': '2px',
  '--Step-connectorRadius': '2px',
};

const getStepIndicator = ({
  steps,
  stepId,
  activeStepId,
  showStepNumber = false,
  showIcon = false,
  showCompleteIndicator = false,
  size,
  isSubStep = false,
}: {
  steps: Step[];
  stepId: string;
  activeStepId: string;
  showStepNumber?: boolean;
  showIcon?: boolean;
  showCompleteIndicator?: boolean;
  size: 'sm' | 'md' | 'lg';
  isSubStep?: boolean;
}) => {
  let stepIndicator: React.ReactNode = null;

  const iconClassName = getIconClassName({
    size,
  });
  const stepStatus = getStepStatus({ stepId, steps, activeStepId });

  const stepIndicatorClassName = getCurrentStepIndicatorClassName({
    size,
    isPending: stepStatus === StepStatus.Pending,
    isSubStep,
  });

  if (showStepNumber) {
    if (showCompleteIndicator && stepStatus === StepStatus.Completed) {
      stepIndicator = <SvgCheck className={iconClassName} strokeWidth={2} />;
    } else {
      stepIndicator = (
        <Typography variant={size === 'lg' ? 'text-sm' : 'text-xs'}>
          {getStepNumberLabel({ stepId, steps })}
        </Typography>
      );
    }
  } else if (stepStatus === StepStatus.Completed) {
    stepIndicator = <SvgCheck className={iconClassName} strokeWidth={2} />;
  } else if (showIcon) {
    const Icon = findStep(steps, stepId)?.Icon;
    stepIndicator = Icon ? <Icon className={iconClassName} /> : null;
  } else {
    stepIndicator = <Box className={stepIndicatorClassName} />;
  }

  return (
    <StepIndicator
      className={clsx('bg-primary border-secondary border', {
        '!bg-brand-solid text-white': stepStatus !== StepStatus.Pending && !isSubStep,
        '!bg-secondary': showStepNumber && stepStatus === StepStatus.Pending,
        'text-brand-solid': isSubStep,
        '!border-brand-solid': stepStatus !== StepStatus.Pending && isSubStep,
        '!bg-disabled_subtle': stepStatus === StepStatus.Pending && !isSubStep,
        'ring-brand': stepStatus === StepStatus.Active,
        'h-4 w-4': size === 'sm',
      })}
      variant={stepStatus !== StepStatus.Completed ? 'soft' : 'solid'}
    >
      {stepIndicator}
    </StepIndicator>
  );
};

function Stepper({
  steps,
  orientation = 'horizontal',
  style,
  className,
  stepDetails,
  controlledState,
  stepLabelClassName,
  stepDescriptionClassName,
  size = 'md',
  ...rest
}: StepperProps) {
  const { activeStepId: controlledActiveStepId, onStepChange } = controlledState ?? {};

  const [activeStepId, setActiveStepId] = useState(
    (controlledActiveStepId || getFirstStepId(steps)) ?? '',
  );

  useEffect(() => {
    if (!_isUndefined(controlledActiveStepId) && controlledActiveStepId !== activeStepId) {
      setActiveStepId(controlledActiveStepId);
    }
  }, [activeStepId, controlledActiveStepId]);

  const setActiveStepIdWrapper = useCallback(
    (updatedActiveStepId: string) => {
      setActiveStepId(updatedActiveStepId);
      onStepChange?.(updatedActiveStepId);
    },
    [onStepChange],
  );

  const getHandleStepChange = useCallback(
    (stepId: string) => () => {
      setActiveStepIdWrapper(stepId);
    },
    [setActiveStepIdWrapper],
  );

  const stepperStyles = useMemo(
    () =>
      getStepperStyles({
        stepsCount: steps.length,
        orientation,
      }),
    [orientation, steps.length],
  );

  const renderTitleAndDescription = useCallback(
    ({ label, description }: { label?: string; description?: string }) => (
      <Stack alignItems="start" className="pl-sm" direction="column">
        <Typography
          className={stepLabelClassName || 'text-secondary'}
          variant={stepDetails?.label?.variant || 'text-sm'}
          weight={stepDetails?.label?.weight || 'semi-bold'}
        >
          {label}
        </Typography>
        {description ? (
          <Typography
            className={clsx('leading-none', 'text-tertiary', stepDescriptionClassName)}
            variant={stepDetails?.description?.variant || 'text-xs'}
            weight={stepDetails?.description?.weight || 'regular'}
          >
            {description}
          </Typography>
        ) : null}
      </Stack>
    ),
    [
      stepDescriptionClassName,
      stepDetails?.description?.variant,
      stepDetails?.description?.weight,
      stepDetails?.label?.variant,
      stepDetails?.label?.weight,
      stepLabelClassName,
    ],
  );

  return (
    <Box className={clsx('flex', className)} style={style} {...rest}>
      <BaseStepper
        className="h-full w-full flex-shrink-0 flex-grow"
        orientation={orientation}
        size={size}
        style={stepperStyles}
        sx={STEPPER_SX}
      >
        {steps.map((step) => {
          const stepStatus = getStepStatus({ stepId: step.value, steps, activeStepId });

          return (
            <BaseStep
              indicator={getStepIndicator({
                stepId: step.value,
                showStepNumber: stepDetails?.showStepNumber,
                showCompleteIndicator: stepDetails?.showCompleteIndicator,
                showIcon: Boolean(step.Icon),
                activeStepId,
                steps,
                size,
              })}
              key={step.value}
              orientation={stepDetails?.orientation || 'vertical'}
              sx={
                stepStatus === StepStatus.Completed
                  ? {
                      ...STEP_SX,
                      '&::after': {
                        background: 'var(--bg-brand-solid)',
                      },
                    }
                  : STEP_SX
              }
            >
              {stepDetails?.clickable ? (
                <StepButton onClick={getHandleStepChange(step.value)}>
                  {renderTitleAndDescription({ label: step.label, description: step.description })}
                </StepButton>
              ) : (
                <>
                  {renderTitleAndDescription({ label: step.label, description: step.description })}
                  {/* We don't support substeps for horizontal orientation yet */}
                  {orientation === 'vertical' && !isEmpty(step.subSteps) ? (
                    <Stack className="pt-lg pl-sm">
                      <BaseStepper
                        orientation={orientation}
                        size={size}
                        sx={{ '--Step-connectorThickness': '0px', '--Stepper-verticalGap': '6px' }}
                      >
                        {step.subSteps?.map((subStep) => (
                          <BaseStep
                            indicator={getStepIndicator({
                              stepId: subStep.value,
                              showStepNumber: stepDetails?.showStepNumber,
                              showCompleteIndicator: stepDetails?.showCompleteIndicator,
                              showIcon: Boolean(subStep.Icon),
                              activeStepId,
                              steps,
                              size,
                              isSubStep: true,
                            })}
                            key={subStep.value}
                            orientation={stepDetails?.orientation ?? 'vertical'}
                          >
                            <Typography
                              className="text-secondary"
                              variant="text-xs"
                              weight="medium"
                            >
                              {subStep.label}
                            </Typography>
                          </BaseStep>
                        ))}
                      </BaseStepper>
                    </Stack>
                  ) : null}
                </>
              )}
            </BaseStep>
          );
        })}
      </BaseStepper>
    </Box>
  );
}

export default Stepper;
