import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { ChatBlockRef, ChatComponentType } from '@unifyapps/defs/blocks/Chat/types';
import { ChatBlockMethods } from '@unifyapps/defs/blocks/Chat/types';
import type { Action } from '@unifyapps/defs/types/action';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { lazy } from 'react';

const importChatComponent = () =>
  import(
    /* webpackChunkName: "chat-block" */
    './Chat'
  );

const Chat = lazy(importChatComponent);

class ChatBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Chat;
  }

  get type(): ComponentTypeUnionType {
    return 'Chat';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: ChatBlockMethods.SubmitMessage,
        label: 'Send Message',
        method: (block: BlockStateUnionType, action: Action, blockRef?: ChatBlockRef) => {
          const methodPayload = action.payload?.methodPayload as
            | { message: string; attachments?: FileType[] }
            | undefined;
          if (methodPayload?.message || methodPayload?.attachments) {
            blockRef?.sendMessage?.({
              message: methodPayload.message,
              attachments: methodPayload.attachments,
            });
          }

          return Promise.resolve();
        },
      },
    ];
  }

  getSlotOrder(block: BlockType): { order: string[] } {
    return {
      order: ['messageInputPanel'],
    };
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ChatComponentType, ['content', 'appearance', 'componentType']),
    });
  }
}

export default ChatBlockStateDefinition;
