import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type {
  BusinessHoursBlockStateType,
  BusinessHoursComponentType,
} from '@unifyapps/defs/blocks/BusinessHours/types';
import { defaultBusinessHourValue } from './consts';

const importBusinessHoursComponent = () =>
  import(
    /* webpackChunkName: "business-hours-block" */
    './BusinessHours'
  );

const BusinessHours = lazy(importBusinessHoursComponent);

class BusinessHoursBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'BusinessHours';
  }

  getEventTargetIds() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType): BusinessHoursBlockStateType =>
      ({
        id: block.id,
        ..._pick(block.component as BusinessHoursComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
        value: defaultBusinessHourValue,
      }) as BusinessHoursBlockStateType;
  }

  getBlockControlMethods() {
    return [];
  }

  getComponent() {
    return BusinessHours;
  }

  preload() {
    return {
      blockPreload: importBusinessHoursComponent,
    };
  }
}

export default BusinessHoursBlockStateDefinition;
