import type { UIFilter } from '@unifyapps/network/generated/models/uIFilter';
import type { BlockComponentStateId, BlockComponentType } from '../../types/block';
import type { SlotType } from '../../types/slot';
import type { StyleObject } from '../../types/styleObject';
import type { FileType } from '../FileUpload/types';
import type { EventTypeEnum } from '../../types/event';
import type { CitationType } from '../Citation/types';

export type AutomationConfig = Record<string, string> & {
  QuickQuestions?: string[];
  type?: string;
};

export const enum AIActions {
  CreateCase = 'create_case_automation',
  FetchMessageAttachment = 'fetch_attachment_by_message_id_automation',
  FetchMessageById = 'fetch_message_by_id_automation',
  ClearMessageList = 'clear_conversation_automation',
  FetchCaseList = 'fetch_case_list_automation',
  RetryCase = 'retry_response_automation',
  FetchConversation = 'fetch_conversation_automation',
  FeedbackAutomation = 'feedbackAutomation',
}

type Content = {
  quickQuestions?: string[];
  welcomeText?: string;
  aiAgentId?: string;
  filters?: UIFilter;
  initialChatId?: string;
  shouldPollInfinitely?: boolean;
  maxPollDuration?: number;
  showCitationPanel?: boolean;
};

type Appearance = {
  styles?: Pick<StyleObject, 'gap' | 'height' | 'width' | 'backgroundColor' | 'borderRadius'>;
  //idea: alternative to this can be mobile view or desktop view where complete mobile customization can be incorporated.
  size: 'sm' | 'lg';
  messageVariant?: 'DEFAULT' | 'BUBBLE';
  useAIAgentAvatar?: boolean;
};

type Slots = {
  messageHistory?: SlotType;
  messageInputPanel?: SlotType;
};

export type CopilotComponentType = BlockComponentType<'Copilot', Content, Appearance, Slots>;

export type CopilotBlockRef = {
  submitMessage?: (input: { message?: string; attachments?: FileType[] }) => void;
  createNewChat?: () => void;
  goToChat?: (chatId: string) => void;
};

export type OnCitationClickEventState = {
  [EventTypeEnum.OnCitationClick]: {
    citationId: string | undefined;
    messageId: string;
  } & Pick<CitationType, 'chunkContents' | 'title' | 'author' | 'name' | 'referenceUrl' | 'date'>;
};

export type CopilotBlockStateType = Pick<
  CopilotComponentType,
  'appearance' | 'content' | 'componentType'
> &
  BlockComponentStateId & {
    chatId?: string;
    errorMessage?: string;
    automationsConfig?: AutomationConfig;
    aiAgentId?: string;
    isAwaitingResponse?: boolean;
    aiAgent: {
      id?: string;
      name?: string;
      avatarUrl?: string;
      isLoading?: boolean;
    };
    events?: {
      [EventTypeEnum.OnButtonClick]: {
        message: string | undefined;
      };
      [EventTypeEnum.OnMessageSent]: {
        message: string;
        attachments?: FileType[];
      };
    } & OnCitationClickEventState;
  };

export type CopilotEvents = {
  onButtonClick: (message: string) => void;
  onCitationClick: (props: OnCitationClickEventState[EventTypeEnum.OnCitationClick]) => void;
  onMessageSent: ({ message, attachments }: { message: string; attachments?: FileType[] }) => void;
  // onMessageReceived: ({ messageId }: { messageId: string }) => void;
};

export const enum CopilotBlockMethods {
  SendMessage = 'submitMessage',
  CreateNewChat = 'createNewChat',
  GoToChat = 'goToChat',
}
