import { useCallback } from 'react';
import { useGetGlobalStateStoreState } from '../../../stores/GlobalStateStore';
import { useGetInterfaceStoreState } from '../../../stores/InterfaceStore';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';

export function useBuildEntitiesForDependencies() {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const getDataSourceRecordStoreState = useDataSourceRecordStore().getState;

  // need to use get method of store because we want the latest values of the store here, not using that
  // makes this function a render cycle back and inconsistent dependencies
  const getEntitiesForDependencies = useCallback(() => {
    const globalStateStoreState = getGlobalStateStoreState();
    const interfaceStoreState = getInterfaceStoreState();
    const dataSourceRecords = getDataSourceRecordStoreState().dataSources;
    const activeInterfacePage =
      interfaceStoreState.interfacePages[interfaceStoreState.activeInterfacePageId];
    const pageFunctions = activeInterfacePage.properties.pageFunctions;
    const pageVariables = activeInterfacePage.properties.pageVariables;
    const stateBlocks = globalStateStoreState.blocks;

    const groupedEntityList = {
      // NOTE: state blocks also have runtimeBlockIds
      blocks: { ...stateBlocks },
      dataSources: { ...dataSourceRecords },
      pageFunctions: { ...pageFunctions },
      // might not need this
      pageVariables: { ...pageVariables },
    };

    return { groupedEntityList };
  }, [getDataSourceRecordStoreState, getGlobalStateStoreState, getInterfaceStoreState]);

  return { getEntitiesForDependencies };
}

export type EntityListType = ReturnType<
  ReturnType<typeof useBuildEntitiesForDependencies>['getEntitiesForDependencies']
>['groupedEntityList'];
