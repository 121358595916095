import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { Popover } from '@unifyapps/ui/components/Popover';
import { Box } from '@unifyapps/ui/components/Box';
import { Fragment, useMemo, useState } from 'react';
import { format } from 'date-fns/format';
import { DateFormats } from '@unifyapps/carbon/utils/date';
import { clsx } from 'clsx';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { Button } from '@unifyapps/ui/components/Button';
import { useTranslation } from '@unifyapps/i18n/client';
import CitationsHelper from '../CitationsHelper';
import { CitationIcon } from './CitationIcon';

export const SPACIOUS_PILL_CLASSNAME =
  'px-lg py-md bg-secondary hover:bg-secondary_hover h-[5.5rem] justify-between rounded-sm';

export const CITATION_WIDTH = 160;
const STYLE = { width: CITATION_WIDTH };

const getDisplayName = (citation: CitationType) => {
  const applicationName = CitationsHelper.getApplicationName(citation);

  if (applicationName) {
    return applicationName;
  }
  if (citation.appName) {
    return citation.appName;
  }
  if (citation.knowledgeSourceType === 'WEBSITE') {
    return 'Website';
  }
  return 'File';
};

function InfoRenderer({ items }: { items: { content: string; className?: string }[] }) {
  if (items.length === 0) {
    return null;
  }

  return (
    <Stack className="gap-xs items-center" direction="row">
      {items.map(({ content, className }, index) => (
        <Fragment key={index}>
          <Typography
            className={clsx(className, 'overflow-ellipsis break-all')}
            color="text-quaternary"
            key={content}
            maxNumberOfLines={1}
            variant="text-xs"
          >
            {content}
          </Typography>
          {index !== items.length - 1 ? (
            <Box className="bg-quaternary size-1 flex-shrink-0 rounded-full" />
          ) : null}
        </Fragment>
      ))}
    </Stack>
  );
}

function CitationPopoverCard({
  citation,
  onClick,
}: {
  citation: CitationType;
  onClick?: () => void;
}) {
  const { t } = useTranslation();
  const { title, name, chunkContents, appName, author, date } = citation;
  const infoItems = useMemo(
    () =>
      [
        appName ? { content: appName, className: 'flex-shrink-0' } : undefined,
        author ? { content: author } : undefined,
        date ? { content: format(date, DateFormats.dMy), className: 'flex-shrink-0' } : undefined,
      ].filter(Boolean) as { content: string; className?: string }[],
    [appName, author, date],
  );

  return (
    <Stack className="size-full">
      <CitationIcon citation={citation} className="size-8 flex-shrink-0" />
      <Box className="pt-2">
        <Typography
          className="break-all"
          color="text-primary"
          maxNumberOfLines={2}
          variant="text-sm"
          weight="medium"
        >
          {title ?? name}
        </Typography>
      </Box>
      <InfoRenderer items={infoItems} />
      {chunkContents?.[0] ? (
        <Box className="pt-1.5">
          <Typography color="text-secondary" maxNumberOfLines={5} variant="text-sm">
            {chunkContents[0]}
          </Typography>
        </Box>
      ) : null}
      <Box className="pt-sm">
        <Button color="brand" onClick={onClick} size="sm" variant="ghost">
          {t('common:Actions.SeeMore')}
        </Button>
      </Box>
    </Stack>
  );
}

export function SpaciousCitationPill({
  citation,
  onClick,
  className,
}: {
  citation: CitationType;
  onClick: () => void;
  className?: string;
}) {
  const [open, setOpen] = useState(false);
  const { title, name } = citation;
  const displayName: string = getDisplayName(citation);

  const handleClick = useEventCallback(() => {
    setOpen(false);
    onClick();
  });

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Popover.Button>
        <Stack className={clsx(SPACIOUS_PILL_CLASSNAME, className)} role="button" style={STYLE}>
          <Typography
            className="break-all"
            color="text-tertiary"
            maxNumberOfLines={3}
            variant="text-xs"
            weight="medium"
          >
            {title ?? name}
          </Typography>
          <Stack className="gap-xxs items-center" direction="row">
            <CitationIcon
              citation={citation}
              className="size-3 flex-shrink-0"
              displayName={displayName}
            />
            <Typography className="ps-[1px]" color="text-quaternary" variant="text-xxs">
              {displayName}
            </Typography>
          </Stack>
        </Stack>
      </Popover.Button>
      <Popover.Content
        className="border-tertiary bg-primary p-xl w-[20rem] cursor-pointer rounded-xl border shadow-lg"
        offset={8}
        placement="top-start"
      >
        <CitationPopoverCard citation={citation} onClick={handleClick} />
      </Popover.Content>
    </Popover>
  );
}
