import type { RefObject } from 'react';
import { forwardRef, useCallback, useMemo, useRef, useImperativeHandle } from 'react';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import { useTranslation } from '@unifyapps/i18n/client';
import { clsx } from 'clsx';
import type { useUppy } from '../../hooks/useUppy';
import DragDrop from '../DragDrop';

export interface UploadCardProps {
  uppy: ReturnType<typeof useUppy>['uppy'];
  accept: string;
  children?: React.ReactNode;
  multiple?: boolean;
  maxNumberOfFiles?: number;
  maxFileSizeInMB?: number;
  referenceId?: string;
  className?: string;
  disabled?: boolean;
  // If isClickable is true (default value), then clicking anywhere on the card will open the file dialog
  // If you want to allow drag events and disable click event, set it to false
  isClickable?: boolean;
}

function DragAndDropPlaceholder() {
  const { t } = useTranslation();

  return (
    <Box className="bg-brand-primary p-xl z-tabs border-brand-solid absolute bottom-0 end-0 start-0 top-0 flex w-full flex-1 animate-pulse flex-col items-center justify-center rounded-2xl border-2 border-dashed">
      <Typography className="w-192 text-center" color="text-placeholder" variant="text-md">
        {t('common:DropFilesPlaceholder')}
      </Typography>
    </Box>
  );
}

export default forwardRef(function UploadCard(
  props: UploadCardProps,
  ref: RefObject<DragDrop | null>,
) {
  const {
    uppy,
    children,
    multiple = false,
    accept,
    disabled,
    isClickable = true,
    referenceId,
    className,
  } = props;

  const fileInput = useRef<DragDrop>(null);
  const showFileSelector = useCallback(() => fileInput.current?.showUploadDialog(), []);
  useImperativeHandle(ref, () => fileInput.current, [fileInput]);
  const DragView = useMemo(() => <DragAndDropPlaceholder />, []);

  return (
    <DragDrop
      DragView={DragView}
      accept={accept}
      className="relative h-full"
      disabled={disabled}
      maxFileSizeInMB={props.maxFileSizeInMB}
      maxNumberOfFiles={props.maxNumberOfFiles}
      multiple={multiple}
      ref={fileInput}
      referenceId={referenceId}
      uppy={uppy}
    >
      <Box
        className={clsx('z-10', className)}
        onClick={isClickable && !disabled ? showFileSelector : undefined}
      >
        {children}
      </Box>
    </DragDrop>
  );
});
