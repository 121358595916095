import type { ReactNode } from 'react';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import type { SnackbarProps } from '@unifyapps/ui/components/Snackbar/types';
import SvgCheckCircle from '@unifyapps/icons/outline/CheckCircle';
import SvgXCircle from '@unifyapps/icons/outline/XCircle';
import SvgAlertCircle from '@unifyapps/icons/outline/AlertCircle';
import type { FeatureIconProps } from '@unifyapps/ui/components/FeaturedIcon';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

type ShowNotificationPayload = {
  title: SnackbarProps['title'];
  description?: SnackbarProps['description'];
  type?: SnackbarProps['color'];
  autoHideDuration?: SnackbarProps['autoHideDuration'];
};

export const AlertColorToFeaturedIconColor: {
  [key in NonNullable<SnackbarProps['color']>]: NonNullable<FeatureIconProps['color']>;
} = {
  brand: 'brand',
  default: 'white',
  error: 'error',
  success: 'success',
  warning: 'warning',
};

export const AlertTypeToIcon: {
  [key in NonNullable<SnackbarProps['color'] | 'info'>]: React.ComponentType<
    React.SVGProps<SVGSVGElement>
  >;
} = {
  success: SvgCheckCircle,
  error: SvgXCircle,
  warning: SvgAlertCircle,
  info: SvgAlertCircle,
  default: SvgAlertCircle,
  brand: SvgAlertCircle, // todo: fix the logo accordingly
};

function useShowNotification() {
  const { showSnackbar } = useSnackbar();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const onShowNotification: ActionHandlerType = ({ action, actionContext }: OnActionArgs) => {
    const { type, title, autoHideDuration, description } =
      action.payload as ShowNotificationPayload;

    const iconName = AlertTypeToIcon[type ?? 'default'];
    const { title: computedTitle, description: computedDescription } = getNoCodeComputedData<{
      title: string;
      description: ReactNode;
    }>({ title, description }, actionContext);

    showSnackbar({
      color: type,
      title: computedTitle,
      description: computedDescription,
      autoHideDuration,
      startDecoratorNode: (
        <FeaturedIcon Icon={iconName} color={AlertColorToFeaturedIconColor[type ?? 'default']} />
      ),
    });
    return Promise.resolve();
  };

  return { onShowNotification };
}

export default useShowNotification;
