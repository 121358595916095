/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-assignment
-- needed */

/**
 * Memoizes a function with a maximum cache size.
 * @param func - The function to memoize.
 * @param maxSize - The maximum size of the cache.
 * @param resolver - A function to resolve the cache key.
 */
export function cappedMemoize<T extends (...args: any[]) => any, K = any>(
  func: T,
  maxSize = 500,
  resolver?: (...args: Parameters<T>) => K,
): T {
  if (typeof func !== 'function' || (resolver !== undefined && typeof resolver !== 'function')) {
    throw new TypeError('Expected a function');
  }

  const cache = new Map<K, ReturnType<T>>();

  const memoizedFunction = function (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ): ReturnType<T> {
    const key = resolver ? resolver.apply(this, args) : (args[0] as K);

    if (cache.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- cache has key
      return cache.get(key)!;
    }

    const result = func.apply(this, args);
    cache.set(key, result);

    if (cache.size > maxSize) {
      const oldestKey = cache.keys().next().value;
      cache.delete(oldestKey);
      // cache.clear();
    }

    return result;
  };

  // Assign the cache to the memoized function for external access if needed
  // (memoizedFunction as any).cache = cache;

  return memoizedFunction as T;
}
