/* eslint-disable @typescript-eslint/no-extraneous-class -- required */
import { isTargetVisible } from '@unifyapps/carbon/no-code/hooks/useIsBlockVisibility';
import type { DataTableComponentType } from '@unifyapps/defs/blocks/Table/DataTable/types';
import type { MRT_RowData as RowData, MRT_ColumnDef as ColumnDef } from '@unifyapps/table/types';

type Columns = DataTableComponentType['content']['columns'];

export class DataTableColumnHelper {
  static isNestedColumnType = (columnType: Columns[number]['type']) => {
    return columnType === 'NESTED_TABLE' || columnType === 'GROUPED';
  };

  static hasNestedColumns = (
    column: Columns[number],
  ): column is Columns[number] & { columns: Columns } => {
    return this.isNestedColumnType(column.type);
  };

  static getColumns = (columns: Columns): Columns => {
    return columns.reduce<Columns>((accumulator, column) => {
      accumulator.push(column);

      if (column.type === 'GROUPED') {
        accumulator.push(...this.getColumns(column.columns));
      }

      return accumulator;
    }, []);
  };

  static hasEditableColumns = (component: DataTableComponentType) => {
    return (
      this.getColumns(component.content.columns).some((column) => column.editable) ||
      Boolean(component.content.mappedColumn?.editable)
    );
  };

  static getMRTColumns = (columns: ColumnDef<RowData>[]): ColumnDef<RowData>[] => {
    return columns.reduce<ColumnDef<RowData>[]>((accumulator, column) => {
      accumulator.push(column);

      if (column.columns) {
        accumulator.push(...this.getMRTColumns(column.columns));
      }

      return accumulator;
    }, []);
  };

  static isMRTColumnEditable = (column: ColumnDef<RowData>): boolean => {
    return Boolean(column.enableEditing);
  };

  static getHasLegacyHiddenField = (column: Columns[number]) => {
    return typeof column.hidden === 'boolean';
  };

  static getIsColumnVisible = (column: Columns[number]): boolean => {
    // this is for backward compatibility in Runner
    if (this.getHasLegacyHiddenField(column)) {
      return !column.hidden;
    }
    return isTargetVisible(column.visibility);
  };

  static getIsColumnVisibleBuilder = (column: Columns[number]) => {
    return !column.visibility || column.visibility.value === true;
  };
}
