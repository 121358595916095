'use client';
import type { ComponentType, ContextType, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { keyBy } from 'lodash';
import { fallbackLng } from '@unifyapps/i18n/settings';
import { useGetUserContext } from '../../hooks/useGetUserContext';
import { useRegistryContext } from '../../components/RegistryProvider';
import AsyncView from '../../../components/AsyncView';
import { useFetchInterfacePermissions } from '../../access-control/hooks/useFetchInterfacePermissions';
import { useRouter } from '../../hooks/useRouter';
import { useDataSourceRecordStore } from '../DataSourceRecordStore';
import { compose } from '../../../utils/compose';
import { Remount } from '../../../components/Remount';
import { useSessionStorageEntry } from '../../../hooks/useSessionStorageEntry';
import { SessionStorageKeys } from '../../../consts/sessionStorageKeys';
import type {
  BaseGlobalStateStoreProviderProps,
  GlobalStateStore,
  GlobalStateStoreInitialValue,
  WithPermissionsProps,
  WithRemountProps,
} from './types';
import { createGlobalStateStore } from './createGlobalStateStore';
import {
  getContextualDialogsForBlocks,
  getInitialBlockState,
  getInitialManuallyTriggeredDataSources,
  getInitialPageVariablesState,
} from './utils/initial';
import { createGlobalStateStoreSelectors } from './selectors';

const GlobalStateStoreContext = createContext<ReturnType<
  typeof createGlobalStateStoreSelectors
> | null>(null);

export const useGlobalStateStore = () => {
  const _useStore = useContext(GlobalStateStoreContext);
  if (!_useStore) {
    throw new Error('Missing GlobalStateStoreProvider');
  }
  return _useStore;
};

function getInitialLocation(
  pathParams: GlobalStateStore['location']['pathParams'],
): GlobalStateStore['location'] {
  // eslint-disable-next-line -- window is not defined in server side
  if (typeof window === 'undefined' || typeof window.location === 'undefined') {
    return {
      href: '',
      hostname: '',
      pathname: '',
      search: '',
      hash: '',
      pathParams,
    };
  }

  const { pathname, search, hash } = window.location;
  return {
    href: window.location.href,
    hostname: window.location.hostname,
    pathname,
    search,
    hash,
    pathParams,
  };
}

function BaseGlobalStateStoreProvider({
  blocks,
  pageVariables,
  pageInputs,
  deviceDetails,
  children,
  dataSources,
  pageFunctions,
  globalStateStoreRef,
  permissions,
  remount,
  interfaceId,
}: PropsWithChildren<BaseGlobalStateStoreProviderProps>) {
  const getDataSourceRecordsStore = useDataSourceRecordStore().getState;
  const { registry } = useRegistryContext();
  const { pathParams } = useRouter();
  const storeRef = useRef<ContextType<typeof GlobalStateStoreContext>>();
  const { setStorageValue: setInterfaceSessionStorage, data: interfaceSessionStorage } =
    useSessionStorageEntry<Record<string, unknown>>(SessionStorageKeys.Interfaces);
  const { userContext } = useGetUserContext();

  if (!storeRef.current) {
    const initialValue: GlobalStateStoreInitialValue = {
      deviceDetails,
      pageInputs: pageInputs ?? {},
      userContext: userContext ?? { locale: fallbackLng },
      manuallyTriggeredDataSources: getInitialManuallyTriggeredDataSources(dataSources),
      location: getInitialLocation(pathParams),
      blocks: blocks ? getInitialBlockState({ blocks, registry }) : {},
      pageVariables: pageVariables ? getInitialPageVariablesState({ pageVariables }) : {},
      permissions,
      pageFunctions: pageFunctions ?? {},
      contextualDialogForBlocks: getContextualDialogsForBlocks(blocks ?? {}),
    };
    storeRef.current = createGlobalStateStoreSelectors(
      createGlobalStateStore({
        dataSourceRecords: dataSources,
        initial: initialValue,
        getDataSourceRecordsStore,
        remount,
        interfaceId,
        interfaceSessionStorage,
        setInterfaceSessionStorage,
      }),
    );
  }

  useEffect(() => {
    if (!isEqual(storeRef.current?.getState().pageInputs, pageInputs)) {
      const _pageInputs: GlobalStateStore['pageInputs'] = pageInputs ?? {};
      storeRef.current?.setState({ pageInputs: _pageInputs });
    }
  }, [pageInputs]);

  useImperativeHandle(globalStateStoreRef, () => {
    return {
      updateBlocks: ({ added, changed, deleted }) => {
        const blocksToUpdate = [...added, ...changed];
        const keyByBlocks = keyBy(blocksToUpdate, 'id');
        const blockIdsToDelete = deleted.map((block) => block.id);

        const initialBlocksState = getInitialBlockState({ blocks: keyByBlocks, registry });
        storeRef.current?.getState().actions.setBlocksState(initialBlocksState);
        storeRef.current?.getState().actions.removeBlocksState(blockIdsToDelete);
      },
    };
  }, [registry]);

  return (
    <GlobalStateStoreContext.Provider value={storeRef.current}>
      {children}
    </GlobalStateStoreContext.Provider>
  );
}

const withPermissions = (Component: typeof BaseGlobalStateStoreProvider) => {
  function Wrapper(props: PropsWithChildren<WithPermissionsProps>) {
    const { interfaceId, client, mode } = props;
    const { permissions, error, isLoading } = useFetchInterfacePermissions({
      mode,
      interfaceId,
      client,
    });

    return (
      // Is empty false is needed in case permissions are not required to be fetched then we don't want to show empty state
      <AsyncView data={permissions} error={error} isEmpty={false} isLoading={isLoading}>
        <Component {...props} permissions={permissions} />
      </AsyncView>
    );
  }

  return Wrapper;
};

const withRemount = (Component: ComponentType<PropsWithChildren<WithPermissionsProps>>) => {
  function Wrapper(props: PropsWithChildren<WithRemountProps>) {
    return <Remount>{(remount) => <Component {...props} remount={remount} />}</Remount>;
  }

  return Wrapper;
};

const GlobalStateStoreProvider = compose<ComponentType<PropsWithChildren<WithRemountProps>>>(
  withRemount,
  withPermissions,
)(BaseGlobalStateStoreProvider);

export { GlobalStateStoreProvider };
