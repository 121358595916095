import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import { withSuspense as withBlockSuspense } from '@unifyapps/carbon/no-code/components/withSuspense';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import {
  type StepperComponentStateType,
  type StepperComponentType,
  StepperContentType,
  type Step,
} from '@unifyapps/defs/blocks/Stepper/types';
import { memo, useEffect } from 'react';
import { usePrevious } from '@react-hookz/web';
import { DynamicStepper } from './component/DynamicStepper';
import { StaticStepper } from './component/StaticStepper';

export const getBlockSteps = (blockState: StepperComponentStateType) => {
  let steps: Step[] = [];
  if (blockState.content.type === StepperContentType.DYNAMIC) {
    steps = blockState.steps ?? [];
  } else {
    steps = blockState.content.steps ?? [];
  }
  return steps;
};

function Stepper({
  dataAttributes,
  updateBlockState,
  events,
  computedBlockState,
}: BlockComponentProps<
  StepperComponentType,
  StepperComponentStateType,
  StepperComponentStateType
>) {
  const { emitEvent } = useBlockEvents(events);

  const onStepChange = useEventCallback(
    ({
      stepId,
      currentStep,
    }: {
      stepId: string;
      currentStep: { value: string; label: string; description?: string; icon?: string };
    }) => {
      updateBlockState((draft) => {
        draft.activeStepId = stepId;
        draft.currentStep = currentStep;
      });
      void emitEvent({ eventType: EventTypeEnum.OnStepChange });
    },
  );

  const { appearance, content, activeStepId, currentStep } = computedBlockState;

  const prevContentType = usePrevious(content.type);
  useEffect(() => {
    if (prevContentType && prevContentType !== content.type) {
      // reset activeStepId and currentStep when content type changes
      updateBlockState((draft) => {
        draft.activeStepId = '';
        draft.currentStep = undefined;
      });
    }
  }, [content.type, prevContentType, updateBlockState]);

  if (content.type === StepperContentType.STATIC) {
    const { steps } = content;

    return (
      <StaticStepper
        activeStepId={activeStepId}
        appearance={appearance}
        currentStep={currentStep}
        dataAttributes={dataAttributes}
        defaultStepValue={content.defaultStepValue}
        onStepChange={onStepChange}
        steps={steps}
        updateBlockState={updateBlockState}
      />
    );
  }

  const { data } = content;

  return (
    <DynamicStepper
      activeStepId={activeStepId}
      appearance={appearance}
      currentStep={currentStep}
      data={data}
      dataAttributes={dataAttributes}
      onStepChange={onStepChange}
      stepMappings={content.stepMappings}
      updateBlockState={updateBlockState}
    />
  );
}

export default memo(withBlockSuspense(Stepper));
