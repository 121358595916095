import type { BlockComponentType, BlockComponentStateId } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';
import type { CommentInputVariant } from '../CommentList/types';
import type { enterKeyBehaviorEnum } from '../common/CommentMessageInputSchemas/schema';
import type { FileType } from '../FileUpload/types';

export enum MessageInputActionType {
  Standard = 'STANDARD',
  Custom = 'CUSTOM',
}

export enum MessageInputActionAlignment {
  Start = 'start',
  End = 'end',
}

export enum StandardActionsEnum {
  AddAttachments = 'addAttachments',
  SendMessage = 'sendMessage',
  EmojiPicker = 'emojiPicker',
}

export type ActionAppearanceConfig = {
  color: 'brand' | 'neutral' | 'danger';
  size: 'sm' | 'md' | 'lg';
  variant: 'solid' | 'outline' | 'soft';
};

export interface BaseAction {
  id: string;
  label: string;
  appearance: ActionAppearanceConfig;
  alignment: MessageInputActionAlignment;
}

export type CustomAction = BaseAction & {
  type: MessageInputActionType.Custom;
  icon: string;
};

export type AddAttachmentAction = BaseAction & {
  type: MessageInputActionType.Standard;
  action: StandardActionsEnum.AddAttachments;
  supportedFileTypes?: string[];
  maxFileSize?: number;
  multipleFiles?: boolean;
  icon: string;
};

export enum SendMessageActionVariant {
  Icon = 'icon',
  Label = 'label',
}

export type SendMessageAction =
  | (BaseAction & {
      variant: SendMessageActionVariant.Icon;
      type: MessageInputActionType.Standard;
      action: StandardActionsEnum.SendMessage;
      icon: string;
      allowDictation?: boolean;
    })
  | (BaseAction & {
      variant: SendMessageActionVariant.Label;
      type: MessageInputActionType.Standard;
      action: StandardActionsEnum.SendMessage;
      startIcon?: string;
      displayLabel: string;
      allowDictation?: boolean;
    });

export type EmojiPickerAction = BaseAction & {
  type: MessageInputActionType.Standard;
  action: StandardActionsEnum.EmojiPicker;
  icon: string;
  appearance: ActionAppearanceConfig & {
    pickerTheme?: 'light' | 'dark';
    emojiPreviewPosition?: 'top' | 'bottom' | 'none';
    pickerSize?: 'sm' | 'lg';
  };
};

export type StandardAction = AddAttachmentAction | SendMessageAction | EmojiPickerAction;

export type MessageInputAction = CustomAction | StandardAction;

type Content = {
  mode?: 'compact' | 'default';
  chatId?: string;
  variant?: CommentInputVariant;
  enterKeyBehavior?: enterKeyBehaviorEnum;
  autoFocus?: boolean;
  placeholder?: string;
  actions: MessageInputAction[];
  isSubmitDisabled?: boolean | string;
};

export type Appearance = {
  disabled?: boolean;
  styles?: Pick<
    StyleObject,
    | 'color'
    | 'fontSize'
    | 'fontWeight'
    | 'width'
    | 'maxWidth'
    | 'margin'
    | 'padding'
    | 'height'
    | 'borderRadius'
    | 'borderColor'
    | 'borderWidth'
    | 'minHeight'
    | 'backgroundColor'
    | 'maxHeight'
    | 'minWidth'
  >;
};

export type MessageInputComponentType = BlockComponentType<'MessageInput', Content, Appearance>;

export type MessageInputBlockStateType = Pick<
  MessageInputComponentType,
  'appearance' | 'componentType' | 'content'
> &
  BlockComponentStateId & {
    value: string | undefined;
    attachments?: FileType[];
    isUploadingAttachments?: boolean;
  };

export type MessageInputComputedBlockStateType = Pick<
  MessageInputComponentType,
  'appearance' | 'componentType'
> & {
  content: Omit<Content, 'isSubmitDisabled'> & {
    isSubmitDisabled: boolean;
  };
} & BlockComponentStateId & {
    value: string | undefined;
    attachments?: FileType[];
    isUploadingAttachments?: boolean;
  };
