import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import type { NestedTableComponentType } from '@unifyapps/defs/blocks/Table/NestedTable/types';
import { lazy } from 'react';
import { getTableEventTargetIds } from '../commons/getEventTargetIds';

const importNestedTableComponent = () =>
  import(
    /* webpackChunkName: "nested-table-block" */
    './NestedTable'
  );

const NestedTable = lazy(importNestedTableComponent);

class NestedTableBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'NestedTable';
  }

  getEventTargetIds(block: BlockType) {
    return getTableEventTargetIds(block);
  }

  getBlockControlMethods() {
    return [
      {
        id: 'clearChangeset' as const,
        label: 'Clear Changeset',
        method: (blockState: BlockStateUnionType) => {
          return {
            ...blockState,
            changesetMap: undefined,
            changesetList: undefined,
          } as BlockStateUnionType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['appearance', 'componentType', 'slots']),
        content: {
          ..._omit((block.component as NestedTableComponentType).content, ['addOns']),
          ...(block.component as NestedTableComponentType).content.addOns,
        },
        ..._pick((block.component as NestedTableComponentType).content, ['addOns']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return NestedTable;
  }

  preload() {
    return {
      blockPreload: importNestedTableComponent,
    };
  }
}

export default NestedTableBlockStateDefinition;
