import type { ValidationRules } from '@unifyapps/defs/blocks/Form/types';
import type { UIFilter } from '@unifyapps/defs/types/uiFilter';
import type { UiSchema } from '@rjsf/utils';
import type { DateTimeAddOns } from '@unifyapps/defs/types/date';
import type { StyleObject } from '@unifyapps/defs/types/styleObject';
import type { KeyValuePairList } from '../../rjsf/fields/KeyValuePairField/utils';

export const enum VariableType {
  String = 'STRING',
  TextArea = 'TEXTAREA', // Same as String but value is stored as 'textarea'
  Email = 'EMAIL', // Same as String but value is stored as 'email'
  Password = 'PASSWORD', // Same as String but value is stored as 'password
  RichText = 'RICH_TEXT',
  CodeEditor = 'CODE_EDITOR',
  Number = 'NUMBER',
  Otp = 'OTP',
  Integer = 'INTEGER',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  DateEpoch = 'DATE_EPOCH', // Same as Date but value is stored as epoch
  DateTimeEpoch = 'DATE_TIME_EPOCH', // Same as DateTime but value is stored as epoch
  Time = 'TIME',
  Boolean = 'BOOLEAN',
  Switch = 'SWITCH', // Same as Boolean, but the value is stored as 'switch' used to render toggle widget
  Array = 'ARRAY',
  Object = 'OBJECT',
  Slot = 'SLOT',
  SingleSelectDropdown = 'SINGLE_SELECT_DROPDOWN',
  MultiSelectDropdown = 'MULTI_SELECT_DROPDOWN',
  Radio = 'RADIO',
  Checkbox = 'CHECKBOX',
  AutoNumber = 'AUTO_NUMBER',
  File = 'FILE',
  Vector = 'VECTOR',
  FileUploader = 'FILE_UPLOADER',
  PhoneNumber = 'PHONE_NUMBER', // Same as String but value is stored as 'phone-number',
  KeyValuePair = 'KEY_VALUE_PAIR',
  JsonSchema = 'JSON_SCHEMA',
  Slider = 'SLIDER',
}

export type AutoGeneratedFieldDefinition = {
  prefix?: string;
  startFrom: number;
};

export type DataSourceForeignKeyReference = {
  dataSource?: Record<string, unknown>[];
  lookupOptions?: {
    mappedValue?: { key: string; path: string; originalPath: string; originalKey: string };
    mappedLabel?: { key: string; path: string; originalPath: string; originalKey: string };
    mappedColor?: { key: string; path: string; originalPath: string; originalKey: string };
    mappedIcon?: { key: string; path: string; originalPath: string; originalKey: string };
  };
};

export type StaticOptionsFormField = {
  optionsType?: 'STATIC';
};

export type LookupObjectsFormField = {
  optionsType?: 'LOOKUP';
  foreignKeyReference?: string;
};

export type LookupDataSourceFormField = {
  optionsType?: 'LOOKUP';
  foreignKeyReference?: DataSourceForeignKeyReference;
};

export type SchemaToFormDataUiSchema = UiSchema & {
  'ua:options'?: {
    otp?: unknown;
    timeStepSize?: number | undefined;
    minNumberOfLines?: number | undefined;
    maxNumberOfLines?: number | undefined;
    dateTimeAddOns?: DateTimeAddOns;
  };
};

export type Option = {
  const: string;
  title: string;
  icon?: string;
  color?: string;
};

// getting the path for fields for array and object, check for rjsf utils
export type SchemaAddModalFormFields = {
  field_name: string;
  field_label?: string;
  field_type: VariableType;
  array_type?: string;

  /**
   * String text-based inputs such as Text, Number, Phone Number, Date, DateTime etc.
   * and options-based inputs such as Single Select Dropdown, Multi Select Dropdown, Radio, Checkbox etc.
   *
   * Is a string for all except for Multi Select Dropdown, Checkbox
   */
  default_value?: unknown;

  icon?: string;
  help?: string;
  description?: string;
  placeholder?: string;
  disabled?: boolean;
  options?: (string | Option)[]; // options is used when optionsType is STATIC
  lookupAction?: string; // lookupAction is used when optionsType is LOOKUP
  hasDependentField?: boolean; // hasDependentField is used when optionsType is LOOKUP
  dependentField?: string; // dependentField is used when hasDependentField is true
  is_optional?: boolean;
  is_secure?: boolean;
  is_skipped?: boolean;
  is_masked?: boolean;
  is_hashed?: boolean; // used in object field form
  'ua:nest_under'?: string;
  searchable?: boolean; // used in object field form
  sortable?: boolean; // used in object field form
  filterable?: boolean; // used in object field form
  primaryKey?: boolean; // used in object field form
  nameField?: boolean; // used in object field form
  autoGeneratedFieldDefinition?: AutoGeneratedFieldDefinition; // used when field_type is AutoNumber
  foreignKeyConstraintEnforced?: boolean; // used in object field form when optionsType is LOOKUP
  // used when array_type is Vector
  dimension?: number;
  methodName?: string;
  engine?: string;
  spaceType?: string;
  encoder?: {
    type?: string;
    params?: KeyValuePairList;
  };
  countryCode?: { showCountryCode: boolean; isConfigurable?: boolean };
  timeStepSize?: number;
  minNumberOfLines?: number; // used in richtextEditor to set editor minHeight
  maxNumberOfLines?: number; // used in richtextEditor to allow scroll
  clientSearchable?: boolean; // used in object field form
  enableSelectAll?: boolean; // used in object field form
  efConstructionSize?: number;
  efSearchSize?: number;
  mBidirectionalLinks?: number;
  disablePast?: boolean; // used in Date field to disable past dates
  multipleFiles?: boolean; // used in FileUploader field to allow multiple files
  supportedFileTypes?: string[]; // used in FileUploader field to allow specific file types
  maxFiles?: number;
  maxFileSize?: number;
  maxRows?: string; // used in TextArea field to set max lines
  minRows?: string; // used in TextArea field to set min lines

  validationRules?: ValidationRules;
  visibility?:
    | { value: boolean; conditions: UIFilter }
    | { value: 'conditions'; conditions: { type: 'filter'; payload: UIFilter } };

  autocomplete?: string;

  allowedFieldTypes?: string[];

  // slider
  showNumberInput?: boolean;
  minimum?: number;
  maximum?: number;
  multipleOf?: number;

  // this is used to send any extraData, that can be used by backend
  additionalData?: unknown;

  richTextAddOns?: {
    toolbar?: string;
  };

  textFieldAddOns?: {
    prefixIcon?: { name?: string };
    suffixIcon?: { name?: string };
  };

  dateTimeAddOns?: DateTimeAddOns;

  emptyState?: {
    description?: string;
    actions?: Record<string, unknown>[];
  };

  otp?: NonNullable<SchemaToFormDataUiSchema['ua:options']>['otp'];

  addLabel?: string;

  // used to indicate whether multi select should accept custom values or not
  enableFreeSolo?: boolean;

  // for code editor
  language?: string;
  expandable?: boolean;
  allowDownload?: boolean;
  foreignKeyReference?: never;
  optionsDropdownStyles?: Pick<StyleObject, 'width' | 'minWidth' | 'maxWidth'>; //used in dropdown fields

  // radio
  orientation?: 'horizontal' | 'vertical';
} & (StaticOptionsFormField | LookupObjectsFormField | LookupDataSourceFormField);
