import Stack from '@unifyapps/ui/_components/Stack';
import SvgTrash01 from '@unifyapps/icons/outline/Trash01';
import SvgPencil01 from '@unifyapps/icons/outline/Pencil01';
import { useTranslation } from '@unifyapps/i18n/client';
import { Toolbar } from '../Toolbar';

interface LinkPreviewPanelProps {
  url: string;
  onEdit: () => void;
  onDelete: () => void;
}

function LinkPreviewPanel(props: LinkPreviewPanelProps) {
  const { url, onEdit, onDelete } = props;
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" className="gap-x-xs" direction="row">
      <a
        className="text-brand-tertiary hover:text-brand-secondary active:text-brand-secondary cursor-pointer truncate text-sm underline"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {url}
      </a>
      <Toolbar.Button
        Icon={SvgPencil01}
        isEditable
        onClick={onEdit}
        tooltip={t('common:Actions.Edit')}
      />
      <Toolbar.Button
        Icon={SvgTrash01}
        isEditable
        onClick={onDelete}
        tooltip={t('common:Actions.Delete')}
      />
    </Stack>
  );
}

export default LinkPreviewPanel;
