import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useComputeContext } from '../../context/ComputeContextProvider';
import { useGetInterfaceStoreState } from '../../stores/InterfaceStore';
import { useBlockRuntimeData } from '../../components/BlockRuntimeStateProvider';
import { useGetGlobalStateStoreState } from '../../stores/GlobalStateStore';
import { useInterfaceEntitiesMethods } from '../../components/InterfaceEntitiesMethodsProvider/InterfaceEntitiesMethodsProvider';
import { buildPartialComputeContext } from '../../context/ComputeContextProvider/ComputeContextProvider';
import { getCombinedBlockState } from './utils/getCombinedBlockState';
import getInterfaceRecordForState from './utils/getInterfaceRecordForState';
import { getCombinedDataSourceState } from './utils/getCombinedDataSourceState';
import { getCombinedPageVariables } from './utils/getCombinedPageVariables';
import { getCombinedBlockMethodsInBlocksState } from './utils/getCombinedBlockMethodsInBlocksState';
import { ComputeContextKeys } from './const';

export function useGetComputeContext() {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const blocksRuntimeState = useBlockRuntimeData() as Record<string, Record<string, unknown>>;
  const interfaceEntityMethods = useInterfaceEntitiesMethods();

  const getComputeContext = useCallback(() => {
    const globalStateStoreState = getGlobalStateStoreState();
    const { blocks, dataSources, pageVariables, ...restComputeContext } =
      buildPartialComputeContext(globalStateStoreState);

    const combinedDataSourceState = getCombinedDataSourceState({
      dataSourcesState: dataSources,
      dataSourceMethods: interfaceEntityMethods.dataSources,
    });

    const combinedPageVariables = getCombinedPageVariables({
      pageVariables,
      pageVariablesMethods: interfaceEntityMethods.pageVariables,
    });

    const blocksStateWithMethod = getCombinedBlockMethodsInBlocksState({
      blocksState: blocks,
      blocksMethods: interfaceEntityMethods.blocks,
    });

    const combinedBlockState = getCombinedBlockState({
      blocksState: blocksStateWithMethod,
      blocksRuntimeState,
    });

    const interfaceRecord = getInterfaceStoreState().interfaceRecord;

    // order of spread matters because partialComputeContext has additional data that we override with the combineDataSourcesState, combinePageVariables and combinedBlockState objects
    return {
      ...restComputeContext,
      ...combinedBlockState,
      ...combinedDataSourceState,
      ...combinedPageVariables,
      [ComputeContextKeys.RUNTIME_BLOCKS]: combinedBlockState.runtimeBlocks,
      [ComputeContextKeys.INTERFACE_RECORD]: getInterfaceRecordForState(interfaceRecord),
    };
  }, [
    blocksRuntimeState,
    getGlobalStateStoreState,
    getInterfaceStoreState,
    interfaceEntityMethods.blocks,
    interfaceEntityMethods.dataSources,
    interfaceEntityMethods.pageVariables,
  ]);

  return {
    getComputeContext,
  };
}

export function useReactiveComputeContext() {
  const blocksRuntimeState = useBlockRuntimeData() as Record<string, Record<string, unknown>>;

  const { computeContext } = useComputeContext();

  if (isEmpty(blocksRuntimeState)) {
    return { context: computeContext ?? {} };
  }

  const { blocksStateWithMethod, ...restComputeContext } = computeContext ?? {};

  const combinedBlockState = getCombinedBlockState({
    blocksState: blocksStateWithMethod,
    blocksRuntimeState,
  });

  const finalContext = {
    ...restComputeContext,
    ...combinedBlockState,
  };

  return { context: finalContext };
}
