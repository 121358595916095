import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type {
  ScannerBlockStateType,
  ScannerComponentType,
} from '@unifyapps/defs/blocks/Scanner/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type { Action } from '@unifyapps/defs/types/action';

const importScannerComponent = () =>
  import(
    /* webpackChunkName: "scanner-block" */
    './Scanner'
  );

const Scanner = lazy(importScannerComponent);

class ScannerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Scanner';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'pause' as const,
        label: 'Pause',
        method: (
          blockState: ScannerBlockStateType,
          action: Action,
          blockRef: { pause: () => void; resume: () => void },
        ) => {
          blockRef.pause();

          return blockState;
        },
      },
      {
        id: 'resume' as const,
        label: 'Resume',
        method: (
          blockState: ScannerBlockStateType,
          action: Action,
          blockRef: { pause: () => void; resume: () => void },
        ) => {
          blockRef.resume();

          return blockState;
        },
      },
      {
        id: 'clear' as const,
        label: 'Clear',
        method: (
          blockState: ScannerBlockStateType,
          action: Action,
          blockRef: { pause: () => void; resume: () => void },
        ) => {
          return {
            ...blockState,
            data: [] as string[],
          };
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ScannerComponentType, ['content', 'appearance', 'componentType']),
      data: [],
    });
  }

  getComponent() {
    return Scanner;
  }

  preload() {
    return {
      blockPreload: importScannerComponent,
    };
  }
}

export default ScannerBlockStateDefinition;
