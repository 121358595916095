import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalculator02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.667 4.6h4.667m0 4.533V11.4m0-4.533h.005M8 6.867h.006m-2.34 0h.007M8 9.133h.006m-2.34 0h.007M8 11.4h.006m-2.34 0h.007M4.5 2.333h7c.645 0 1.167.508 1.167 1.134v9.066c0 .626-.522 1.134-1.167 1.134h-7c-.644 0-1.166-.508-1.166-1.134V3.467c0-.626.522-1.134 1.166-1.134"
    />
  </svg>
);
export default SvgCalculator02;
