export const BASE_ROUTE = '/ai-models';

export enum ModelsManagerTabsEnum {
  Models = 'models',
  Playground = 'playground',
}

export const getModelsManagerTabRoute = (tab: ModelsManagerTabsEnum) => `${BASE_ROUTE}/${tab}`;

export const getCreateModelRoute = () =>
  `${getModelsManagerTabRoute(ModelsManagerTabsEnum.Models)}/create`;

export const getEditModelRoute = (modelId: string) =>
  `${getModelsManagerTabRoute(ModelsManagerTabsEnum.Models)}/edit/${modelId}`;
