import { Op } from '@unifyapps/network/generated/models/op';
import type { UIFilter } from '@unifyapps/network/generated/models/uIFilter';
import { current, type WritableDraft } from 'immer';
import { isEmpty } from 'lodash';

function removeValueFromMissingOrExistsOperator(formData: WritableDraft<UIFilter>): void {
  if (formData.filter?.operator === Op.MISSING || formData.filter?.operator === Op.EXISTS) {
    formData.filter.value = undefined;
  }
}

function isFilterValid(filterBlock: WritableDraft<UIFilter>): boolean {
  let isPresent = false;

  if (filterBlock.filter) {
    // If the user doesn't input anything, the property will default to an empty string
    isPresent = filterBlock.property !== undefined && filterBlock.property !== '';
  }

  if (filterBlock.filters?.length && filterBlock.operator) {
    isPresent = true;
  }

  if (!isEmpty(filterBlock.resolvedFilter?.filter)) {
    isPresent = true;
  }

  return isPresent;
}

function sanitizeFilters(formData?: WritableDraft<UIFilter>) {
  if (formData?.filters) {
    for (const filter of formData.filters) {
      sanitizeFilters(filter);
    }

    formData.filters = formData.filters.filter((filter) => isFilterValid(filter));

    if (!isFilterValid(current(formData))) {
      Object.keys(formData).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- we need to delete the key
        delete formData[key];
      });
    }
  } else if (formData?.filter?.operator) {
    removeValueFromMissingOrExistsOperator(formData);
  }
}

export default sanitizeFilters;
