import type { BeforeRequestHook } from 'ky';
import { getOriginFromReq, isServer } from '../../utils';
import { requestWithNewUrl } from '../../../request';

const INTERNAL_SVC_HOST = {
  '/api/': process.env.API_GLOBAL_SVC_SERVICE_HOST ?? '',
  '/auth/': process.env.AUTH_GLOBAL_SVC_SERVICE_HOST ?? '',
};

const INTERNAL_SVC_PORT = {
  '/api/': process.env.API_GLOBAL_SVC_SERVICE_PORT_EXTERNAL ?? '',
  '/auth/': process.env.AUTH_GLOBAL_SVC_SERVICE_PORT_EXTERNAL ?? '',
};

export const getInternalSvcRequest: BeforeRequestHook = async (request) => {
  if (isServer() && !process.env.DISABLE_INTERNAL_SVC_ROUTING) {
    // req from nextjs server req
    const origin = await getOriginFromReq();
    // url to fetch
    const reqUrl = new URL(request.url);
    // Check if the request is for microservices
    if (reqUrl.origin === origin) {
      for (const key in INTERNAL_SVC_HOST) {
        // Check if the request is for internal service, and it's env is present
        if (reqUrl.pathname.startsWith(key) && INTERNAL_SVC_HOST[key] && INTERNAL_SVC_PORT[key]) {
          const internalHost = `http://${INTERNAL_SVC_HOST[key]}:${INTERNAL_SVC_PORT[key]}`;
          // Replace the origin with internal service host
          const internalUrl = request.url.replace(origin, internalHost);
          request.headers.set('host', reqUrl.host);
          // x-forwarded-host is preferred over host header and so setting it. for this it is not overridden by proxy
          request.headers.set('X-Forwarded-Host', reqUrl.host);
          return requestWithNewUrl(request, internalUrl);
        }
      }
    }
  }
};
