import { createContext, useContext, useMemo } from 'react';
import { invariant } from 'ts-invariant';
import { useBlocksInActiveInterfacePage, useInterfaceStore } from '../../stores/InterfaceStore';
import { useDataSourceRecords } from '../../stores/DataSourceRecordStore';
import { useRegistryContext } from '../RegistryProvider';
import { OnActionType, useActionsContext } from '../ActionsProvider/context';
import { getBlocksMethods } from './utils/getBlocksMethods';
import { getDataSourcesMethods } from './utils/getDataSourcesMethods';
import { getPageVariablesMethods } from './utils/getPageVariablesMethods';

export const InterfaceEntitiesMethodsContext = createContext<{
  blocks: Record<string, unknown>;
  dataSources: Record<string, unknown>;
  pageVariables: Record<string, unknown>;
} | null>(null);

export const useInterfaceEntitiesMethods = () => {
  const context = useContext(InterfaceEntitiesMethodsContext);
  if (!context) {
    throw new Error(
      'useInterfaceEntitiesMethods must be used within a InterfaceEntitiesMethodsProvider',
    );
  }
  return context;
};

function InterfaceEntitiesMethodsProvider({ children }: { children: React.ReactNode }) {
  const { registry } = useRegistryContext();

  const blocks = useBlocksInActiveInterfacePage();
  const dataSources = useDataSourceRecords();
  const pageVariables = useInterfaceStore().use.page.pageVariables();

  const { doAction: onAction } = useActionsContext();

  invariant(onAction, 'onAction must be defined');

  const value = useMemo(() => {
    return {
      blocks: getBlocksMethods({ blocks, onAction, blockRegistry: registry }),
      dataSources: getDataSourcesMethods({ dataSources, onAction }),
      pageVariables: getPageVariablesMethods({ pageVariables, onAction }),
    };
  }, [blocks, dataSources, onAction, pageVariables, registry]);

  return (
    <InterfaceEntitiesMethodsContext.Provider value={value}>
      {children}
    </InterfaceEntitiesMethodsContext.Provider>
  );
}

export default InterfaceEntitiesMethodsProvider;
