import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useBlocksFromPage } from '../../../../stores/InterfaceStore';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import { getContextualDialogsForBlocks } from '../../../../stores/GlobalStateStore/utils/initial';

export function useCollectContextualDialogForBlocks() {
  const blocks = useBlocksFromPage();
  const contextualDialogsForBlocks = useGlobalStateStore().use.contextualDialogsForBlocks();
  const { setContextualDialogForBlocks } = useGlobalStateStore().use.actions();

  useEffect(() => {
    if (!blocks) return;
    const newContextualDialogsForBlocks = getContextualDialogsForBlocks(blocks);
    if (!isEqual(newContextualDialogsForBlocks, contextualDialogsForBlocks)) {
      setContextualDialogForBlocks(newContextualDialogsForBlocks);
    }
  }, [blocks, contextualDialogsForBlocks, setContextualDialogForBlocks]);
}
