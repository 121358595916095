export const Modules = {
  AccessProfile: 'ACCESS_PROFILE',
  ai_agent: 'ai_agent',
  AlertDefinition: 'ALERT_DEFINITION',
  APIClient: 'API_CLIENT',
  APIEndpointCollection: 'API_ENDPOINT_COLLECTION',
  APIPolicy: 'API_POLICY',
  ApiReport: 'API_REPORT',
  api_platform: 'API_ENDPOINT',
  BusinessHolidays: 'e_business_holiday',
  BusinessHours: 'e_business_hours',
  CallableInterface: 'CALLABLE_INTERFACE',
  Changeset: 'CHANGESET',
  ChangesetRequest: 'CHANGESET_REQUEST',
  Connection: 'CONNECTION',
  ConnectorDefinition: 'CONNECTOR_DEFINITION',
  Copilot: 'CO_PILOT',
  CodeSnippet: 'e_code_snippet',
  CustomerEnvironment: 'CUSTOMER_ENVIRONMENT',
  data_catalog: 'e_data_catalog_asset',
  EntityType: 'ENTITY_TYPE',
  EnvironmentVariables: 'e_env_variables',
  e_campaign: 'e_campaign',
  e_streams: 'e_streams',
  e_template: 'e_template',
  NoCodeInterface: 'e_interface',
  Security: 'IDENTITY_PROVIDER',
  Notification: 'NOTIFICATIONS',
  overview: 'PROJECT_OVERVIEW',
  PipelineConfig: 'DATA_PIPELINE',
  Project: 's_project',
  Reporting: 'REPORTING',
  Roles: 'ROLE_DEFINITION',
  runtime_environments: 'RemoteRuntimeEnvironment',
  SegmentDefinition: 'SEGMENT_DEFINITION',
  Teams: 'USER_GROUP',
  UnifiedEntityAccessControl: 'UNIFIED_ENTITY_ACCESS_CONTROL',
  UnifiedEntityModel: 'UNIFIED_ENTITY_MODEL',
  User: 'USER',
  WorkflowDefinition: 'WORKFLOW_DEFINITION',
  WorkflowLibrary: 'TEMPLATE',
  Settings: 'settings',
  UserMenu: 'userMenu',
  decision_tables: 'e_decision_table',
  AIModels: 'ai_agent_llm_model', // TODO: check
  DataRetention: 'DATA_RETENTION',
  CustomModels: 'CUSTOM_MODELS',
  ThemeBuilder: 'THEME_BUILDER',
} as const;

export type ModuleValues = (typeof Modules)[keyof typeof Modules];

// NOTE: This is needed because we do not get entity permissions for all modules
export const ModulesWithoutEntityPermissions = [
  Modules.Notification,
  Modules.AIModels, // TODO: check
] as ModuleValues[];
