import { useKey } from 'react-use';
import type { KeyBindingsType } from '../types';

function matchesKeyBinding(event: Event, keyBinding: KeyBindingsType) {
  const { action, uniqueKey, ...bindingProps } = keyBinding;

  const bindingPropsArray = Object.entries(bindingProps);
  return bindingPropsArray.every(([key, value]) => {
    return event[key] === value;
  });
}

export default function KeyBindingHandler({
  keyBinding,
  handleAction,
  inputRef,
  isMentionListActiveRef,
}: {
  keyBinding: KeyBindingsType;
  handleAction: (actionType: string, event: KeyboardEvent) => void;
  inputRef: React.MutableRefObject<HTMLDivElement | null>;
  isMentionListActiveRef?: React.MutableRefObject<boolean>;
}) {
  useKey(
    (event: KeyboardEvent) => {
      return matchesKeyBinding(event, keyBinding);
    },
    (event: KeyboardEvent) => {
      if (isMentionListActiveRef?.current) {
        return;
      }
      handleAction(keyBinding.action, event);
    },
    {
      event: 'keydown',
      target: inputRef.current,
    },
  );

  return null;
}
