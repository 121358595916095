import { useKey } from 'react-use';
import { useDataSourceRecordStore } from '../stores/DataSourceRecordStore';
import { useActiveBlockFromPage, useGetInterfaceStoreState } from '../stores/InterfaceStore';
import { useGetGlobalStateStoreState, useGlobalStateStore } from '../stores/GlobalStateStore';
import { useInterfaceDrawerStore } from '../stores/InterfaceDrawerStore';
import { useDependencyGraphProvider } from '../context/DependencyGraphContext/DependencyGraphContext';
import { useGetComputeContext } from './computeContext';

export default function useDebugging() {
  const blocksState = useGlobalStateStore().use.blocks();
  const activeBlockFromPage = useActiveBlockFromPage();
  const dataSourcesState = useGlobalStateStore().use.dataSources();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getComputeContext } = useGetComputeContext();
  const dataSourceRecord = useDataSourceRecordStore().getState;
  const { graphManager, subscriptionManager } = useDependencyGraphProvider();
  const getInterfaceDrawerStore = useInterfaceDrawerStore().getState;

  useKey(
    'B',
    () => {
      console.log('blockState', blocksState);
    },
    {},
    [blocksState],
  );

  useKey(
    'G',
    () => {
      console.log('graph', graphManager);
      console.log('subscription', subscriptionManager);
    },
    {},
    [dataSourcesState],
  );

  useKey(
    'D',
    () => {
      console.log('dataSourcesState', dataSourcesState);
    },
    {},
    [dataSourcesState],
  );

  useKey(
    'C',
    () => {
      console.log('context', getComputeContext());
    },
    {},
    [getComputeContext],
  );

  useKey(
    'I',
    () => {
      console.log('interfaceStoreState', getInterfaceStoreState());
    },
    {},
    [getInterfaceStoreState],
  );

  useKey(
    'S',
    () => {
      console.log('globalState', getGlobalStateStoreState());
      console.log('interfaceStoreState', getInterfaceStoreState());
      console.log('dataSourceRecord', dataSourceRecord());
    },
    {},
    [getGlobalStateStoreState, getInterfaceStoreState],
  );

  useKey(
    'A',
    () => {
      console.log('activeBlock', activeBlockFromPage);
    },
    {},
    [activeBlockFromPage],
  );

  useKey(
    'R',
    () => {
      console.log('drawerStore', getInterfaceDrawerStore());
    },
    {},
    [getInterfaceDrawerStore],
  );
}
