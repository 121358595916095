import { extractPropertyPaths } from '../../dependency/utils/extractPropertyPaths';
import { isDynamicValue, stringToJS } from './dynamicBindings';
import { defaultUpdater } from './recursiveUpdate';
import { indirectEvalExpression } from './indirectEval';
import { isNoCodeEntityId } from './isNoCodeEntityId';

// though this is not an entity, ig used in ua entity table
const OTHER_RESERVED_KEYWORDS = ['currentRow'];

const isValidEntityId = (entityId: string) => {
  return isNoCodeEntityId(entityId) || OTHER_RESERVED_KEYWORDS.includes(entityId);
};

export const isEntityIdPresentInValue = (expression: string) => {
  const { result } = extractPropertyPaths(expression, isValidEntityId);
  return Boolean(Object.keys(result).length);
};

export const doNotEvaluateExpression = (_: string) => {
  return false;
};

export function evaluateResult<T>({
  context,
  resolvedStringToEvaluate,
  shouldEvaluateResult,
}: {
  context: object;
  resolvedStringToEvaluate: string;
  shouldEvaluateResult: (result: string) => boolean;
}): T | undefined {
  try {
    const result = indirectEvalExpression(resolvedStringToEvaluate, context);

    // if the result value is also a dynamic value, then evaluate it recursively
    if (typeof result === 'string' && shouldEvaluateResult(result)) {
      return oldEvaluateExpression({
        context,
        expression: result,
        shouldEvaluateResult,
      });
    }

    return result as T;
  } catch (e) {
    // console.error(`Error in evaluating expression ${resolvedStringToEvaluate} with context`, context, e);
  }
}

export function optimisedEvaluateResult<T>({
  context,
  resolvedStringToEvaluate,
  shouldEvaluateResult,
  expressionEvaluator,
}: {
  context: object;
  resolvedStringToEvaluate: string;
  shouldEvaluateResult: (result: string) => boolean;
  expressionEvaluator: (script: string) => unknown;
}): T | undefined {
  try {
    const result = expressionEvaluator(resolvedStringToEvaluate);
    // if the result value is also a dynamic value, then evaluate it recursively
    if (typeof result === 'string' && shouldEvaluateResult(result)) {
      return optimisedEvaluateExpression({
        context,
        expression: result,
        shouldEvaluateResult,
        expressionEvaluator,
      });
    }

    return result as T;
  } catch (e) {
    // console.error(`Error in evaluating expression ${resolvedStringToEvaluate} with context`, context, e);
  }
}

function oldEvaluateExpression<T>({
  context,
  expression,
  shouldEvaluateResult = isDynamicValue,
}: {
  expression: string;
  context: object;
  // this flag is used to resolve the result of the expression if it is a dynamic value
  // it keeps on evaluating the expression until it is not a dynamic value anymore
  shouldEvaluateResult?: (result: string) => boolean;
}): T | undefined {
  if (!isDynamicValue(expression)) return expression as T;

  const toBeResolvedJS = stringToJS(expression);
  const resolvedStringToEvaluate = defaultUpdater(context, toBeResolvedJS, shouldEvaluateResult) as
    | string
    | undefined;

  if (resolvedStringToEvaluate === undefined) return undefined;

  return evaluateResult<T>({
    context,
    resolvedStringToEvaluate,
    shouldEvaluateResult,
  });
}

function optimisedEvaluateExpression<T>({
  context,
  expression,
  shouldEvaluateResult = isDynamicValue,
  expressionEvaluator,
}: {
  expression: string;
  context: object;
  // this flag is used to resolve the result of the expression if it is a dynamic value
  // it keeps on evaluating the expression until it is not a dynamic value anymore
  shouldEvaluateResult?: (result: string) => boolean;
  expressionEvaluator: (script: string) => unknown;
}): T | undefined {
  if (!isDynamicValue(expression)) return expression as T;

  const toBeResolvedJS = stringToJS(expression);

  const resolvedStringToEvaluate = defaultUpdater(context, toBeResolvedJS, shouldEvaluateResult) as
    | string
    | undefined;

  if (resolvedStringToEvaluate === undefined) return undefined;

  return optimisedEvaluateResult<T>({
    context,
    resolvedStringToEvaluate,
    shouldEvaluateResult,
    expressionEvaluator,
  });
}

export { oldEvaluateExpression, optimisedEvaluateExpression };
