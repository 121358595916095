import { Loader } from '@unifyapps/ui/components/Loader';
import type { LoaderComponentProps } from './types';

function DefaultLoader(props: LoaderComponentProps) {
  const { computedBlockState } = props;
  const { appearance } = computedBlockState;

  return <Loader size={appearance?.size} variant="arc" />;
}

export default DefaultLoader;
