import { produce } from 'immer';
import { invariant } from 'ts-invariant';
import type {
  ArrayTypePageVariable,
  BooleanTypePageVariable,
  NumberTypePageVariable,
  ObjectTypePageVariable,
  PageVariable,
  StringTypePageVariable,
} from '@unifyapps/defs/types/pageVariable';
import { VARIABLE_PREFIX } from '../../../const';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getIdWithPrefix } from '../../../../utils/id';

export type PageVariableActionProps =
  | {
      operation: 'CREATE';
      payload: (
        | Omit<StringTypePageVariable, 'id' | 'name' | 'createdTime'>
        | Omit<NumberTypePageVariable, 'id' | 'name' | 'createdTime'>
        | Omit<BooleanTypePageVariable, 'id' | 'name' | 'createdTime'>
        | Omit<ArrayTypePageVariable, 'id' | 'name' | 'createdTime'>
        | Omit<ObjectTypePageVariable, 'id' | 'name' | 'createdTime'>
      ) & {
        name?: string;
      };
    }
  | {
      operation: 'DELETE';
      payload: {
        pageVariableId: string;
      };
    }
  | {
      operation: 'UPDATE';
      payload: {
        pageVariableId: string;
        updatedPageVariable: PageVariable;
      };
    };

const getPageVariableActions =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ operation, payload }: PageVariableActionProps) => {
    const { get, set, setPageVariableState } = storeArgs;
    const state = get();
    const { incrementVariableCounter } = state.actions;
    const activePageId = state.activeInterfacePageId;

    switch (operation) {
      case 'CREATE': {
        const newVariableId = getIdWithPrefix(VARIABLE_PREFIX);
        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          const count = currentPage.properties.metadata?._variableCounter;
          const newCount = (count ?? 0) + 1;

          if (!currentPage.properties.pageVariables) {
            currentPage.properties.pageVariables = {
              [newVariableId]: {
                ...payload,
                id: newVariableId,
                name: payload.name ?? `Variable_${newCount}`,
                createdTime: Number(new Date()),
              },
            };
          } else {
            currentPage.properties.pageVariables[newVariableId] = {
              ...payload,
              id: newVariableId,
              name: payload.name ?? `Variable_${newCount}`,
              createdTime: Number(new Date()),
            };
          }

          draftState.touchedJavascriptEntities.add(newVariableId);
        });

        set(newState);
        setPageVariableState(newVariableId, {
          id: newVariableId,
          name: newVariableId,
          value: payload.initialValue,
        });

        incrementVariableCounter();
        return newVariableId;
      }
      case 'DELETE': {
        const { pageVariableId } = payload;

        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          if (currentPage.properties.pageVariables?.[pageVariableId]) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- dynamic delete is required here
            delete currentPage.properties.pageVariables[pageVariableId];
          }
          draftState.touchedJavascriptEntities.add(pageVariableId);
        });

        set(newState);
        setPageVariableState(pageVariableId, undefined);
        break;
      }
      case 'UPDATE': {
        const { pageVariableId, updatedPageVariable } = payload;

        const newState = produce(state, (draftState) => {
          const draftPageVariables =
            draftState.interfacePages[activePageId].properties.pageVariables;

          if (!draftPageVariables?.[pageVariableId]) {
            return;
          }

          draftPageVariables[pageVariableId] = updatedPageVariable;

          draftState.touchedJavascriptEntities.add(pageVariableId);
        });

        set(newState);

        setPageVariableState(pageVariableId, {
          id: pageVariableId,
          name: updatedPageVariable.name,
          value: updatedPageVariable.initialValue,
        });

        break;
      }
    }
  };

export default getPageVariableActions;
