import type { StoreApi } from 'zustand/vanilla';
import _omit from 'lodash/omit';
import type { GlobalStateStore } from '../types';

export type ParallelUpdatesActionProps =
  | {
      operation: 'ADD_IN_PARALLEL_UPDATES';
      payload: {
        user: {
          userId: string;
          userName: string;
        };
      };
    }
  | {
      operation: 'REMOVE_FROM_PARALLEL_UPDATES';
      payload: {
        user: {
          userId: string;
          userName: string;
        };
      };
    }
  | {
      operation: 'SKIP_PARALLEL_UPDATES';
      payload: undefined;
    };

const getParallelUpdatesAction =
  (set: StoreApi<GlobalStateStore>['setState']) =>
  ({ operation, payload }: ParallelUpdatesActionProps) => {
    switch (operation) {
      case 'ADD_IN_PARALLEL_UPDATES':
        set((state) => {
          return {
            ...state,
            live: {
              ...state.live,
              parallelUpdates: {
                ...state.live.parallelUpdates,
                updates: state.live.parallelUpdates.updates[payload.user.userId]
                  ? state.live.parallelUpdates.updates
                  : {
                      ...state.live.parallelUpdates.updates,
                      [payload.user.userId]: payload.user,
                    },
              },
            },
          };
        });
        break;
      case 'REMOVE_FROM_PARALLEL_UPDATES':
        set((state) => {
          const newUpdates = _omit({ ...state.live.parallelUpdates.updates }, [
            payload.user.userId,
          ]);

          return {
            ...state,
            live: {
              ...state.live,
              parallelUpdates: {
                ...state.live.parallelUpdates,
                updates: newUpdates,
              },
            },
          };
        });
        break;
        break;
      case 'SKIP_PARALLEL_UPDATES':
        set((state) => {
          return {
            ...state,
            live: {
              ...state.live,
              parallelUpdates: {
                skip: true,
                updates: {},
              },
            },
          };
        });
        break;
      default:
        break;
    }
  };

export default getParallelUpdatesAction;
