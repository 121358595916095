import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { PageFunction } from '@unifyapps/defs/types/pageFunction';
import type { UnsavedDataSource } from '../../stores/DataSourceRecordStore';
import { useGetInterfaceStoreState } from '../../stores/InterfaceStore';
import type {
  DependencyGraphContextType,
  DependencyGraphProviderProps,
  DependencyGraphStoreRefType,
} from './types';
import {
  blockDependencyBuilder,
  dataSourceDependencyBuilder,
  pageFunctionDependencyBuilder,
} from './builders';
import type { EntityListType } from './hooks/useBuildEntitiesForDependencies';
import { useBuildEntitiesForDependencies } from './hooks/useBuildEntitiesForDependencies';
import { useHandleSavedDependencies } from './hooks/useHandleSavedDependencies';

const DependencyGraphContext = createContext<DependencyGraphContextType | null>(null);

export function DependencyGraphProvider({
  children,
  graphManager,
  subscriptionManager,
  actions,
  dependencyGraphStoreRef,
}: React.PropsWithChildren<DependencyGraphProviderProps>) {
  const isInitialized = useRef(false);
  const [isRebuildingGraph, setIsRebuildingGraph] = useState(false);
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const { getEntitiesForDependencies } = useBuildEntitiesForDependencies();

  const { handleSavedDependencies, buildNewDependencies } = useHandleSavedDependencies();

  const buildGraph = useCallback(
    (entityList: EntityListType) => {
      // Now build/update the graph from allEntities:
      // 1. Clear graph and re-add nodes
      graphManager.reset();

      // This boolean indicates that we don't need to calc the dependency graph, we can use it from each block dependsOn
      const activeInterfacePage =
        getInterfaceStoreState().interfacePages[getInterfaceStoreState().activeInterfacePageId];

      const isAllDepsCalculated = Boolean(
        activeInterfacePage.properties.metadata?.flags?.isAllDepsCalculated,
      );

      // 3. Build the dependency graph for each entity
      if (isAllDepsCalculated) {
        handleSavedDependencies(graphManager);
      } else {
        buildNewDependencies({
          entityList,
          graphManager,
        });
      }

      // TODO: update all entities condPaths, dynPaths

      // 4. Run middlewares to handle cases of entity data sources, etc.

      // 5. Notify all subscribers
      subscriptionManager.notifyAll();
    },
    [
      buildNewDependencies,
      getInterfaceStoreState,
      graphManager,
      handleSavedDependencies,
      subscriptionManager,
    ],
  );

  const buildEntityDependency = useCallback<DependencyGraphStoreRefType['buildEntityDependency']>(
    (entity: unknown, entityType) => {
      switch (entityType) {
        case 'blocks':
          return blockDependencyBuilder({ blockState: entity as BlockStateUnionType });
        case 'pageFunctions':
          return pageFunctionDependencyBuilder({ pageFunction: entity as PageFunction });
        case 'dataSources':
          return dataSourceDependencyBuilder({
            dataSource: entity as UnsavedDataSource,
          });
      }
    },
    [],
  );

  const shouldRebuildGraph = useCallback(() => {
    setIsRebuildingGraph(true);
  }, []);

  const refReturn = useMemo(() => {
    return {
      buildEntityDependency,
      shouldRebuildGraph,
    };
  }, [buildEntityDependency, shouldRebuildGraph]);

  useImperativeHandle(dependencyGraphStoreRef, () => {
    return refReturn;
  }, [refReturn]);

  if (!isInitialized.current) {
    const { groupedEntityList } = getEntitiesForDependencies();
    buildGraph(groupedEntityList);
    isInitialized.current = true;
  }

  useEffect(() => {
    // when we have to update the graph
    if (isRebuildingGraph) {
      const { groupedEntityList } = getEntitiesForDependencies();
      buildGraph(groupedEntityList);
      setIsRebuildingGraph(false);
    }
  }, [buildGraph, getEntitiesForDependencies, isRebuildingGraph]);

  const value = useMemo<DependencyGraphContextType>(() => {
    return {
      graphManager,
      subscriptionManager,
      actions,
      ...refReturn,
    };
  }, [actions, graphManager, refReturn, subscriptionManager]);

  return (
    <DependencyGraphContext.Provider value={value}>{children}</DependencyGraphContext.Provider>
  );
}

export function useDependencyGraphProvider() {
  const context = useContext(DependencyGraphContext);

  if (!context) {
    throw new Error('DependencyGraphProvider must be used to access the dependency graph');
  }

  return context;
}
