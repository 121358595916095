import { useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';

/**
 *
 * @param styleProperties - This is an array of CSS properties that we want to compute for a given node.
 * @returns callbackRef - This is a ref that should be passed to the node whose styles we want to compute.
 * @returns styleState - This is an object that contains the computed styles for the given node.
 */
export function useGetComputedStyles({
  styleProperties,
}: {
  styleProperties: (keyof CSSStyleDeclaration)[];
}) {
  const [styleState, setStyleState] = useState<
    Record<keyof CSSStyleDeclaration, string> | undefined
  >();

  const callbackRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        const computedStyles = window.getComputedStyle(node);
        const updatedStyleState = {} as Record<keyof CSSStyleDeclaration, string>;

        styleProperties.forEach((key) => {
          const computedValue = computedStyles.getPropertyValue(key as string);
          updatedStyleState[key] = computedValue;
        });

        if (!isEqual(updatedStyleState, styleState)) {
          setStyleState(updatedStyleState);
        }
      }
    },
    [styleProperties, styleState],
  );

  return { callbackRef, styleState };
}
