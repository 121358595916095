export enum DeviceVariantType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export const deviceTypes = Object.values(DeviceVariantType);

export enum MobileVariant {
  Iphone14AndIphone15ProMax = 'iphone14Pro',
  Iphone13And14 = 'iphone14',
  Iphone8 = 'iphone8',
  IphoneX = 'iphoneX',
  GooglePixel6Pro = 'googlePixel6Pro',
  GooglePixel = 'googlePixel',
  GalaxyS8 = 'galaxyS8',
}

export enum DesktopVariant {
  Laptop1366x709 = 'laptop1366x709',
  Laptop1440x834 = 'laptop1440x834',
  Desktop1680x1050 = 'desktop1680x1050',
  Desktop1920x1080 = 'desktop1920x1080',
  Desktop2560x1440 = 'desktop2560x1440',
}

export const DEFAULT_MOBILE_VARIANT = MobileVariant.Iphone14AndIphone15ProMax;
export const DEFAULT_DESKTOP_VARIANT = DesktopVariant.Laptop1440x834;
export const DEFAULT_DEVICE_VARIANT = DeviceVariantType.DESKTOP;

export type DeviceDetails = {
  base: DeviceVariantType;
};
