import { useMemo } from 'react';
import _pick from 'lodash/pick';
import { fallbackLng } from '@unifyapps/i18n/settings';
import type { CurrentUserDetails } from '@unifyapps/network/generated/models/currentUserDetails';
import { getGetApiUserContextQueryKey } from '@unifyapps/network/generated/user-context-rest-api/user-context-rest-api';
import { useQueryClient } from '@unifyapps/network/react-query';
import userStore from '../../../auth/userStore';

const KEYS_TO_PICK = [
  'id',
  'email',
  'name',
  'username',
  'contactDetails',
  'customProperties',
  'roles',
  'ssoLogin',
  'locale',
];

export function useGetUserContext() {
  const queryClient = useQueryClient();
  const data = userStore.use.currentUserDetails();
  const user = useMemo(() => {
    if (data?.user) return data.user;
    // adding a fallback to the queryClient in case the userStore is not yet populated
    // such that stores are initialized with the correct data
    return queryClient.getQueryData<CurrentUserDetails>(getGetApiUserContextQueryKey())?.user;
  }, [data?.user, queryClient]);

  const userContext = useMemo(
    () => (user ? { locale: fallbackLng, ..._pick(user, KEYS_TO_PICK) } : undefined),
    [user],
  );
  return { userContext };
}
