import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import {
  useFindEntityById,
  useSearchEntities,
} from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import { type InterfacePageEntity } from '@unifyapps/defs/types/page';
import { InterfaceClient } from '../../../../../stores/InterfaceStore';
import { useFetchDataSourcesByComponentId } from '../useFetchDataSourcesByComponentId';
import { useInterfaceModules } from '../useFetchInterfaceModules';
import InterfaceHelper from '../../../../../stores/InterfaceStore/helper/InterfaceHelper';
import { getLookupInterfaceQueryFilter } from '../../../../../utils/interfaceQueryFilters';
import { useFetchInterfaceNamespaces } from '../useFetchInterfaceNamespaces';
import type { UseFetchResourcesHookType } from './type';

const useFetchInterfaceResources: UseFetchResourcesHookType = ({
  interfaceId,
  interfaceComponent,
  client,
  mode,
}) => {
  const {
    data: interfaceRecord,
    isLoading: isLoadingInterfaceRecord,
    error: errorInterfaceRecord,
  } = useFindEntityById<InterfaceEntity>(UAEntityTypes.Interface, interfaceId, {
    query: {
      enabled: Boolean(interfaceId) && client === InterfaceClient.UA_PLATFORM,
      retry: 3,
    },
  });

  const {
    data: pageEntities,
    isLoading: isLoadingPage,
    error: errorPage,
  } = useSearchEntities(
    UAEntityTypes.Component,
    getLookupInterfaceQueryFilter({ interfaceId, interfaceComponent }),
    {
      query: {
        enabled: Boolean(interfaceId),
        retry: 3,
      },
    },
  );

  const interfacePage = pageEntities?.objects?.[0] as InterfacePageEntity | undefined;
  // If we're getting id from interfaceComponent, use that, otherwise use id from interfacePage
  const interfacePageId = interfaceComponent.id || interfacePage?.id || '';

  const {
    isLoading: isLoadingInterfaceNamespaces,
    error: errorInterfaceNamespaces,
    commonNamespace,
    interfaceComponentNamespace,
  } = useFetchInterfaceNamespaces({
    interfaceId,
    interfacePageId,
    mode,
    interfaceRecord,
  });

  const { dataSources, errorDS, isLoadingDS } = useFetchDataSourcesByComponentId(interfacePageId);

  const {
    interfaceModulesMap,
    isLoading: isLoadingInterfaceModules,
    error: interfaceModulesError,
  } = useInterfaceModules({
    interfaceId,
  });

  const _isLoading =
    isLoadingInterfaceRecord ||
    isLoadingPage ||
    isLoadingDS ||
    isLoadingInterfaceModules ||
    isLoadingInterfaceNamespaces;

  // Uncomment this line to debug ssr -- they all should be prefetched i.e. all should be false
  // console.log(
  //   `isLoading:${_isLoading} isLoadingInterfaceRecord:${isLoadingInterfaceRecord} ; isLoadingPage:${isLoadingPage}; isLoadingDS:${isLoadingDS}; isLoadingInterfaceModules:${isLoadingInterfaceModules} `,
  // );

  return {
    interfaceModulesMap,
    isLoading: _isLoading,
    error:
      errorInterfaceRecord ||
      errorPage ||
      errorDS ||
      interfaceModulesError ||
      errorInterfaceNamespaces,
    data: interfaceRecord,
    dataSources,
    interfacePage,
    interfaceComponentNamespace,
    commonNamespace,
  };
};

export default useFetchInterfaceResources;
