import { memo } from 'react';
import { clsx } from 'clsx';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import type { FeaturedIconColors } from '@unifyapps/ui/components/FeaturedIcon';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import type { IconComponentType } from '@unifyapps/ui/types';
import { cva } from 'class-variance-authority';
import { useModalStateProvider } from '../useModalStateProvider';
import type { ModalVariant } from '../Modal';
import type { ModalHeaderProps } from './ModalHeaderBase';
import ModalHeaderBase from './ModalHeaderBase';

type ModalHeaderHorizontalProps = Omit<ModalHeaderProps, 'children' | 'className'> & {
  title?: string;
  description?: React.ReactNode;
  Icon?: IconComponentType;
  iconUrl?: string;
  className?: string;
  onClose?: () => void;
  color?: FeaturedIconColors;
  CloseIcon?: IconComponentType;
};

const titleVariant = cva('', {
  variants: {
    modalVariant: {
      xs: 'text-md',
      sm: 'text-md',
      md: 'text-lg',
      lg: 'text-lg',
      xl: 'text-lg',
    },
  },
});

const getContainerClassName = ({
  hasDescription,
  modalVariant,
}: {
  hasDescription: boolean;
  modalVariant: string;
}) => {
  if (modalVariant === 'xs') {
    return '!px-2xl !pt-xl !pb-2xl !border-none';
  }

  return hasDescription ? '!py-xl !pe-xl' : '';
};

const getIconClassName = (hasDescription: boolean, modalVariant: ModalVariant) => {
  if (!hasDescription) {
    return '';
  }
  if (modalVariant === 'xs' || modalVariant === 'sm') {
    return 'mt-xxs';
  }
  return 'mt-xs';
};

/** NOTE: Modal.Header should be used inside the Modal component.
 */
function ModalHeader(props: ModalHeaderHorizontalProps) {
  const { Icon, iconUrl, description, title, onClose, color = 'brand', CloseIcon } = props;
  const { variant } = useModalStateProvider();
  const iconSize = description ? 'md' : 'sm';

  const className = getContainerClassName({
    hasDescription: Boolean(description),
    modalVariant: variant,
  });

  return (
    <ModalHeaderBase CloseIcon={CloseIcon} className={className} onClose={onClose}>
      <Stack className={clsx('gap-lg flex-1', { 'items-center': !description })} direction="row">
        {Icon ? (
          <FeaturedIcon
            Icon={Icon}
            className={getIconClassName(Boolean(description), variant)}
            color={color}
            size={iconSize}
          />
        ) : null}
        {iconUrl ? (
          <FeaturedIcon alt={title ?? ''} color={color} iconUrl={iconUrl} size={iconSize} />
        ) : null}
        <Stack className={description ? '' : 'justify-center'}>
          {title ? (
            <Typography
              color="text-primary"
              maxNumberOfLines={1}
              variant={titleVariant({ modalVariant: variant }) as TypographyVariants}
              weight="semi-bold"
            >
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography
              color="text-tertiary"
              maxNumberOfLines={2}
              variant="text-sm"
              weight="regular"
            >
              {description}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </ModalHeaderBase>
  );
}

export default memo(ModalHeader);
