import * as React from 'react';
import type { SVGProps } from 'react';
const SvgErrorOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 34 34"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <rect width={22} height={22} x={6} y={6} strokeWidth={2} opacity={0.3} rx={11} />
    <rect width={32} height={32} x={1} y={1} strokeWidth={2} opacity={0.1} rx={16} />
    <g clipPath="url(#a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 14.333V17m0 2.667h.007M23.667 17a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.334 0"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9 9h16v16H9z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgErrorOutline;
