'use client';
import React, { memo, Suspense, useMemo } from 'react';
import type { BlockType, ComputedBlockStateUnionType } from '@unifyapps/defs/types/block';
import type { DrawerBlockStateType } from '@unifyapps/defs/blocks/Drawer/types';
import type {
  ContextualDialogBlockStateType,
  ModalBlockStateType,
} from '@unifyapps/defs/types/blocks';
import { invariant } from 'ts-invariant';
import { useReactiveComputeContext } from '../../hooks/computeContext';
import { compose } from '../../../utils/compose';
import useIsBlockVisibility from '../../hooks/useIsBlockVisibility';
import { useSetBlockRef } from '../../hooks/useSetBlockRef';
import useDerivedEvents from '../../hooks/useDerivedEvents';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import useSetBlockState from '../../hooks/useSetBlockState';
import type { BlockRendererProps, BlockRendererWrapperProps } from '../RegistryProvider/types';
import { useRegistryContext } from '../RegistryProvider';
import { useBlockDataAttributes } from '../../hooks/useBlockDataAttributes';
import { InterfaceModes, useBlockFromPage, useInterfaceStore } from '../../stores/InterfaceStore';
import { isEmptyBlock } from '../../utils/block';
import { useCanAccess } from '../../access-control/hooks/useCanAccess';
import { PlaceholderBlock } from '../PlaceholderBlock';
import useComputeBlockState from '../../hooks/useComputeBlockState';
import { useTriggerAutomaticDataSources } from '../DataSourceController/hooks/useTriggerAutomaticDataSources';

function NoopComponent() {
  return null;
}

const noopFallback = <NoopComponent />;

function BlockRenderer({
  block,
  blockState,
  updateBlockState,
  context,
  updateBlockSessionState,
}: BlockRendererProps) {
  const { id, component, dataSourceIds, events, fallback } = block;
  const { registry } = useRegistryContext();
  const Block = registry.getBlockComponent(component.componentType);
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();
  const { blockRef } = useSetBlockRef({
    blockState,
  });

  invariant(blockState, `Block state is not defined for block with ${blockState?.id}`);

  const computedBlockState = useComputeBlockState<ComputedBlockStateUnionType>({
    blockState,
    context,
  });

  const { derivedEvents } = useDerivedEvents({
    blockStateId: blockState.id,
  });

  const dataAttributes = useBlockDataAttributes({
    id,
    component,
    pageId: activeInterfacePageId,
    blockStateId: blockState.id, //blockStateId will be different from blockId in case of runtime blocks
  });

  // The platform automatically configures derived block events for a block.
  // Place derived events first to ensure they are fired first,
  // as events before them might take too much time and prevent them from firing.
  const allEvents = useMemo(() => {
    return [...derivedEvents, ...(events || [])];
  }, [events, derivedEvents]);

  if (!Block) {
    console.error(
      'Component type not recognized: ',
      component,
      ' in BlocksRegistry ',
      registry.getRegistryName(),
    );
    return null; // or some fallback UI
  }

  return (
    <Suspense fallback={null}>
      <ErrorBoundary fallback={noopFallback}>
        {/* Assuming your components accept a prop named 'component' that matches their expected type */}
        <Block
          component={component}
          computedBlockState={computedBlockState}
          dataAttributes={dataAttributes}
          dataSourceIds={dataSourceIds}
          events={allEvents}
          fallback={fallback}
          key={id}
          registry={registry}
          updateBlockSessionState={updateBlockSessionState}
          updateBlockState={updateBlockState}
          blockRef={blockRef}
          // so it doesn't throw error
          blockState={blockState}
        />
      </ErrorBoundary>
    </Suspense>
  );
}

const withTriggerAutomaticDataSources = (
  WrappedComponent: React.ComponentType<BlockRendererProps>,
) => {
  return function WithTriggerAutomaticDataSourcesWrapper(props: BlockRendererProps) {
    useTriggerAutomaticDataSources({
      dataSourceIds: props.block.dataSourceIds,
    });

    return <WrappedComponent {...props} />;
  };
};

const withDetachedBlockCheck = (WrappedComponent: React.ComponentType<BlockRendererProps>) => {
  return function WithDetachedBlockWrapper(props: BlockRendererProps) {
    const { block, blockState } = props;
    const isDetachedBlock =
      block.component.componentType === 'Modal' ||
      block.component.componentType === 'Drawer' ||
      block.component.componentType === 'ContextualDialog';

    // we are evicting from mounting these blocks because they are not visible, so we don't want to mount them
    // this is only applicable for detached blocks
    if (
      isDetachedBlock &&
      !(blockState as DrawerBlockStateType | ModalBlockStateType | ContextualDialogBlockStateType)
        .state.open
    ) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

const withBlockState = (WrappedComponent: React.ComponentType<BlockRendererProps>) => {
  return function WithBlockStateWrapper({ block, context }: BlockRendererProps) {
    const { id } = block;
    const { blockState, updateBlockState, updateBlockSessionState } = useSetBlockState(id);

    return (
      <WrappedComponent
        block={block}
        blockState={blockState}
        context={context}
        updateBlockSessionState={updateBlockSessionState}
        updateBlockState={updateBlockState}
      />
    );
  };
};

const withVisibilityAndPermissionsCheck = (
  WrappedComponent: React.ComponentType<{
    block: BlockType;
    context: Record<string, unknown>;
  }>,
) => {
  return function WithVisibilityAndPermissionsWrapper({
    blockId,
    parentBlockId,
  }: BlockRendererWrapperProps) {
    const block = useBlockFromPage({ blockId });
    const interfaceMode = useInterfaceStore().use.mode();
    const isBlockPermissible = useCanAccess(block?.permissions);
    const { context } = useReactiveComputeContext();
    const { getIsBlockVisibility } = useIsBlockVisibility();

    if (!isBlockPermissible) {
      return null;
    }

    if (isEmptyBlock(blockId)) {
      if (interfaceMode === InterfaceModes.BUILDER) {
        return <PlaceholderBlock parentBlockId={parentBlockId} />;
      }
      return null;
    }

    if (!getIsBlockVisibility(block?.visibility, context)) {
      return null;
    }

    if (block) {
      return <WrappedComponent block={block} context={context} />;
    }

    console.error(`Unexpected scenario! Block with id ${blockId} not found.`);
    return null;
  };
};

const ComposedBlockRenderer = compose<React.ComponentType<BlockRendererWrapperProps>>(
  withVisibilityAndPermissionsCheck,
  withBlockState,
  withDetachedBlockCheck,
  withTriggerAutomaticDataSources,
  memo,
)(BlockRenderer);

export default ComposedBlockRenderer;
