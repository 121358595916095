import { memo } from 'react';
import { useModalStateProvider } from '../useModalStateProvider';
import type { FooterProps } from './FooterBase';
import ModalFooterBase from './FooterBase';

/** NOTE: Always use Modal.Footer inside the Modal.
 */

function ModalFooter(props: Omit<FooterProps, 'className'>) {
  const { variant: modalVariant } = useModalStateProvider();
  const footerVariant = modalVariant === 'xs' ? 'full' : 'right';

  return <ModalFooterBase {...props} footerVariant={footerVariant} modalVariant={modalVariant} />;
}

export default memo(ModalFooter);
