import { useMemo } from 'react';

function StyleOverride({ style, id }: { style?: string; id?: string }) {
  const innerHTML = useMemo(
    () => ({
      __html: style || '',
    }),
    [style],
  );
  return <style dangerouslySetInnerHTML={innerHTML} id={id} />;
}

export default StyleOverride;
