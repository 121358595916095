import { forwardRef } from 'react';
import { default as BaseAlert } from '@mui/joy/Alert';
import XClose from '@unifyapps/icons/outline/XClose';
import { decode18NInterpolatedString } from '../../utils/decode18NInterpolatedString';
import { Typography } from '../Typography';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Button } from '../Button';
import type { AlertProps } from './types';
import { alertVariants, containerVariants, infoVariants } from './styles';

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const {
    className,
    direction = 'row',
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorNode,
    color = 'default',
    title,
    titleColor = 'text-secondary',
    description,
    primaryAction,
    secondaryAction,
    onClose,
    bottomDecoratorNode,
    ...rest
  } = props;

  const alertClassName = alertVariants({ className, color, direction });
  const containerClassName = containerVariants({ direction });
  const infoClassName = infoVariants({ direction });

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent height={38} width={38} />
  ) : (
    startDecoratorNode
  );

  const content = (
    <Box className={containerClassName}>
      <Box className={infoClassName}>
        {title ? (
          <Typography color={titleColor} variant="text-sm" weight="semi-bold">
            {decode18NInterpolatedString(title)}
          </Typography>
        ) : null}
        {title && description && direction === 'row' ? ' ' : null}
        {description ? (
          <Typography className="break-anywhere" color="text-tertiary" variant="text-sm">
            {description}
          </Typography>
        ) : null}
        {endDecoratorNode ? endDecoratorNode : null}
      </Box>
      {/* check if className needed after LinkButton updated */}
      {primaryAction || secondaryAction ? (
        <Box className="gap-x-lg flex flex-row items-center">
          {secondaryAction?.text ? (
            <Button
              color="neutral"
              component={secondaryAction.href ? 'a' : 'button'}
              href={secondaryAction.href}
              onClick={secondaryAction.onClick}
              size="md"
              // @ts-expect-error -- valid prop for anchor tag
              target={secondaryAction.href ? '_blank' : undefined}
              variant="ghost"
            >
              {secondaryAction.text}
            </Button>
          ) : null}
          {primaryAction?.text ? (
            <Button
              color="brand"
              component={primaryAction.href ? 'a' : 'button'}
              href={primaryAction.href}
              onClick={primaryAction.onClick}
              size="md"
              // @ts-expect-error -- valid prop for anchor tag
              target={primaryAction.href ? '_blank' : undefined}
              variant="ghost"
            >
              {primaryAction.text}
            </Button>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );

  return (
    <BaseAlert
      className={alertClassName}
      ref={ref}
      startDecorator={startDecorator}
      variant="soft"
      {...rest}
    >
      <Box className="flex flex-1 flex-row">
        {bottomDecoratorNode ? (
          <Box className="flex flex-col gap-y-3">
            {content}
            {bottomDecoratorNode}
          </Box>
        ) : (
          content
        )}
        {onClose ? (
          <IconButton
            Icon={XClose}
            className="ms-auto"
            color="neutral"
            onClick={onClose}
            size="md"
            variant="soft"
          />
        ) : null}
      </Box>
    </BaseAlert>
  );
});

Alert.displayName = 'Alert';

export default Alert;
