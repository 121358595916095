import { memo } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { slowCn } from '@unifyapps/ui/lib/utils';

export type ModalContentProps = {
  children?: React.ReactNode;
  className?: string;
};

const DEFAULT_CLASSNAME = 'bg-primary p-2xl flex-1 overflow-auto gap-xl w-full';

function ModalContentBase(props: ModalContentProps) {
  const { children, className } = props;

  return <Stack className={slowCn(DEFAULT_CLASSNAME, className)}>{children}</Stack>;
}

export default memo(ModalContentBase);
