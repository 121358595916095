import { lazy } from 'react';
import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import type { Action } from '@unifyapps/defs/types/action';
import type {
  DataTableBlockStateType,
  DataTableComponentType,
} from '@unifyapps/defs/blocks/Table/DataTable/types';
import { getTableEventTargetIds } from '../commons/getEventTargetIds';
import type { DataTableBlockRef } from './types';
import { clearChangeset } from './utils/changeset';

const importTableComponent = () =>
  import(
    /* webpackChunkName: "table-block" */
    './Table'
  );

const Table = lazy(importTableComponent);

class TableBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Table'; // Todo: Rename to DataTable
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds(block: BlockType) {
    return getTableEventTargetIds(block);
  }

  getBlockControlMethods() {
    return [
      {
        id: 'clearChangeset' as const,
        label: 'Clear Changeset',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const tableBlockState = blockState as DataTableBlockStateType;
          const payload = action.payload as { methodPayload?: { changesetCursor: number } };

          return {
            ...tableBlockState,
            ...clearChangeset({
              changesetMap: tableBlockState.changesetMap,
              changesetList: tableBlockState.changesetList,
              identifier: tableBlockState.content.identifier,
              changesetCursor: payload.methodPayload?.changesetCursor,
            }),
          } as BlockStateUnionType;
        },
      },
      {
        id: 'selectAllRows' as const,
        label: 'Select All Rows',
        method: (_: BlockStateUnionType, __: Action, blockRef?: DataTableBlockRef) => {
          if (!blockRef?.selectAllRows) {
            console.error('No setRowSelection method found on blockRef');
          }
          blockRef?.selectAllRows?.();
          return Promise.resolve();
        },
      },
      {
        id: 'clearRowSelection' as const,
        label: 'Clear Row Selection',
        method: (blockState: BlockStateUnionType) => {
          const tableBlockState = blockState as DataTableBlockStateType;

          return {
            ...tableBlockState,
            selectedRowKey: undefined,
            selectedRow: undefined,
            selectedRowKeys: undefined,
            selectedRows: undefined,
          };
        },
      },
      {
        id: 'refetchData' as const,
        label: 'Refetch Data',
        method: async (_: BlockStateUnionType, __: Action, blockRef?: DataTableBlockRef) => {
          await blockRef?.refetch?.();
          return Promise.resolve();
        },
      },
      {
        id: 'exportData' as const,
        label: 'Export Data',
        method: (_: BlockStateUnionType, action: Action, blockRef?: DataTableBlockRef) => {
          const payload = action.payload as {
            methodPayload: { fileType: string; exportHiddenColumns?: boolean };
          };
          if (!blockRef?.exportData) {
            console.error('No exportData method found on blockRef');
          }
          blockRef?.exportData?.(payload.methodPayload);
          return Promise.resolve();
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['appearance', 'componentType', 'slots']),
        content: {
          ..._omit((block.component as DataTableComponentType).content, ['addOns']),
          ...(block.component as DataTableComponentType).content.addOns,
        },
        ..._pick((block.component as DataTableComponentType).content, ['addOns']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Table;
  }

  preload() {
    return {
      blockPreload: importTableComponent,
    };
  }
}

export default TableBlockStateDefinition;
