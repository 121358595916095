'use client';

import React from 'react';
import type { DeviceDetailsType } from '../../stores/GlobalStateStore';
import AsyncView from '../../../components/AsyncView';
import {
  InterfaceClient,
  InterfaceModes,
  type InterfaceStoreState,
} from '../../stores/InterfaceStore';
import { InterfaceResourceProvider } from '../InterfaceResourceProvider';
import type { InterfaceResourceProviderProps } from '../InterfaceResourceProvider/types';
import type { InterfaceComponent } from '../../types/interfaceComponent';
import './defaultStyles.scss';
import type { InterfaceResourceStrategyType } from './context/constants';
import {
  InterfaceResourceStrategyProvider,
  useInterfaceResourceStrategy,
} from './context/InterfaceResourceStrategy';
import { RESOURCE_STRATEGY_VS_HOOK } from './constants/resourceStrategyVsHook';

export type InterfaceProviderProps = Pick<
  InterfaceResourceProviderProps,
  'basePath' | 'registry' | 'emitPageEvent' | 'isPublic' | 'pageInputs'
> & {
  interfaceSlug: string;
  interfaceComponent: InterfaceComponent;
  children: React.ReactNode;
  disableInteractiveMode?: boolean;
  renderFallback?: () => JSX.Element;
  deviceDetails?: DeviceDetailsType;
  mode?: InterfaceStoreState['mode'];
  client?: InterfaceStoreState['client'];
};

function InterfaceProvider({
  interfaceSlug: interfaceId,
  interfaceComponent,
  children,
  deviceDetails,
  registry,
  pageInputs,
  emitPageEvent,
  disableInteractiveMode,
  isPublic,
  renderFallback,
  mode = InterfaceModes.RUNNER,
  client = mode === InterfaceModes.RUNNER ? InterfaceClient.UA_MATRIX : InterfaceClient.UA_PLATFORM,
  basePath,
}: InterfaceProviderProps) {
  const { strategy } = useInterfaceResourceStrategy();

  const useResourceStrategy = RESOURCE_STRATEGY_VS_HOOK[strategy];

  const {
    data,
    dataSources,
    error,
    interfacePage,
    interfaceModulesMap,
    isLoading,
    commonNamespace,
    interfaceComponentNamespace,
  } = useResourceStrategy({ interfaceId, interfaceComponent, client, mode });

  // Uncomment the following lines to debug ssr
  // const shouldRender = data && interfacePage && !isLoading;
  // console.log('InterfaceProvider:shouldRender', shouldRender);
  // console.log('InterfaceProvider:isLoading', isLoading);

  return (
    <AsyncView
      data={data}
      error={error}
      isLoading={isLoading}
      remountOnError={mode === InterfaceModes.RUNNER}
      renderError={renderFallback}
      withSuspense={false}
    >
      {data && interfacePage ? (
        <InterfaceResourceProvider
          basePath={basePath}
          client={client}
          commonNamespace={commonNamespace}
          dataSources={dataSources}
          deviceDetails={deviceDetails}
          disableInteractiveMode={disableInteractiveMode}
          emitPageEvent={emitPageEvent}
          interfaceComponentNamespace={interfaceComponentNamespace}
          interfaceRecord={data}
          isPublic={isPublic}
          mode={mode}
          modules={interfaceModulesMap}
          page={interfacePage}
          pageInputs={pageInputs}
          registry={registry}
        >
          {children}
        </InterfaceResourceProvider>
      ) : null}
    </AsyncView>
  );
}

export function InterfaceProviderContainer({
  resourceStrategy,
  ...rest
}: InterfaceProviderProps & {
  resourceStrategy: {
    strategy: InterfaceResourceStrategyType;
  };
}) {
  return (
    <InterfaceResourceStrategyProvider strategy={resourceStrategy.strategy}>
      <InterfaceProvider {...rest} />
    </InterfaceResourceStrategyProvider>
  );
}

export default InterfaceProviderContainer;
