// Note: same as createPathId, server requires 'outputs' meta to be added
// NOTE: this function is required as 'outputs' meta needs to be added when sending pathId to server
import type { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import { ID_SEPARATOR } from '@unifyapps/form/const';

export type CreatePathIdProps = {
  schemaId: string;
  path: string[];
  options?: { metaString?: string };
};

// Note: same as createPathId, server requires 'outputs' meta to be added
// NOTE: this function is required as 'outputs' meta needs to be added when sending pathId to server
export function createPathId({
  schemaId,
  path,
  options = { metaString: 'outputs' },
}: CreatePathIdProps) {
  const { metaString } = options;
  return metaString
    ? [schemaId, metaString, ...path].join(ID_SEPARATOR)
    : [schemaId, ...path].join(ID_SEPARATOR);
}

export function resolvePathId(pathId: string, metaString = 'outputs') {
  const [schemaId, meta, ...path] = pathId.split(ID_SEPARATOR);
  return {
    schemaId,
    path: meta && meta !== metaString ? [meta, ...path] : path,
  };
}

// adding a new format type `secure` to render lock icon and in PropertiesPanel output tab
export function isSecure(schema: JSONSchema7) {
  return schema.format === 'secure';
}

export const isMasked = (schema: JSONSchema7) => {
  return schema.format === 'masked';
};

export const isHashed = (schema: JSONSchema7) => {
  return schema.format === 'hash';
};

// Exclude field in runs
export const isSkipped = (schema: JSONSchema7) => {
  return schema.format === 'skip';
};

export const isFileUploader = (schema: JSONSchema7) => {
  return schema.format === 'file-uploader';
};

function getNextPath({ path, key }: { path: string; key: string }) {
  if (path) {
    return `${path}.${key}`;
  }

  return key;
}

export type PathObj = {
  key: string;
  path: string;
  resolvedPath: string[];
};

/**
 * Currently handles non referenced schema only
 */
export function getPrimitiveFieldPaths({
  schema,
  path = '',
  key = '',
  resolvedPath = [],
  shouldTraverseSchema = () => true,
}: {
  schema: JSONSchema7Definition | JSONSchema7Definition[];
  path?: string;
  key?: string;
  resolvedPath?: string[];
  shouldTraverseSchema?: ({
    schema,
  }: {
    schema: JSONSchema7Definition | JSONSchema7Definition[];
  }) => boolean;
}): PathObj[] {
  if (typeof schema === 'boolean') {
    return [{ path, key, resolvedPath }];
  }

  /*
   * If schema is an array, loop over each element,
   * recursively find primitive paths for each element by recursively calling this function for each item,
   * append the paths to the accumulator
   */
  if (Array.isArray(schema)) {
    if (shouldTraverseSchema({ schema })) {
      return schema.reduce(
        (acc, item, index) => [
          ...acc,
          ...getPrimitiveFieldPaths({
            schema: item,
            path: getNextPath({ path, key: String(index) }),
            resolvedPath: resolvedPath.length ? [...resolvedPath, String(index)] : [String(index)],
            shouldTraverseSchema,
          }),
        ],
        [],
      );
    }
    return [{ path, key, resolvedPath }];
  }

  /*
   * If schema is of type object, iterate over each property,
   * recursively find primitive paths for each property by recursively calling this function for each value,
   * append the paths to the accumulator
   */
  if (schema.type === 'object' && shouldTraverseSchema({ schema })) {
    return Object.entries(schema.properties ?? {}).reduce<PathObj[]>(
      (acc, [fieldKey, value]: [string, JSONSchema7]) => {
        const title = value.title ?? fieldKey;

        const paths = getPrimitiveFieldPaths({
          schema: value,
          path: getNextPath({ path, key: fieldKey }),
          key: fieldKey,
          resolvedPath: resolvedPath.length ? [...resolvedPath, title] : [title],
          shouldTraverseSchema,
        });

        return [...acc, ...paths];
      },
      [],
    );
  }

  /*
   * If schema is of type array,
   * find primitive paths for each item by recursively calling this function for items
   */
  if (schema.type === 'array' && Array.isArray(schema.items) && shouldTraverseSchema({ schema })) {
    return getPrimitiveFieldPaths({
      schema: schema.items,
      path,
      resolvedPath,
      shouldTraverseSchema,
    });
  }

  return [{ path, key, resolvedPath }];
}

/**
 * Recursively searches for a reference string within an unknown value.
 *
 * @param value - The value to search within. Can be of any type.
 * @param reference - The string to search for within the value.
 * @returns `true` if the reference string is found within the value, otherwise `false`.
 *
 * @remarks
 * - If the input value is `null` or `undefined`, the function returns `false`.
 * - If the input value is a string and includes the reference string, the function returns `true`.
 * - If the input value is an object or array, the function recursively checks its properties.
 */
export const objectSearchValue = (value: unknown, reference: string): boolean => {
  // If the input is null or undefined, return false
  if (value === null || value === undefined) return false;

  // If it's a string and includes the reference, return true
  if (typeof value === 'string' && value.includes(reference)) return true;

  // If it's an object or array, recursively check its properties
  if (typeof value === 'object') {
    return Object.values(value).some((val) => objectSearchValue(val, reference));
  }

  return false;
};
