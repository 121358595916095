import { lazy } from 'react';
import { useClientContext } from '../../context/ClientContext';
import type { HTMLContentProps } from './types';

export const importBlockEditorHTMLContent = () =>
  import(
    /* webpackChunkName: "block-editor-html" */
    './HtmlContent'
  );
const BlockEditorHTMLContent = lazy(importBlockEditorHTMLContent);

export const importHTMLContentVanilla = () =>
  import(
    /* webpackChunkName: "block-editor-html-vanilla" */
    './HTMLContentVanilla'
  );
const HTMLContentVanilla = lazy(importHTMLContentVanilla);

function HtmlContent(props: HTMLContentProps) {
  const clientContext = useClientContext();

  // todo: apply this check only for salesforce, since in salesforce tiptap editor is not working due to getSelection api breaking
  if (clientContext?.client === 'DELTA_MATRIX') {
    return <HTMLContentVanilla {...props} />;
  }

  return <BlockEditorHTMLContent {...props} />;
}

export default HtmlContent;
