import { useStorageValue } from '../useStorageValue';
import { useSessionStorage } from '../useSessionStorage';

export const useSessionStorageEntry = <T>(
  key: string,
  options?: { onSuccess?: (data: T | undefined) => void },
  enabled = true,
) => {
  const [setItem, getItem] = useSessionStorage<T>(key);

  return useStorageValue({
    onSuccess: options?.onSuccess,
    getItem,
    setItem,
    enabled,
    queryKey: ['sessionStorage', key],
  });
};
