import { create } from 'zustand';
import { createSelectors } from '../../../utils/state/zustand';
import type { DrawerBlockStore, DrawerHistoryItem } from './types';

export const createInterfaceDrawerStore = ({
  containerEl,
  renderInCanvas,
}: {
  containerEl: DrawerBlockStore['containerEl'] | null;
  renderInCanvas: boolean;
}) => {
  const store = create<DrawerBlockStore>((set) => ({
    renderInCanvas,
    history: [],
    containerEl,
    drawerRenderedPages: {},
    actions: {
      pushDrawer: (drawerInfo: DrawerHistoryItem) => {
        set((state) => {
          return {
            history: [...state.history, drawerInfo],
          };
        });
      },
      removeDrawer: ({ blockId, interfacePageId }) => {
        set((state) => {
          return {
            history: state.history.filter(
              (drawer) =>
                !(drawer.blockId === blockId && drawer.interfacePageId === interfacePageId),
            ),
          };
        });
      },
      markDrawerPageRendered: (interfacePageId, value) => {
        set((state) => {
          return {
            drawerRenderedPages: {
              ...state.drawerRenderedPages,
              [interfacePageId]: value,
            },
          };
        });
      },
      setContainerEl: (_containerEl) => {
        set({ containerEl: _containerEl });
      },
      popDrawer: () => {
        set((state) => {
          return { history: state.history.slice(0, -1) };
        });
      },
      setRenderInCanvas: (_renderInCanvas) => {
        set({ renderInCanvas: _renderInCanvas });
      },
      clearDrawerHistory: () => {
        set({ history: [] });
      },
    },
  }));

  return createSelectors(store);
};
