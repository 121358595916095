export const getPreviewUrl = (url: string) => {
  const previewUrl = new URL(url);
  previewUrl.searchParams.set('preview', 'true');
  return previewUrl.toString();
};

const API_PATH = '/api/file/download/';

// function for extracting only the path and search query from the URL
export const adaptPrivateUrl = (url: string) => {
  // if we have a relative path, we can simply return the path.
  // because we are extracting the path and search query from the URL in this function
  if (!url.startsWith('http')) return url;

  const urlPath = new URL(url).pathname;
  if (!urlPath.startsWith(API_PATH)) return url;

  // checking for 3000, 3001, 3002...
  if (process.env.NODE_ENV !== 'development' && !process.env.VERCEL) {
    return url;
  }

  try {
    const urlObj = new URL(url);
    return `${urlObj.pathname}?${urlObj.searchParams.toString()}`;
  } catch (e) {
    console.error('Error parsing URL', e);
    return url;
  }
};
