/* eslint-disable @typescript-eslint/no-explicit-any -- can't escape any*/
/* eslint-disable @typescript-eslint/no-unsafe-argument -- can't escape any */
import { get } from 'lodash';
import { EXPRESSION_REGEX, STRINGIFIED_EXPRESSION } from '../../utils/regex';
import { safeJsonStringify } from '../../utils/json';
import { getComputedData } from './getComputedData';

const NEWLINE_REGEX = /\r?\n|\r/g;
const WHITESPACE_REGEX = /\s+/g;

function convertToSingleLine(obj: object): string {
  return (safeJsonStringify(obj) as unknown as string)
    .replace(NEWLINE_REGEX, '') // Remove newlines
    .replace(WHITESPACE_REGEX, ' '); // Replace multiple spaces with single space
}

/**
 * Recursively updates the value of a given object with an updater function.
 */

export type Obj = Record<string, any> | undefined | null | string;
export function recursiveUpdate(obj: Obj, updater: (value: string | null | undefined) => Obj): Obj {
  // If obj is an object and not null
  if (typeof obj === 'object' && obj !== null) {
    // If obj is an array
    if (Array.isArray(obj)) {
      // Map over each element in the array and apply recursiveUpdate
      return obj.map(function callRecursiveUpdate(value) {
        return recursiveUpdate(value, updater);
      });
    }

    /**
     * Date object does not have any keys to iterate over, it stores date internally
     * hence we need to return the date object as is. Otherwise in case of date object, this will return an empty object
     */
    if (obj instanceof Date) {
      return obj;
    }
    // If obj is a regular object
    const updatedObj: Obj = {};
    // Iterate over each key in obj
    for (const key in obj) {
      // Check if obj has the key (not inherited from prototype chain)
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Update the value at the key using recursiveUpdate
        updatedObj[key] = recursiveUpdate(obj[key], updater);
      }
    }
    return updatedObj;
  }

  // If obj is not an object, apply the updater function and return the result
  return updater(obj);
}

/**
 * Recursively call the updater function, if the key matches the with objectKey or if true is returned from value sector
 * @returns updated object
 */
export function recursiveUpdateWithSelector({
  obj,
  updater,
  valueSelector,
}: {
  obj: Obj | undefined | null;
  updater: (key: string, value: any) => unknown;
  valueSelector: (key: string, value: unknown) => boolean;
}): Obj {
  // If obj is an object and not null
  if (typeof obj === 'object' && obj !== null) {
    // If obj is an array
    if (Array.isArray(obj)) {
      // Map over each element in the array and apply recursiveUpdate
      return obj.map((value) =>
        recursiveUpdateWithSelector({
          obj: value as Obj,
          updater,
          valueSelector,
        }),
      );
    }

    /**
     * Date object does not have any keys to iterate over, it stores date internally
     * hence we need to return the date object as is. Otherwise in case of date object, this will return an empty object
     */
    if (obj instanceof Date) {
      return obj;
    }

    // If obj is a regular object
    const updatedObj: Obj = {};
    // Iterate over each key in obj
    for (const key in obj) {
      // Check if obj has the key (not inherited from prototype chain)
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const shouldUpdate = valueSelector(key, obj[key]);
        updatedObj[key] = shouldUpdate
          ? updater(key, obj[key])
          : recursiveUpdateWithSelector({
              obj: obj[key] as Obj,
              updater,
              valueSelector,
            });
      }
    }
    return updatedObj;
  }

  return obj as Obj;
}

export const defaultUpdater = (
  dataToResolveWith: Obj,
  valueToResolve: any,
  shouldEvaluateResult: (result: string) => boolean,
) => {
  if (typeof valueToResolve === 'string') {
    if (valueToResolve.match(EXPRESSION_REGEX) === null) return valueToResolve;
    // when the entire string is an expression
    if (valueToResolve.match(STRINGIFIED_EXPRESSION)?.[0] === valueToResolve) {
      const strippedPath = valueToResolve.match(STRINGIFIED_EXPRESSION)?.[1];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- we are returning the value from lodash
      return strippedPath ? get(dataToResolveWith as any, strippedPath) : valueToResolve;
    }

    return valueToResolve.replaceAll(EXPRESSION_REGEX, (match, variable: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we are using lodash get
      const value = get(dataToResolveWith as any, variable);

      if (typeof value === 'object') {
        return convertToSingleLine(
          getComputedData(
            value,
            dataToResolveWith as Record<string, unknown>,
            shouldEvaluateResult,
          ),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- known return value either string | number | boolean | undefined | null
      return value;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- same reason as top of the file
  return valueToResolve;
};
