import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getBlockFromInterfacePage } from './getBlockFromInterfacePage';

/**
 * this function updates the reinitialized the block state by picking the block info from the accurate place in the interface page
 * also after updating the block state, it updates the dependency graph
 * @param storeArgs - store args
 * @param blockId - block id
 */
export function updateBlockStateByActiveDevice({
  storeArgs,
  blockId,
}: {
  storeArgs: InterfaceStoreStateGetterAndSetter;
  blockId: string;
}) {
  const {
    get,
    getDeviceDetails,
    registry,
    updateBlockState,
    getGlobalStateStore,
    getDependencyGraphActions,
  } = storeArgs;
  const currentDevice = getDeviceDetails().device;
  const interfacePage = get().interfacePages[get().activeInterfacePageId];

  const block = getBlockFromInterfacePage(interfacePage, currentDevice, blockId);

  if (!block) {
    console.error(`updateBlockStateByActiveDevice: Block with id ${blockId} not found`);
    return;
  }

  const nextBlockState = registry.getBlockInitialState(block.component.componentType, block);

  if (nextBlockState) {
    // update only block state content appearance slots and events as
    // they can only be changed by the user rest state is persisted
    updateBlockState(blockId, (draft) => {
      Object.assign(
        draft as BlockStateUnionType,
        _pick(nextBlockState, ['appearance', 'content', 'slots', 'events']),
      );
    });

    const latestBlock = getGlobalStateStore().blocks[blockId];

    const dependencies = getDependencyGraphActions().buildEntityDependency(latestBlock, 'blocks');
    const blockDeps = dependencies[blockId];

    get().actions.updateBlock.dependency({
      blockId,
      dependency: blockDeps,
    });

    // the reason we are using updateNodes, instead of updateNode is
    // the buildBlockDependency function could add additional entities that are not stored in the backend
    getDependencyGraphActions().updateDependenciesAndNotify(dependencies);
  }
}
