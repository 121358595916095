import _forEach from 'lodash/forEach';
import type { BlockType } from '@unifyapps/defs/types/block';
import { CarouselContentType } from '@unifyapps/defs/blocks/Carousel/types';
import { RUN_TIME_BLOCK_KEY } from '../const';

export function getRuntimeBlockId({
  originalBlockId,
  runtimeBlockKey,
}: {
  originalBlockId: string;
  runtimeBlockKey: string;
}) {
  return `${RUN_TIME_BLOCK_KEY}_${originalBlockId}_${runtimeBlockKey}`;
}

export function getOriginalBlockId(runTimeBlockId: string) {
  if (!isRuntimeBlockId(runTimeBlockId)) {
    return runTimeBlockId;
  }

  const firstIndex = runTimeBlockId.indexOf('_');
  const lastIndex = runTimeBlockId.lastIndexOf('_');
  return runTimeBlockId.slice(firstIndex + 1, lastIndex);
}

export const isRuntimeBlockId = (blockId: string) => blockId.startsWith(RUN_TIME_BLOCK_KEY);

export const isRuntimeSourceBlock = (block: BlockType) => {
  switch (block.component.componentType) {
    case 'Repeatable':
      return true;
    case 'Carousel': {
      if (block.component.content.type === CarouselContentType.DYNAMIC) {
        return true;
      }
      return false;
    }
    default:
      return false;
  }
};

export type StateDetails = Record<
  string,
  { name: string; blockStateReference: string; children?: StateDetails }
>[];

export const getAllRuntimeChildrenStateOfRuntimeSourceBlock = ({
  children,
  computedRuntimeSourceBlockDetails,
  previousPrimaryKey,
}: {
  children: BlockType[] | undefined;
  computedRuntimeSourceBlockDetails: {
    data: unknown[] | undefined;
    primaryKey: string | undefined;
  };
  previousPrimaryKey?: string;
}): StateDetails => {
  if (!children) {
    return [];
  }
  const { data, primaryKey } = computedRuntimeSourceBlockDetails;

  const datumChildren = [] as StateDetails;

  _forEach(data, (datum, index) => {
    const allChildren = {};

    const primaryKeyValue = primaryKey
      ? (((datum as Record<string, string>)[primaryKey] as string | undefined) ?? index.toString())
      : index.toString();

    _forEach(children, (child) => {
      const runtimeBlockKey = getRuntimeBlockId({
        originalBlockId: child.id,
        runtimeBlockKey: previousPrimaryKey
          ? `${previousPrimaryKey}_${primaryKeyValue}`
          : primaryKeyValue,
      });

      allChildren[runtimeBlockKey] = {
        name: child.displayName,
        blockStateReference: `{{ ${runtimeBlockKey} }}`,
      };
    });
    datumChildren.push(allChildren);
  });

  return datumChildren;
};
