import type { BlockType } from '@unifyapps/defs/types/block';
import { createDraft, finishDraft } from 'immer';
import type { Context, Delta, Filter } from 'jsondiffpatch';
import * as JSONDiffPatch from 'jsondiffpatch';
import _omit from 'lodash/omit';
import {
  collectChildrenPatchFilter,
  collectChildrenReverseFilter,
  diffFilter,
  patchFilter,
  reverseFilter,
} from 'jsondiffpatch-arrays-by-hash/dist/jsondiffpatch-arrays-by-hash.esm.js';
import _isObject from 'lodash/isObject';
import _toString from 'lodash/toString';
import { invariant } from 'ts-invariant';
import { NoCodeDependencyKeys } from '../../../graph/noCodeBuildDependencyGraph';

const instance = JSONDiffPatch.create({
  // Define an object hash function
  objectHash: function getObjectHash(obj, index: number) {
    if (_isObject(obj)) {
      const id = (obj as { id?: string }).id;
      invariant(id, 'id is required for objectHash');
      return id;
    }

    return _toString(index);
  },
  // Setting matchByPosition as a fallback for when objectHash returns undefined can create smaller diffs
  matchByPosition: true,
});

// Replace the default array filter with jsondiffpatch-arrays-by-hash array filter for the pipes we need to care about
instance.processor.pipes.diff.replace('arrays', diffFilter as Filter<Context<unknown>>);
instance.processor.pipes.patch
  .replace('arrays', patchFilter as Filter<Context<unknown>>)
  .replace('arraysCollectChildren', collectChildrenPatchFilter as Filter<Context<unknown>>);
instance.processor.pipes.reverse
  .replace('arrays', reverseFilter as Filter<Context<unknown>>)
  .replace('arraysCollectChildren', collectChildrenReverseFilter as Filter<Context<unknown>>);

const KEYS_TO_OMIT_FOR_DIFF = [
  NoCodeDependencyKeys.dynamicPaths,
  NoCodeDependencyKeys.conditionalPaths,
  NoCodeDependencyKeys.DependsOn,
];

export const createBlockDiff = ({
  baseBlock,
  nonBaseBlock,
}: {
  baseBlock: BlockType;
  nonBaseBlock: BlockType;
}) => {
  const baseBlockWithoutKeys = _omit(baseBlock, KEYS_TO_OMIT_FOR_DIFF);
  const nonBaseBlockWithoutKeys = _omit(nonBaseBlock, KEYS_TO_OMIT_FOR_DIFF);
  return instance.diff(baseBlockWithoutKeys, nonBaseBlockWithoutKeys);
};

//instance.patch is not a pure func it mutates the block so wrapping in createDraft and finishDraft
export const applyBlockDiff = ({ block, diff }: { block: BlockType; diff: Delta }) =>
  finishDraft(instance.patch(createDraft(block), diff)) as BlockType;
