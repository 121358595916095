import React from 'react';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import type { InterfaceResourceProviderProps } from '../InterfaceResourceProvider/types';
import useSetLocationInStore from './hooks/useSetLocationInStore';
import useCollectDataSourcesOutputSchemas from './hooks/useCollectDataSourcesOutputSchemas';
import useHandleOnPageOutputChange from './hooks/useHandleOnOutputChange';
import useSetUserInStore from './hooks/useSetUserInStore';
import usePreloadBlocks from './hooks/usePreloadBlocks';
import useSetPageVariablesValues from './hooks/useSetPageVariablesValues';
import useCustomBlockStyles from './hooks/useCustomBlockStyles';
import { useCollectContextualDialogForBlocks } from './hooks/useCollectContextualDialogForBlocks';
import useSetInteractiveModeInStore from './hooks/useSetInteractiveModeInStore';
import { useSetSessionStorageInGlobalStateStore } from './hooks/useSetSessionStorageInGlobalStateStore';
import { usePageEventEffects } from './hooks/usePageEventEffects';

function InterfaceSideEffects({
  children,
  onOutputChange,
  interfaceId,
}: {
  children: React.ReactNode;
  onOutputChange: InterfaceResourceProviderProps['onOutputChange'];
  interfaceId: string;
}) {
  const mode = useInterfaceStore().use.mode();

  usePreloadBlocks({ mode });
  useSetLocationInStore();
  useCollectDataSourcesOutputSchemas(mode);
  useSetUserInStore();
  useHandleOnPageOutputChange(onOutputChange);
  useSetPageVariablesValues();
  useCustomBlockStyles();
  useCollectContextualDialogForBlocks();
  useSetInteractiveModeInStore();
  useSetSessionStorageInGlobalStateStore({ interfaceId });
  usePageEventEffects();

  return <>{children}</>;
}

export default InterfaceSideEffects;
