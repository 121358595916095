import _reduce from 'lodash/reduce';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import _merge from 'lodash/merge';
import { getOriginalBlockId, isRuntimeBlockId } from '../../../utils/runTimeBlocks';

const mergeRunTimeBlockState = ({
  originalBlockState,
  runTimeBlockState,
}: {
  originalBlockState: BlockStateUnionType | undefined;
  runTimeBlockState: BlockStateUnionType;
}) => _merge({}, originalBlockState, runTimeBlockState);

export function getCombinedBlockMethodsInBlocksState({
  blocksMethods,
  blocksState,
}: {
  blocksState: Record<string, BlockStateUnionType | undefined>;
  blocksMethods: Record<string, unknown>;
}) {
  const updatedBlocksState = _reduce(
    blocksState,
    (acc, blockState, blockId) => {
      if (isRuntimeBlockId(blockId)) {
        const originalBlockId = getOriginalBlockId(blockId);

        //merging run time block state with original as user can
        //pill map anything from an input data panel for run time blocks
        acc[blockId] = mergeRunTimeBlockState({
          originalBlockState: blocksState[originalBlockId],
          runTimeBlockState: {
            ...(acc[blockId] as Record<string, unknown>),
          } as BlockStateUnionType,
        });
      } else {
        acc[blockId] = blockState;
      }

      return acc;
    },
    { ...blocksState },
  );

  const updatedBlockState = _reduce(
    blocksMethods,
    (acc, blockMethod, blockId) => {
      acc[blockId] = {
        ...(acc[blockId] as Record<string, unknown>),
        ...(blockMethod as Record<string, unknown>),
      } as BlockStateUnionType;

      return acc;
    },
    {
      ...updatedBlocksState,
    } as Record<string, BlockStateUnionType | undefined>,
  );

  //to get runtime blocks, we need to filter out the blocks that have RUN_TIME_BLOCK_KEY
  const runtimeBlocks = _reduce(
    { ...updatedBlockState },
    (acc, value, key) => {
      if (isRuntimeBlockId(key)) {
        acc[key] = value;
      }

      return acc;
    },
    {},
  );

  return { ...updatedBlockState, runtimeBlocks } as Record<
    string,
    BlockStateUnionType | undefined
  > & {
    runtimeBlocks: Record<string, BlockStateUnionType | undefined>;
  };
}
