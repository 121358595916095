import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import {
  ButtonGroupModes,
  ButtonGroupTypes,
  type ButtonGroupBlockStateType,
  type ButtonGroupComponentType,
} from '@unifyapps/defs/blocks/ButtonGroup/types';
import { useCallback, memo } from 'react';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import _isEmpty from 'lodash/isEmpty';
import { ToggleButtonGroup } from './ToggleButtonGroup';
import { DefaultButtonGroup } from './DefaultButtonGroup';
import { DropdownButtonGroup } from './DropdownButtonGroup';
import { useComputeButtonGroupOptions } from './useComputeButtonGroupOptions';
import { usePermissibleButtons } from './hooks/usePermissibleButtons';

function ButtonGroup(
  props: BlockComponentProps<
    ButtonGroupComponentType,
    ButtonGroupBlockStateType,
    ButtonGroupBlockStateType
  >,
) {
  const { blockState, dataAttributes, updateBlockState, events, computedBlockState } = props;

  const { emitEvent } = useBlockEvents(events);

  const { content, appearance, isOpen } = computedBlockState;

  const { type, options, mode } = content;
  const { size, color, variant, popup } = appearance ?? {};

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const _computedOptions = useComputeButtonGroupOptions({
    mode,
    blockState,
    options: options?.data as Record<string, unknown>[] | undefined,
  });

  const computedOptions = usePermissibleButtons({
    buttons: _computedOptions,
  });

  const onAction = useCallback(
    (itemId: string, itemLabel: string) => {
      updateBlockState((draft) => {
        draft.selectedOptionId = itemId;
        draft.selectedOption = { id: itemId, label: itemLabel };
      });
      void emitEvent({
        eventType: EventTypeEnum.OnClick,
        targetId: mode === ButtonGroupModes.manual ? itemId : undefined,
      });
    },
    [emitEvent, mode, updateBlockState],
  );

  if (_isEmpty(computedOptions)) {
    return null;
  }

  if (type === ButtonGroupTypes.default) {
    return (
      <DefaultButtonGroup
        className={className}
        color={color}
        dataAttributes={dataAttributes}
        onAction={onAction}
        options={computedOptions}
        size={size}
        style={style}
        variant={variant}
      />
    );
  }

  if (type === ButtonGroupTypes.toggle) {
    return (
      <ToggleButtonGroup
        className={className}
        dataAttributes={dataAttributes}
        onAction={onAction}
        options={computedOptions}
        selectedOptionId={computedBlockState.selectedOptionId}
        size={size as 'xs' | 'sm' | 'md'}
        style={style}
      />
    );
  }

  return (
    <DropdownButtonGroup
      className={className}
      color={color}
      dataAttributes={dataAttributes}
      endDecorator={appearance?.endDecorator}
      isOpen={isOpen}
      onAction={onAction}
      options={computedOptions}
      popupAppearance={popup}
      size={size}
      startDecorator={appearance?.startDecorator}
      style={style}
      text={content.text}
      updateBlockState={updateBlockState}
      variant={variant}
    />
  );
}

export default memo(ButtonGroup);
