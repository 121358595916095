'use client';

import BlockRenderer from '@unifyapps/carbon/no-code/components/BlockRenderer';
import BlocksRegistry from '@unifyapps/carbon/no-code/components/RegistryProvider/BlocksRegistry';
import { AlertBlockStateDefinition } from '../../Alert';
import { AudioPlayerBlockStateDefinition } from '../../AudioPlayer';
import { BusinessHoursBlockStateDefinition } from '../../BusinessHours';
import { ButtonBlockStateDefinition } from '../../Button';
import { ButtonGroupBlockStateDefinition } from '../../ButtonGroup';
import { CalendarBlockStateDefinition } from '../../Calendar';
import { CameraBlockStateDefinition } from '../../Camera';
import { CardBlockStateDefinition } from '../../Card';
import { CarouselBlockFeatureStateDefinition } from '../../Carousel';
import { CategoryFilterBlockStateDefinition } from '../../CategoryFilter';
import { ChartBlockStateDefinition } from '../../Chart';
import { ChatBlockStateDefinition } from '../../Chat';
import CitationBlockStateDefinition from '../../CopilotChat/components/Citation/CitationBlockStateDefinition';
import { CodeEditorBlockStateDefinition } from '../../CodeEditor';
import { CommentsBlockStateDefinition } from '../../Comments';
import { ContainerBlockStateDefinition } from '../../Container';
import { ContextualDialogBlockStateDefinition } from '../../ContextualDialog';
import { CopilotBlockStateDefinition } from '../../Copilot';
import { DividerBlockStateDefinition } from '../../Divider';
import { DrawerBlockStateDefinition } from '../../Drawer';
import { FileUploadBlockStateDefinition } from '../../FileUpload';
import { FiltersBlockStateDefinition } from '../../Filters';
import { FormBlockStateDefinition } from '../../Form';
import { IconBlockStateDefinition } from '../../Icon';
import { IconButtonBlockStateDefinition } from '../../IconButton';
import { ImageBlockStateDefinition } from '../../Image';
import { KeyValueBlockStateDefinition } from '../../KeyValue';
import { LinkBlockStateDefinition } from '../../Link';
import { LoaderBlockStateDefinition } from '../../Loader';
import { MediaBlockStateDefinition } from '../../Media';
import { MenuBlockStateDefinition } from '../../Menu';
import { MessageInputBlockStateDefinition } from '../../MessageInput';
import { ModalBlockStateDefinition } from '../../Modal';
import { ModuleBlockStateDefinition } from '../../Module';
import { MultiStatsCardBlockStateDefinition } from '../../MultiStatsCard';
import { NavigationContainerBlockStateDefinition } from '../../NavigationContainer';
import { NavigationMenuBlockStateDefinition } from '../../NavigationMenu';
import { NestedTableBlockStateDefinition } from '../../Table/NestedTable';
import { OrganizationChartBlockStateDefinition } from '../../OrganizationChart';
import { PDFViewerBlockStateDefinition } from '../../PDFViewer';
import { ProgressBlockStateDefinition } from '../../Progress';
import { RepeatableBlockStateDefinition } from '../../Repeatable';
import { RepeatableListBlockStateDefinition } from '../../RepeatableList';
import { StackBlockStateDefinition } from '../../Stack';
import { StatCardBlockStateDefinition } from '../../StatCard';
import { SteppedContainerBlockStateDefinition } from '../../SteppedContainer';
import { StepperBlockStateDefinition } from '../../Stepper';
import { TableBlockStateDefinition } from '../../Table/DataTable';
import { TabsBlockStateDefinition } from '../../Tabs';
import { TagBlockStateDefinition } from '../../Tag';
import { TextInputBlockStateDefinition } from '../../TextInput';
import { TimelineBlockStateDefinition } from '../../Timeline';
import { TimerBlockStateDefinition } from '../../Timer';
import { TypographyBlockStateDefinition } from '../../Typography';
import { VideoBlockStateDefinition } from '../../Video';
import { CommentListBlockStateDefinition } from '../../CommentList';
import { CommentInputBlockStateDefinition } from '../../CommentInput';
import { ScannerBlockStateDefinition } from '../../Scanner';
import { QRCodeBlockStateDefinition } from '../../QRCode';
import { CopilotMessageBlockStateDefinition } from '../../CopilotMessage';

export const blockStateDefinitions = [
  new AlertBlockStateDefinition(),
  new AudioPlayerBlockStateDefinition(),
  new BusinessHoursBlockStateDefinition(),
  new ButtonBlockStateDefinition(),
  new ButtonGroupBlockStateDefinition(),
  new CalendarBlockStateDefinition(),
  new CameraBlockStateDefinition(),
  new CardBlockStateDefinition(),
  new CarouselBlockFeatureStateDefinition(),
  new CategoryFilterBlockStateDefinition(),
  new ChartBlockStateDefinition(),
  new ChatBlockStateDefinition(),
  new CitationBlockStateDefinition(),
  new CodeEditorBlockStateDefinition(),
  new CommentsBlockStateDefinition(),
  new ContainerBlockStateDefinition(),
  new ContextualDialogBlockStateDefinition(),
  new CopilotBlockStateDefinition(),
  new DividerBlockStateDefinition(),
  new DrawerBlockStateDefinition(),
  new FileUploadBlockStateDefinition(),
  new FiltersBlockStateDefinition(),
  new FormBlockStateDefinition(),
  new IconBlockStateDefinition(),
  new IconButtonBlockStateDefinition(),
  new ImageBlockStateDefinition(),
  new KeyValueBlockStateDefinition(),
  new LinkBlockStateDefinition(),
  new LoaderBlockStateDefinition(),
  new MediaBlockStateDefinition(),
  new MenuBlockStateDefinition(),
  new MessageInputBlockStateDefinition(),
  new ModalBlockStateDefinition(),
  new ModuleBlockStateDefinition(),
  new MultiStatsCardBlockStateDefinition(),
  new NavigationContainerBlockStateDefinition(),
  new NavigationMenuBlockStateDefinition(),
  new NestedTableBlockStateDefinition(),
  new OrganizationChartBlockStateDefinition(),
  new PDFViewerBlockStateDefinition(),
  new ProgressBlockStateDefinition(),
  new RepeatableBlockStateDefinition(),
  new RepeatableListBlockStateDefinition(),
  new StackBlockStateDefinition(),
  new StatCardBlockStateDefinition(),
  new SteppedContainerBlockStateDefinition(),
  new StepperBlockStateDefinition(),
  new TableBlockStateDefinition(),
  new TabsBlockStateDefinition(),
  new TagBlockStateDefinition(),
  new TextInputBlockStateDefinition(),
  new TimelineBlockStateDefinition(),
  new TimerBlockStateDefinition(),
  new TypographyBlockStateDefinition(),
  new VideoBlockStateDefinition(),
  new CommentListBlockStateDefinition(),
  new CommentInputBlockStateDefinition(),
  new ScannerBlockStateDefinition(),
  new QRCodeBlockStateDefinition(),
  new CopilotMessageBlockStateDefinition(),
];

const webRunnerRegistry = new BlocksRegistry({
  name: 'web-runner',
  blockStateDefinitions,
  blockRenderer: BlockRenderer,
  blocks: {},
});

export default webRunnerRegistry;
