import { useCallback } from 'react';
import { createQueryParams } from '../../../../utils/createQueryParams';
import { checkBuilderMode, PAGE_INPUT_QUERY_KEY } from '../../../../const';
import { InterfaceModes, useInterfaceStore } from '../../../../stores/InterfaceStore';
import { useRouter } from '../../../useRouter';
import type { OnActionArgs } from '../../../../components/ActionsProvider/context';
import {
  getInterfacePageBuilderRoute,
  getInterfacePageNavigationBuilderURL,
  getInterfacePageNavigationPreviewURL,
  getInterfacePreviewRoute,
} from '../../../../utils/interfaceRoutes';
import { NO_CODE_TEMPLATES_BY_ID } from '../../../../const/noCodeTemplates';
import { useGetNoCodeComputedData } from '../../../computeContext/useGetNoCodeComputedData';

type NavigateToPagePayloadType = {
  pageId?: string;
  pageInputs?: Record<string, unknown>;
};

const getUrl = ({
  interfacePageSlug,
  interfaceRecordId,
  isPublic,
  mode,
  pathname,
}: {
  interfacePageSlug: string;
  interfaceRecordId: string;
  mode: InterfaceModes;
  isPublic?: boolean;
  pathname: string;
}) => {
  switch (mode) {
    case InterfaceModes.RUNNER:
      if (isPublic) {
        return `/public/${interfacePageSlug}`;
      }
      return `/${interfacePageSlug}`;
    case InterfaceModes.BUILDER:
      return getInterfacePageBuilderRoute(interfaceRecordId, interfacePageSlug);

    case InterfaceModes.PREVIEW: {
      //  we are checking if we are in builder mode from url so that we can redirect to page in builder mode.
      // since we render pages and modules in drawer in preview mode; so, redirecting from there to some page
      //renders the page naturally in preview mode while we may actually be in builder mode
      if (checkBuilderMode(pathname, interfacePageSlug)) {
        return getInterfacePageBuilderRoute(interfaceRecordId, interfacePageSlug);
      }
      return getInterfacePreviewRoute({
        interfaceId: interfaceRecordId,
        interfacePageSlug,
      });
    }
    default:
      return null;
  }
};

const getLegacyUrl = ({
  interfacePageId,
  interfaceRecordId,
  mode,
  isPublic,
  pathname,
}: {
  interfacePageId: string;
  interfaceRecordId: string;
  mode: InterfaceModes;
  isPublic?: boolean;
  pathname: string;
}) => {
  switch (mode) {
    case InterfaceModes.RUNNER:
      if (isPublic) {
        return `/public/${interfacePageId}`;
      }
      return `/${interfacePageId}`;
    case InterfaceModes.BUILDER:
      return getInterfacePageNavigationBuilderURL(interfaceRecordId, interfacePageId);
    case InterfaceModes.PREVIEW: {
      //  we are checking if we are in builder mode from url so that we can redirect to page in builder mode.
      // since we render pages and modules in drawer in preview mode; so, redirecting from there to some page
      //renders the page naturally in preview mode while we may actually be in builder mode
      if (checkBuilderMode(pathname, interfacePageId)) {
        return getInterfacePageNavigationBuilderURL(interfaceRecordId, interfacePageId);
      }
      return getInterfacePageNavigationPreviewURL(interfaceRecordId, interfacePageId);
    }
    default:
      return null;
  }
};

export default function useNavigateToPage() {
  const router = useRouter();
  const { pathname } = router;
  const mode = useInterfaceStore().use.mode();
  const basePath = useInterfaceStore().use.basePath();
  const isPublic = useInterfaceStore().use.isPublic();
  const interfaceRecordId = useInterfaceStore().use.record.id();
  const interfacePageIdVsSlugMap = useInterfaceStore().use.record.interfacePageIdVsSlugMap();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const navigateToPage = useCallback(
    ({ action, actionContext, domEvent }: OnActionArgs) => {
      const payload = (action.payload ?? {}) as NavigateToPagePayloadType;
      const { pageInputs, pageId: interfacePageId } =
        getNoCodeComputedData<NavigateToPagePayloadType>(payload, actionContext);

      if (!interfacePageId || !interfaceRecordId) {
        return Promise.resolve();
      }

      const standardPage = NO_CODE_TEMPLATES_BY_ID[interfacePageId];

      const interfacePageSlug =
        standardPage?.interfacePageSlug ?? interfacePageIdVsSlugMap?.[interfacePageId];

      const path = interfacePageSlug
        ? getUrl({ interfacePageSlug, interfaceRecordId, mode, isPublic, pathname })
        : getLegacyUrl({ interfacePageId, interfaceRecordId, mode, isPublic, pathname });

      if (path) {
        const query = pageInputs ? createQueryParams(PAGE_INPUT_QUERY_KEY, pageInputs) : '';
        if (domEvent?.stopPropagation) {
          domEvent.stopPropagation();
        }
        const finalPath = basePath ? `${basePath}${path}` : path;
        router.push(`${finalPath}?${query}`);
      }
      return Promise.resolve();
    },
    [interfaceRecordId, interfacePageIdVsSlugMap, mode, isPublic, basePath, router, pathname],
  );

  return { navigateToPage };
}
