export enum enterKeyBehaviorEnum {
  SendMessage = 'SEND_MESSAGE',
  StartNewLine = 'START_NEWLINE',
}

export function getEnterkeyBehaviorSchema({ postType }: { postType: string }) {
  return {
    type: 'string',
    default: enterKeyBehaviorEnum.StartNewLine,
    oneOf: [
      { title: `Send the ${postType}`, const: enterKeyBehaviorEnum.SendMessage },

      {
        title: 'Start a new line (use cmd/ctrl + Enter to send)',
        const: enterKeyBehaviorEnum.StartNewLine,
      },
    ],
  };
}

export function getEnterKeyBehaviorUiSchema({ postType }: { postType: string }) {
  return {
    'ui:title': `When writing a ${postType}, press Enter to…`,
  };
}
