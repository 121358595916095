import { useCallback, useEffect, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { Stepper } from '@unifyapps/ui/components/Stepper';
import type { Step as BaseStepType } from '@unifyapps/ui/components/Stepper/types';
import type {
  StaticContent,
  Step,
  StepperComponentStateType,
} from '@unifyapps/defs/blocks/Stepper/types';
import { useMountEffect } from '@react-hookz/web';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import _find from 'lodash/find';
import type { UpdateBlockStateType } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import _findIndex from 'lodash/findIndex';
import {
  getConditionalIcon,
  getConditionalPropertyValue,
} from '@unifyapps/carbon/utils/conditionalValues';
import { findStep, getFirstStepId } from '@unifyapps/ui/components/Stepper/utils';
import { useVisibleSteps } from '../hooks/useVisibleSteps';

const adaptSteps = ({ steps }: { steps?: Step[] }): BaseStepType[] => {
  return _map(steps, (item) => {
    return {
      value: item.value,
      label: item.label ?? '',
      Icon: getConditionalIcon(item.icon),
      description: item.description ?? '',
      subSteps: item.subSteps ? adaptSteps({ steps: item.subSteps }) : undefined,
    };
  });
};

export function StaticStepper({
  steps: _steps,
  appearance,
  dataAttributes,
  onStepChange: _onStepChange,
  activeStepId,
  currentStep,
  updateBlockState,
  defaultStepValue,
}: {
  steps?: Step[];
  appearance: StepperComponentStateType['appearance'];
  dataAttributes?: Record<string, string>;
  onStepChange: ({ stepId, currentStep }: { stepId: string; currentStep: Step }) => void;
  activeStepId: string;
  currentStep?: Step;
  updateBlockState: UpdateBlockStateType<StepperComponentStateType>;
  defaultStepValue?: StaticContent['defaultStepValue'];
}) {
  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const steps = useVisibleSteps(_steps);

  const adaptedSteps = useMemo(() => adaptSteps({ steps }), [steps]);

  const onStepChange = useCallback(
    (stepId: string) => {
      const updatedCurrentStep = findStep(steps as BaseStepType[], stepId) as Step;

      _onStepChange({
        stepId,
        currentStep: {
          value: updatedCurrentStep.value,
          label: updatedCurrentStep.label,
          description: updatedCurrentStep.description,
          icon: updatedCurrentStep.icon,
        },
      });
    },
    [_onStepChange, steps],
  );

  const controlledState = useMemo(
    () => ({
      onStepChange,
      activeStepId,
    }),
    [activeStepId, onStepChange],
  );

  useEffect(() => {
    if (currentStep?.value !== activeStepId) {
      onStepChange(activeStepId);
    }
  }, [activeStepId, adaptedSteps, currentStep?.value, onStepChange, updateBlockState]);

  useEffect(() => {
    // initialize activeStepId to the first step if not set
    if (!activeStepId) {
      const firstStepId = getFirstStepId(adaptedSteps);
      if (firstStepId) {
        onStepChange(firstStepId);
      }
    }
  }, [activeStepId, adaptedSteps, onStepChange, steps]);

  useMountEffect(() => {
    if (
      defaultStepValue &&
      typeof defaultStepValue === 'object' &&
      'conditionalValueFilters' in defaultStepValue
    ) {
      const defaultValue = getConditionalPropertyValue<string>({ value: defaultStepValue });
      const activeStep = findStep(adaptedSteps, activeStepId);

      if (defaultValue && defaultValue !== activeStep?.value) {
        onStepChange(defaultValue);
      }
    }
  });

  if (_isEmpty(steps)) {
    return null;
  }

  const stepLabelClassName = appearance?.stepDetails.label?.color ?? '';
  const stepDescriptionClassName = appearance?.stepDetails.description?.color ?? '';

  return (
    <Stepper
      className={className}
      controlledState={controlledState}
      orientation={appearance?.orientation}
      size={appearance?.size}
      stepDescriptionClassName={stepDescriptionClassName}
      stepDetails={appearance?.stepDetails}
      stepLabelClassName={stepLabelClassName}
      steps={adaptedSteps}
      style={style}
      {...dataAttributes}
    />
  );
}
