import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoCodeColorScanner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 38"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M25.914 1.602h6.524a5.075 5.075 0 0 1 5.075 5.074v6.525m0 11.598v6.525a5.075 5.075 0 0 1-5.075 5.074h-6.524M2.716 13.2V6.676A5.075 5.075 0 0 1 7.79 1.602h6.525m0 34.796H7.79a5.075 5.075 0 0 1-5.074-5.074v-6.525"
    />
    <path
      stroke="#B7B4FE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.2}
      d="M20 19.457v-6.184m4.935 0h.012m-4.96-4.948H20m11.132 4.948h-1.856m1.856-4.948h-6.185m-11.873 0h-2.227c-.692 0-1.039 0-1.303.135a1.24 1.24 0 0 0-.54.54c-.136.265-.136.612-.136 1.304v2.227c0 .692 0 1.039.135 1.303.119.233.308.422.54.54.265.136.612.136 1.304.136h2.227c.692 0 1.039 0 1.303-.135.233-.119.422-.308.54-.54.136-.265.136-.612.136-1.304v-2.227c0-.692 0-1.039-.135-1.303a1.24 1.24 0 0 0-.54-.54c-.265-.136-.612-.136-1.304-.136"
    />
    <rect
      width={25.038}
      height={12.712}
      x={32.519}
      y={30.77}
      fill="#B7B4FE"
      rx={2}
      transform="rotate(-180 32.52 30.77)"
    />
    <path stroke="#705AF8" strokeLinecap="round" strokeWidth={2.2} d="m38.813 18.839-37.625.074" />
  </svg>
);
export default SvgNoCodeColorScanner;
