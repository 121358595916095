import dynamic from 'next/dynamic';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import type { StandardAction as StandardActionType, InputMethodsRef } from '../../types';
import { StandardActionsEnum } from '../../types';
import type { useUppy } from '../../../../hooks/useUppy';

type StandardActionProps = {
  action: StandardActionType;
  uppy: ReturnType<typeof useUppy>['uppy'];
  isLoading: boolean;
  disabled: boolean;
  input: string | undefined;
  onInputSubmit: () => void | Promise<void>;
  attachments?: FileType[];
  isSubmitDisabled?: boolean;
  onEmojiSelect: (emojiData: { native: string }) => void;
  inputWidth?: number;
  onInputChange: (value: string) => void;
  textAreaRootRef: React.MutableRefObject<HTMLDivElement | null>;
  onStartDictation?: () => void;
  methodsRef?: InputMethodsRef;
  inputContainerRef?: React.RefObject<HTMLDivElement | null>;
};

const AddAttachments = dynamic(
  () => import(/* webpackChunkName: "message-input-add-attachment" */ './AddAttachment'),
);

const SendMessage = dynamic(
  () => import(/* webpackChunkName: "message-input-send-message" */ './SendMessage'),
);

const EmojiPicker = dynamic(
  () => import(/* webpackChunkName: "message-input-emoji-picker" */ './EmojiPicker'),
);

export function StandardAction({
  action,
  uppy,
  isLoading,
  disabled,
  input,
  onInputSubmit,
  attachments,
  isSubmitDisabled,
  onEmojiSelect,
  inputWidth,
  onInputChange,
  textAreaRootRef,
  methodsRef,
  onStartDictation,
  inputContainerRef,
}: StandardActionProps) {
  switch (action.action) {
    case StandardActionsEnum.AddAttachments:
      return (
        <AddAttachments action={action} attachments={attachments} disabled={disabled} uppy={uppy} />
      );
    case StandardActionsEnum.SendMessage:
      return (
        <SendMessage
          action={action}
          attachments={attachments}
          disabled={Boolean(disabled || isSubmitDisabled)}
          input={input}
          isLoading={isLoading}
          methodsRef={methodsRef}
          onInputChange={onInputChange}
          onInputSubmit={onInputSubmit}
          onStartDictation={onStartDictation}
          textAreaRootRef={textAreaRootRef}
        />
      );
    case StandardActionsEnum.EmojiPicker:
      return (
        <EmojiPicker
          action={action}
          disabled={disabled}
          inputContainerRef={inputContainerRef}
          inputWidth={inputWidth}
          onEmojiSelect={onEmojiSelect}
        />
      );
    default:
      return null;
  }
}
