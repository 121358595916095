'use client';
import { useState, useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';
import Snackbar from '@unifyapps/ui/_components/Snackbar';
import { lookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import { LookupByKeysRequestType } from '@unifyapps/network/generated/models/lookupByKeysRequestType';
import type { LookupByKeysRequest } from '@unifyapps/network/generated/models/lookupByKeysRequest';
import { UA_THEME_VARS } from '@unifyapps/style/themes/consts';
import { useWindowSize } from 'react-use';
import { clsx } from 'clsx';
import { SmallIconButton } from '@unifyapps/ui/components/SmallIconButton';
import SvgXClose from '@unifyapps/icons/outline/XClose';
import type { Notification as NotificationType } from '@unifyapps/network/generated/models/notification';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import useScreen from '@unifyapps/hooks/useScreen';
import userStore from '../../auth/userStore';
import { safeJsonParse } from '../../utils/json';
import { useUserInboxSubscription } from './hooks/useUserInboxSubscription';
import { useNotificationCategories } from './hooks/useNotificationCategories';
import { NotificationContent } from './NotificationItem';
import type { Notification, NotificationEventTypesLookupResponse } from './types';

type Props = {
  children: ReactNode;
};

const DESKTOP_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'right' } as const;
const NON_DESKTOP_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'center' } as const;
const NOTIFICATIONS_SNACKBAR_AUTOHIDE_DURATION = 5000;
const ICON_APPERANCE = { size: 'sm' as const, className: 'size-4' };
const CONTENT_TYPOGRAPHY = { variant: 'text-sm' as const };

export function NotificationsSnackbarProvider({ children }: Props) {
  const { width: windowWidth } = useWindowSize();

  const { isDesktop } = useScreen();

  const [snackbarProps, setSnackbarProps] = useState<{
    open?: boolean;
    notification?: Notification;
  }>({});
  const { categories } = useNotificationCategories();

  const userData = userStore.use.currentUserDetails();
  const loggedInUserId = userData?.user?.id;

  const onNotificationReceive = useEventCallback(async (payload: Buffer) => {
    if (!snackbarProps.open) {
      const notificationPayload = safeJsonParse(payload.toString());
      const { notificationEventType } = notificationPayload as NotificationType;

      const { response } = (await lookup({
        type: LookupByKeysRequestType.ByKeys,
        lookupType: 'ENTITY',
        keys: [notificationEventType],
        options: {
          entity_type: UAEntityTypes.NotificationEventType,
        },
      } as LookupByKeysRequest)) as NotificationEventTypesLookupResponse;

      const category = notificationEventType
        ? response?.objects?.[notificationEventType].properties.category
        : undefined;

      setSnackbarProps({
        open: true,
        notification: {
          ...notificationPayload,
          category: category ? categories?.[category] : undefined,
        },
      });
    }
  });

  const onClose = useCallback(() => {
    setSnackbarProps({ open: false });
  }, []);

  useUserInboxSubscription({
    loggedInUserId,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- not needed
    onNotificationReceive,
  });

  const style = useMemo(
    () => ({
      // NOTE: calculated using golden ratio of the screen width
      maxWidth: Math.max(windowWidth * 0.618 * 0.382, 444),
    }),
    [windowWidth],
  );

  const endDecorator = useMemo(
    () => <SmallIconButton Icon={SvgXClose} iconClassName="size-4" onClick={onClose} size="sm" />,
    [onClose],
  );

  const anchorOrigin = isDesktop ? DESKTOP_ANCHOR_ORIGIN : NON_DESKTOP_ANCHOR_ORIGIN;

  return (
    <>
      {children}
      {snackbarProps.open && snackbarProps.notification ? (
        <Snackbar
          anchorOrigin={anchorOrigin}
          autoHideDuration={
            snackbarProps.notification.previewTime
              ? snackbarProps.notification.previewTime * 1000
              : NOTIFICATIONS_SNACKBAR_AUTOHIDE_DURATION
          }
          className={clsx('border-tertiary items-start border', UA_THEME_VARS)}
          endDecorator={endDecorator}
          onClose={onClose}
          open
          style={style}
        >
          <NotificationContent
            category={snackbarProps.notification.category}
            content={snackbarProps.notification.content ?? ''}
            icon={ICON_APPERANCE}
            typography={CONTENT_TYPOGRAPHY}
          />
        </Snackbar>
      ) : null}
    </>
  );
}
