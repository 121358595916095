import { customAlphabet, nanoid } from 'nanoid';

// The order of characters is optimized for better gzip and brotli compression. removed -_ for URL
const urlAlphabet = 'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict';
const generator = customAlphabet(urlAlphabet, 5);

// mixed types (string and number) does not work well with JS objects,
// so always prefixing with _ so that id is always string and not number
export const generatePrefix = (prefix = '') => `${prefix}_`;

export function getIdWithPrefix(prefix?: string) {
  return prefix ? `${generatePrefix(prefix)}${generator()}` : generator();
}

export function getLongId() {
  return nanoid();
}
