import { useRef } from 'react';
import { useReactiveComputeContext } from '../../../../hooks/computeContext';
import { useGetNoCodeComputedData } from '../../../../hooks/computeContext/useGetNoCodeComputedData';

export function useComputeDataSourcePayload<T>({
  data,
  refetchOnPayloadChange = true,
}: {
  data: Record<string, unknown>;
  refetchOnPayloadChange?: boolean;
}) {
  const { context } = useReactiveComputeContext();
  const { getNoCodeComputedData } = useGetNoCodeComputedData();

  const computedPayload = useRef<T | null>(null);

  if (refetchOnPayloadChange) {
    return getNoCodeComputedData<T>(data, context);
  }

  if (computedPayload.current === null) {
    computedPayload.current = getNoCodeComputedData<T>(data, context);
  }

  return computedPayload.current;
}
