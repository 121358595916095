import { memo } from 'react';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { Box } from '@unifyapps/ui/components/Box';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { LoaderBlockStateType, LoaderComponentType } from '@unifyapps/defs/types/blocks';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import AILoader from './AILoader';
import ContentLoader from './ContentLoader';
import DefaultLoader from './DefaultLoader';
import type { LoaderComponentProps } from './types';

const config: Partial<Record<string, React.FC<LoaderComponentProps>>> = {
  AI: AILoader,
  DEFAULT: DefaultLoader,
  CONTENT: ContentLoader,
};
const classNameConfig: Partial<Record<string, string>> = {
  AI: 'w-fit',
  DEFAULT: 'w-fit',
  CONTENT: 'h-[68px] min-h-[12px] w-full', // min-height of a single bar
};

function Loader({
  dataAttributes,
  computedBlockState,
}: BlockComponentProps<LoaderComponentType, LoaderBlockStateType, LoaderBlockStateType>) {
  const { appearance } = computedBlockState;

  const type = appearance?.type || 'DEFAULT';
  const LoaderComponent = config[type] || DefaultLoader;

  const { className } = useBlockAppearanceStyles({ appearanceStyles: appearance?.styles });

  return (
    <Box className={slowCn(classNameConfig[type], className)} {...dataAttributes}>
      <LoaderComponent computedBlockState={computedBlockState} />
    </Box>
  );
}

export default memo(Loader);
