import type { BlockId, BlockType, ComponentsUnionType } from '@unifyapps/defs/types/block';
import type { SlotType } from '@unifyapps/defs/types/slot';
import _keyBy from 'lodash/keyBy';
import { isEmptyBlock } from '../no-code/utils/block';
import type { BuildDependencyGraphItem } from '../dependency/utils/buildDependencyGraph';
import type { DynamicAndConditionalPathHandler } from '../no-code/context/DependencyGraphContext/handlers';
import { DependencyHelper } from '../dependency/helpers/DependencyHelper';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- false positive
export class BlockHelper {
  static getComponentBlockIds(block: BlockType) {
    // @ts-expect-error -- blockIds and config.blockIds are present
    if ('blockId' in block.component.content) {
      return [(block.component as { content: { blockId: BlockId } }).content.blockId];
    }

    // @ts-expect-error -- blockIds and config.blockIds are present
    return (block.component.blockIds ??
      // @ts-expect-error -- blockIds and config.blockIds are present
      (block.component.content as { blockIds: string[] } | undefined)?.blockIds) as
      | BlockId[]
      | undefined;
  }

  static getBlockSlots(block: BlockType) {
    return ('slots' in block.component ? block.component.slots : {}) as
      | Record<string, SlotType | undefined>
      | undefined;
  }

  static getBlockItems(block: BlockType) {
    return 'content' in block.component && 'items' in block.component.content
      ? block.component.content.items
      : [];
  }

  static shouldAddEmptyComponent(blockId?: BlockId) {
    // NOTE: if blockId is null, it means it's an empty block, and we should add an add component button
    return isEmptyBlock(blockId);
  }
  static getBlocksMapById(blocks: BlockType[]) {
    return _keyBy(blocks, 'id');
  }

  static getBlocksByComponentTypes(
    blocks: BlockType[],
    componentType: ComponentsUnionType['componentType'] | ComponentsUnionType['componentType'][],
  ) {
    const componentTypes = Array.isArray(componentType) ? componentType : [componentType];

    return blocks.filter((block) => componentTypes.includes(block.component.componentType));
  }

  static getDynamicPaths(block?: BlockType) {
    return block?.dP;
  }

  static getConditionalPaths(block?: BlockType) {
    return block?.cP;
  }

  static getDependsOn(block?: BlockType) {
    return block?.dpOn;
  }

  static extractBlockDependencyKeys(
    dep?: BuildDependencyGraphItem<DynamicAndConditionalPathHandler>,
  ): Pick<BlockType, 'cP' | 'dP' | 'dpOn'> {
    return {
      dpOn: DependencyHelper.getDependsOn(dep),
      cP: DependencyHelper.getConditionalPaths(dep),
      dP: DependencyHelper.getDynamicPaths(dep),
    };
  }
}
