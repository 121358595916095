'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

export type ClientContextValue =
  | {
      /**
       * The client who's rendering the app
       */
      client?: 'PLATFORM' | 'MATRIX' | 'MATRIX_NATIVE';
      /**
       * The app inside which the client is rendering
       * Not applicable for PLATFORM or MATRIX
       */
      app?: never;
      value?: { client?: 'PLATFORM' | 'MATRIX' | 'MATRIX_NATIVE'; app?: never };
    }
  | {
      /**
       * The client who's rendering the app
       */
      client: 'DELTA_MATRIX' | 'DELTA_PLATFORM' | 'WEB_SDK';
      /**
       * The app inside which the client is rendering
       */
      app: 'SALESFORCE' | 'SHOPIFY' | 'ZENDESK' | 'UNKNOWN'; // `app` is required for these clients
    };

const ClientContext = createContext<null | ClientContextValue>(null);

const useClientContext = () => {
  const context = useContext(ClientContext);

  if (!context) {
    // throw new Error('ClientContext must be used within ClientContextProvider'); // It's not mandatory, hence not throwing the error
  }

  return context;
};

function ClientContextProvider(props: PropsWithChildren<ClientContextValue>) {
  const { children, ...rest } = props;
  return <ClientContext.Provider value={rest}>{children}</ClientContext.Provider>;
}

export { useClientContext, ClientContextProvider };
