import type { SchemaAndLayout } from '@unifyapps/network/generated/models/schemaAndLayout';
import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';
import type { DataSource } from '../dataSource';
import type { PageVariable } from '../pageVariable';
import type { PageFunction } from '../pageFunction';
import type { BlockId, BlockType } from '../block';
import type { Event } from '../event';
import type { Entity } from './entity';
import type { DevicesOverrides } from './deviceOverrides';

// Make sure that this type is in sync with the type defined in the API backend https://qa.unifyapps.com/api/entity-type?entityType=e_component

export type PageEventType = {
  eventType: string;
  label: string;
  payload?: object;
};

export interface DataSourceDependencyType {
  type: 'DATA_SOURCE';
  dataSourceId: string;
}

export interface LookupRequestDependencyType {
  type: 'LOOKUP_REQUEST';
  lookupRequest: LookupRequest;
}

export type InterfaceDependencyType = DataSourceDependencyType | LookupRequestDependencyType;

export enum InterfacePageEntityComponentType {
  PAGE = 'PAGE',
  MODULE = 'MODULE',
}

export type DependsOnType = {
  /**
   * The unique identifier of the entity that this entity depends on.
   */
  id: string;
  /**
   * A list of property path from other entities that this entity relies on.
   * For example, if this entity uses values from a data source or another widget’s state, it should be listed here.
   *
   * Example:
   * ```TypeScript
   * p: [
   *  "appState.user.name",
   *  "settings.defaultCurrency"
   * ]
   * ```
   */
  p: string[];
};

export type DynamicPath = {
  p: string;
};

export type ConditionalPath = {
  p: string;
};

export interface InterfacePageType {
  /**
   * The type of the component, helps in identifying the usecase of because we use same entity to store multiple tenant's sitemap
   */
  componentType?: InterfacePageEntityComponentType;

  /**
   * The InterfaceEntity this page belongs to
   */
  interfaceId?: string;

  name?: string;
  /**
   * The title of the web page
   */
  pageTitle?: string;
  /**
   * Indicates if the page should be publicly visible or restricted to logged-in users
   */
  visibility?: 'public' | 'private';
  /**
   * The layout of the page including header and body
   */
  layout?: PageBody;
  /**
   * Block structure of the page
   */
  blocks?: Record<string, BlockType>;
  /**
   * Data sources for the page
   */
  dataSources?: Record<string, DataSource>;
  /**
   * Variables for the page
   */
  pageVariables?: Record<string, PageVariable>;
  /**
   * Functions for the page
   */
  pageFunctions?: Record<string, PageFunction>;

  /**
   * The query param schema of the page
   */
  inputSchema?: SchemaAndLayout;

  /**
   * The query param schema of the page
   */
  outputSchema?: SchemaAndLayout;

  /**
   * The output value of page
   */
  output?: Record<string, unknown>;

  /**
   * The custom events that the page will emit, a parent page can do handling based on these events
   */
  events?: PageEventType[];
  /**
   * The slug of the page
   */
  slug?: string;

  /**
   * The permissions needed to access the page
   */
  permissions?: Record<string, string[]>;

  metadata?: {
    flags?: {
      /**
       * we are moving to save deps of blocks, page variables, page function and data sources.
       * this boolean marks the shift that for this page all the deps have been calculated and saved
       * if this is true, we don't compute the deps of a block at runtime in matrix
       */
      isAllDepsCalculated?: boolean;
    };
    // NOTE: this is used for maintaining count for each block type, which is used in generating block ids
    // e.g., container_1, button_1, button_2, keyValue_1
    _blockCounter?: Record<string, number>;
    _variableCounter?: number;
    _functionCounter?: number;
    //[blockId]: { [blockProperty]: count }
    //eg: [table_1]: { column: 1 }
    _blockPropertyCounters?: Record<string, Record<string, number>>;
    // NOTE: this is used for maintaining count for each page event, which is used in generating page event
    _pageEventCounter?: number;
    // NOTE: this is used for dependencies between data sources and lookup requests, more to be added
    dependencies?: Partial<Record<string, string>>;
    dependencyById?: Partial<Record<string, InterfaceDependencyType>>;
  };
  devices?: DevicesOverrides;
  customCode?: { header?: string; footer?: string };
  interactions?: { events?: Event[] };
}

export interface PageBody {
  header?: BlockId;
  body?: BlockId;
  footer?: BlockId;
}

export type { DevicesOverrides };

export type InterfacePageEntity = Entity<InterfacePageType>;
