import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 79 64"
    {...props}
  >
    <path
      fill="#ABC4D9"
      fillRule="evenodd"
      d="M4.437.355A4.437 4.437 0 0 0 0 4.792V57.98a5.324 5.324 0 0 0 5.324 5.324h68.274a5.324 5.324 0 0 0 5.323-5.324V12.83a5.324 5.324 0 0 0-5.324-5.324H38.811l-2.35-4.698A4.44 4.44 0 0 0 32.494.355z"
      clipRule="evenodd"
    />
    <path
      fill="#5889B3"
      d="M0 23.243a7.15 7.15 0 0 1 7.15-7.15h64.7a7.15 7.15 0 0 1 7.15 7.15V58.68a5.324 5.324 0 0 1-5.324 5.324H5.324A5.324 5.324 0 0 1 0 58.679z"
    />
  </svg>
);
export default SvgFolder;
