import { useMemo } from 'react';
import type { ComponentsUnionType } from '@unifyapps/defs/types/block';
import { useRuntimeBlockKey } from '../components/BlockRuntimeStateProvider';

export const enum BlockDataAttributes {
  InterfacePageId = 'data-interface-page-id',
  BlockId = 'data-block-id',
  BlockType = 'data-block-type',
  BlockVariant = 'data-block-variant',
  DataColorSchema = 'data-color-scheme',
  BlockRunTimeBlockId = 'data-block-runtime-block-id',
}

export const useBlockDataAttributes = ({
  id,
  component,
  pageId,
  blockStateId,
}: {
  id: string;
  component: ComponentsUnionType & {
    appearance?: { theme?: string };
  };
  pageId: string;
  blockStateId: string | undefined;
}): Record<string, string> => {
  const { componentType, appearance } = component;
  const runTimeBlockKey = useRuntimeBlockKey();

  return useMemo(() => {
    const dataAttributes: Record<string, string> = {
      [BlockDataAttributes.BlockId]: id,
      [BlockDataAttributes.BlockType]: componentType,
    };

    if (componentType === 'Module') {
      dataAttributes[BlockDataAttributes.InterfacePageId] = pageId;
    }

    if (runTimeBlockKey && blockStateId) {
      dataAttributes[BlockDataAttributes.BlockRunTimeBlockId] = blockStateId;
    }
    if (appearance?.theme) {
      dataAttributes[BlockDataAttributes.DataColorSchema] = appearance.theme;
    }

    return dataAttributes;
  }, [id, componentType, runTimeBlockKey, appearance?.theme, pageId, blockStateId]);
};
