/* eslint-disable @typescript-eslint/no-explicit-any -- needed */
import { ReactRenderer } from '@tiptap/react';
import type React from 'react';
import { default as Tippy } from 'tippy.js';
import type { Instance as TippyInstance, Placement } from 'tippy.js';
import type { SuggestionOptions, SuggestionProps } from '../../../tiptap/suggestion';
import { DOM_RECT_FALLBACK } from './consts';
import './arrow.css';

export type SuggestionListRef<T> = {
  // For convenience using this SuggestionList from within the
  // mentionSuggestionOptions, we'll match the signature of SuggestionOptions's
  // `onKeyDown` returned in its `render` function
  onKeyDown: NonNullable<ReturnType<NonNullable<SuggestionOptions<T>['render']>>['onKeyDown']>;
};

let activeSuggestion: { cancel: () => void } | null = null;

export default function getSuggestions<T = any>({
  items,
  SuggestionList,
  placement,
  arrow,
  isMentionListActiveRef,
}: {
  items: SuggestionOptions<T>['items'];
  SuggestionList: React.ComponentType<SuggestionProps<T>>;
  placement?: Placement;
  arrow?: string | boolean;
  isMentionListActiveRef?: React.MutableRefObject<boolean>;
}): Omit<SuggestionOptions<T>, 'editor'> {
  return {
    items,
    render: () => {
      let component: ReactRenderer<SuggestionListRef<T>> | undefined;
      let popup: TippyInstance | undefined;

      return {
        onStart: (props) => {
          // Cancel the previous suggestion if it exists
          if (activeSuggestion) {
            activeSuggestion.cancel();
          }
          if (isMentionListActiveRef) {
            isMentionListActiveRef.current = true;
          }
          component = new ReactRenderer(SuggestionList, {
            props,
            editor: props.editor,
          });

          popup = Tippy('body', {
            getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            sticky: 'popper',
            interactive: true,
            trigger: 'manual',
            placement,
            arrow,
          })[0];

          // Set the current active suggestion to this one
          activeSuggestion = {
            cancel: () => {
              popup?.destroy();
              component?.destroy();
              popup = undefined;
              component = undefined;
            },
          };
        },

        onUpdate(props) {
          component?.updateProps(props);

          popup?.setProps({
            getReferenceClientRect: () => props.clientRect?.() ?? DOM_RECT_FALLBACK,
          });
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup?.hide();
            return true;
          }

          if (props.event.key === 'Enter') {
            popup?.hide();
            return true;
          }

          if (!component?.ref) return false;
          return component.ref.onKeyDown(props);
        },

        onExit() {
          if (isMentionListActiveRef) {
            isMentionListActiveRef.current = false;
          }
          popup?.destroy();
          component?.destroy();
          popup = undefined;
          component = undefined;
        },
      };
    },
  };
}
