import type { StoreApi, UseBoundStore } from 'zustand';
import type { GlobalStateStoreType } from './types';

export const createGlobalStateStoreSelectors = (
  store: UseBoundStore<StoreApi<GlobalStateStoreType>>,
) => {
  const storeSelectors = {
    pageInputs: () => store((state) => state.pageInputs),
    location: () => store((state) => state.location),
    userContext: () => store((state) => state.userContext),
    deviceDetails: () => store((state) => state.deviceDetails),
    permissions: () => store((state) => state.permissions),
    dataSources: () => store((state) => state.dataSources),
    dataSourcesMeta: () => store((state) => state.dataSourcesMeta),
    blocks: () => store((state) => state.blocks),
    pageVariables: () => store((state) => state.pageVariables),
    pageFunctions: () => store((state) => state.pageFunctions),
    interfaceSessionStorage: () => store((state) => state.interfaceSessionStorage),
    actions: () => store.getState().actions,
    manuallyTriggeredDataSources: () => store((state) => state.manuallyTriggeredDataSources),
    previewTriggeredDataSources: () => store((state) => state.previewTriggeredDataSources),
    liveUpdateDetails: () => store((state) => state.live),
    contextualDialogsForBlocks: () => store((state) => state.contextualDialogForBlocks),
  };

  const dataSourceSelectors = {
    result: (dataSourceId: string) => {
      return store((state) => state.dataSources[dataSourceId]);
    },
    meta: (dataSourceId: string) => {
      return store((state) => state.dataSourcesMeta[dataSourceId]);
    },
    dependencyFlowStatus: (dataSourceId: string) => {
      return store((state) => state.dependencyFlowManager.dataSources[dataSourceId]?.status);
    },
    triggered: {
      manually: (dataSourceId: string) => {
        return store((state) => state.manuallyTriggeredDataSources[dataSourceId]);
      },
      preview: (dataSourceId: string) => {
        return store((state) => state.previewTriggeredDataSources[dataSourceId]);
      },
    },
    previewResult: (dataSourceId: string) => {
      return store((state) => state.dataSourcesMeta[dataSourceId]?.previewResult);
    },
  };

  const blockSelectors = {
    details: (blockId: string) => {
      return store((state) => state.blocks[blockId]);
    },
    ref: (blockId: string) => {
      return store((state) => state.blockRefs[blockId]);
    },
    contextualDialog: (blockId: string) => {
      return store((state) => state.contextualDialogForBlocks[blockId]);
    },
  };

  const intraDependencySelectors = {
    dataSources: () => {
      return store((state) => state.intraDependencies.dataSources);
    },
  };

  const dependencyFlowSelectors = {
    dataSources: () => {
      return store((state) => state.dependencyFlowManager.dataSources);
    },
  };

  const flowsSelectors = {
    importObjectRecords: () => store((state) => state.flows.importObjectRecords),
  };

  const pageFunctionSelectors = {
    details: (pageFunctionId: string) => {
      return store((state) => state.pageFunctions[pageFunctionId]);
    },
  };

  const liveUpdateSelectors = {
    versionUpdates: () => store((state) => state.live.versionUpdates),
    parallelUpdates: () => store((state) => state.live.parallelUpdates),
  };

  return Object.assign(store, {
    use: {
      ...storeSelectors,
      dataSource: dataSourceSelectors,
      block: blockSelectors,
      flows: flowsSelectors,
      pageFunction: pageFunctionSelectors,
      intraDependencies: intraDependencySelectors,
      dependencyFlow: dependencyFlowSelectors,
      liveUpdates: liveUpdateSelectors,
    },
  });
};
