import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { TypographyComponentType } from '@unifyapps/defs/blocks/Typography/types';
import { importBlockEditorHTMLContent } from '@unifyapps/carbon/components/HtmlContent';
import Typography from './Typography';

class TypographyBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Typography';
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Typography;
  }

  getSlotOrder(block: BlockType): { order: string[] } {
    return {
      order: ['collapsedTypography', 'expandedTypography'],
    };
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  preload() {
    return {
      miscellaneous: (block?: BlockType<TypographyComponentType>) => {
        const response = [] as unknown[];
        if (block?.component.content.type === 'HTML') {
          response.push(importBlockEditorHTMLContent);
        }
        return response as (() => Promise<void>)[];
      },
    };
  }
}

export default TypographyBlockStateDefinition;
