import type { Event } from '@unifyapps/defs/types/event';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { ActionType } from '@unifyapps/defs/types/action';
import { useMemo } from 'react';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import { getOriginalBlockId } from '../../utils/runTimeBlocks';

function useDerivedEvents({ blockStateId = '' }: { blockStateId?: string }) {
  const contextualDialogId = useGlobalStateStore().use.block.contextualDialog(
    getOriginalBlockId(blockStateId),
  );

  const contextualDialogEvents: Event[] = useMemo(() => {
    if (!contextualDialogId) return [];
    return [
      {
        id: 'contextual-dialog-toggle',
        eventType: EventTypeEnum.OnClick,
        action: {
          actionType: ActionType.ControlBlockMethod,
          payload: {
            blockId: contextualDialogId,
            methodName: 'toggleContextualDialog',
            blockStateId,
          },
        },
      },
    ];
  }, [blockStateId, contextualDialogId]);

  return { derivedEvents: contextualDialogEvents };
}

export default useDerivedEvents;
