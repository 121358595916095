import type {
  ContextualDialogBlockStateType,
  ContextualDialogComponentType,
} from '../../blocks/ContextualDialog/types';
import type {
  CategoryFilterComponentType,
  CategoryFilterStateType,
} from '../../blocks/CategoryFilter/types';
import type { ConditionEvaluationObject } from '../conditionEvaluationObject';
import type {
  FileUploadBlockStateType,
  FileUploadComponentType,
} from '../../blocks/FileUpload/types';
import type { Event } from '../event';
import type {
  ButtonBlockStateType,
  ButtonComponentType,
  CameraComponentType,
  CardBlockStateType,
  CardComponentType,
  ComputedButtonBlockStateType,
  DeprecatedFormComponentType,
  DividerBlockStateType,
  DividerComponentType,
  EvaluatedField as KeyValueEvaluatedField,
  FiltersBlockStateType,
  FiltersComponentType,
  KeyValueBlockStateType,
  KeyValueComponentType,
  LinkBlockStateType,
  LinkComponentType,
  LoaderBlockStateType,
  LoaderComponentType,
  ModalBlockStateType,
  ModalComponentType,
  PDFViewerBlockStateType,
  PDFViewerComponentType,
  PillComponentType,
  SSOButtonComponentType,
  StackBlockStateType,
  StackComponentType,
  TagBlockStateType,
  TagComponentType,
  TimeFilterBarComponentType,
} from '../blocks';
import type { AlertBlockStateType, AlertComponentType } from '../../blocks/Alert/types';
import type { UAEntityTableComponentType as TableDeprecatedComponentType } from '../../blocks/Table/UAEntityTable/types';
import type {
  DataTableBlockStateType,
  DataTableComponentType,
  EvaluatedData,
} from '../../blocks/Table/DataTable/types';
import type {
  RepeatableBlockStateType,
  RepeatableComponentType,
} from '../../blocks/Repeatable/types';
import type { ContainerBlockStateType, ContainerComponentType } from '../../blocks/Container/types';
import type { ProgressBarComponentType, ProgressBarStateType } from '../../blocks/Progress/types';
import type {
  IconButtonBlockStateType,
  IconButtonComponentType,
} from '../../blocks/IconButton/types';
import type {
  EvaluatedField as StatsCardEvaluatedField,
  EvaluatedTrend as StatsCardEvaluatedTrend,
  StatCardBlockStateType,
  StatCardComponentType,
} from '../../blocks/StatCard/types';
import type {
  CarouselComponentStateType,
  CarouselComponentType,
} from '../../blocks/Carousel/types';
import type {
  TypographyBlockStateType,
  TypographyComponentType,
} from '../../blocks/Typography/types';
import type {
  ComputedTabBlockStateType,
  TabsBlockStateType,
  TabsComponentType,
} from '../../blocks/Tabs/types';
import type { IconBlockStateType, IconComponentType } from '../../blocks/Icon/types';
import type { ImageBlockStateType, ImageComponentType } from '../../blocks/Image/types';
import type {
  MultiStatsCardBlockStateType,
  MultiStatsCardComponentType,
} from '../../blocks/MultiStatsCard/types';
import type { ChatComponentType as CopilotChatComponentType } from '../../blocks/CopilotChat/types';
import type { DrawerBlockStateType, DrawerComponentType } from '../../blocks/Drawer/types';
import type {
  ChartComponentType,
  ChartStateType,
  ComputedChartStateType,
} from '../../blocks/Chart/types';
import type { FormBlockStateType, FormComponentType } from '../../blocks/Form/types';
import type { ModuleBlockStateType, ModuleComponentType } from '../../blocks/Module/types';
import type {
  CodeEditorBlockStateType,
  CodeEditorComponentType,
} from '../../blocks/CodeEditor/types';
import type { TimelineBlockStateType, TimelineComponentType } from '../../blocks/Timeline/types';
import type { TextInputComponentType, TextInputStateType } from '../../blocks/TextInput/types';
import type { StepperComponentStateType, StepperComponentType } from '../../blocks/Stepper/types';
import type {
  SteppedContainerComponentType,
  SteppedContainerStateType,
} from '../../blocks/SteppedContainer/types';
import type {
  ButtonGroupBlockStateType,
  ButtonGroupComponentType,
} from '../../blocks/ButtonGroup/types';
import type {
  NestedTableBlockStateType,
  NestedTableComponentType,
} from '../../blocks/Table/NestedTable/types';
import type { TimerBlockStateType, TimerComponentType } from '../../blocks/Timer/types';
import type { MenuComponentStateType, MenuComponentType } from '../../blocks/Menu/types';
import type {
  CommentsBlockStateType,
  CommentsComponentType,
  ComputedCommentsComponentType,
} from '../../blocks/Comments/types';
import type { CopilotBlockStateType, CopilotComponentType } from '../../blocks/Copilot/types';
import type {
  MessageInputBlockStateType,
  MessageInputComponentType,
  MessageInputComputedBlockStateType,
} from '../../blocks/MessageInput/types';
import type { MediaBlockStateType, MediaComponentType } from '../../blocks/Media/types';
import type { CalendarBlockStateType, CalendarComponentType } from '../../blocks/Calendar/types';
import type {
  AudioPlayerBlockStateType,
  AudioPlayerComponentType,
} from '../../blocks/AudioPlayer/types';
import type {
  BusinessHoursBlockStateType,
  BusinessHoursComponentType,
  BusinessHourValueType,
} from '../../blocks/BusinessHours/types';
import type { CitationBlockStateType, CitationComponentType } from '../../blocks/Citation/types';
import type {
  NavigationMenuComponentType,
  NavigationMenuStateType,
} from '../../blocks/NavigationMenu/types';
import type {
  NavigationContainerComponentType,
  NavigationContainerStateType,
} from '../../blocks/NavigationContainer/types';
import type { ChatBlockStateType, ChatComponentType } from '../../blocks/Chat/types';
import type { VideoBlockStateType, VideoComponentType } from '../../blocks/Video/types';
import type {
  CommentListBlockStateType,
  CommentListComponentType,
  CommentListComputedBlockStateType,
} from '../../blocks/CommentList/types';
import type {
  CommentInputBlockStateType,
  CommentInputComponentType,
} from '../../blocks/CommentInput/types';
import type { ScannerBlockStateType, ScannerComponentType } from '../../blocks/Scanner/types';
import type { ConditionalPath, DependsOnType, DynamicPath } from '../page';
import type {
  OrganizationChartBlockStateType,
  OrganizationChartComponentType,
  OrganizationChartComputedBlockStateType,
} from '../../blocks/OrganizationChart/types';
import type { QRCodeBlockStateType, QRCodeComponentType } from '../../blocks/QRCode/types';
import type {
  CopilotMessageBlockStateType,
  CopilotMessageComponentType,
} from '../../blocks/CopilotMessage/types';

export type VisibilityPayloadType = {
  value?: true | false | 'conditions';
  conditions?: ConditionEvaluationObject;
};

export type ComputedVisibilityPayloadType = {
  value?: true | false | 'conditions';
  conditions?: boolean;
};

export interface BlockType<T = ComponentsUnionType> {
  /**
   * The unique identifier of the block
   */
  id: string;
  /**
   * The parent block id of the block
   */
  parentId?: BlockId;
  /**
   * The display name of the block to be shown in the editor. It has no use outside the editor.
   */
  // we always set a display name when creating a block, but for backwards compatibility we need to make it optional
  displayName?: string;
  dataSourceIds?: string[];
  /**
   * The query IDs to not suspend
   */
  nonBlockingDataSourceIds?: string[];
  /**
   * Events associated with this component.
   */
  events?: Event[];
  // visibility is used to hide/show the block based on conditions
  visibility?: VisibilityPayloadType;
  fallback?: T;
  component: T;
  permissions?: Record<string, string[]>;
  dP?: DynamicPath[];
  cP?: ConditionalPath[];
  dpOn?: DependsOnType[];
  additional?: {
    isRootBlock?: boolean;
    customCSS?: string;
  };
}

export type ComponentsUnionType =
  | StackComponentType
  | PillComponentType
  | TypographyComponentType
  | DividerComponentType
  | SSOButtonComponentType
  | LinkComponentType
  | DeprecatedFormComponentType
  | ButtonComponentType
  | ImageComponentType
  | LoaderComponentType
  | TableDeprecatedComponentType
  | DataTableComponentType
  | NestedTableComponentType
  | TimeFilterBarComponentType
  | TabsComponentType
  | ContainerComponentType
  | CardComponentType
  | KeyValueComponentType
  | AlertComponentType
  | RepeatableComponentType
  | ProgressBarComponentType
  | IconButtonComponentType
  | IconComponentType
  | StatCardComponentType
  | MultiStatsCardComponentType
  | CopilotChatComponentType
  | ChartComponentType
  | DrawerComponentType
  | ModalComponentType
  | FormComponentType
  | TagComponentType
  | FiltersComponentType
  | ModuleComponentType
  | CodeEditorComponentType
  | TimelineComponentType
  | FileUploadComponentType
  | BusinessHoursComponentType
  | TextInputComponentType
  | CategoryFilterComponentType
  | CarouselComponentType
  | CameraComponentType
  | ContextualDialogComponentType
  | StepperComponentType
  | SteppedContainerComponentType
  | ButtonGroupComponentType
  | MenuComponentType
  | TimerComponentType
  | CopilotComponentType
  | CommentsComponentType
  | MessageInputComponentType
  | MediaComponentType
  | CalendarComponentType
  | AudioPlayerComponentType
  | PDFViewerComponentType
  | CitationComponentType
  | NavigationMenuComponentType
  | NavigationContainerComponentType
  | ChatComponentType
  | VideoComponentType
  | CommentListComponentType
  | CommentInputComponentType
  | ScannerComponentType
  | OrganizationChartComponentType
  | QRCodeComponentType
  | CopilotMessageComponentType;

export type BlockStateUnionType = (
  | ButtonBlockStateType
  | KeyValueBlockStateType
  | StackBlockStateType
  | ContainerBlockStateType
  | IconBlockStateType
  | DividerBlockStateType
  | ProgressBarStateType
  | IconButtonBlockStateType
  | CardBlockStateType
  | ImageBlockStateType
  | LinkBlockStateType
  | MultiStatsCardBlockStateType
  | TabsBlockStateType
  | TypographyBlockStateType
  | StatCardBlockStateType
  | AlertBlockStateType
  | LoaderBlockStateType
  | RepeatableBlockStateType
  | DataTableBlockStateType
  | NestedTableBlockStateType
  | ChartStateType
  | DrawerBlockStateType
  | ModalBlockStateType
  | CodeEditorBlockStateType
  | FormBlockStateType
  | TagBlockStateType
  | FiltersBlockStateType
  | ModuleBlockStateType
  | TimelineBlockStateType
  | FileUploadBlockStateType
  | BusinessHoursBlockStateType
  | TextInputStateType
  | CategoryFilterStateType
  | CarouselComponentStateType
  | CameraComponentType
  | ContextualDialogBlockStateType
  | StepperComponentStateType
  | SteppedContainerStateType
  | ButtonGroupBlockStateType
  | MenuComponentStateType
  | CopilotBlockStateType
  | TimerBlockStateType
  | CommentsBlockStateType
  | MessageInputBlockStateType
  | MediaBlockStateType
  | CalendarBlockStateType
  | AudioPlayerBlockStateType
  | PDFViewerBlockStateType
  | CitationBlockStateType
  | NavigationMenuStateType
  | NavigationContainerStateType
  | ChatBlockStateType
  | VideoBlockStateType
  | CommentListBlockStateType
  | CommentInputBlockStateType
  | ScannerBlockStateType
  | OrganizationChartBlockStateType
  | QRCodeBlockStateType
  | CopilotMessageBlockStateType
) & {
  id: string;
  //for run time block state
  originalBlockId?: string;
  context?: Record<string, unknown>;
  events?: Event[];
};

export type ComputedBlockStateUnionType = (
  | ComputedButtonBlockStateType
  | KeyValueBlockStateType<KeyValueEvaluatedField>
  | StackBlockStateType
  | ContainerBlockStateType
  | IconBlockStateType
  | DividerBlockStateType
  | ProgressBarStateType
  | IconButtonBlockStateType
  | CardBlockStateType
  | ImageBlockStateType
  | LinkBlockStateType
  | MultiStatsCardBlockStateType<StatsCardEvaluatedField, StatsCardEvaluatedTrend>
  | ComputedTabBlockStateType
  | TypographyBlockStateType
  | StatCardBlockStateType<StatsCardEvaluatedField, StatsCardEvaluatedTrend>
  | AlertBlockStateType
  | LoaderBlockStateType
  | RepeatableBlockStateType<unknown>
  | DataTableBlockStateType<EvaluatedData>
  | ComputedChartStateType
  | DrawerBlockStateType
  | ModalBlockStateType
  | CodeEditorBlockStateType
  | FormBlockStateType
  | TagBlockStateType
  | FiltersBlockStateType
  | ModuleBlockStateType
  | TimelineBlockStateType<Record<string, string | number>[]>
  | FileUploadBlockStateType
  | BusinessHoursBlockStateType<BusinessHourValueType>
  | TextInputStateType
  | CategoryFilterStateType
  | CarouselComponentStateType
  | CameraComponentType
  | ContextualDialogBlockStateType
  | StepperComponentStateType
  | SteppedContainerStateType
  | ButtonGroupBlockStateType
  | MenuComponentStateType
  | CopilotBlockStateType
  | TimerBlockStateType
  | ComputedCommentsComponentType
  | MessageInputBlockStateType
  | MediaBlockStateType
  | CalendarBlockStateType
  | AudioPlayerBlockStateType
  | PDFViewerBlockStateType
  | CitationBlockStateType
  | NavigationMenuStateType
  | NavigationContainerStateType
  | ChatBlockStateType
  | VideoBlockStateType
  | CommentListComputedBlockStateType
  | CommentInputBlockStateType
  | ScannerBlockStateType
  | MessageInputComputedBlockStateType
  | OrganizationChartComputedBlockStateType
  | QRCodeBlockStateType
  | CopilotMessageBlockStateType
) & {
  id: string;
  //for run time block state
  originalBlockId?: string;
  context?: Record<string, unknown>;
  events?: Event[];
};

export enum ComposedBlockComponentType {
  RepeatableList = 'RepeatableList',
  Navigation = 'Navigation',
}

export type ComponentTypeUnionType = ComponentsUnionType['componentType'];

export type BlockTypeUnionType =
  | BlockType['component']['componentType']
  | ComposedBlockComponentType;

export type BlockComponentType<
  ComponentType extends string,
  Content extends object,
  Appearance extends object,
  Slots = undefined,
> = {
  componentType: ComponentType;
  content: Content;
  appearance?: Appearance;
  context?: Record<string, unknown>;
  slots?: Slots;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type BlockComponentWithRequiredAppearance<
  ComponentType extends string,
  Content extends object,
  Appearance extends object,
  Slots = undefined,
> = WithRequired<BlockComponentType<ComponentType, Content, Appearance, Slots>, 'appearance'>;

export type BlockId = string | null;
export type BlockIds = BlockId[];

export type BlockComponentStateId = {
  id: string;
  originalBlockId?: string;
};

export type BlockCompositionData = {
  // Controls whether the display name of the block should be preserved during the creation process.
  // If set to true, the display name provided for the block will be maintained and not automatically altered
  // or overridden by any naming conventions or automatic generation logic. This is useful when the display name
  // needs to remain consistent for tracking, identification, or user interface purposes, even after the block
  // is created. If false or undefined, the display name may be generated or modified according to the system's
  // standard rules for naming blocks during creation.
  persistDisplayName?: boolean;
  childBlocks?: Record<string, BlockType>;
  mergeWithBlockInitialFormData?: boolean;
  compose?: ({
    rootBlock,
    childBlocks,
  }: {
    rootBlock: BlockType;
    childBlocks: Record<string, BlockType>;
  }) => {
    rootBlock: BlockType;
    childBlocks: Record<string, BlockType>;
  };
};
