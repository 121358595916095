import { useCallback } from 'react';
import { getComputedData } from '../../utils/getComputedData';
import { useGetShouldEvaluateResult } from './useGetShouldEvaluateResult';

export function useGetNoCodeComputedData() {
  const shouldEvaluateResult = useGetShouldEvaluateResult();

  const getNoCodeComputedData = useCallback(
    <T>(data: Record<string, unknown> | string | unknown[], context: Record<string, unknown>) => {
      return getComputedData<T>(data, context, shouldEvaluateResult);
    },
    [shouldEvaluateResult],
  );

  return { getNoCodeComputedData, shouldEvaluateResult };
}

export type UseGetNoCodeComputeDataType = ReturnType<typeof useGetNoCodeComputedData>;
