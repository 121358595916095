import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type {
  CodeEditorBlockRef,
  CodeEditorComponentType,
} from '@unifyapps/defs/blocks/CodeEditor/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type { Action } from '@unifyapps/defs/types/action';

const importCodeEditorComponent = () =>
  import(
    /* webpackChunkName: "code-editor-block" */
    './CodeEditor'
  );

const CodeEditor = lazy(importCodeEditorComponent);

class CodeEditorBlockStateDefinition implements BlockStateDefinition {
  useBlockRef?: boolean | undefined = true;

  getComponent() {
    return CodeEditor;
  }

  get type(): ComponentTypeUnionType {
    return 'CodeEditor';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'insertText' as const,
        label: 'Insert Text',
        method: (blockState, action: Action, blockRef?: CodeEditorBlockRef) => {
          const methodPayload = action.payload?.methodPayload as { text: string } | undefined;
          blockRef?.insertTextAtCursor?.(methodPayload?.text ?? '');
          return Promise.resolve();
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as CodeEditorComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
    });
  }

  preload() {
    return {
      blockPreload: importCodeEditorComponent,
    };
  }
}

export default CodeEditorBlockStateDefinition;
