import { useMemo } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import * as React from 'react';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import Media from '../../../Media';
import { type MediaItemProps, MediaVariants } from '../../../Media/types';
import { SpaciousMediaItem } from '../../../Media/components/SpaciousMediaItem';

function ImageItem({ item, onRemoveItem, onClick, overrides }: MediaItemProps) {
  return (
    <SpaciousMediaItem
      className="!size-12 flex-none"
      item={item}
      onClick={onClick}
      onRemoveItem={onRemoveItem}
      overrides={overrides}
      showCaption={false}
      size="sm"
    />
  );
}

const OVERRIDES = { ImageItem };

const STYLE = {
  //since the Root component has padding of 12px, we need to adjust the width of the list to avoid horizontal scroll getting clipped
  '--margin-inline': '-12px',
  '--attachment-list-width': 'calc(100% + 24px)',
};

export function AttachmentsList({
  attachments,
  onRemoveAttachment,
}: {
  attachments: FileType[];
  onRemoveAttachment?: (id: string) => void;
}) {
  const media = useMemo(
    () =>
      attachments.map((attachment) => ({
        ...attachment,
        isLoading: attachment.isUploaded === false,
      })),
    [attachments],
  );

  return (
    <Stack
      className="-mt-lg mx-[var(--margin-inline)] w-[var(--attachment-list-width)] items-center overflow-x-auto"
      direction="row"
      gap="2"
      style={STYLE as React.CSSProperties}
    >
      <Media
        className="pt-lg px-lg !flex h-full flex-row flex-nowrap overflow-x-auto"
        cols={4}
        itemClassName="!max-w-[200px]"
        maxUpfrontItems={100}
        media={media}
        onRemoveItem={onRemoveAttachment}
        overrides={OVERRIDES}
        size="md"
        variant={MediaVariants.Compact}
      />
    </Stack>
  );
}
