/* eslint-disable @typescript-eslint/no-extraneous-class -- needed here */
import type { CurrentUserDetails } from '@unifyapps/network/generated/models/currentUserDetails';

export class UserHelper {
  static getLocale(userContext: CurrentUserDetails | undefined) {
    return (
      userContext?.user?.locale ??
      (userContext?.user?.customProperties?.locale as string | undefined)
    );
  }
}
