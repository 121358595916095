import { useCallback } from 'react';
import type { InterfacePermissions } from '@unifyapps/defs/types/interface';
import { useGetGlobalStateStoreState } from '../../stores/GlobalStateStore';
import { hasAccess } from '../utils/hasAccess';
import { useInterfaceStore } from '../../stores/InterfaceStore';

export const useGetCanAccess = () => {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const interfaceMode = useInterfaceStore().use.mode();

  const canAccess = useCallback(
    (permissionsToAccess?: InterfacePermissions) => {
      const currentPermissions = getGlobalStateStoreState().permissions;
      return hasAccess({
        currentPermissions,
        permissionsToAccess,
        mode: interfaceMode,
      });
    },
    [getGlobalStateStoreState, interfaceMode],
  );

  return canAccess;
};
