'use client';

import { clsx } from 'clsx';
import { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import useDebugging from '../../hooks/useDebugging';
import {
  useGetDeviceDetailsInStateStore,
  useGlobalStateStore,
} from '../../stores/GlobalStateStore';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import { useRegistryContext } from '../RegistryProvider';
import PageLayout, { PageFooter, PageHeader, PageWorkspace } from '../../../components/PageLayout';
import { NEW_BLOCK_ID } from '../../const';
import { getPageLayout } from './getPageBody';
import type { InterfacePageProps } from './types';

type Content = { blockIds?: string[] } | undefined;

const getIsBlockEmpty = (content: Content) => {
  if (!content) return true;
  if (!content.blockIds?.length) return true;
  return content.blockIds.length === 1 && content.blockIds[0] === NEW_BLOCK_ID;
};

export function InterfacePage({
  name,
  layout,
  className,
  workspaceClassName,
  ignorePageRefSettingInStore,
  device,
  setInterfacePageEl,
  BlockRenderer,
}: InterfacePageProps) {
  const { header, body, footer } = getPageLayout(layout);
  const blocks = useGlobalStateStore().use.blocks();
  const isFooterEmpty = footer ? getIsBlockEmpty(blocks[footer] as Content) : true;
  const isHeaderEmpty = header ? getIsBlockEmpty(blocks[header] as Content) : true;

  useDebugging();

  return (
    <>
      <title>{name || 'Page'}</title>
      <PageLayout
        className={className}
        ref={ignorePageRefSettingInStore ? undefined : setInterfacePageEl}
      >
        {isHeaderEmpty ? (
          <PageHeader headerClassName="!p-0 !basis-auto">
            {header ? <BlockRenderer blockId={header} key={header} /> : null}
          </PageHeader>
        ) : null}
        {body ? (
          <PageWorkspace
            className={clsx(
              'overflow-y-auto',
              {
                'rounded-t-none': !header,
                'rounded-b-3xl': Boolean(footer),
                '!me-0': (!header && !footer) || device === DeviceVariantType.MOBILE,
              },
              workspaceClassName,
            )}
          >
            {body ? <BlockRenderer blockId={body} key={body} /> : null}
          </PageWorkspace>
        ) : null}
        {isFooterEmpty ? (
          // we don't want to add padding to the footer in no-code app
          <PageFooter className="!p-0">
            {footer ? <BlockRenderer blockId={footer} key={footer} /> : null}
          </PageFooter>
        ) : null}
      </PageLayout>
    </>
  );
}

export type Props = {
  className?: string;
  workspaceClassName?: string;
  ignorePageRefSettingInStore?: boolean;
};

function InterfacePageContainer(props: Props) {
  const layout = useInterfaceStore().use.page.layout();
  const name = useInterfaceStore().use.page.name();
  const { device } = useGetDeviceDetailsInStateStore();
  const { setInterfacePageEl } = useInterfaceStore().use.actions();
  const BlockRenderer = useRegistryContext().registry.getBlockRenderer();
  return (
    <InterfacePage
      BlockRenderer={BlockRenderer}
      device={device}
      layout={layout}
      name={name}
      setInterfacePageEl={setInterfacePageEl}
      {...props}
    />
  );
}

export default InterfacePageContainer;
