import { createContext, useContext } from 'react';
import type { ModalVariant } from './Modal';

type ModalProps = { variant: ModalVariant };

export const ModalStateContext = createContext<ModalProps | null>(null);

export const useModalStateProvider = () => {
  const context = useContext(ModalStateContext);
  if (context === null) {
    throw new Error('useModalStateProvider must be used within a ModalStateProvider');
  }
  return context;
};
