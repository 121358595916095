export const enum UAApps {
  StorageByUA = 'storage_by_unifyapps',
  CommentsByUnifyApps = 'comments_by_unifyapps',
  Callables = 'callables',
  AuthByUA = 'auth_by_unifyapps',
  CodeByUA = 'code_by_unifyapps',
  CustomHttpEndpoint = 'custom_http_endpoint',
  PlatformByUA = 'platform_by_unifyapps',
  StandardEntities = 'standard_entities',
}

export const enum UAAppResources {
  CallablesReturnResponseToApi = 'callables_return_response_to_api',
  CustomHttpEndpointExecute = 'custom_http_endpoint_execute',
  EDIToolsByUAGenerateEDI = 'edi_tools_by_unifyapps_generate_edi',
}
