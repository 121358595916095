import _reduce from 'lodash/reduce';
import { ActionType } from '@unifyapps/defs/types/action';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { OnActionType } from '../../ActionsProvider/context';

export const getPageVariablesMethods = ({
  pageVariables,
  onAction,
}: {
  pageVariables?: InterfacePageEntity['properties']['pageVariables'];
  onAction: OnActionType;
}) => {
  return _reduce(
    pageVariables,
    (acc, pageVariable) => {
      acc[pageVariable.id] = {
        setValue: (value: unknown) => {
          void onAction({
            action: {
              actionType: ActionType.SetPageVariable,
              payload: {
                variableId: pageVariable.id,
                operationDetails: {
                  operation: 'SET',
                  value,
                },
              },
            },
            actionContext: {},
          });
        },
      };
      return acc;
    },
    {},
  );
};
