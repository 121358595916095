import { default as BaseStack } from '@unifyapps/ui/_components/Stack';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import type { StackBlockStateType, StackComponentType } from '@unifyapps/defs/blocks/Stack/types';
import { getBlockKey } from '@unifyapps/carbon/no-code/utils/block';
import { clsx } from 'clsx';
import { memo } from 'react';
import { getDirection } from './utils';

function Stack({
  blockState,
  dataAttributes,
  computedBlockState,
  registry,
}: BlockComponentProps<StackComponentType, StackBlockStateType, StackBlockStateType>) {
  const { content, appearance } = computedBlockState;

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const BlockRenderer = registry.getBlockRenderer();
  const wrapClassName = appearance?.wrapContent ? 'flex-wrap' : 'flex-nowrap';

  return (
    <BaseStack
      alignItems={appearance?.alignItems}
      className={clsx(className, wrapClassName)}
      direction={getDirection(appearance)}
      justifyContent={appearance?.justifyContent}
      style={style}
      {...dataAttributes}
    >
      {content.blockIds?.map((blockId, index) => (
        <BlockRenderer
          blockId={blockId}
          key={getBlockKey(blockId, index)}
          parentBlockId={blockState.id}
        />
      ))}
    </BaseStack>
  );
}

export default memo(Stack);
