import {
  BLOCK_PREFIX,
  ENTITY_DATA_SOURCE_PREFIX,
  ENTITY_DATA_SOURCE_SEPARATOR,
  ENTITY_PREFIX,
  FUNCTION_PREFIX,
  RUN_TIME_BLOCK_KEY,
  VARIABLE_PREFIX,
} from '../const';
import { RESERVED_COMPUTE_CONTEXT_KEYS } from '../hooks/computeContext/const';

const allPrefixes = [
  VARIABLE_PREFIX,
  FUNCTION_PREFIX,
  RUN_TIME_BLOCK_KEY,
  BLOCK_PREFIX,
  ENTITY_PREFIX,
].map((prefix) => `${prefix}_`);

const RESERVED_LOGIN_DATASOURCE_IDS = [
  'usernameLoginRequest',
  'loginRequest',
  'fetchIDPs',
  'emailAndPassLoginRequest',

  'ua_governance_ds_send_forget_password_email',

  'resendCode',
  'verifyCode',

  'emailAndPasswordPublicLogin',

  'ua_governance_data_source_valid_session_id',
  'ua_governance_data_source_reset_password',

  'updatePasswordDatasource',
];

const allEntities = [
  ...RESERVED_LOGIN_DATASOURCE_IDS,
  ...allPrefixes,
  // adding ENTITY_DATA_SOURCE_PREFIX separate because it doesn't end with _ but rather $
  `${ENTITY_DATA_SOURCE_PREFIX}${ENTITY_DATA_SOURCE_SEPARATOR}`,
  ...RESERVED_COMPUTE_CONTEXT_KEYS,
];

export const isNoCodeEntityId = (id: string) => {
  return allEntities.some((prefix) => id.startsWith(prefix));
};
