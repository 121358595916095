/*
Visual Structure Example:
Root
├── Repeatable_1
│   ├── Card_1
│   └── Repeatable_2
│       └── Card_2
└── Repeatable_3
    └── Card_3

Output:
{
  schemas: [...transformed schemas],
  childBlocksMetadata: {
    'card_1': { title: 'Card 1', icon: 'NoCodeCard' },
    'card_2': { title: 'Card 2', icon: 'NoCodeCard' },
    ...
  }
}
*/

import _keyBy from 'lodash/keyBy';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _filter from 'lodash/filter';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import { ID_SEPARATOR } from '@unifyapps/form/const';
import type { JSONTreeSchemaObject } from '../../types';
import type { NodeMetadata } from '../../components/JSONTree/types';
import type { FlattenedItem } from '../types/flattenedItem';
import { isRuntimeSourceBlock } from './runTimeBlocks';

export function getRuntimeSourceBlockIdToChildrenMap({
  flattenedBlocks,
  blocks,
}: {
  flattenedBlocks: FlattenedItem[];
  blocks: Record<string, BlockType | undefined>;
}) {
  const runtimeSourceBlocks = _filter(blocks, (block) =>
    block ? isRuntimeSourceBlock(block) : false,
  ) as BlockType[];

  const runtimeSourceBlockIds = new Set(runtimeSourceBlocks.map((block) => block.id));
  const blockIdVsClosestRuntimeSourceBlockAncestor = new Map<string, string>();

  _forEach(flattenedBlocks, (item: FlattenedItem) => {
    const { visualPath, blockId, componentType } = item as {
      visualPath: string;
      blockId: string;
      componentType: ComponentTypeUnionType | undefined;
    };

    if (!componentType) return;
    if (runtimeSourceBlockIds.has(blockId)) {
      const pathSegments = visualPath.split('.');
      const currentBlockIndex = pathSegments.indexOf(blockId);

      for (let i = currentBlockIndex - 1; i >= 0; i--) {
        const potentialruntimeSourceBlockParentId = pathSegments[i];
        if (runtimeSourceBlockIds.has(potentialruntimeSourceBlockParentId)) {
          blockIdVsClosestRuntimeSourceBlockAncestor.set(
            blockId,
            potentialruntimeSourceBlockParentId,
          );
          break;
        }
      }
      return;
    }

    const pathSegments = visualPath.split(ID_SEPARATOR);
    const currentBlockIndex = pathSegments.indexOf(blockId);

    for (let i = currentBlockIndex - 1; i >= 0; i--) {
      const potentialParentId = pathSegments[i];
      if (runtimeSourceBlockIds.has(potentialParentId)) {
        blockIdVsClosestRuntimeSourceBlockAncestor.set(blockId, potentialParentId);
        break;
      }
    }
  });

  const runtimeSourceBlockIdVsChildrenBlocks = _reduce(
    runtimeSourceBlocks,
    (acc, { id }) => {
      const childBlocks = _filter(
        flattenedBlocks,
        ({
          blockId,
          componentType,
        }: {
          blockId: string;
          componentType: ComponentTypeUnionType | undefined;
        }) => componentType && blockIdVsClosestRuntimeSourceBlockAncestor.get(blockId) === id,
      ) as FlattenedItem[];

      acc[id] = _filter(
        _map(childBlocks, ({ blockId }: { blockId: string }) => blocks[blockId]),
        Boolean,
      ) as BlockType[];

      return acc;
    },
    {} as Record<string, BlockType[] | undefined>,
  );

  return { runtimeSourceBlockIdVsChildrenBlocks, blockIdVsClosestRuntimeSourceBlockAncestor };
}

function getTransformedSchema(
  schema: JSONTreeSchemaObject,
  blocksSchemas: Record<string, JSONTreeSchemaObject | undefined>,
  runtimeSourceBlockIdVsChildrenBlocks: Record<string, BlockType[] | undefined>,
): JSONTreeSchemaObject {
  const children = runtimeSourceBlockIdVsChildrenBlocks[schema.id];

  if (!children) {
    return schema;
  }

  const childSchemas = _map(children, (block) => {
    const childSchema = blocksSchemas[block.id];
    if (!childSchema) return null;

    if (isRuntimeSourceBlock(block)) {
      return getTransformedSchema(childSchema, blocksSchemas, runtimeSourceBlockIdVsChildrenBlocks);
    }
    return childSchema;
  }).filter(Boolean) as JSONTreeSchemaObject[];

  return {
    ...schema,
    schema: {
      ...schema.schema,
      properties: {
        ...schema.schema.properties,
        items: {
          type: 'array',
          items: {
            type: 'object',
            properties: _reduce(
              childSchemas,
              (childAcc, childSchema) => {
                childAcc[childSchema.id] = { ...childSchema.schema, title: childSchema.title };
                return childAcc;
              },
              {} as Record<string, JSONTreeSchemaObject['schema']>,
            ),
          },
        },
      },
    },
  };
}

export function getAdaptedRunTimeBlockSchema({
  initialBlocksSchemas,
  flattenedBlocks,
  blocks,
}: {
  initialBlocksSchemas: JSONTreeSchemaObject[];
  flattenedBlocks: FlattenedItem[];
  blocks: Record<string, BlockType | undefined>;
}) {
  const { runtimeSourceBlockIdVsChildrenBlocks, blockIdVsClosestRuntimeSourceBlockAncestor } =
    getRuntimeSourceBlockIdToChildrenMap({
      flattenedBlocks,
      blocks,
    });
  const blocksSchemasMap = _keyBy(initialBlocksSchemas, 'id');

  //Block root pills inside runtime originator blocks should not be selectable
  //as they are not directly selectable by the user and block icon should be used
  const schemaPillsMetadata = _reduce(
    initialBlocksSchemas,
    (acc, schema) => {
      if (!blockIdVsClosestRuntimeSourceBlockAncestor.has(schema.id)) {
        return acc;
      }

      acc[schema.id] = {
        icon: schema.icon,
        extras: { isSelectable: false },
      };

      return acc;
    },
    {} as Record<string, NodeMetadata>,
  );

  const blocksSchemas = _reduce(
    initialBlocksSchemas,
    (acc, schema) => {
      //runtime originator blocks at top level should not be transformed
      if (blockIdVsClosestRuntimeSourceBlockAncestor.has(schema.id)) {
        return acc;
      }

      //other blocks need to be transformed
      acc.push(
        getTransformedSchema(schema, blocksSchemasMap, runtimeSourceBlockIdVsChildrenBlocks),
      );
      return acc;
    },
    [] as JSONTreeSchemaObject[],
  );

  return {
    blocksSchemas,
    schemaPillsMetadata,
  };
}
