import type { ReactNode } from 'react';
import type {
  TypographyColors,
  TypographyVariants,
  TypographyWeight,
} from '@unifyapps/ui/components/Typography';
import type { MediaType } from '../MediaViewer/types';

export enum MediaVariants {
  Compact = 'compact',
  Spacious = 'spacious',
}

export type MediaItemProps = {
  item: MediaType;
  onRemoveItem?: (id: string) => void;
  className?: string;
  onClick?: () => void;
  size: 'sm' | 'md' | 'lg';
  showCaption?: boolean;
  overrides?: MediaOverrides;
  slotProps?: SlotPropsType;
};

export type MediaOverrides = {
  ImageItem?: (props: Omit<MediaItemProps, 'overrides'>) => ReactNode;
};

export type SlotPropsType = {
  icon?: { className?: string };
  image?: { className?: string };
  overflowTypography?: {
    className?: string;
    color?: TypographyColors;
    variant?: TypographyVariants;
    weight?: TypographyWeight;
  };
  MediaLabel?: {
    weight?: TypographyWeight;
    style?: React.CSSProperties;
  };
  tooltip?: {
    disabled?: boolean;
  };
};