import { Bold } from '@tiptap/extension-bold';
import { TextStyle } from '@tiptap/extension-text-style';
import { FontFamily } from '@tiptap/extension-font-family';
import { Placeholder } from '@tiptap/extension-placeholder';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Document } from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Strike } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
import type { CSSProperties } from 'react';
import isUndefined from 'lodash/isUndefined';

export const RichTextEditorPlugins = {
  Bold: Bold.name,
  TextStyle: TextStyle.name,
  FontFamily: FontFamily.name,
  Placeholder: Placeholder.name,
  BulletList: BulletList.name,
  Document: Document.name,
  HardBreak: HardBreak.name,
  History: History.name,
  Italic: Italic.name,
  ListItem: ListItem.name,
  OrderedList: OrderedList.name,
  Paragraph: Paragraph.name,
  Strike: Strike.name,
  Text: Text.name,
};

// Matches various forms of empty paragraph tags at the end of a string:
// 1. <p>   </p> (with optional whitespace/&nbsp;)
// 2. <p /> (self-closing)
// 3. <p><br/></p> (with br having optional ProseMirror class)
// 4. <p><br><br>...</p> (with multiple <br> tags and optional last br with ProseMirror class)
// 5. Can match multiple consecutive empty paragraphs
const EMPTY_P_TAG_END_REGEX =
  /(?<temp1><p[^>]*>(?:(?:\s|&nbsp;|<br\s*\/?>|<br\s+class="ProseMirror-trailingBreak"\s*\/?>)*|)<\/p>\s*|<p\s*\/>\s*|<p><br\s*\/?>*(?:<br\s+class="ProseMirror-trailingBreak"\s*\/?>)*<\/p>\s*)+$/;
export function removeEmptyTrailingPTags(html: string) {
  const cleanHtml = html.replace(EMPTY_P_TAG_END_REGEX, '').trim();

  return cleanHtml || undefined;
}

const DEFAULT_LINE_HEIGHT = '1.25rem';
export const DEFAULT_MIN_NUMBER_OF_LINES = 1;

/**
 *
 * @param minNumberOfLines - minimum number of lines of richtext editor to show always
 * @param maxNumberOfLines - maximum number of lines of richtext editor to show upfront and then show scroll
 * @returns sets CSS variable for min and max number of lines
 */
export const getContainerStyles = (
  minNumberOfLines: number | undefined,
  maxNumberOfLines: number | undefined,
): CSSProperties => {
  return {
    '--min-number-of-lines': minNumberOfLines ? `${minNumberOfLines}` : DEFAULT_MIN_NUMBER_OF_LINES,
    ...(!isUndefined(maxNumberOfLines) && { '--max-number-of-lines': `${maxNumberOfLines}` }),
  } as CSSProperties;
};

/**
 *
 * @param minNumberOfLines - minimum number of lines of richtext editor to show always
 * @param maxNumberOfLines - maximum number of lines of richtext editor to show upfront and then show scroll
 * @returns minHeight, overflowY and maxHeight of richtext editor
 */
export const getEditorContentStyles = (
  maxNumberOfLines: number | undefined,
  styleState: Record<keyof CSSStyleDeclaration, string> | undefined,
): CSSProperties => {
  const paddingTop = styleState
    ? parseInt(styleState['padding-top' as keyof CSSStyleDeclaration], 10)
    : 0;
  const paddingBottom = styleState
    ? parseInt(styleState['padding-bottom' as keyof CSSStyleDeclaration], 10)
    : 0;

  const totalVerticalPadding = paddingTop + paddingBottom;

  /**
   * Editor when used in MessageInput also receives minHeight and maxHeight,
   * But if min-number-of-lines and maxNumberOfLines is provided,
   * this styles should be applied
   */
  return {
    minHeight: `calc(var(--typography-line-height, ${DEFAULT_LINE_HEIGHT})* var(--min-number-of-lines) + ${totalVerticalPadding}px) !important`,
    ...(maxNumberOfLines && {
      maxHeight: `calc(var(--typography-line-height, ${DEFAULT_LINE_HEIGHT}) * var(--max-number-of-lines) + ${totalVerticalPadding}px) !important`,
      overflowY: 'auto',
    }),
  };
};
