'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { MqttClient, PacketCallback } from 'mqtt';
import { useUnmount } from 'react-use';
import { useGetMqttConnectionDetails } from './generated/mqtt-rest-api/mqtt-rest-api';

export { MqttClient, type PacketCallback };

type MqttContextType = {
  client: MqttClient | null;
  // connect mqtt client if not connected
  connectClient: () => Promise<void>;
  // disconnect mqtt client
  disconnectClient: () => void;
  // publish a new message to a topic
  publish: MqttClient['publish'];
  // subscribe to a topic and listen for messages
  subscribeToTopic: (topic: string, callback: (message: string | Buffer) => void) => void;
  // unsubscribe from a topic
  unsubscribeFromTopic: (topic: string) => void;
};

const MqttContext = createContext<MqttContextType | null>(null);

function MqttProvider({ children, resourceType }: PropsWithChildren<{ resourceType: string }>) {
  const [client, setClient] = useState<MqttClient | null>(null);

  const { data: connectionData } = useGetMqttConnectionDetails(
    { resourceTypes: [resourceType] },
    { query: { staleTime: Infinity } },
  );

  const brokerUrl = connectionData?.[0]?.brokerUrl;
  const jwtToken = connectionData?.[0]?.jwtToken;

  const isConnectingRef = useRef(false);

  const connectClientIfRequired = useCallback(async () => {
    if (!brokerUrl || brokerUrl.startsWith('wss://null')) return; // because backend sometimes return url like 'wss://null/mqtt'
    if (client && client.connected) {
      return;
    }
    if (isConnectingRef.current) {
      return;
    }
    isConnectingRef.current = true;
    try {
      const mqtt = (
        await import(
          /* webpackChunkName: "lib-mqtt" */
          'mqtt'
        )
      ).default;
      const newClient = mqtt.connect(brokerUrl, { username: '', password: jwtToken });
      newClient.on('connect', () => {
        console.debug('mqtt client: connected');
      });
      newClient.on('error', (error) => {
        console.error('mqtt client: error:', error);
      });
      setClient(newClient);
    } catch (error) {
      console.error('mqtt client: Error connecting to mqtt broker', error);
      isConnectingRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- donot add client in dependency as it will cause recursive connect calls
  }, [brokerUrl, jwtToken]);

  const disconnectClient = useCallback(() => {
    client?.end();
    isConnectingRef.current = false;
  }, [client]);

  useUnmount(() => {
    disconnectClient();
  });

  const mqttContextValue = useMemo<MqttContextType>(
    () => ({
      client,
      connectClient: connectClientIfRequired,
      disconnectClient,
      publish: client?.publish.bind(client) as MqttClient['publish'],
      unsubscribeFromTopic: (topic: string) => {
        try {
          if (!client) {
            console.error(
              'mqtt client: No client connected, connectClient method should be called before unsubscribing from a topic.',
            );
            return;
          }

          client.unsubscribe(topic, (err) => {
            if (err) {
              console.debug(`mqtt client: Error unsubscribing from ${topic}:`, err);
            } else {
              console.debug(`mqtt client: Unsubscribed from ${topic}`);
            }
          });
        } catch (error) {
          console.debug('mqtt client: Error unsubscribe from MQTT topic', error);
        }
      },
      subscribeToTopic: (topic: string, callback: (message: string | Buffer) => void) => {
        try {
          if (!client) {
            console.error(
              'mqtt client: No client connected, connectClient method should be called before subscribing to a topic.',
            );
            return;
          }

          client.subscribe(topic, (err) => {
            if (err) {
              console.debug(`mqtt client: Error subscribing to ${topic}:`, err);
            } else {
              console.debug(`mqtt client: Subscribed to ${topic}`);
            }
          });

          client.on('message', (_topic: string, message) => {
            if (_topic === topic) {
              callback(message);
            }
          });
        } catch (error) {
          console.debug('mqtt client: Error subscribing to MQTT topic', error);
        }
      },
    }),
    [client, connectClientIfRequired, disconnectClient],
  );

  return <MqttContext.Provider value={mqttContextValue}>{children}</MqttContext.Provider>;
}

const useMqttClient = (): MqttContextType => {
  const mqttContext = useContext(MqttContext);

  if (!mqttContext) {
    throw new Error('useMqttClient must be used within a MqttProvider');
  }

  return mqttContext;
};

export { MqttProvider, useMqttClient };
