import type { Step, StepperComponentStateType } from '@unifyapps/defs/blocks/Stepper/types';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { getConditionalIcon } from '@unifyapps/carbon/utils/conditionalValues';
import { useCallback, useEffect, useMemo } from 'react';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { Stepper } from '@unifyapps/ui/components/Stepper';
import { EMPTY_ARRAY } from '@unifyapps/carbon/consts/empty';
import { usePrevious } from '@react-hookz/web';
import isEqual from 'react-fast-compare';
import type { UpdateBlockStateType } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import { findStep, getFirstStepId } from '@unifyapps/ui/components/Stepper/utils';

export const getAccessorKey = (accessor: string | { path: string }): string => {
  return accessor && typeof accessor === 'object' ? accessor.path : accessor;
};

export function DynamicStepper({
  data,
  stepMappings,
  appearance,
  dataAttributes,
  onStepChange: _onStepChange,
  activeStepId,
  currentStep,
  updateBlockState,
}: {
  data?: unknown[];
  stepMappings?: Step;
  appearance: StepperComponentStateType['appearance'];
  dataAttributes?: Record<string, string>;
  onStepChange: ({ stepId, currentStep }: { stepId: string; currentStep: Step }) => void;
  activeStepId: string;
  currentStep?: Step;
  updateBlockState: UpdateBlockStateType<StepperComponentStateType>;
}) {
  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const { valueAccessorKey, labelAccessorKey, icon, descriptionAccessorKey } = useMemo(() => {
    return {
      valueAccessorKey: stepMappings?.value ? getAccessorKey(stepMappings.value) : undefined,
      labelAccessorKey: stepMappings?.label ? getAccessorKey(stepMappings.label) : undefined,
      descriptionAccessorKey: stepMappings?.description
        ? getAccessorKey(stepMappings.description)
        : undefined,
      icon: stepMappings?.icon,
    };
  }, [stepMappings?.description, stepMappings?.icon, stepMappings?.label, stepMappings?.value]);

  const steps = useMemo(
    () =>
      valueAccessorKey
        ? _map(data, (datum: Record<string, string>) => ({
            value: datum[valueAccessorKey],
            label: labelAccessorKey ? datum[labelAccessorKey] : '',
            description: descriptionAccessorKey ? datum[descriptionAccessorKey] : '',
            Icon: getConditionalIcon(icon),
          }))
        : (EMPTY_ARRAY as { label: string; description: string; value: string }[]),
    [valueAccessorKey, data, labelAccessorKey, descriptionAccessorKey, icon],
  );

  const previousSteps = usePrevious(steps);

  const onStepChange = useCallback(
    (stepId: string) => {
      const updatedCurrentStep = findStep(steps, stepId);

      _onStepChange({
        stepId,
        currentStep: updatedCurrentStep
          ? {
              value: updatedCurrentStep.value,
              label: updatedCurrentStep.label,
              description: updatedCurrentStep.description,
              icon: stepMappings?.icon,
            }
          : ({} as Step),
      });
    },
    [_onStepChange, stepMappings?.icon, steps],
  );

  const controlledState = useMemo(
    () => ({
      onStepChange,
      activeStepId,
    }),
    [activeStepId, onStepChange],
  );

  useEffect(() => {
    if (currentStep?.value !== activeStepId) {
      onStepChange(activeStepId);
    }
  }, [activeStepId, currentStep?.value, onStepChange, steps]);

  useEffect(() => {
    // initialize activeStepId to the first step if not set
    if (!activeStepId) {
      const firstStepId = getFirstStepId(steps);
      if (firstStepId) {
        onStepChange(firstStepId);
      }
    }
  }, [activeStepId, onStepChange, steps]);

  useEffect(() => {
    if (!isEqual(steps, previousSteps)) {
      updateBlockState((draft) => {
        draft.steps = steps;
      });
    }
  }, [previousSteps, steps, updateBlockState]);

  if (_isEmpty(data)) {
    return null;
  }

  const stepLabelClassName = appearance?.stepDetails.label?.color ?? '';
  const stepDescriptionClassName = appearance?.stepDetails.description?.color ?? '';

  return (
    <Stepper
      className={className}
      controlledState={controlledState}
      orientation={appearance?.orientation}
      size={appearance?.size}
      stepDescriptionClassName={stepDescriptionClassName}
      stepDetails={appearance?.stepDetails}
      stepLabelClassName={stepLabelClassName}
      steps={steps}
      style={style}
      {...dataAttributes}
    />
  );
}
