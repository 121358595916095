import { useRef, useState, memo, useMemo } from 'react';
import { useTranslation } from '@unifyapps/i18n/client';
import type { RichEditorProps } from '@unifyapps/editor/editors/RichTextEditor';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import { slowCn } from '@unifyapps/ui/lib/utils';
import _noop from 'lodash/noop';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import useControlledState from '@unifyapps/ui/hooks/useControlledState';
import type { CommentInputVariant } from '@unifyapps/defs/blocks/CommentList/types';
import { MessageInputActionType, StandardActionsEnum } from '../../../MessageInput/types';
import type { InputRef, MessageInputAction } from '../../../MessageInput/types';
import { BaseMessageInput } from '../../../MessageInput/MessageInput';
import { getPlaceHolderClassName, getRootClassName, getInputClassName } from './style';
import { useExtensions } from './extension/hooks/useExtensions';
import type { MentionItem } from './extension/UserMentionsList';
import type { KeyBindingsType } from './types';

const getSlotProps = ({
  extensions,
  canAddComment,
  inputClassName,
  size,
}: {
  canAddComment: boolean;
  extensions: RichEditorProps['extensions'];
  inputClassName: string;
  size?: 'sm' | 'md';
}) => ({
  textarea: {
    hideToolbar: !canAddComment,
    extensions,
    autofocus: canAddComment,
    minNumberOfLines: 2,
    inputClassName,
    rootClassName: '!outline-none !border-none',
    minRows: 2,
    className: 'resize-none',
  },
  root: {
    className: 'bg-primary',
  },
  endDecorator: {
    className: clsx('px-lg pb-md mt-0 w-full'),
  },
  actions: {
    className: size === 'sm' ? 'pb-md' : 'pb-xl',
  },
});

type CommentInputProps = {
  size?: 'sm' | 'md';
  autofocus?: boolean;
  variant?: CommentInputVariant;
  extensions?: RichEditorProps['extensions'];
  getMentionsItems?: ({ query }: { query: string }) => Promise<MentionItem[]>;
  className?: string;
  isMentionListActiveRef?: React.MutableRefObject<boolean>;
  editorKey?: string;
  style?: React.CSSProperties;
  actions: MessageInputAction[];
  input?: string | undefined;
  keyBindings?: KeyBindingsType[];
  onInputChange?: (value: string | undefined) => void;
};

function CommentInput({
  actions,
  size,
  getMentionsItems,
  autofocus = false,
  variant = 'richText',
  className,
  input: _input,
  onInputChange: _onInputChange,
  keyBindings,
  editorKey,
  extensions: _extensions,
  isMentionListActiveRef,
  ...rest
}: CommentInputProps) {
  const [input, onInputChange] = useControlledState({
    controlledValue: _input,
    defaultValue: '',
    onChange: _onInputChange,
  });

  const editorRef = useRef() as InputRef;
  const { t } = useTranslation(['interfaces']);
  const [canAddComment, setCanAddComment] = useState<boolean>(Boolean(autofocus));

  const isEditorEmpty = useEventCallback(() => {
    if (variant === 'textarea') {
      return !input;
    }
    return editorRef.current?.isEmpty?.();
  });

  const emptyEditor = useEventCallback(() => {
    if (variant === 'textarea') {
      onInputChange('');
    } else {
      editorRef.current?.clearContent?.();
      onInputChange('');
    }
  });

  const updatedActions = useMemo(
    () =>
      actions.map((action) => {
        if (action.type === MessageInputActionType.Standard) {
          if (action.action === StandardActionsEnum.SendMessage) {
            return {
              ...action,
              onClick: async ({
                message,
                attachments,
              }: {
                message: string | undefined;
                attachments: FileType[];
              }) => {
                setCanAddComment(false);
                await action.onClick({
                  message,
                  attachments,
                });
                emptyEditor();
              },
            };
          }
        }
        return action;
      }),
    [actions, emptyEditor],
  ) as MessageInputAction[];

  const onFocus = useEventCallback(() => {
    setCanAddComment(true);
  });

  const onBlur = useEventCallback(() => {
    if (isEditorEmpty()) {
      setCanAddComment(false);
      emptyEditor();
    }
  });

  const inputClassName = useMemo(
    () =>
      getInputClassName({
        size,
      }),
    [size],
  );

  const commentInputPlaceHolderClassName = useMemo(
    () =>
      getPlaceHolderClassName({
        className:
          'gap-lg mb-xl hover:bg-primary_hover border-tertiary flex w-full cursor-pointer items-center rounded-md border',
        size,
      }),
    [size],
  );

  const { extensions } = useExtensions({
    getMentionsItems,
    _extensions,
    isMentionListActiveRef,
  });

  const slotProps = useMemo(
    () => getSlotProps({ canAddComment, extensions, inputClassName, size }),
    [canAddComment, extensions, inputClassName, size],
  );

  const rootClassName = useMemo(
    () =>
      getRootClassName({
        size,
        variant,
      }),
    [size, variant],
  );

  if (!canAddComment) {
    return (
      <Box
        className={slowCn(commentInputPlaceHolderClassName, className)}
        onClick={onFocus}
        {...rest}
      >
        <Typography className="text-placeholder" variant={size === 'md' ? 'text-sm' : 'text-xs'}>
          {t('interfaces:Collaboration.AddComment')}
        </Typography>
      </Box>
    );
  }

  return (
    <BaseMessageInput
      actions={updatedActions}
      className={clsx(rootClassName, className)}
      editorKey={editorKey}
      inlineActions={false}
      inputRef={editorRef}
      isMentionListActiveRef={isMentionListActiveRef}
      keyBindings={keyBindings}
      onChange={onInputChange}
      onClickAway={onBlur}
      onFocus={onFocus}
      placeholder={t('interfaces:Collaboration.AddComment')}
      slotProps={slotProps}
      textareaClassName={clsx({
        '!p-0': variant === 'richText',
      })}
      value={input}
      variant={variant}
    />
  );
}

export default memo(CommentInput);
