import type { HTMLAttributes } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type {
  AutocompleteRenderOptionState,
  AutocompleteSlotsAndSlotProps,
} from '@mui/joy/Autocomplete';
import BaseAutocomplete from '@mui/joy/Autocomplete';
import Cross from '@unifyapps/icons/outline/X';
import ChevronDown from '@unifyapps/icons/outline/ChevronDown';
import { clsx } from 'clsx';
import { useTranslation } from '@unifyapps/i18n/client';
import { INPUT_VARS_CLASSNAME } from '../Input/styles';
import { getInputState } from '../../lib/getInputState';
import { Chip } from '../Chip';
import { Loader } from '../Loader';
import { usePopoverGlobalAnchorContext } from '../Popover/PopoverGlobalContainer';
import { EmptyState } from '../EmptyState';
import type { AutocompleteProps } from './types';
import {
  autocompleteListboxVariants,
  autocompleteRootVariants,
  clearIndicatorVariants,
  dropdownIndicatorVariants,
  inputVariants,
} from './styles';
import AutocompleteOption from './AutocompleteOption';
import '../Input/input.vars.css';
import './autocomplete.css';

const clearIcon = <Cross className="text-fg-quinary" height={16} width={16} />;

const SLOT_PROPS = {
  title: {
    className: '!text-secondary !text-sm',
  },
};

function Loading({ title }: { title: string }) {
  return (
    <EmptyState
      className="py-lg"
      icon={<Loader size="sm" variant="circle" />}
      size="sm"
      slotProps={SLOT_PROPS}
      title={title}
      variant="hug"
    />
  );
}

function Autocomplete<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) {
  const { t } = useTranslation();

  const {
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    size = 'md',
    variant = 'outlined',
    error,
    slots,
    renderOptionLabel,
    getOptionLabel,
    disableSearch = false,
    value,
    classes,
    styles,
    disabled = false,
    readOnly,
    multiple = false,
    disableCloseOnSelect = multiple,
    loading,
    getOptionKey,
    optionClassName,
    listBoxPlacement,
    slotProps,
    ...rest
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const inputState = getInputState(props);
  const globalContainerEl = usePopoverGlobalAnchorContext();
  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent
      className="text-quaternary me-md"
      height={size === 'sm' ? 18 : 20}
      width={size === 'sm' ? 18 : 20}
    />
  ) : (
    startDecoratorNode
  );

  const renderOption = useCallback(
    (
      optionProps: Omit<HTMLAttributes<HTMLLIElement>, 'color'> & { key?: string },
      option: Value,
      state: AutocompleteRenderOptionState,
    ) => {
      return (
        <AutocompleteOption<Value, Multiple, DisableClearable, FreeSolo>
          className={optionClassName}
          getOptionKey={getOptionKey}
          getOptionLabel={getOptionLabel}
          key={getOptionKey?.(option) ?? optionProps.id}
          // @ts-expect-error -- this should not be an error
          multiple={multiple}
          option={option}
          optionProps={optionProps}
          renderOptionLabel={renderOptionLabel}
          size={size}
          state={state}
        />
      );
    },
    [optionClassName, getOptionKey, getOptionLabel, renderOptionLabel, size, multiple],
  );

  // temporary, need to get disabled states of these
  const dropdownIndicatorClassName = dropdownIndicatorVariants({ disabled, size });
  const dropdownIndicator = (
    <ChevronDown className={dropdownIndicatorClassName} height={20} width={20} />
  );

  const autocompleteSlotProps = useMemo<AutocompleteSlotsAndSlotProps['slotProps']>(
    () => ({
      root: ({ open, focused }) => {
        const rootClassName = autocompleteRootVariants({
          size,
          multiple: Boolean(multiple),
          focused: Boolean(focused),
          open: Boolean(open),
          error: Boolean(error),
          disabled: Boolean(disabled),
          readOnly: Boolean(readOnly),
        });

        return {
          className: clsx(INPUT_VARS_CLASSNAME, rootClassName),
        };
      },
      wrapper: {
        className: 'gap-sm',
      },
      input: () => ({
        // temporary, need to get disabled states of these
        className: inputVariants({ size }),
        readOnly: disableSearch || readOnly,
      }),
      listbox: () => ({
        container: globalContainerEl,
        className: autocompleteListboxVariants({ size, className: classes?.listbox }),
        placement: listBoxPlacement,
        style: {
          '--Autocomplete-ListboxMaxWidth': `${(ref.current?.clientWidth ?? 0) * 1.5}px`,
          ...styles?.listbox,
        } as React.CSSProperties,
        ...slotProps?.listbox,
        modifiers: [
          {
            name: 'offset',
            options: {
              /**
               * Earlier we were using default 4px offset which was defined below
               * https://github.com/mui/material-ui/blob/e0f56a0fbe2d0cb950d149f7224e9682055380af/packages/mui-joy/src/Autocomplete/Autocomplete.tsx#L664.
               * In addition to this we were using 8px margin in the listbox container which was breaking the overflow detection in Popper.js
               * It was not considered to be overflowing until it was 8px outside the boundary.
               *
               * So, we removed the margin and added the offset modifier(4+8) to position the listbox at the same place where it was before.
               */
              offset: [0, 12],
            },
          },
        ],
      }),
      limitTag: () => ({ className: 'm-none text-secondary' }),
      endDecorator: { size, className: 'p-xxs min-h-0 min-w-0 w-5 h-5' },
      clearIndicator: {
        size,
        className: clearIndicatorVariants({ size }),
      },
      noOptions: {
        className: 'p-lg text-tertiary text-sm font-normal',
      },
    }),
    [
      classes?.listbox,
      styles?.listbox,
      disableSearch,
      disabled,
      error,
      globalContainerEl,
      listBoxPlacement,
      multiple,
      readOnly,
      size,
      slotProps?.listbox,
    ],
  );

  const autocompleteSlots = useMemo(
    () => ({
      ...slots,
      popupIndicator: slots?.popupIndicator ?? 'span',
    }),
    [slots],
  );

  const renderTags = useCallback(
    (tags: Value[]) =>
      tags.map((option, index) => {
        return (
          <Chip
            className="-m-[1px] max-w-full"
            key={index.toString()}
            label={getOptionLabel(option)}
            size="sm"
            variant="outlined"
          />
        );
      }),
    [getOptionLabel],
  );

  const getLimitTagsText = useCallback(
    (more: string | number) => (
      <Chip className="mt-none" label={`+${more}`} size="sm" variant="outlined" />
    ),
    [],
  );

  return (
    <BaseAutocomplete
      {...rest}
      clearIcon={clearIcon}
      data-size={size}
      data-state={inputState}
      data-variant={variant}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      endDecorator={loading ? <Loader size="xxs" variant="circle" /> : null}
      error={error}
      getLimitTagsText={getLimitTagsText}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      loading={loading}
      loadingText={<Loading title={t('common:Autocomplete.Loading')} />}
      multiple={multiple as Multiple}
      noOptionsText={t('common:NoOptionsFound')}
      popupIcon={dropdownIndicator}
      renderOption={rest.renderOption ?? renderOption}
      renderTags={rest.renderTags ?? renderTags}
      size={size}
      slotProps={autocompleteSlotProps}
      slots={autocompleteSlots}
      startDecorator={startDecorator}
      value={value}
      variant="outlined"
      readOnly={readOnly}
      // @ts-expect-error -- ref exists
      ref={ref}
    />
  );
}

Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;
