import type { BlockComponentType, BlockComponentStateId } from '../../types/block';
import type { InterfacePermissions } from '../../types/interface';
import type { StyleObject } from '../../types/styleObject';

export type Option = {
  id: string;
  label: string;
  appearance?: {
    startDecorator?: string;
    endDecorator?: string;
    disabled?: boolean;
    loading?: boolean;
  };
  addOns?: {
    tooltip?: {
      content: 'string';
    };
  };
  permissions?: InterfacePermissions;
};

export enum ButtonGroupTypes {
  default = 'default',
  dropdown = 'dropdown',
  toggle = 'toggle',
}

export enum ButtonGroupModes {
  manual = 'manual',
  mapped = 'mapped',
}

export type ManualOptions = {
  data: Option[];
};

export type DefaultButtonGroupContent = {
  mode: ButtonGroupModes.manual;
  type: ButtonGroupTypes.default;
  options?: ManualOptions;
};

export type DropdownButtonGroupContent = {
  mode: ButtonGroupModes.manual;
  type: ButtonGroupTypes.dropdown;
  text: string;
  options?: ManualOptions;
};

export type ToggleButtonGroupContent = {
  mode: ButtonGroupModes.manual;
  type: ButtonGroupTypes.toggle;
  options?: ManualOptions;
};

export type MappedOptions = {
  data: string;
  id: string | { path: string } | undefined;
  label: string | { path: string } | undefined;
};

export type DefaultButtonGroupMappedContent = {
  mode: ButtonGroupModes.mapped;
  type: ButtonGroupTypes.default;
  options?: MappedOptions;
};

export type DropdownButtonGroupMappedContent = {
  mode: ButtonGroupModes.mapped;
  type: ButtonGroupTypes.dropdown;
  text: string;
  options?: MappedOptions;
};

export type ToggleButtonGroupMappedContent = {
  mode: ButtonGroupModes.mapped;
  type: ButtonGroupTypes.toggle;
  options?: MappedOptions;
};

export type Content =
  | DefaultButtonGroupContent
  | DropdownButtonGroupContent
  | ToggleButtonGroupContent
  | DefaultButtonGroupMappedContent
  | DropdownButtonGroupMappedContent
  | ToggleButtonGroupMappedContent;

export type Appearance = {
  color?: 'brand' | 'neutral' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'solid' | 'outline' | 'soft' | 'ghost';
  styles?: Pick<StyleObject, 'padding' | 'margin' | 'width'>;
  endDecorator?: string;
  startDecorator?: string;
  popup?: {
    styles?: Pick<StyleObject, 'maxHeight' | 'overflow'>;
  };
};

export type ButtonGroupComponentType = BlockComponentType<'ButtonGroup', Content, Appearance>;

export type ButtonGroupBlockStateType = Pick<
  ButtonGroupComponentType,
  'appearance' | 'content' | 'componentType'
> &
  BlockComponentStateId & {
    // @deprecated
    selectedOptionId?: string;
    isOpen?: boolean;
    selectedOption?: { id?: string; label?: string };
  };
