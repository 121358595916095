import React, { type ContextType, createContext, memo, useContext, useEffect, useRef } from 'react';
import { InterfaceModes, useInterfaceStore } from '../InterfaceStore';
import { createInterfaceDrawerStore } from './createInterfaceDrawerStore';

export const InterfaceDrawerStoreContext = createContext<ReturnType<
  typeof createInterfaceDrawerStore
> | null>(null);

export const useInterfaceDrawerStore = () => {
  const _useStore = useContext(InterfaceDrawerStoreContext);
  if (!_useStore) {
    throw new Error('Missing InterfaceDrawerStoreProvider');
  }
  return _useStore;
};

export const useIsDrawerStoreProviderPresentInParent = () => {
  const isDrawerStoreProviderPresentInParent = useContext(InterfaceDrawerStoreContext) !== null;
  return { isDrawerStoreProviderPresentInParent };
};

// This component is only mounted for the first page over which we want to handle the stacking of drawer etc
function CreateInterfaceDrawerStoreProvider({ children }: { children: React.ReactNode }) {
  const storeRef = useRef<ContextType<typeof InterfaceDrawerStoreContext>>();
  const pageEl = useInterfaceStore().use.page.interfacePageEl();
  const pageMode = useInterfaceStore().use.mode();

  useEffect(() => {
    // we are setting containerEl in effect, because we always mount the drawer to the containerEl of the first page rendered In the app, if we don't do this,
    // Issue: when the first drawer is opened through a module, the drawer is rendered because it picks the containerEl of the base page, now if u navigate though different page from that module, this store will be recreated, however, the interface store might
    // not have the pageEl set from the new page, this leads to no containerEl in the newly created drawer store
    if (pageEl) {
      storeRef.current?.setState({
        containerEl: pageEl,
      });
    }
  }, [pageEl]);

  if (!storeRef.current) {
    const renderInCanvas = pageMode !== InterfaceModes.RUNNER;
    storeRef.current = createInterfaceDrawerStore({ containerEl: pageEl, renderInCanvas });
  }

  return (
    <InterfaceDrawerStoreContext.Provider value={storeRef.current}>
      {children}
    </InterfaceDrawerStoreContext.Provider>
  );
}

function InterfaceDrawerStoreProvider({ children }: { children: React.ReactNode }) {
  const { isDrawerStoreProviderPresentInParent } = useIsDrawerStoreProviderPresentInParent();

  // an InterfaceResourceProvider can be used inside a itself from React tree perspective using other block that embeds it,
  // but we want to kind of have only one InterfaceResourceProvider for the first page and when that page unmounts we want to clear the store, so here we are checking for parent, if not parent in the tree then we create a new store, other wise
  // we use the drawer store of parent page

  if (isDrawerStoreProviderPresentInParent) {
    return <>{children}</>;
  }

  return <CreateInterfaceDrawerStoreProvider>{children}</CreateInterfaceDrawerStoreProvider>;
}

export default memo(InterfaceDrawerStoreProvider);
