import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import SvgZap from '@unifyapps/icons/outline/Zap';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import type { CustomAction as CustomActionType } from '../../types';

function CustomAction({ action, disabled }: { action: CustomActionType; disabled?: boolean }) {
  const { appearance } = action;

  return (
    <IconButton
      Icon={getIconFromRegistry(action.icon) ?? SvgZap}
      color={appearance.color}
      disabled={disabled}
      onClick={action.onClick}
      size={appearance.size}
      title={action.label}
      variant={appearance.variant}
    />
  );
}

export default CustomAction;
