import type { StoreApi } from 'zustand/vanilla';
import type { GlobalStateStore } from '../types';
import type { BuildDependencyGraphItem } from '../../../../dependency/utils/buildDependencyGraph';
import type { BuildDependencyGraph } from '../../../../dependency/manager/types';

const getIntraDependenciesActions = (set: StoreApi<GlobalStateStore>['setState']) => {
  const setIntraDependencies = ({
    dependencyGraph,
    key,
  }: {
    key: 'dataSources';
    dependencyGraph: BuildDependencyGraph;
  }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: dependencyGraph,
        },
      };
    });
  };

  const setIntraDependenciesSingle = ({
    dependencies,
    entityId,
    key,
  }: {
    key: 'dataSources';
    entityId: string;
    dependencies: BuildDependencyGraphItem<unknown>;
  }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: {
            ...state.intraDependencies[key],
            [entityId]: dependencies,
          },
        },
      };
    });
  };

  const removeInterDependencies = ({ key, entityId }: { key: 'dataSources'; entityId: string }) => {
    set((state) => {
      return {
        ...state,
        intraDependencies: {
          ...state.intraDependencies,
          [key]: {
            ...state.intraDependencies[key],
            [entityId]: undefined,
          },
        },
      };
    });
  };

  return { setIntraDependencies, setIntraDependenciesSingle, removeInterDependencies };
};

export default getIntraDependenciesActions;
