import { useEffect, useMemo, type CSSProperties } from 'react';
import type { Option } from '@unifyapps/defs/blocks/ButtonGroup/types';
import BaseToggleButtonGroup from '@unifyapps/ui/components/ToggleButtonGroup';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { Typography } from '@unifyapps/ui/components/Typography';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import _map from 'lodash/map';
import { useTranslation } from '@unifyapps/i18n/client';
import useEventCallback from '@unifyapps/hooks/useEventCallback';

type Props = {
  size?: 'xs' | 'sm' | 'md';
  className?: string;
  style?: CSSProperties;
  dataAttributes: Record<string, string> | undefined;
  options: Option[];
  onAction: (id: string, label: string) => void;
  selectedOptionId: string | undefined;
};

export function ToggleButtonGroup({
  size,
  className,
  style,
  options,
  dataAttributes,
  onAction,
  selectedOptionId,
}: Props) {
  const { t } = useTranslation(['interfaces']);

  const items = useMemo(
    () =>
      options.map((option) => ({
        id: option.id,
        title: option.appearance?.loading ? `${t('common:Loading')}...` : option.label,
        tooltipContent: option.addOns?.tooltip ? option.addOns.tooltip.content : undefined,
        type: 'label' as const,
        disabled: option.appearance?.disabled,
        endDecoratorComponent: getIconFromRegistry(option.appearance?.endDecorator),
        startDecoratorComponent: getIconFromRegistry(option.appearance?.startDecorator),
      })),
    [options, t],
  );

  const handleChange = useEventCallback((event, value) => {
    const selectedOption = items.find((item) => item.id === value);
    if (selectedOption) {
      onAction(selectedOption.id, selectedOption.title);
    }
  });

  useEffect(() => {
    if (!selectedOptionId && items.length) {
      onAction(items[0]?.id, items[0]?.title);
    }
  }, [items, onAction, selectedOptionId]);

  return (
    <BaseToggleButtonGroup
      className={className}
      onChange={handleChange}
      style={style}
      {...dataAttributes}
    >
      {_map(items, (item) => (
        <BaseToggleButtonGroup.Button
          className="whitespace-nowrap"
          disabled={item.disabled}
          endDecoratorComponent={item.endDecoratorComponent}
          key={item.id}
          selected={selectedOptionId === item.id}
          size={size}
          startDecoratorComponent={item.startDecoratorComponent}
          value={item.id}
        >
          {/* Adding Tooltip on Typography since it will not work button as it is disabled */}

          {item.tooltipContent ? (
            <Tooltip placement="top" title={item.tooltipContent}>
              <Typography className="pointer-events-auto" variant="text-sm" weight="medium">
                {item.title}
              </Typography>
            </Tooltip>
          ) : (
            <Typography className="pointer-events-auto" variant="text-sm" weight="medium">
              {item.title}
            </Typography>
          )}
        </BaseToggleButtonGroup.Button>
      ))}
    </BaseToggleButtonGroup>
  );
}
