import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { Op } from '@unifyapps/network/generated/models/op';
import { useSearchEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { getLookupInterfaceQueryFilter } from '../../../../../utils/interfaceQueryFilters';
import type { InterfaceComponent } from '../../../../../types/interfaceComponent';

function useInterfaceComponent({
  interfaceId,
  interfaceComponent,
  searchFilters,
}: {
  interfaceId: string;
  interfaceComponent?: InterfaceComponent;
  searchFilters?: {
    field: string;
    op: Op;
    values: string[];
  }[];
}): {
  interfaceComponents: InterfacePageEntity[] | undefined;
  isLoading: boolean;
  error: Error | null;
} {
  const {
    data: interfaceComponents,
    isLoading,
    error,
  } = useSearchEntities(
    UAEntityTypes.Component,
    getLookupInterfaceQueryFilter({
      interfaceId,
      interfaceComponent,
      searchFilters,
    }),
    {
      query: {
        enabled: Boolean(interfaceId),
        retry: 3,
      },
    },
  );

  return {
    interfaceComponents: interfaceComponents?.objects as InterfacePageEntity[] | undefined,
    isLoading,
    error,
  };
}

export { useInterfaceComponent };
