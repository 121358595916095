import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import { usePrevious } from '@react-hookz/web';
import { adaptPageVariablesForContext } from '../../hooks/computeContext/utils/adaptPageVariablesForContext';
import {
  BLOCKS_STATE_WITH_METHOD,
  ComputeContextKeys,
  contextUtils,
} from '../../hooks/computeContext/const';
import { useGetInterfaceStoreState } from '../../stores/InterfaceStore';
import getInterfaceRecordForState from '../../hooks/computeContext/utils/getInterfaceRecordForState';
import { getCombinedDataSourceState } from '../../hooks/computeContext/utils/getCombinedDataSourceState';
import { getCombinedPageVariables } from '../../hooks/computeContext/utils/getCombinedPageVariables';
import { useInterfaceEntitiesMethods } from '../../components/InterfaceEntitiesMethodsProvider/InterfaceEntitiesMethodsProvider';
import type { GlobalStateStoreType } from '../../stores/GlobalStateStore';
import { useGetGlobalStateStoreState, useGlobalStateStore } from '../../stores/GlobalStateStore';
import { getCombinedBlockMethodsInBlocksState } from '../../hooks/computeContext/utils/getCombinedBlockMethodsInBlocksState';

export type ComputeContextType = {
  computeContext:
    | {
        blocksStateWithMethod: Record<string, BlockStateUnionType | undefined>;
        runtimeBlocks?: Record<string, BlockStateUnionType | undefined>;
        dataSourcesMeta: Record<string, unknown>;
        [key: string]: unknown;
      }
    | undefined;
  trigger: number;
};

const ComputeContextContext = createContext<ComputeContextType | null>(null);

export const buildPartialComputeContext = (newState: GlobalStateStoreType) => {
  // When the global state changes, update the context cache
  const blocksState = newState.blocks;
  const blockRefs = newState.blockRefs;
  const dataSourcesState = newState.dataSources;
  const dataSourcesMeta = newState.dataSourcesMeta;
  const pageInputs = newState.pageInputs;
  const location = newState.location;
  const userContext = newState.userContext;
  const pageVariables = adaptPageVariablesForContext(newState.pageVariables);
  const pageFunctions = newState.pageFunctions;
  const interfaceSessionStorage = newState.interfaceSessionStorage;

  return {
    blocks: blocksState,
    blockRefs,
    dataSources: dataSourcesState,
    pageVariables,
    dataSourcesMeta,
    [ComputeContextKeys.PAGE_INPUTS]: pageInputs,
    [ComputeContextKeys.SESSION_STORAGE]: {
      ...interfaceSessionStorage,
      // spreading data here for backward compact, as previously data was exposed directly at the first level
      ...(interfaceSessionStorage?.data ?? {}),
    },
    [ComputeContextKeys.LOCATION]: location,
    [ComputeContextKeys.USER_CONTEXT]: userContext,
    /**
     * Page Variables and Functions Assignment
     *
     * @deprecated This implementation is slated for removal in a future update.
     *
     * Historical Context:
     * - Previously, page variables and functions were stored under objects
     *   with keys __PAGE_VARS__ and __PAGE_FUNCTIONS__ respectively.
     * - This was done to maintain backward compatibility with existing code.
     *
     * Current Implementation:
     * - Page variables and functions are now stored flat in the compute context.
     *
     *
     */
    ...pageFunctions,
    [ComputeContextKeys.PAGE_VARIABLES]: pageVariables,
    [ComputeContextKeys.PAGE_FUNCTIONS]: pageFunctions,
    [ComputeContextKeys.RUNTIME_BLOCKS]: undefined,
    [ComputeContextKeys.UTILS]: contextUtils,
  };
};

export function ComputeContextProvider({ children }) {
  const computeContextRef = useRef<ComputeContextType['computeContext']>();
  const [trigger, setTrigger] = useState(0);
  const interfaceEntityMethods = useInterfaceEntitiesMethods();
  const previousTrigger = usePrevious(trigger);
  const subscribe = useGlobalStateStore().subscribe;

  // Effect to subscribe to globalStateStore and update the cache on changes
  useEffect(() => {
    const unsubscribe = subscribe(() => {
      // use module technique to maximum overflows
      setTrigger((prev) => (prev + 1) % Number.MAX_SAFE_INTEGER);
    });

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();

  const getComputeContext = useCallback(() => {
    const newState = getGlobalStateStoreState();
    const { blocks, dataSources, pageVariables, ...restComputeContext } =
      buildPartialComputeContext(newState);

    const combinedDataSourceState = getCombinedDataSourceState({
      dataSourcesState: dataSources,
      dataSourceMethods: interfaceEntityMethods.dataSources,
    });

    const combinedPageVariables = getCombinedPageVariables({
      pageVariables,
      pageVariablesMethods: interfaceEntityMethods.pageVariables,
    });

    const blocksStateWithMethod = getCombinedBlockMethodsInBlocksState({
      blocksState: blocks,
      blocksMethods: interfaceEntityMethods.blocks,
    });

    const interfaceRecord = getInterfaceStoreState().interfaceRecord;

    // order of spread matters because restComputeContext has additional data that we override with the combineDataSourcesState, combinePageVariables and combinedBlockState objects
    return {
      ...restComputeContext,
      ...blocksStateWithMethod,
      ...combinedDataSourceState,
      ...combinedPageVariables,
      // to be used when context is used in the block runtime
      [BLOCKS_STATE_WITH_METHOD]: blocksStateWithMethod,
      [ComputeContextKeys.INTERFACE_RECORD]: getInterfaceRecordForState(interfaceRecord),
    };
  }, [
    getGlobalStateStoreState,
    getInterfaceStoreState,
    interfaceEntityMethods.blocks,
    interfaceEntityMethods.dataSources,
    interfaceEntityMethods.pageVariables,
  ]);

  const getLatestComputeContext = useCallback(() => {
    if (previousTrigger !== trigger) {
      computeContextRef.current = getComputeContext();
    }
    return computeContextRef.current;
  }, [getComputeContext, previousTrigger, trigger]);

  const value = useMemo(() => {
    return {
      computeContext: getLatestComputeContext(),
      trigger,
    };
  }, [getLatestComputeContext, trigger]);

  return <ComputeContextContext.Provider value={value}>{children}</ComputeContextContext.Provider>;
}

export const useComputeContext = () => {
  const context = useContext(ComputeContextContext);
  if (!context) {
    throw new Error('useComputeContext must be used within a ComputeContextProvider');
  }
  return context;
};
