import { useSearchDeployedChildEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';

export function useFetchDeployedDataSourcesByComponentIdAndVersion({
  interfaceComponentId,
  interfaceComponentVersion,
}: {
  interfaceComponentId?: string;
  interfaceComponentVersion?: number;
}) {
  const {
    data: pageDataSources,
    isLoading: isLoadingDS,
    error: errorDS,
  } = useSearchDeployedChildEntities<DataSourceEntity[]>(
    {
      entityType: UAEntityTypes.DataSource,
      parentEntityId: interfaceComponentId,
      parentEntityType: UAEntityTypes.Component,
      parentVersion: interfaceComponentVersion,
    },
    {
      query: {
        enabled: Boolean(interfaceComponentId && interfaceComponentVersion),
        retry: 3,
      },
    },
  );

  const dataSources = pageDataSources?.reduce((acc, v) => {
    if (!v.id) return acc;
    acc[v.id] = v;
    return acc;
  }, {}) as Record<string, DataSourceEntity | undefined>;

  return {
    dataSources,
    isLoadingDS,
    errorDS,
  };
}
