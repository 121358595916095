import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  MessageInputBlockStateType,
  MessageInputComponentType,
} from '@unifyapps/defs/blocks/MessageInput/types';
import { lazy } from 'react';
import type { Action } from '@unifyapps/defs/types/action';
import _map from 'lodash/map';

const importMessageInputComponent = () =>
  import(
    /* webpackChunkName: "message-input-block" */
    './MessageInput'
  );

const MessageInput = lazy(importMessageInputComponent);

class MessageInputBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'MessageInput';
  }

  useBlockRef?: boolean | undefined = true;

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as MessageInputComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
      value: '',
    });
  }

  getEventTargetIds(block: BlockType<MessageInputComponentType>) {
    return _map(block.component.content.actions, (action) => action.id);
  }

  getBlockControlMethods() {
    return [
      {
        id: 'setValue' as const,
        label: 'Set Value',
        method: (blockState: MessageInputBlockStateType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { content: string | undefined };
          };

          return { ...blockState, value: methodPayload.content };
        },
      },
    ];
  }

  getComponent() {
    return MessageInput;
  }

  getKeysToOmitInComputation() {
    return ['value'];
  }
}

export default MessageInputBlockStateDefinition;
