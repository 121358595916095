/* eslint-disable @typescript-eslint/naming-convention -- needed */
export const enum UAError {
  UNMAPPED = -1,
  STALE_OBJECT = 5001,
  ASSET_NOT_FOUND = 5002,
  TRY_AGAIN = 5003,
  VALIDATION_ERROR = 5004,
  WORKFLOW_ERROR = 5005,
  RESOURCE_NOT_FOUND = 5006,
  MISSING_PROPERTY = 5007,
  USER_VISIBLE_ERROR = 5008,
  DUPLICATE_KEY_ERROR = 5010,
  FORBIDDEN = 5011,
}

export type ErrorPayload<T = Record<string, unknown>> = {
  errorId?: string;
  errorCode?: UAError;
  message?: string;
  errorMessage?: string;
} & T;
