import _get from 'lodash/get';
import { INTERNALS_PARAMETERS_KEY } from '../../const';
import { useInterfaceStore } from '../InterfaceStore';
import { useGlobalStateStore } from './GlobalStateStoreProvider';

export const useGetGlobalStateStoreState = () => {
  return {
    getGlobalStateStoreState: useGlobalStateStore().getState,
  };
};

export const useGetDeviceDetailsInStateStore = () => {
  return useGlobalStateStore().use.deviceDetails();
};

export const useDeviceDetailsGetter = () => {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const getDeviceDetails = () => getGlobalStateStoreState().deviceDetails;
  return { getDeviceDetails };
};

export const useGetBlockStateFromSessionStorage = <T = unknown>(blockId: string) => {
  const { data } = useGlobalStateStore().use.interfaceSessionStorage();
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();

  const blockSessionState = _get(data, [
    INTERNALS_PARAMETERS_KEY,
    activeInterfacePageId,
    blockId,
  ]) as T | undefined;

  return { blockSessionState };
};
