'use client';

import React, { createContext, useContext, useMemo } from 'react';

type PublishedInterfaceDetailsType = {
  deployedVersion: number;
};

export const PublishedInterfaceDetailsContext = createContext<PublishedInterfaceDetailsType | null>(
  null,
);

export const useDeployedInterfaceDetails = () => {
  const context = useContext(PublishedInterfaceDetailsContext);
  if (context === null) {
    throw new Error('PublishedInterfaceDetails not found');
  }
  return context;
};

export function PublishedInterfaceDetailsProvider({
  deployedVersion,
  children,
}: {
  deployedVersion: number;
  children: React.ReactNode;
}) {
  const value = useMemo(
    () => ({
      deployedVersion,
    }),
    [deployedVersion],
  );

  return (
    <PublishedInterfaceDetailsContext.Provider value={value}>
      {children}
    </PublishedInterfaceDetailsContext.Provider>
  );
}
