import _set from 'lodash/set';
import _get from 'lodash/get';
import { produce } from 'immer';
import { useCallback } from 'react';
import { LocalStorageKeys } from '../../../../consts/localStorageKeys';
import { useLocalStorageEntry } from '../../../../hooks/useLocalStorageEntry';

export function useInterfaceLocalStorage<T>({
  key,
  options,
  enabled,
}: {
  key: string;
  options?: { onSuccess?: (data: T | undefined) => void };
  enabled?: boolean;
}) {
  const { onSuccess } = options ?? {};

  const onSuccessWrapper = useCallback(
    (data: Record<string, unknown> | undefined) => {
      const value = _get(data, key) as T;
      onSuccess?.(value);
    },
    [key, onSuccess],
  );

  const {
    data,
    isLoading: loading,
    setStorageValue: setLocalStorageValue,
  } = useLocalStorageEntry<Record<string, unknown>>(
    LocalStorageKeys.Interfaces,
    { onSuccess: onSuccessWrapper },
    enabled,
  );

  const setValue = useCallback(
    (value: T) => {
      const updatedData = produce(data ?? {}, (draft) => {
        _set(draft, key, value);
      });
      setLocalStorageValue(updatedData);
    },
    [data, key, setLocalStorageValue],
  );

  const value = _get(data, key) as T;

  return {
    value,
    loading,
    setValue,
  };
}
