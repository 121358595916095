import { useMemo } from 'react';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { ROOT_ID } from '../../const';
import useResolvePageInputs from '../../hooks/useResolvePageInputs';
import type { InterfaceStoreStateInitialValue } from '../../stores/InterfaceStore';
import type { InterfaceResourceProviderProps } from './types';

function useInterfaceResourceProvider(props: InterfaceResourceProviderProps) {
  const {
    page,
    dataSources,
    interfaceRecord,
    pageInputs,
    modules,
    mode,
    isInteractiveModeEnabled,
    isPublic,
    interfacePageEl,
    client,
    basePath,
    initialActiveBlockId,
    commonNamespace,
    interfaceComponentNamespace,
  } = props;

  const { pageInputs: finalPageInputs } = useResolvePageInputs({
    mode,
    page,
    pageInputs,
  });

  const dataSourceRecordStoreInitialValue = useMemo(() => {
    return {
      dataSources: dataSources || {},
    };
  }, [dataSources]);

  const interfacePages = useMemo(
    () => ({
      [page.id]: page,
      ...(modules ?? {}),
    }),
    [modules, page],
  ) as Record<string, InterfacePageEntity>;

  const interfaceStoreInitialValue: InterfaceStoreStateInitialValue = useMemo(() => {
    return {
      client,
      basePath: basePath ?? null,
      mode,
      isInteractiveModeEnabled: Boolean(isInteractiveModeEnabled),
      activeBlockId: initialActiveBlockId ?? ROOT_ID,
      interfacePageEl: interfacePageEl ?? null,
      propertyPanel: {
        history: [
          {
            blockId: ROOT_ID,
            viewDetails: {
              viewType: 'BLOCK_DETAILS',
              data: undefined,
            },
          },
        ],
      },
      activeInterfacePageId: page.id,
      isPublic: Boolean(isPublic),
      interfacePages,
      interfaceRecord,
      commonNamespace,
      interfaceComponentNamespace,
      modules,
    };
  }, [
    client,
    basePath,
    mode,
    isInteractiveModeEnabled,
    initialActiveBlockId,
    interfacePageEl,
    page.id,
    isPublic,
    interfacePages,
    interfaceRecord,
    commonNamespace,
    interfaceComponentNamespace,
    modules,
  ]);

  return {
    finalPageInputs,
    dataSourceRecordStoreInitialValue,
    interfaceStoreInitialValue,
  };
}

export default useInterfaceResourceProvider;
