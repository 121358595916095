import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import { deviceTypes } from '@unifyapps/defs/types/deviceVariant';
import type { DevicesOverrides, InterfacePageEntity } from '@unifyapps/defs/types/page';
import _forEach from 'lodash/forEach';
import type { WritableDraft } from 'immer';
import { createDraft, finishDraft } from 'immer';
import _isEmpty from 'lodash/isEmpty';
import type { BlockId, BlockType } from '@unifyapps/defs/types/block';
import { invariant } from 'ts-invariant';
import { FOOTER_ID, HEADER_ID, ROOT_ID } from '../../../const';
import InterfacePageHelper from '../../../helper/InterfacePage';
import type BlocksRegistry from '../../../components/RegistryProvider/BlocksRegistry';
import { getFlattenBlocks } from '../../../hooks/useFlattenBlocks/flattenBlocks';
import { blockDependencyBuilder } from '../../../context/DependencyGraphContext/builders';
import { BlockHelper } from '../../../../helpers/BlockHelper';
import { applyBlockDiff, createBlockDiff } from './blockDiffUtils';

function filterOrphanedBlocks({
  interfacePageDraft,
  registry,
}: {
  interfacePageDraft: WritableDraft<InterfacePageEntity>;
  registry: BlocksRegistry;
}) {
  const allBlocks = interfacePageDraft.properties.blocks ?? {};
  const flattenBlocks = getFlattenBlocks({
    blocks: allBlocks,
    layout: interfacePageDraft.properties.layout,
    registry,
    componentType: interfacePageDraft.properties.componentType,
  });

  const allBlockIds = Array.from(new Set(Object.keys(allBlocks)));

  // adding our platform blockId because, in module header_id and footer_id is not included in the hierarchy,
  // but we don't want to delete them
  const flattenBlockIds = new Set([
    ...flattenBlocks.map((block) => block.blockId),
    HEADER_ID,
    ROOT_ID,
    FOOTER_ID,
  ]);

  // we are filtering out the blocks that are not present in the hierarchy assuming that must be orphaned because we assume that all the
  // blocks of a page must be accessible from the hierarchy
  const newBlocks = Array.from(allBlockIds).reduce<Record<string, BlockType>>((acc, blockId) => {
    const isBlockOrphaned = !flattenBlockIds.has(blockId);
    if (isBlockOrphaned) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`Block with id ${blockId} is orphaned`);
      }

      console.debug(`Block with id ${blockId} is orphaned`);
      return acc;
    }

    acc[blockId] = allBlocks[blockId];
    return acc;
  }, {});

  interfacePageDraft.properties.blocks = newBlocks;
}

export const getNextInterfacePage = ({
  currentDevice,
  interfacePage,
  baseDevice,
  registry,
  touchedBlocks,
}: {
  interfacePage: InterfacePageEntity;
  currentDevice: DeviceVariantType;
  baseDevice: DeviceVariantType;
  touchedBlocks: Set<BlockId>;
  registry: BlocksRegistry;
}) => {
  const nextInterfacePageDraft = createDraft(interfacePage);

  if (currentDevice === baseDevice) {
    const changedBlocks = Array.from(touchedBlocks);

    _forEach(changedBlocks, (blockId) => {
      if (!blockId) {
        return;
      }

      const baseBlock = InterfacePageHelper.getBaseDeviceBlock(interfacePage, blockId);

      if (!baseBlock) {
        return;
      }

      deviceTypes.forEach((device) => {
        if (device === baseDevice) {
          return;
        }

        const diff = InterfacePageHelper.getBlockDiffInDevice(interfacePage, device, blockId);

        //if diff is not found, it means the block is not changes in the non-base device
        if (_isEmpty(diff)) {
          return;
        }

        let updatedBlock = applyBlockDiff({
          block: baseBlock,
          diff,
        });

        const initialBlockState = registry.getBlockInitialState(
          baseBlock.component.componentType,
          baseBlock,
        );

        invariant(
          initialBlockState,
          `initialBlockState is not found for ${baseBlock.component.componentType}`,
        );

        // calculate the deps of the block, which is created after applying the diff
        // this new block might have different deps than the base block
        const blockDeps = blockDependencyBuilder({
          blockState: initialBlockState,
        })[blockId];

        updatedBlock = {
          ...updatedBlock,
          ...BlockHelper.extractBlockDependencyKeys(blockDeps),
        };

        const updatedDeviceOverrides: DevicesOverrides['desktop'] = {
          ...InterfacePageHelper.getDeviceOverrides(interfacePage, device),
          blocks: {
            ...InterfacePageHelper.getDeviceBlocks(interfacePage, device),
            [blockId]: updatedBlock,
          },
        };

        nextInterfacePageDraft.properties.devices = {
          ...nextInterfacePageDraft.properties.devices,
          [device]: updatedDeviceOverrides,
        };
      });
    });
    // case 1: If switch to base device
    // case 2: If switch to a non-base device,
    // calculate diff from base variant block and save that in the deviceOverrides for that
    //device
  } else {
    const changedBlocks = Array.from(touchedBlocks);

    _forEach(changedBlocks, (blockId) => {
      if (!blockId) {
        return;
      }
      const baseBlock = interfacePage.properties.blocks?.[blockId];
      const nonBaseBlock = interfacePage.properties.devices?.[currentDevice]?.blocks?.[blockId];

      if (!baseBlock || !nonBaseBlock) {
        throw new Error('Block not found while creating diff');
      }

      const diff = createBlockDiff({ baseBlock, nonBaseBlock });

      const updatedDeviceOverrides: DevicesOverrides['desktop'] = {
        blocks: InterfacePageHelper.getDeviceBlocks(interfacePage, currentDevice),
        diffs: {
          blocks: {
            ...InterfacePageHelper.getDeviceBlocksDiff(interfacePage, currentDevice),
            [blockId]: diff,
          },
        },
      };

      nextInterfacePageDraft.properties.devices = {
        ...nextInterfacePageDraft.properties.devices,
        [currentDevice]: updatedDeviceOverrides,
      };
    });
  }

  // Previously there was a bug where we didn't delete all the children blocks of a page
  // to fix that we are filtering orphaned blocks here
  filterOrphanedBlocks({ interfacePageDraft: nextInterfacePageDraft, registry });

  return finishDraft(nextInterfacePageDraft);
};
