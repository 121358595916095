import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { CommentsComponentType } from '@unifyapps/defs/blocks/Comments/types';
import { lazy } from 'react';

const importCommentsComponent = () =>
  import(
    /* webpackChunkName: "comments-block" */
    './Comments'
  );

const Comments = lazy(importCommentsComponent);

class CommentsBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Comments';
  }

  getEventTargetIds() {
    return [];
  }

  getSlotOrder(block: BlockType): { order: string[] } {
    return {
      order: ['commentInput', 'commentList'],
    };
  }

  getBlockControlMethods() {
    return [];
  }
  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as CommentsComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
    });
  }

  getComponent() {
    return Comments;
  }

  preload() {
    return {
      blockPreload: importCommentsComponent,
    };
  }
}

export default CommentsBlockStateDefinition;
