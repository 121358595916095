import type { CSSProperties, ReactElement } from 'react';
import * as React from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { ClickAwayListener } from '@unifyapps/ui/components/ClickAwayListener';
import useEventCallback from '@unifyapps/hooks/useEventCallback';

export function TextareaRoot({
  children,
  className,
  style,
  textAreaRootRef,
  ...rest
}: {
  children: ReactElement;
  className: string;
  style: CSSProperties;
  textAreaRootRef: React.MutableRefObject<HTMLDivElement | null>;
  ownerState: {
    slotProps: {
      textarea: {
        onClickAway?: () => void;
      };
    };
  };
}) {
  const { ownerState } = rest;

  const onClickAway = useEventCallback(() => {
    ownerState.slotProps.textarea.onClickAway?.();
  });

  //upon clicking inside in the MessageInput we don't want to lose focus, hence using ClickAwayListener
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Stack
        className={slowCn('bg-primary !gap-lg min-h-[52px] w-full', className)}
        style={style}
        {...rest}
        ref={textAreaRootRef}
      >
        <Stack
          className="default-placeholder gap-sm w-full flex-1 flex-wrap items-center"
          direction="row"
        >
          {children}
        </Stack>
      </Stack>
    </ClickAwayListener>
  );
}
