import type { ConditionalPath, DependsOnType, DynamicPath } from '@unifyapps/defs/types/page';
import type { DynamicAndConditionalPathHandler } from '../../no-code/context/DependencyGraphContext/handlers';
import type { BuildDependencyGraphItem } from '../utils/buildDependencyGraph';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- false positive
export class DependencyHelper {
  static getPathsFromDynamicPaths(dynPaths?: DynamicPath[]) {
    return dynPaths?.flatMap((dPath) => dPath.p);
  }

  static getPathsFromConditionalPaths(condPaths?: ConditionalPath[]) {
    return condPaths?.flatMap((cPath) => cPath.p);
  }

  static getDependsOnIds(dependsOn?: DependsOnType[]) {
    return dependsOn?.map((depend) => depend.id);
  }

  static getDependsOn(dependency?: BuildDependencyGraphItem<unknown>): DependsOnType[] | undefined {
    return dependency?.dependsOn;
  }

  static getCombinedDependsOnProperties = (dependsOn?: DependsOnType[]) => {
    return dependsOn?.flatMap((depend) => depend.p);
  };

  static getDynamicPaths = (
    dependency?: BuildDependencyGraphItem<DynamicAndConditionalPathHandler>,
  ): DynamicPath[] | undefined => {
    return dependency?.output?.dynPaths?.map((dPath) => ({
      p: dPath.path,
    }));
  };

  static getConditionalPaths = (
    dependency?: BuildDependencyGraphItem<DynamicAndConditionalPathHandler>,
  ) => {
    return dependency?.output?.condPaths?.map((cPath) => ({
      p: cPath.path,
    }));
  };

  static getDependsOnId(depend: DependsOnType) {
    return depend.id;
  }

  static getDependsOnProperties(depend: DependsOnType) {
    return depend.p;
  }

  static updateDependsOn(dependsOn: DependsOnType, properties: Set<string>): DependsOnType {
    return {
      id: dependsOn.id,
      p: [...dependsOn.p, ...Array.from(properties)],
    };
  }

  static createDependsOn(id: string, properties: string[]): DependsOnType {
    return {
      id,
      p: properties,
    };
  }

  static makePropertiesUnique(dependsOn: DependsOnType): DependsOnType {
    return {
      id: dependsOn.id,
      p: Array.from(new Set(dependsOn.p)),
    };
  }
}
