import { useSearchDeployedEntities } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import {
  InterfacePageEntityComponentType,
  type InterfacePageEntity,
} from '@unifyapps/defs/types/page';
import { useMemo } from 'react';
import { getLookupInterfaceQueryFilter } from '../../../../../utils/interfaceQueryFilters';
import type {
  InterfaceComponent,
  InterfaceComponentPage,
} from '../../../../../types/interfaceComponent';

type Args = {
  interfaceId: string;
  interfaceComponent: InterfaceComponent;
  defaultPageId: string | undefined;
};

export const useFetchDeployedInterfacePage = ({
  interfaceId,
  interfaceComponent,
  defaultPageId,
}: Args) => {
  const { lookupInterfaceQueryFilter, shouldSearchDeployedComponent } = useMemo(() => {
    let interfaceComponentWithFallback = interfaceComponent;
    // If we dont have a pageId or slug, then we fallback to default page defined in interfaceRecord
    if (!interfaceComponent.id && !(interfaceComponent as InterfaceComponentPage).slug) {
      interfaceComponentWithFallback = {
        slug: defaultPageId ?? '',
        type: InterfacePageEntityComponentType.PAGE,
      };
    }

    return {
      shouldSearchDeployedComponent: Boolean(
        interfaceId &&
          (interfaceComponentWithFallback.id ||
            (interfaceComponentWithFallback as InterfaceComponentPage).slug),
      ),
      lookupInterfaceQueryFilter: getLookupInterfaceQueryFilter({
        interfaceId,
        interfaceComponent: interfaceComponentWithFallback,
      }),
    };
  }, [interfaceComponent, interfaceId, defaultPageId]);

  const {
    data: interfacePage,
    isLoading: isLoadingDeployedComponent,
    error,
  } = useSearchDeployedEntities(UAEntityTypes.Component, lookupInterfaceQueryFilter, {
    query: {
      enabled: shouldSearchDeployedComponent,
      retry: 3,
      select: (resp) => resp.objects?.[0] as InterfacePageEntity | undefined,
    },
  });

  const isLoadingDeployedComponentQuery =
    isLoadingDeployedComponent && shouldSearchDeployedComponent;

  return { interfacePage, isLoading: isLoadingDeployedComponentQuery, error };
};
