import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { StepStatus, type Step } from './types';

export const hasSubStepsFn = (step: Step) => {
  return !_isEmpty(step.subSteps);
};

export const getFirstStepId = (steps: Step[]) => {
  if (_isEmpty(steps)) {
    return undefined;
  }

  const firstStep = steps[0];
  if (hasSubStepsFn(firstStep)) {
    return steps[0].subSteps?.[0].value;
  }
  return firstStep.value;
};

export const findStep = (steps: Step[], stepId: string): Step | undefined => {
  for (const step of steps) {
    if (step.value === stepId) {
      return step;
    }
    if (hasSubStepsFn(step)) {
      const found = findStep(step.subSteps as Step[], stepId);
      if (found) {
        return found;
      }
    }
  }
};

export const flattenSteps = (steps: Step[]): Step[] => {
  return steps.reduce<Step[]>((acc, step) => {
    if (hasSubStepsFn(step)) {
      return [...acc, step, ...flattenSteps(step.subSteps ?? [])];
    }
    return [...acc, step];
  }, []);
};

export const getStepStatus = ({
  stepId,
  steps,
  activeStepId,
}: {
  stepId: string;
  steps: Step[];
  activeStepId: string;
}) => {
  if (stepId === activeStepId) {
    return StepStatus.Active;
  }

  const step = findStep(steps, stepId);
  if (!step) {
    return StepStatus.Pending;
  }
  const hasSubSteps = hasSubStepsFn(step);

  // If the step has no substeps, check if activeStepId is before or after the step
  if (!hasSubSteps) {
    const flattenedSteps = flattenSteps(steps);
    const stepIndex = _findIndex(flattenedSteps, (it) => it.value === stepId);
    const activeStepIndex = _findIndex(flattenedSteps, (it) => it.value === activeStepId);
    if (stepIndex < activeStepIndex) {
      return StepStatus.Completed;
    }
    return StepStatus.Pending;
  }

  // If the step has substeps, check substeps
  for (const subStep of step.subSteps ?? []) {
    // If any substep is active, the step is active
    if (subStep.value === activeStepId) {
      return StepStatus.Active;
    }
    const subStepStatus = getStepStatus({ stepId: subStep.value, steps, activeStepId });
    // If any substep is pending, the step is pending
    if (subStepStatus === StepStatus.Pending) {
      return StepStatus.Pending;
    }
  }

  // If all substeps are completed, the step is completed
  return StepStatus.Completed;
};

export const getStepNumberLabel = ({
  stepId,
  steps,
  parentLabel = '',
}: {
  steps: Step[];
  stepId: string;
  parentLabel?: string;
}): string | undefined => {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    const currentLabel = parentLabel ? `${parentLabel}.${i + 1}` : `${i + 1}`;

    // If the current step matches the stepId, return its label
    if (step.value === stepId) {
      return currentLabel;
    }

    // If the current step has substeps, search recursively
    if (hasSubStepsFn(step)) {
      const subStepLabel = getStepNumberLabel({
        steps: step.subSteps ?? [],
        stepId,
        parentLabel: currentLabel,
      });
      if (subStepLabel) {
        return subStepLabel;
      }
    }
  }

  // Return undefined if no matching step is found
  return undefined;
};

export const getNextStep = ({ stepId, steps }: { stepId: string; steps: Step[] }) => {
  const flattenedSteps = flattenSteps(steps);
  const stepIndex = _findIndex(flattenedSteps, (it) => it.value === stepId);
  if (stepIndex >= flattenedSteps.length) {
    return stepId;
  }
  const nextStep = flattenedSteps[stepIndex + 1];
  if (hasSubStepsFn(nextStep)) {
    return nextStep.subSteps?.[0]?.value;
  }
  return nextStep.value;
};

export const getPreviousStep = ({ stepId, steps }: { stepId: string; steps: Step[] }) => {
  const flattenedSteps = flattenSteps(steps);
  const stepIndex = _findIndex(flattenedSteps, (it) => it.value === stepId);
  if (stepIndex <= 0) {
    return stepId;
  }
  const prevStep = flattenedSteps[stepIndex - 1];
  if (hasSubStepsFn(prevStep)) {
    const prevPrevStep = flattenedSteps[stepIndex - 2] as Step | undefined;
    if (!prevPrevStep) {
      return stepId;
    }
    return prevPrevStep.value;
  }
  return prevStep.value;
};
