import type { AggregationQuery } from '@unifyapps/network/generated/models/aggregationQuery';
import type { Projection } from '@unifyapps/network/generated/models/projection';
import type { Filter } from '@unifyapps/network/generated/models/filter';
import type { Sort } from '@unifyapps/network/generated/models/sort';
import type { TimeFilter } from '@unifyapps/network/generated/models/timeFilter';

export class AggregationQueryBuilder {
  private readonly aggregationQuery: AggregationQuery;

  constructor() {
    this.aggregationQuery = {};
  }

  public withId(id: string): this {
    this.aggregationQuery.id = id;
    return this;
  }

  public withProjections(projections: Projection[]): this {
    this.aggregationQuery.projections = projections;
    return this;
  }

  public withFilter(filter: Filter): this {
    this.aggregationQuery.filter = filter;
    return this;
  }

  public withTimeFilter(timeFilter: TimeFilter): this {
    this.aggregationQuery.timeFilter = timeFilter;
    return this;
  }

  public withAdditionalParams(additional: Record<string, unknown>): this {
    this.aggregationQuery.additional = additional;
    return this;
  }

  public withSorts(sorts: Sort[]): this {
    this.aggregationQuery.sorts = sorts;
    return this;
  }

  public withEntityType(entityType: string): this {
    this.aggregationQuery.entityType = entityType;
    return this;
  }

  public withGroup(group: string): this {
    this.aggregationQuery.group = group;
    return this;
  }

  public withIncludeTotalHits(includeTotalHits: boolean): this {
    this.aggregationQuery.includeTotalHits = includeTotalHits;
    return this;
  }

  public build(): AggregationQuery {
    return this.aggregationQuery;
  }
}
