import { default as BaseIconButton } from '@mui/joy/IconButton';
import ButtonGroupContext from '@mui/joy/ButtonGroup/ButtonGroupContext';
import { forwardRef, useContext } from 'react';
import type { ButtonGroupIconButtonProps, ButtonGroupSizes } from './types';
import { buttonGroupButtonBaseClassname, buttonGroupIconButtonSizeVariants } from './styles';
import { buttonGroupIconSizeMap } from './utils';

const ButtonGroupIconButton = forwardRef<HTMLButtonElement, ButtonGroupIconButtonProps>(
  (props, ref): JSX.Element => {
    const { size: sizeProp, className, Icon, ...rest } = props;
    const buttonGroup = useContext(ButtonGroupContext);

    // @ts-expect-error -- buttonGroup.size is of same type as ButtonGroupSizes. We are not overriding internal types currently
    const size: ButtonGroupSizes = sizeProp ?? buttonGroup.size ?? 'sm';

    const iconSize = buttonGroupIconSizeMap[size];

    const iconButtonClassName = buttonGroupIconButtonSizeVariants({
      className: [buttonGroupButtonBaseClassname, className],
      size,
    });

    return (
      <BaseIconButton ref={ref} {...rest} className={iconButtonClassName}>
        {Icon ? <Icon height={iconSize} width={iconSize} /> : null}
      </BaseIconButton>
    );
  },
);

ButtonGroupIconButton.displayName = 'ButtonGroupIconButton';

export default ButtonGroupIconButton;
