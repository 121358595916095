import { useMemo, memo } from 'react';
import { useBlocksInActiveInterfacePage } from '../../stores/InterfaceStore';
import { getRuntimeSourceBlockIdToChildrenMap } from '../../utils/getAdaptedRunTimeBlockSchema';
import { BlockHelper } from '../../../helpers/BlockHelper';
import { useFlattenBlocks } from '../../hooks/useFlattenBlocks';
import { isRuntimeSourceBlock } from '../../utils/runTimeBlocks';
import { RuntimeBlockPreComputationContext } from './context';

function RuntimeBlockPreComputationProvider({ children }: { children: React.ReactNode }) {
  const blocks = useBlocksInActiveInterfacePage();
  const blockIdVsBlockMap = useMemo(() => BlockHelper.getBlocksMapById(blocks), [blocks]);

  const flattenedBlocks = useFlattenBlocks(blockIdVsBlockMap);

  const runtimeSourceBlockIds = useMemo(
    () => new Set(blocks.filter((block) => isRuntimeSourceBlock(block)).map((block) => block.id)),
    [blocks],
  );

  const { runtimeSourceBlockIdVsChildrenBlocks, blockIdVsClosestRuntimeSourceBlockAncestor } =
    useMemo(
      () =>
        getRuntimeSourceBlockIdToChildrenMap({
          flattenedBlocks,
          blocks: blockIdVsBlockMap,
        }),
      [blockIdVsBlockMap, flattenedBlocks],
    );

  const value = useMemo(
    () => ({
      runtimeSourceBlockIdVsChildrenBlocks,
      runtimeSourceBlockIds,
      blockIdVsClosestRuntimeSourceBlockAncestor,
    }),
    [
      runtimeSourceBlockIdVsChildrenBlocks,
      runtimeSourceBlockIds,
      blockIdVsClosestRuntimeSourceBlockAncestor,
    ],
  );

  return (
    <RuntimeBlockPreComputationContext.Provider value={value}>
      {children}
    </RuntimeBlockPreComputationContext.Provider>
  );
}

export default memo(RuntimeBlockPreComputationProvider);
