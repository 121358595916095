import { cva } from 'class-variance-authority';

export const featuredIconVariants = cva('flex items-center justify-center flex-shrink-0', {
  variants: {
    size: {
      xxs: 'size-5 rounded-xs',
      xs: 'size-6 rounded-xs',
      sm: 'size-8 rounded-sm',
      md: 'size-10 rounded-lg',
      lg: 'size-12 rounded-lg',
      xl: 'size-14 rounded-xl',
      '2xl': 'size-[3.875rem] rounded-xl',
    },
    color: {
      white: 'border border-secondary',
      clear: '',
      gray: 'bg-secondary',
      brand: 'bg-brand-secondary',
      error: 'bg-palette-error-100',
      warning: 'bg-palette-warning-100',
      success: 'bg-palette-success-100',
      'blue-light': 'bg-palette-blue-light-100',
      blue: 'bg-palette-blue-dark-100',
      indigo: 'bg-palette-indigo-100',
      purple: 'bg-palette-purple-100',
      fuchsia: 'bg-palette-fuchsia-100',
      cyan: 'bg-palette-cyan-100',
      lime: 'bg-utility-lime-100',
    },
  },
});

export const featuredIconIconVariants = cva('', {
  variants: {
    size: {
      xxs: 'size-3',
      xs: 'size-4',
      sm: 'size-5',
      md: 'size-6',
      lg: 'size-7',
      xl: 'size-8',
      '2xl': 'size-9',
    },
    color: {
      white: 'text-utility-gray-600',
      clear: 'text-utility-gray-600',
      gray: 'text-utility-gray-600',
      brand: 'text-utility-brand-600',
      error: 'text-utility-error-600',
      warning: 'text-utility-warning-600',
      success: 'text-utility-success-600',
      'blue-light': 'text-utility-blue-light-600',
      blue: 'text-utility-blue-600',
      indigo: 'text-utility-indigo-600',
      purple: 'text-utility-purple-600',
      fuchsia: 'text-utility-fuchsia-600',
      cyan: 'text-utility-cyan-600',
      lime: 'text-utility-lime-600',
    },
  },
});
