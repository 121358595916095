import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import type { TextInputStateType } from '@unifyapps/defs/blocks/TextInput/types';
import TextInput from './TextInput';

class TextInputBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'TextInput';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'resetTextInput',
        label: 'Reset',
        method: (blockState: TextInputStateType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { resetToDefault: boolean };
          };

          const { resetToDefault } = methodPayload;

          return {
            ...blockState,
            resetToDefault,
          } as TextInputStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return TextInput;
  }

  getKeysToOmitInComputation() {
    return ['value'];
  }
}

export default TextInputBlockStateDefinition;
