import type {
  BlockStateDefinition,
  ComponentType,
} from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockStateUnionType, BlockType } from '@unifyapps/defs/types/block';
import { ComposedBlockComponentType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importRepeatableComponent = () =>
  import(
    /* webpackChunkName: "repeatable-block" */
    '../Repeatable/Repeatable'
  ) as Promise<{ default: ComponentType }>;

const Repeatable = lazy(importRepeatableComponent) as ComponentType;

class RepeatableListBlockStateDefinition implements BlockStateDefinition {
  get type() {
    return ComposedBlockComponentType.RepeatableList;
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['componentType', 'content', 'appearance']),
        ..._pick(block, ['events']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Repeatable;
  }

  preload() {
    return {
      blockPreload: importRepeatableComponent,
    };
  }
}

export default RepeatableListBlockStateDefinition;
