import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { useQuery } from '@unifyapps/network/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
  QueryClient,
} from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { executeFetch } from '@unifyapps/network/utils/fetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- same as in the generated hooks
type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAuthDeployedInterface = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<InterfaceEntity>(
    {
      url: `/auth/interface/deployed`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getAuthDeployedInterfaceQueryKey = () => {
  return [`/auth/interface/deployed`] as const;
};

export const getAuthDeployedInterfaceQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthDeployedInterface>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAuthDeployedInterface>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthDeployedInterfaceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthDeployedInterface>>> = ({
    signal,
  }) => getAuthDeployedInterface(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 5000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuthDeployedInterface>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuthDeployedInterfaceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuthDeployedInterface>>
>;
export type GetAuthDeployedInterfaceQueryError = ErrorType<unknown>;

export const useGetAuthDeployedInterface = <
  TData = Awaited<ReturnType<typeof getAuthDeployedInterface>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getAuthDeployedInterface>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthDeployedInterfaceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetAuthDeployedInterface = async <
  TData = Awaited<ReturnType<typeof getAuthDeployedInterface>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAuthDeployedInterface>>, TError, TData>
    >;
  },
): Promise<QueryClient> => {
  const queryOptions = getAuthDeployedInterfaceQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
