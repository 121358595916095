import type { PropsWithChildren, ReactElement, SyntheticEvent, MutableRefObject } from 'react';
import { Children, useMemo } from 'react';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import { Divider } from '@unifyapps/ui/components/Divider';
import type { CardAppearance } from '@unifyapps/defs/types/blocks';
import Stack from '@unifyapps/ui/_components/Stack';
import SvgChevronDown from '@unifyapps/icons/outline/ChevronDown';
import ChevronUp from '@unifyapps/icons/outline/ChevronUp';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useCollapse } from 'react-collapsed';
import { UA_INTERFACE_ENABLE_HOVER_EFFECT } from '@unifyapps/style/themes/consts';
import { useCardAppearance } from './useCardAppearance';

const enum CardLayoutSlots {
  Header = 'HEADER',
  Body = 'BODY',
  Footer = 'FOOTER',
}

type SlotProps = { name: CardLayoutSlots; id?: string };

function Slot(props: PropsWithChildren<SlotProps>): null {
  return null;
}

function CardLayout({
  children,
  appearance,
  dataAttributes,
  onClick,
  hasClickEvent,
  collapsibleParams,
  blockRef,
}: {
  children: ReactElement[];
  appearance?: CardAppearance;
  dataAttributes?: Record<string, string>;
  onClick?: (e: SyntheticEvent) => void;
  hasClickEvent?: boolean;
  collapsibleParams: {
    isCollapsed: boolean;
    setIsCollapsed: () => void;
    enabled: boolean;
  };
  blockRef: MutableRefObject<unknown> | undefined;
}): ReactElement {
  const { setIsCollapsed, isCollapsed } = collapsibleParams;

  const { showDivider } = appearance ?? {};

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: !isCollapsed,
    easing: 'linear',
    duration: 200,
  }) as {
    getCollapseProps: () => Record<string, unknown>;
    getToggleProps: () => Record<string, unknown>;
  };

  const { rootClassName, rootStyle, rootAndChildrenStyles, rootAndChildrenClassName } =
    useCardAppearance({
      appearance,
    });

  const childrenArr = Children.toArray(children);

  const headerSlot = childrenArr.find(
    (child: ReactElement<SlotProps>) => child.props.name === CardLayoutSlots.Header,
  ) as ReactElement<{ children: ReactElement }> | undefined;

  const bodySlot = childrenArr.find(
    (child: ReactElement<SlotProps>) => child.props.name === CardLayoutSlots.Body,
  ) as ReactElement<{ children: ReactElement }> | undefined;

  const footerSlot = childrenArr.find(
    (child: ReactElement<SlotProps>) => child.props.name === CardLayoutSlots.Footer,
  ) as ReactElement<{ children: ReactElement }> | undefined;

  const isHeaderPresent = Boolean(headerSlot?.props.children);
  const isBodyPresent = Boolean(bodySlot?.props.children);
  const isFooterPresent = Boolean(footerSlot?.props.children);

  const toggleCollapse = useEventCallback(() => {
    setIsCollapsed();
  });

  const toggleCollapseProps = useMemo(
    () => ({
      ...getToggleProps(),
    }),
    [getToggleProps],
  );

  const collapsibleProps = useMemo(
    () => ({
      ...getCollapseProps(),
    }),
    [getCollapseProps],
  );

  return (
    <Box
      className={clsx('flex flex-col', rootClassName, {
        [`${UA_INTERFACE_ENABLE_HOVER_EFFECT} cursor-pointer`]: Boolean(hasClickEvent),
      })}
      {...dataAttributes}
      onClick={onClick}
      ref={blockRef as MutableRefObject<HTMLDivElement>}
      style={rootStyle}
    >
      {isHeaderPresent ? (
        <Stack
          alignItems="center"
          className="gap-xs"
          direction="row"
          justifyContent="space-between"
          onClick={collapsibleParams.enabled ? toggleCollapse : undefined}
        >
          {headerSlot?.props.children}
          {collapsibleParams.enabled ? (
            <IconButton
              Icon={isCollapsed ? SvgChevronDown : ChevronUp}
              color="neutral"
              size="sm"
              variant="soft"
              {...toggleCollapseProps}
            />
          ) : null}
        </Stack>
      ) : null}

      {/* 
      transition property is height.
      shrink-1 will make child height as 0  in collapsed state, 0 to 0 animation wil not work so child should not be shrinked to 0
      if shrink-1 is not added child's height will be constant, in that case 0 - > child height animation will take place 
      */}

      <Stack
        className={clsx(rootAndChildrenClassName, 'min-h-0 grow')}
        direction="column"
        style={rootAndChildrenStyles}
        {...collapsibleProps}
      >
        {showDivider && isHeaderPresent ? <Divider /> : null}
        {isBodyPresent ? (
          <Box className={clsx('min-h-0 grow')}>{bodySlot?.props.children}</Box>
        ) : null}
        {showDivider && isFooterPresent ? <Divider /> : null}
        {isFooterPresent ? <Box className="w-full">{footerSlot?.props.children}</Box> : null}
      </Stack>
    </Box>
  );
}

CardLayout.Slot = Slot;
export { CardLayout, CardLayoutSlots };
