import { memo } from 'react';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import XClose from '@unifyapps/icons/outline/XClose';
import type { IconComponentType } from '@unifyapps/ui/types';
import Stack from '@unifyapps/ui/_components/Stack';
import { slowCn } from '@unifyapps/ui/lib/utils';

export type ModalHeaderProps = {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  CloseIcon?: IconComponentType;
  iconButtonClassName?: string;
};

function ModalHeaderBase(props: ModalHeaderProps) {
  const { children, className, onClose, CloseIcon, iconButtonClassName } = props;
  return (
    <Stack
      className={slowCn('ps-2xl pe-lg py-lg border-tertiary bg-primary gap-lg border-b', className)}
      direction="row"
    >
      {children}
      {onClose ? (
        <IconButton
          Icon={CloseIcon ?? XClose}
          className={iconButtonClassName}
          color="neutral"
          onClick={onClose}
          size="md"
          variant="soft"
        />
      ) : null}
    </Stack>
  );
}

export default memo(ModalHeaderBase);
