const nestedExprRegex = /{{.*?}}/g;

// This regex matches JavaScript-style property and array access patterns.
// It starts with a valid variable name (`entityId`) and matches one or more
// valid property or array accesses, such as
//   - dot notation: obj.prop
//   - string property access: obj['prop']
//   - numeric array access: obj[0]
const pathRegex =
  /(?<entityId>[$A-Z_a-z][\w$]*)(?:(?:\.(?![$A-Z_a-z][\w$]*\s*\()[$A-Z_a-z][\w$]*)|(?:\['[^']*'])|(?:\[\d+]))+/g;

const dynamicRegex = /^{{|}}$/g;

export const findAllPaths = (expr: string): { path: string; entityId: string }[] => {
  const paths: { path: string; entityId: string }[] = [];

  // Remove whitespace and outer curly braces
  const cleanExpr = expr.trim().replace(dynamicRegex, '');

  // Use matchAll to get all matches with capturing groups
  const matchesIterator = cleanExpr.matchAll(pathRegex);

  for (const match of matchesIterator) {
    const path = match[0];
    const { entityId } = match.groups ?? {};

    if (entityId) {
      paths.push({ path, entityId });
    }
  }

  return paths;
};

export const findAllPathsWithNested = (
  inputString: string,
): { path: string; entityId: string }[] => {
  const allPaths: { path: string; entityId: string }[] = [];

  // Process the main expression
  allPaths.push(...findAllPaths(inputString));

  // Find and process all nested expressions
  const nestedMatches = inputString.match(nestedExprRegex);
  if (nestedMatches) {
    nestedMatches.forEach((match) => {
      allPaths.push(...findAllPaths(match));
    });
  }

  return allPaths;
};

export function extractPropertyPaths(
  inputString: string,
  isValidEntityId: (entityId: string) => boolean,
) {
  const result: Record<string, Set<string> | undefined> = {};

  // Get all paths using the memoized function
  const allPaths = findAllPathsWithNested(inputString);

  // Filter and organize paths by valid entity IDs
  allPaths.forEach(({ path, entityId }) => {
    if (isValidEntityId(entityId)) {
      if (!result[entityId]) {
        result[entityId] = new Set();
      }
      result[entityId].add(path);
    }
  });

  return { result };
}
