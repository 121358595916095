import ReactContentLoader from 'react-content-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { LoaderComponentProps } from './types';

const BAR_HEIGHT = 12;
const GAP_HEIGHT = 16;

const calculateRows = (availableHeight: number): number => {
  const rowHeight = BAR_HEIGHT + GAP_HEIGHT;
  return Math.floor((availableHeight + GAP_HEIGHT) / rowHeight);
};

function ContentLoader(__: LoaderComponentProps) {
  return (
    <AutoSizer>
      {({ width, height }) => {
        const rows = calculateRows(height);
        const totalHeight = rows * BAR_HEIGHT + (rows - 1) * GAP_HEIGHT;

        return (
          <ReactContentLoader height={totalHeight} speed={2} width={width}>
            {Array.from({ length: rows }).map((_, index) => {
              const isLastRow = index && index === rows - 1;
              const barWidth = isLastRow ? width * 0.7 : width;
              const yPosition = index * (BAR_HEIGHT + GAP_HEIGHT);

              return (
                <rect
                  height={BAR_HEIGHT}
                  key={index}
                  rx={8}
                  ry={8}
                  width={barWidth}
                  x="0"
                  y={yPosition}
                />
              );
            })}
          </ReactContentLoader>
        );
      }}
    </AutoSizer>
  );
}

export default ContentLoader;
