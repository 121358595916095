import type { Field } from '../api/Field';
import type { Filter } from '../api/Filter';
import type { FilterBuilder } from '../api/FilterBuilder';
import type { FilterContext } from '../api/FilterContext';
import type { FilterEvaluator } from '../api/FilterEvaluator';
import type { ComparableValue } from '../api/ComparableValue';

export abstract class LeafFilterBuilder implements FilterBuilder {
  build<RecordType>(
    filterContext: FilterContext<RecordType>,
    filter: Filter,
  ): FilterEvaluator<RecordType> {
    const field = filterContext.createField(filter);

    return this._buildEvaluator(filter, field, filter.getValues() as object[] | null);
  }

  protected abstract _buildEvaluator<RecordType>(
    filter: Filter,
    field: Field<RecordType>,
    values: object[] | null,
  ): FilterEvaluator<RecordType>;

  protected toComparableValues<RecordType>(
    field: Field<RecordType>,
    values: object[],
  ): ComparableValue<unknown>[] {
    return field.toValues(values);
  }
}
