import type { ConditionEvaluationObject } from '@unifyapps/defs/types/conditionEvaluationObject';
import type { DependencyHandler } from '../../../dependency/utils/buildDependencyGraph';
import { isDynamicValue } from '../../utils/dynamicBindings';

export type DynamicAndConditionalPathHandler = {
  /**
   * A list of property paths within this entity that use dynamic expressions (e.g. `{{ }}`).
   * These dynamic expressions can reference internal or external values that might change at runtime.
   *
   * Example:
   * ```TypeScript
   * dynamicPropertyPaths: [
   *   { path: "userProfile.name" },
   *   { path: "product.price" }
   * ]
   * ```
   */
  dynPaths?: {
    path: string;
  }[];

  /**
   * A list of property paths within this entity that are conditionally structured.
   * This typically means the shape or presence of these properties can change based on certain conditions (like toggling a sidebar).
   *
   * Example:
   * ```TypeScript
   * conditionalPropertyPaths: [
   *   { path: "layout.sidebar.visible" }
   * ]
   * ```
   */
  condPaths?: {
    path: string;
  }[];
};

// when scanning the object, it is every expensive to check each value against all the keys
// to see if it is a conditional operator.
// so we will only check the keys that are most likely to be conditional operators
export const CONDITIONAL_KEYS_TO_CHECK = new Set([
  'hidden',
  'ui:disabled',
  'ua:visibility',
  'disabled',
  'runCondition',
  'conditions',
  'loading',
  'error',
  'conditionalValueFilters',
]);

export function isConditionalOperator(
  key: string,
  value: unknown,
): value is ConditionEvaluationObject {
  if (!CONDITIONAL_KEYS_TO_CHECK.has(key)) return false;

  // Cast the value to an object with optional properties 'type' and 'payload'
  const _value = value as { type?: string; payload?: string } | undefined;

  // If the value is undefined, return false
  if (!_value) return false;

  // Destructure the 'type' and 'payload' properties from the value
  const { type, payload } = _value;

  // Return true if the following conditions are met:
  // - The key is included in the CONDITIONAL_KEYS_TO_CHECK array
  // - The type is 'filter' and the payload is an object
  // - The type is 'boolean' and the payload is a boolean
  // Otherwise, return false
  return Boolean(
    (type === 'filter' && typeof payload === 'object') ||
      (type === 'boolean' && typeof payload === 'boolean'),
  );
}

export const dynamicAndConditionalPathsHandler = ({
  path,
  value,
  dependencies,
}: DependencyHandler<DynamicAndConditionalPathHandler>) => {
  if (typeof value === 'string' && isDynamicValue(value)) {
    if (!dependencies.output) {
      dependencies.output = {};
    }
    // Initialize dynamicPropertyPaths if it doesn't exist
    if (!dependencies.output.dynPaths) {
      dependencies.output.dynPaths = [];
    }

    dependencies.output.dynPaths.push({ path });
  }

  if (isConditionalOperator('conditions', value)) {
    if (!dependencies.output) {
      dependencies.output = {};
    }
    // Initialize conditionalPropertyPaths if it doesn't exist
    if (!dependencies.output.condPaths) {
      dependencies.output.condPaths = [];
    }
    dependencies.output.condPaths.push({ path });
  }
};

export const dedupeDynamicAndConditionalPathsHandler = (
  output: DynamicAndConditionalPathHandler,
) => {
  if (output.dynPaths) {
    output.dynPaths = output.dynPaths.filter(
      (path, index, self) => self.findIndex((p) => p.path === path.path) === index,
    );
  }

  if (output.condPaths) {
    output.condPaths = output.condPaths.filter(
      (path, index, self) => self.findIndex((p) => p.path === path.path) === index,
    );
  }

  return output;
};
