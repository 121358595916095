import { clsx } from 'clsx';
import type { CSSProperties } from 'react';
import React, { memo, useMemo } from 'react';
import type {
  ModalDialogProps as BaseModalDialogProps,
  ModalProps as BaseModalProps,
} from '@unifyapps/ui/_components/Modal';
import {
  Modal as BaseModal,
  ModalDialog as BaseModalDialog,
} from '@unifyapps/ui/_components/Modal';
import { ModalStateContext } from './useModalStateProvider';
import './styles.css';

export type ModalProps = {
  children: React.ReactNode;
  variant: ModalVariant;
  minHeight?: string;
  /**
   * @deprecated Do not use this prop. Use `variant` instead.
   */
  style?: CSSProperties;
} & Pick<BaseModalProps, 'open' | 'onClose' | 'slotProps' | 'container' | 'disableEnforceFocus'>;

export type ModalVariant = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const ModalVariantsConfig: Record<
  ModalVariant,
  { className: string; maxWidth: string; maxHeight?: string; style?: CSSProperties }
> = {
  xs: {
    className: '',
    maxWidth: '25rem',
    maxHeight: '18.75rem',
  },
  sm: {
    className: '',
    maxWidth: '27.5rem',
    maxHeight: '31.25rem',
  },
  md: {
    className: '',
    maxWidth: '37.5rem',
    maxHeight: '45rem',
  },
  lg: {
    className: 'min-h-[640px]',
    maxWidth: '64rem',
    maxHeight: '45rem',
  },
  xl: {
    className: 'min-h-[640px]',
    style: {
      height: 'calc(100vh - 60px)',
    },
    maxWidth: 'calc(100vw - 60px)',
    maxHeight: 'calc(100vh - 60px)',
  },
};

function Modal(props: ModalProps) {
  const { children, variant, style, ...baseModalProps } = props;

  const variantConfig = ModalVariantsConfig[variant];

  const maxWidth = variantConfig.maxWidth;
  const maxHeight = variantConfig.maxHeight;
  const variantStyle = variantConfig.style ?? style;

  const baseModalDialogSlotProps = useMemo<BaseModalDialogProps['slotProps']>(
    () => ({
      root: {
        sx: {
          gap: 0,
          padding: 0,
          width: maxWidth ? `clamp(0px, 100%, ${maxWidth})` : undefined,
          maxHeight,
          minHeight: props.minHeight ?? undefined,
          ...variantStyle,
        },
      },
    }),
    [maxHeight, maxWidth, props.minHeight, variantStyle],
  );

  const contextValue = useMemo(() => ({ variant }), [variant]);

  return (
    <ModalStateContext.Provider value={contextValue}>
      <BaseModal {...baseModalProps}>
        <BaseModalDialog
          className={clsx('ua-modal shadow-xs', variantConfig.className)}
          size="lg"
          slotProps={baseModalDialogSlotProps}
        >
          {children}
        </BaseModalDialog>
      </BaseModal>
    </ModalStateContext.Provider>
  );
}

export default memo(Modal);
