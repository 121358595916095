import omit from 'lodash/omit';
import type { DataSource } from '@unifyapps/defs/types/dataSource';
import { UAResources } from '@unifyapps/defs/types/resources';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { BlockType } from '@unifyapps/defs/types/block';

export const isInfiniteDataSourceWorkflowNode = (
  dataSource: Pick<DataSource, 'context' | 'inputs'>,
) => {
  switch (dataSource.context?.resourceName) {
    case UAResources.AnalyticsByUAQueryReports: {
      return true;
    }
    case UAResources.StorageByUAFetchRecords: {
      return dataSource.inputs?.numberOfRecordsToFetch === 'MULTIPLE';
    }
    case UAResources.StandardEntitiesAudit:
    case UAResources.StandardEntitiesFetchEntities:
    case UAResources.UserAudit: {
      return true;
    }
    default: {
      return false;
    }
  }
};

interface PayloadWithPageParams {
  inputs?: {
    page?: {
      offset?: number;
      limit?: number;
      paginateBy?: 'OFFSET' | 'CURSOR';
    };
  };
}

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 20;

export const getInfiniteDataSourceWorkflowNodePageParams = (
  payload: ExecuteWorkflowNodeRequest,
) => {
  const inputs = payload.inputs as PayloadWithPageParams['inputs'];

  return {
    offset: inputs?.page?.offset ?? DEFAULT_OFFSET,
    limit: inputs?.page?.limit ?? DEFAULT_PAGE_SIZE,
    paginateBy: inputs?.page?.paginateBy,
    payload: omit(payload, ['inputs.page']) as ExecuteWorkflowNodeRequest,
  };
};

export const getInitialPageParams = (data: {
  offset: number;
  limit: number;
  paginateBy?: 'OFFSET' | 'CURSOR';
}): PayloadWithPageParams => {
  return {
    inputs: { page: { offset: data.offset, limit: data.limit, paginateBy: data.paginateBy } },
  };
};

export const getNextPageParams = (
  lastPageParam: PayloadWithPageParams,
  paginateBy?: 'OFFSET' | 'CURSOR',
): PayloadWithPageParams => {
  const lastOffset = lastPageParam.inputs?.page?.offset ?? DEFAULT_OFFSET;
  const lastLimit = lastPageParam.inputs?.page?.limit ?? DEFAULT_PAGE_SIZE;

  return {
    inputs: { page: { offset: lastOffset + lastLimit, limit: lastLimit, paginateBy } },
  };
};

const isInfinitePaginationUsedInBlock = (block: BlockType) => {
  switch (block.component.componentType) {
    case 'Table': {
      const paginationInfo = block.component.content.addOns?.page;
      return Boolean(paginationInfo && paginationInfo.type !== 'PAGE');
    }
    case 'Timeline': {
      return true;
    }
    case 'Repeatable': {
      const paginationInfo = block.component.content.addOns?.page;
      return Boolean(paginationInfo && paginationInfo.type !== 'PAGE');
    }
    default: {
      return false;
    }
  }
};

export const shouldUseInfiniteDataSourceWorkflowNode = (
  dataSourceId: string,
  blocks: BlockType[],
) => {
  return blocks.some((block) => {
    if (!block.dataSourceIds?.includes(dataSourceId)) {
      return false;
    }
    return isInfinitePaginationUsedInBlock(block);
  });
};
