import { create, type StateCreator, type StoreApi } from 'zustand';
import { fallbackLng } from '@unifyapps/i18n/settings';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';
import type { RefObject } from 'react';
import { DEFAULT_DEVICE_VARIANT } from '@unifyapps/defs/types/deviceVariant';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import DataSourceHelper from '../../helper/DataSourceHelper';
import createObjectSetters from '../../../utils/setters/createObjectSetters';
import type { DataSourceRecordStoreState } from '../DataSourceRecordStore';
import type { RemountFunction } from '../../../components/Remount/Remount';
import getDeviceVariantActions from './actions/deviceVariantAction';
import getInterfaceSessionStorageAction from './actions/getInterfaceSessionStorageAction';
import type {
  DataSourceMetaType,
  GlobalStateStore,
  GlobalStateStoreInitialValue,
  GlobalStateStoreType,
  ManuallyTriggeredDataSourceType,
  QueryRequestResult,
} from './types';
import DependencyFlowHelper from './helpers/DependencyFlowHelper';
import { getInitialDatasourceDependencyFlow } from './utils/initial';
import getDataSourceActions from './actions/dataSourceActions';
import getVersionUpdatesAction from './actions/versionUpdatesAction';
import getParallelUpdatesAction from './actions/parallelUpdatesActions';

export const createGlobalStateStore = ({
  dataSourceRecords,
  initial,
  getDataSourceRecordsStore,
  remount,
  interfaceSessionStorage,
  setInterfaceSessionStorage,
  interfaceId,
}: {
  initial?: GlobalStateStoreInitialValue;
  dataSourceRecords?: Record<string, DataSourceEntity | undefined>;
  getDataSourceRecordsStore: StoreApi<DataSourceRecordStoreState>['getState'];
  remount: RemountFunction;
  interfaceId: string;
  interfaceSessionStorage: Record<string, unknown> | undefined;
  setInterfaceSessionStorage: (value: Record<string, unknown> | undefined) => void;
}) => {
  const initializer: StateCreator<GlobalStateStoreType> = (set, get) => {
    const { setSingle: setDataSourceState, remove: removeDataSourceState } = createObjectSetters<
      GlobalStateStoreType,
      'dataSources',
      QueryRequestResult
    >('dataSources', set);

    const { setSingle: setManualDataSourceState, remove: removeManualDataSourceState } =
      createObjectSetters<GlobalStateStoreType, 'manualDataSources', QueryRequestResult>(
        'manualDataSources',
        set,
      );

    const {
      setSingle: setBlockState,
      updateSingle: updateBlockState,
      setMultiple: setBlocksState,
      removeMultiple: removeBlocksState,
    } = createObjectSetters<GlobalStateStoreType, 'blocks', BlockStateUnionType>('blocks', set);

    const { setSingle: setPreviewTriggeredDataSource } = createObjectSetters<
      GlobalStateStoreType,
      'previewTriggeredDataSources',
      boolean
    >('previewTriggeredDataSources', set);

    const { setSingle: setPageVariableState, setMultiple: setPageVariablesState } =
      createObjectSetters<GlobalStateStoreType, 'pageVariables', PageVariableState>(
        'pageVariables',
        set,
      );

    const { setSingle: setPageFunctionState } = createObjectSetters<
      GlobalStateStoreType,
      'pageFunctions',
      PageFunctionState
    >('pageFunctions', set);

    const {
      setSingle: setManuallyTriggeredDataSource,
      removeMultiple: removeManuallyTriggeredDataSources,
    } = createObjectSetters<
      GlobalStateStoreType,
      'manuallyTriggeredDataSources',
      ManuallyTriggeredDataSourceType
    >('manuallyTriggeredDataSources', set);

    const { setMultiple: setLocation } = createObjectSetters<
      GlobalStateStoreType,
      'location',
      string
    >('location', set);

    const { setMultiple: setUserContext } = createObjectSetters<
      GlobalStateStoreType,
      'userContext',
      string
    >('userContext', set);

    const { setSingle: setDataSourceMeta } = createObjectSetters<
      GlobalStateStoreType,
      'dataSourcesMeta',
      DataSourceMetaType
    >('dataSourcesMeta', set);

    const { setMultiple: setContextualDialogForBlocks } = createObjectSetters<
      GlobalStateStoreType,
      'contextualDialogForBlocks',
      string
    >('contextualDialogForBlocks', set);

    const setAggregationMetadataInDSMeta = (
      dataSourceId: string,
      data: QueryRequestResult | undefined,
    ) => {
      const currentMeta = get().dataSourcesMeta[dataSourceId];
      setDataSourceMeta(dataSourceId, { ...currentMeta, aggregationMetadata: data });
    };

    const setPreviewDataInDSMeta = (dataSourceId: string, data: QueryRequestResult | undefined) => {
      const currentMeta = get().dataSourcesMeta[dataSourceId];
      setDataSourceMeta(dataSourceId, { ...currentMeta, previewResult: data });
    };

    const { setSingle: setBlockRef, remove: removeBlockRef } = createObjectSetters<
      GlobalStateStoreType,
      'blockRefs',
      RefObject<unknown>
    >('blockRefs', set);

    const dataSourceActions = getDataSourceActions({
      get,
      removeDataSourceState,
      removeManuallyTriggeredDataSources,
      set,
      setPreviewTriggeredDataSource,
      getDataSourceRecordsStore,
      setManuallyTriggeredDataSource,
    });

    const setPreBuiltFlow: GlobalStateStoreType['actions']['setPreBuiltFlow'] = ({
      data,
      type,
    }) => {
      set((state) => {
        return {
          ...state,
          flows: {
            ...state.flows,
            [type]: data,
          },
        };
      });
    };

    const removePreBuiltFlow: GlobalStateStoreType['actions']['removePreBuiltFlow'] = (type) => {
      set((state) => {
        return {
          ...state,
          flows: {
            ...state.flows,
            [type]: undefined,
          },
        };
      });
    };

    const resetBlockRefs = () => {
      set((state) => {
        return {
          ...state,
          blockRefs: {},
        };
      });
    };

    const dataSourceDependenciesGraph = dataSourceRecords
      ? DataSourceHelper.getDataSourceIntraDependencies(dataSourceRecords)
      : {};

    const initialDataSourceDependencyFlow = getInitialDatasourceDependencyFlow(dataSourceRecords);
    const completeDatasourceDependencies = DependencyFlowHelper.getDependencyFlowStatus({
      dependencyFlowInstance: {},
      entities: initialDataSourceDependencyFlow,
      intraDependencies: dataSourceDependenciesGraph,
    });

    const setIsInitialized = () => {
      set((state) => {
        return {
          ...state,
          isInitialized: true,
        };
      });
    };

    const reset = () => {
      set(() => storeObject);
    };

    const state: GlobalStateStore = {
      location: {},
      blocks: {},
      pageInputs: {},
      dataSources: {},
      manualDataSources: {},
      contextualDialogForBlocks: {},
      dataSourcesMeta: {},
      previewTriggeredDataSources: {},
      manuallyTriggeredDataSources: {},
      userContext: {
        locale: fallbackLng,
      },
      pageVariables: {},
      blockRefs: {},
      flows: {},
      deviceDetails: {
        device: DEFAULT_DEVICE_VARIANT,
      },
      permissions: {},
      pageFunctions: {},
      interfaceSessionStorage: interfaceSessionStorage?.[interfaceId] ?? {},
      dependencyFlowManager: {
        dataSources: completeDatasourceDependencies,
      },
      intraDependencies: {
        dataSources: dataSourceDependenciesGraph,
      },
      live: {
        versionUpdates: {
          showVersionError: false,
          outdatedEntities: [],
          entitiesToIgnore: [],
        },
        parallelUpdates: {
          updates: {},
          skip: false,
        },
      },
      ...initial,
    };

    const storeObject: GlobalStateStoreType = {
      ...state,
      actions: {
        removeManuallyTriggeredDataSources,
        setBlocksState,
        removeBlocksState,
        setDataSourceState,
        setBlockState,
        updateBlockState,
        setUserContext,
        setLocation,
        setManualDataSourceState,
        removeManualDataSourceState,
        datasource: dataSourceActions,
        setBlockRef,
        resetBlockRefs,
        removeBlockRef,
        setPageVariableState,
        setPageVariablesState,
        setPageFunctionState,
        setContextualDialogForBlocks,
        setPreviewDataInDSMeta,
        setAggregationMetadataInDSMeta,
        setPreBuiltFlow,
        removePreBuiltFlow,
        deviceVariantActions: getDeviceVariantActions(set),
        setInterfaceSessionStorage: getInterfaceSessionStorageAction(
          get,
          set,
          interfaceSessionStorage,
          setInterfaceSessionStorage,
        ),
        setIsInitialized,
        reset,
        remount,
        live: {
          versionUpdatesActions: getVersionUpdatesAction(set),
          parallelUpdatesActions: getParallelUpdatesAction(set),
        },
      },
    };

    return storeObject;
  };
  return create<GlobalStateStoreType>()(initializer);
};
