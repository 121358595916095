import { cva } from 'class-variance-authority';
import type { AvatarColors, AvatarSizes } from './types';

// keeping empty strings here, so styles can be easily added without modifying any other file
// Used palette colors to keep colors consistent across themes
export const avatarRootVariants = cva('font-semibold rounded-full', {
  variants: {
    color: {
      primary: 'bg-brand-solid text-fg-white',
      gray: 'bg-secondary text-fg-quaternary',
      brand_subtle: 'bg-utility-brand-50 text-utility-brand-500',
      warning: 'bg-palette-warning-500 text-fg-white',
      blue: 'bg-palette-blue-700 text-fg-white',
      error: 'bg-palette-error-500 text-fg-white',
      success: 'bg-palette-success-500 text-fg-white',
      cyan: 'bg-palette-cyan-500 text-fg-white',
    } satisfies Record<AvatarColors, string>,
    size: {
      xxs: 'size-5 text-xxxs shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
      xs: 'size-6 text-xxxs shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
      sm: 'size-8 text-sm shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
      md: 'size-10 text-md shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
      lg: 'size-12 text-lg shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
      xl: 'size-14 text-xl shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
      '2xl': 'size-16 display-xs shadow-[inset_0_0_0_1px_var(--avatar-contrast-border)]',
    } satisfies Record<AvatarSizes, string>,
  },
});

export const getAvatarRootStyles = (color: AvatarColors) => {
  if (color === 'brand_subtle') {
    return { '--avatar-contrast-border': 'var(--utility-brand-100)' } as React.CSSProperties;
  }
  return undefined;
};
