import { BubbleMenu } from '@tiptap/react';
import type { Editor } from '@tiptap/core';
import { isTextSelection } from '@tiptap/core';
import SvgBulletList from '@unifyapps/icons/solid/wysiwyg/BulletList';
import SvgNumberedList from '@unifyapps/icons/solid/wysiwyg/NumberedList';
import SvgStrikethrough01 from '@unifyapps/icons/outline/Strikethrough01';
import SvgBold from '@unifyapps/icons/solid/wysiwyg/Bold';
import SvgItalic from '@unifyapps/icons/solid/wysiwyg/Italic';
import type { Placement } from 'tippy.js';
import { RichTextEditorPlugins } from '../../editors/RichTextEditor/utils';
import { Link } from '../../extensions/link';
import { Toolbar } from '../Toolbar';
import AddLinkMenu from './AddLinkMenu';

const tippyOptions = {
  appendTo: 'parent' as const,
  placement: 'top-start' as Placement,
};

const isCustomNodeSelected = ({ editor }: { editor: Editor }) => {
  const customNodes = [Link.name];
  return customNodes.some((type) => editor.isActive(type));
};

const isTextSelected = ({ editor }: { editor: Editor }) => {
  const { doc, selection } = editor.state;
  const { from, to, empty } = selection;
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

const shouldShow = ({ editor }: { editor: Editor }) => {
  if (isCustomNodeSelected({ editor })) {
    return false;
  }

  return isTextSelected({ editor });
};

function SelectionMenu({ editor, editable }: { editor: Editor; editable?: boolean }) {
  return (
    <BubbleMenu
      editor={editor}
      shouldShow={editable ? shouldShow : undefined}
      tippyOptions={tippyOptions}
    >
      <Toolbar.Wrapper className="rounded-md shadow-sm">
        <Toolbar.Button
          Icon={SvgBold}
          isActive={editor.isActive(RichTextEditorPlugins.Bold)}
          isEditable
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
        <Toolbar.Button
          Icon={SvgItalic}
          isActive={editor.isActive(RichTextEditorPlugins.Italic)}
          isEditable
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
        <Toolbar.Button
          Icon={SvgStrikethrough01}
          isActive={editor.isActive(RichTextEditorPlugins.Strike)}
          isEditable
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />
        <Toolbar.Button
          Icon={SvgBulletList}
          isActive={editor.isActive(RichTextEditorPlugins.BulletList)}
          isEditable
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
        <Toolbar.Button
          Icon={SvgNumberedList}
          isActive={editor.isActive(RichTextEditorPlugins.OrderedList)}
          isEditable
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
        <AddLinkMenu editor={editor} />
      </Toolbar.Wrapper>
    </BubbleMenu>
  );
}

export default SelectionMenu;
