import { EventTypeEnum } from '../../types/event';

export const supportedFileTypes = [
  {
    title: 'Any File',
    const: '*',
  },
  {
    title: 'PDF',
    const: '.pdf',
  },
  {
    title: 'Images',
    const: 'image/*',
  },
  {
    title: 'CSV',
    const: '.csv',
  },
  {
    title: 'Excel 2007+',
    const: '.xlsx',
  },
  {
    title: 'Excel 97-2003',
    const: '.xls',
  },
  {
    title: 'Videos',
    const: 'video/*',
  },
  {
    title: 'Audio',
    const: 'audio/*',
  },
  {
    title: 'Text',
    const: 'text/*',
  },
  {
    title: 'Plain text',
    const: 'text/plain',
  },
  {
    title: 'JPEG',
    const: 'image/jpeg',
  },
  {
    title: 'JPG',
    const: 'image/jpg',
  },
  {
    title: 'PNG',
    const: '.png',
  },
  {
    title: 'XML',
    const: '.xml',
  },
  {
    title: 'XSD',
    const: '.xsd',
  },
  {
    title: 'SVG',
    const: '.svg',
  },
  {
    title: 'ICO',
    const: '.ico',
  },
];

export const contentSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  properties: {
    label: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    selectionType: {
      type: 'string',
      oneOf: [
        {
          title: 'Single',
          const: 'single',
        },
        {
          title: 'Multiple',
          const: 'multiple',
        },
      ],
    },
    supportedFileTypes: {
      type: 'array',
      items: {
        type: 'string',
        oneOf: supportedFileTypes,
      },
    },
    allowDownload: {
      type: 'boolean',
      default: true,
    },
    divider: {
      type: 'boolean',
    },
    divider1: {
      type: 'boolean',
    },
    addOns: {
      type: 'object',
      properties: {
        templateFile: {
          type: 'object',
        },
      },
    },
    interactions: {
      type: 'string',
    },
    permissions: {
      type: 'string',
    },
  },
  allOf: [
    {
      if: {
        properties: {
          selectionType: {
            const: 'multiple',
          },
        },
        required: ['selectionType'],
      },
      then: {
        properties: {
          maxFiles: {
            type: 'number',
          },
          maxFileSize: {
            type: 'number',
          },
        },
      },
    },
  ],
};

export const contentUISchema = {
  label: {
    'ui:title': 'Label',
    'ua:i18n': true,
  },
  description: {
    'ui:title': 'Description',
    'ua:i18n': true,
  },
  maxFiles: {
    'ui:title': 'Maximum Files',
    'ui:help': 'The maximum number of files that can be uploaded',
  },
  maxFileSize: {
    'ui:title': 'Maximum File Size (MB)',
    'ui:placeholder': '10',
    'ui:help': 'The maximum file size that can be Uploaded',
  },
  allowDownload: {
    'ui:title': 'Allow Download',
    'ui:slots': {
      label: {
        variant: 'text-xs',
      },
    },
  },
  supportedFileTypes: {
    'ui:widget': 'MultiSelectWidget',
    'ui:title': 'Supported File Types',
    'ui:help': 'Select the file types that can be uploaded',
  },
  divider: { 'ui:label': false, 'ui:widget': 'DividerWidget' },
  divider1: { 'ui:label': false, 'ui:widget': 'DividerWidget' },
  selectionType: {
    'ui:title': 'Selection Type',
  },
  interactions: {
    'ui:title': 'Interactions',
    'ui:field': 'InteractionsField',
    'ua:options': {
      events: [{ eventType: EventTypeEnum.OnFileUpload, label: 'On File Uploaded' }],
    },
  },
  permissions: {
    'ui:field': 'BlockPermissionsField',
    'ui:title': 'Permissions',
  },
  addOns: {
    'ui:title': 'Add-ons',
    'ui:field': 'AddOnsField',
    'ua:i18n': true,
    'ua:options': {
      'ua:i18n': true,
      addOns: [
        {
          id: 'templateFile',
          label: 'Template File',
          showNestedDetails: true,
          'ua:i18n': true,
          viewDetails: {
            viewType: 'FILE_UPLOAD_TEMPLATE_DETAILS',
            propertyPath: 'component.content.addOns.templateFile',
          },
          initialValue: {
            label: 'Template File',
            icon: 'SvgDownload01',
            source: 'url',
            url: '',
          },
        },
      ],
    },
  },
  'ui:order': [
    'label',
    'description',
    'supportedFileTypes',
    'divider',
    'selectionType',
    'maxFiles',
    'maxFileSize',
    'allowDownload',
    'divider1',
    'addOns',
    'interactions',
    'permissions',
  ],
};
