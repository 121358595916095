import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDataSourceRecordStore } from '../../../../../stores/DataSourceRecordStore';
import { useGlobalStateStore } from '../../../../../stores/GlobalStateStore';
import DependencyFlowHelper from '../../../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import DataSourceHelper from '../../../../../helper/DataSourceHelper';
import useShowDisabledDataSourceSnackbar from '../../../hooks/useShowDisabledDataSourceSnackbar';
import { useIsDataSourceDisabled } from '../../../hooks/useFinishDisabledAutomaticDS';

function useManualDataSourceTriggerIds() {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const dsIntraDependencies = useGlobalStateStore().use.intraDependencies.dataSources();
  const dataSourceRecords = useDataSourceRecordStore().use.dataSources();
  const manuallyTriggeredDataSources = useGlobalStateStore().use.manuallyTriggeredDataSources();
  const manualDataSourceTriggerIds = Object.keys(manuallyTriggeredDataSources);
  const { removeManuallyTriggeredDataSources } = useGlobalStateStore().use.actions();
  const { showDisabledDSMessage } = useShowDisabledDataSourceSnackbar();
  const { getIsDisabled } = useIsDataSourceDisabled();
  const freeManualDataSourceTriggerIds = manualDataSourceTriggerIds.filter((key) => {
    const id = manuallyTriggeredDataSources[key].dataSourceId;
    return DependencyFlowHelper.areAllDependenciesFinished({
      dependencies: dsIntraDependencies?.[id],
      dependencyFlowInstance: dsDependencyFlow,
    });
  });

  const disabledKeys = useMemo(() => {
    const _ids = freeManualDataSourceTriggerIds.filter((key) => {
      const id = manuallyTriggeredDataSources[key].dataSourceId;
      const queryDisabled = DataSourceHelper.advancedOptions(dataSourceRecords[id]).queryDisabled;
      if (isEmpty(queryDisabled)) return false;

      return getIsDisabled({ disabled: queryDisabled });
    });

    return new Set(_ids);
  }, [
    dataSourceRecords,
    freeManualDataSourceTriggerIds,
    getIsDisabled,
    manuallyTriggeredDataSources,
  ]);

  const enabledKeys = freeManualDataSourceTriggerIds.filter((key) => {
    return !disabledKeys.has(key);
  });

  useEffect(() => {
    if (disabledKeys.size) {
      // as manual ds is fire and forget, we remove the entry because it is unnecessary as at the time of firing the disability was met
      disabledKeys.forEach((key) => {
        const dataSourceId = manuallyTriggeredDataSources[key].dataSourceId;
        manuallyTriggeredDataSources[key].callback?.();
        showDisabledDSMessage(dataSourceId);
      });
      removeManuallyTriggeredDataSources(Array.from(disabledKeys));
    }
  }, [
    disabledKeys,
    manuallyTriggeredDataSources,
    removeManuallyTriggeredDataSources,
    showDisabledDSMessage,
  ]);

  return {
    triggerIds: enabledKeys,
    requests: manuallyTriggeredDataSources,
  };
}

export default useManualDataSourceTriggerIds;
