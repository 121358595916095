import { memo, type MutableRefObject, useCallback, useMemo, useRef } from 'react';
import _find from 'lodash/find';
import _partition from 'lodash/partition';
import Textarea from '@unifyapps/ui/components/Textarea';
import Stack from '@unifyapps/ui/_components/Stack';
import useControlledState from '@unifyapps/ui/hooks/useControlledState';
import { slowCn } from '@unifyapps/ui/lib/utils';
import _merge from 'lodash/merge';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import _isEmpty from 'lodash/isEmpty';
import { removeEmptyTrailingPTags } from '@unifyapps/editor/editors/RichTextEditor/utils';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { clsx } from 'clsx';
import { ClickAwayListener } from '@unifyapps/ui/components/ClickAwayListener';
import { UppyProvider } from '../UppyProvider';
import type { useUppy } from '../../hooks/useUppy';
import KeyBindingHandler from '../CommentsWidget/component/CommentInput/components/KeyBindingHandler';
import { TextareaRoot } from './components/Inputs/TextareaRoot';
import { RichTextEditor } from './components/Inputs/RichTextEditor';
import { CustomAction } from './actions/customAction';
import { StandardAction } from './actions/standardAction';
import { useAttachmentsUpload } from './hooks/useAttachmentsUpload';
import type { MessageInputAction, MessageInputProps, InputMethodsRef, InputMethods } from './types';
import { MessageInputActionAlignment, MessageInputActionType, StandardActionsEnum } from './types';
import { REFERENCE_ID } from './constants';
import { AttachmentsList } from './components/Attachments/AttachmentsList';
import { useMessageInputFocusEvents } from './hooks/useMessageInputFocusEvents';

const SMALL_TEXTAREA = {
  className: 'resize-none ps-xs text-sm self-center',
};
const MEDIUM_TEXTAREA = {
  className: 'resize-none ps-xs text-md self-center',
};

const DEFAULT_SLOT_PROPS = {
  endDecorator: {
    className: 'mt-0 w-full',
  },
  startDecorator: {
    className: 'mt-0 w-full -mb-[10px] pt-xxs',
  },
};

export enum CommentInputActions {
  Submit = 'Submit',
  SubmitAndPreventDefault = 'SubmitAndPreventDefault',
}

function CommentsActions({
  disabled,
  attachments,
  isSubmitDisabled,
  isLoading,
  uppy,
  onInputSubmit,
  startAlignedActions,
  endAlignedActions,
  input,
  className,
  onEmojiSelect,
  inputWidth,
  onInputChange,
  textAreaRootRef,
  methodsRef,
  onStartDictation,
  inputContainerRef,
}: {
  disabled?: boolean;
  attachments?: FileType[];
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  uppy: ReturnType<typeof useUppy>['uppy'];
  onInputSubmit: () => void | Promise<void>;
  startAlignedActions: MessageInputAction[];
  endAlignedActions: MessageInputAction[];
  input?: string;
  className?: string;
  onEmojiSelect: (emojiData: { native: string }) => void;
  inputWidth?: number;
  onInputChange: (newValue: string) => void;
  textAreaRootRef: MutableRefObject<HTMLDivElement | null>;
  methodsRef?: InputMethodsRef;
  onStartDictation?: () => void;
  inputContainerRef?: React.RefObject<HTMLDivElement | null>;
}) {
  const renderActions = (actionList: MessageInputAction[]) =>
    actionList.map((action) =>
      action.type === MessageInputActionType.Custom ? (
        <CustomAction action={action} disabled={disabled} key={action.id} />
      ) : (
        <StandardAction
          action={action}
          attachments={attachments}
          disabled={Boolean(disabled)}
          input={input}
          inputContainerRef={inputContainerRef}
          inputWidth={inputWidth}
          isLoading={Boolean(isLoading)}
          isSubmitDisabled={isSubmitDisabled}
          key={action.id}
          methodsRef={methodsRef}
          onEmojiSelect={onEmojiSelect}
          onInputChange={onInputChange}
          onInputSubmit={onInputSubmit}
          onStartDictation={onStartDictation}
          textAreaRootRef={textAreaRootRef}
          uppy={uppy}
        />
      ),
    );

  return (
    <Stack
      className={clsx('w-full items-center', className)}
      direction="row"
      justifyContent="space-between"
    >
      {!_isEmpty(startAlignedActions) ? (
        <Stack className="gap-sm flex-1 items-center" direction="row">
          {renderActions(startAlignedActions)}
        </Stack>
      ) : null}
      {!_isEmpty(endAlignedActions) ? (
        <Stack className="gap-lg ms-auto flex-1 items-center justify-end" direction="row">
          {renderActions(endAlignedActions)}
        </Stack>
      ) : null}
    </Stack>
  );
}

export const BaseMessageInput = memo(function BaseMessageInput(props: MessageInputProps) {
  const {
    isLoading,
    actions,
    placeholder,
    onChange,
    keyBindings,
    value,
    className,
    slotProps: externalSlotProps,
    disabled,
    variant = 'richText',
    inputRef,
    startDecoratorNode,
    isSubmitDisabled,
    isMentionListActiveRef,
    onFocus,
    editorKey,
    onClickAway,
    inlineActions = true,
    autoFocus = true,
    dataAttributes,
    textareaClassName,
    minRows,
    style,
    textareaVariant,
    ...restProps
  } = props;
  const [input, setInput] = useControlledState<string | undefined>({
    onChange,
    controlledValue: value,
  });

  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const methodsRef = useRef<InputMethods | null>(null);
  const textAreaRootRef = useRef<HTMLDivElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const onStartDictation = useCallback(() => {
    inputRef?.current?.focus?.();
    textAreaRef.current?.focus();
  }, [inputRef]);

  const { onAttachmentsUpdate, onSendMessage } = useMemo(
    () => ({
      onAttachmentsUpdate: _find(
        actions,
        (action: MessageInputAction) =>
          action.type === MessageInputActionType.Standard &&
          action.action === StandardActionsEnum.AddAttachments,
      )?.onClick,
      onSendMessage: _find(
        actions,
        (action: MessageInputAction) =>
          action.type === MessageInputActionType.Standard &&
          action.action === StandardActionsEnum.SendMessage,
      )?.onClick,
    }),
    [actions],
  );

  const { uppy, attachments, isUploading, onRemoveAttachment, onClearAll } = useAttachmentsUpload({
    referenceId: REFERENCE_ID,
    onAttachmentsUpdate,
  });

  const onInputChange = useEventCallback((_newValue: string) => {
    methodsRef.current?.onInputChange?.(_newValue);
    setInput(_newValue);
  });

  const onInputSubmit = useEventCallback(async () => {
    if (isUploading || !(input || attachments?.length) || isSubmitDisabled) return;
    let newValue = input;

    if (variant === 'richText') {
      newValue = removeEmptyTrailingPTags(input ?? '');
    }

    onInputChange(newValue ?? '');

    await onSendMessage?.({ message: newValue, attachments });
    onClearAll();
  });

  const onEmojiSelect = useEventCallback((emojiData: { native: string }) => {
    inputRef?.current?.insertHtml?.(emojiData.native);
  });

  const [startAlignedActions, endAlignedActions] = useMemo(
    () => _partition(actions, (action) => action.alignment === MessageInputActionAlignment.Start),
    [actions],
  );

  const startDecorator = useMemo(() => {
    return startDecoratorNode ? startDecoratorNode : null;
  }, [startDecoratorNode]);

  const endDecorator = useMemo(() => {
    if (_isEmpty(actions)) {
      return null;
    }

    return (
      <Stack className="gap-lg w-full">
        {!attachments || _isEmpty(attachments) ? null : (
          <AttachmentsList attachments={attachments} onRemoveAttachment={onRemoveAttachment} />
        )}

        {inlineActions ? (
          <CommentsActions
            attachments={attachments}
            disabled={disabled}
            endAlignedActions={endAlignedActions}
            input={input}
            inputContainerRef={inputContainerRef}
            inputWidth={inputContainerRef.current?.offsetWidth}
            isLoading={isLoading}
            isSubmitDisabled={isSubmitDisabled}
            methodsRef={methodsRef}
            onEmojiSelect={onEmojiSelect}
            onInputChange={onInputChange}
            onInputSubmit={onInputSubmit}
            onStartDictation={onStartDictation}
            startAlignedActions={startAlignedActions}
            textAreaRootRef={textAreaRootRef}
            uppy={uppy}
          />
        ) : null}
      </Stack>
    );
  }, [
    actions,
    attachments,
    onRemoveAttachment,
    inlineActions,
    disabled,
    endAlignedActions,
    input,
    isLoading,
    isSubmitDisabled,
    onEmojiSelect,
    onInputChange,
    onInputSubmit,
    onStartDictation,
    startAlignedActions,
    uppy,
  ]);

  const slots = useMemo(
    () => ({
      root: TextareaRoot,
      ...(variant === 'richText' ? { textarea: RichTextEditor } : {}),
    }),
    [variant],
  );

  const slotProps = useMemo(
    () => ({
      root: {
        ...(externalSlotProps?.root ?? {}),
        textAreaRootRef,
      },
      textarea: {
        ...(externalSlotProps?.textarea ?? {}),
        onInputChange,
        inputRef,
        placeholder,
      },

      startDecorator: {
        ...(externalSlotProps?.startDecorator ?? {}),
      },
      endDecorator: {
        ...(externalSlotProps?.endDecorator ?? {}),
        ...(!_isEmpty(attachments)
          ? {
              className: clsx(
                DEFAULT_SLOT_PROPS.endDecorator.className,
                (externalSlotProps?.endDecorator as { className?: string }).className,
              ),
            }
          : {}),
      },
    }),
    [
      externalSlotProps?.root,
      externalSlotProps?.textarea,
      externalSlotProps?.startDecorator,
      externalSlotProps?.endDecorator,
      onInputChange,
      inputRef,
      placeholder,
      attachments,
    ],
  );

  const handleClickAway = useCallback(
    () => onClickAway?.({ hasAttachments: !_isEmpty(attachments) }),
    [attachments, onClickAway],
  );

  const handleAction = useCallback(
    (actionType: string, event: Event) => {
      switch (actionType) {
        case CommentInputActions.SubmitAndPreventDefault:
          event.preventDefault();
          void onInputSubmit();
          break;
        case CommentInputActions.Submit:
          void onInputSubmit();
          break;
        default:
      }
    },
    [onInputSubmit],
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <>
        {/* items-center added here since this stack is additionally added over textarea which was initially center aligned but not now because
         * this stack is  taking full width and aligning the textarea to the start.
         */}
        <Stack
          className={slowCn('w-full items-center', className)}
          ref={inputContainerRef}
          style={style}
          {...dataAttributes}
        >
          <Textarea
            // eslint-disable-next-line jsx-a11y/no-autofocus -- autoFocus is passed as a prop
            autoFocus={autoFocus}
            className={clsx(
              'gap-xs flex items-center shadow-md',
              {
                'mb-lg': !inlineActions,
              },
              textareaClassName,
            )}
            disabled={disabled}
            endDecorator={endDecorator}
            key={editorKey}
            minRows={minRows}
            onChangeValue={onInputChange}
            onFocus={onFocus}
            placeholder={placeholder}
            slotProps={slotProps}
            slots={slots}
            startDecorator={startDecorator}
            value={input}
            variant={textareaVariant}
            {...restProps}
            ref={textAreaRef}
          />
          {!inlineActions ? (
            <CommentsActions
              attachments={attachments}
              className={externalSlotProps?.actions?.className}
              disabled={disabled}
              endAlignedActions={endAlignedActions}
              input={input}
              inputContainerRef={inputContainerRef}
              inputWidth={inputContainerRef.current?.offsetWidth}
              isLoading={isLoading}
              isSubmitDisabled={isSubmitDisabled}
              onEmojiSelect={onEmojiSelect}
              onInputChange={onInputChange}
              onInputSubmit={onInputSubmit}
              onStartDictation={onStartDictation}
              startAlignedActions={startAlignedActions}
              textAreaRootRef={textAreaRootRef}
              uppy={uppy}
            />
          ) : null}
        </Stack>
        {keyBindings?.map((keyBinding) => (
          <KeyBindingHandler
            handleAction={handleAction}
            inputRef={textAreaRootRef}
            isMentionListActiveRef={isMentionListActiveRef}
            key={keyBinding.uniqueKey}
            keyBinding={keyBinding}
          />
        ))}
      </>
    </ClickAwayListener>
  );
});

function MessageInput(props: MessageInputProps) {
  const { slotProps: _slotProps, onFocus, extensions, ...rest } = props;

  const { handleFocus, onClickAway, showMinimalisticState } = useMessageInputFocusEvents({
    input: props.value,
    onFocus,
  });

  const slotProps = useMemo(
    () => ({
      //todo: govern the size of the textarea based on size 'sm' or 'md'
      textarea: _merge({}, SMALL_TEXTAREA, _slotProps?.textarea, { extensions }),
      startDecorator: showMinimalisticState
        ? { className: 'hidden' }
        : _merge({}, DEFAULT_SLOT_PROPS.startDecorator, _slotProps?.startDecorator),
      endDecorator: showMinimalisticState
        ? { className: 'hidden' }
        : _merge({}, DEFAULT_SLOT_PROPS.endDecorator, _slotProps?.endDecorator),
    }),
    [
      _slotProps?.endDecorator,
      _slotProps?.startDecorator,
      _slotProps?.textarea,
      extensions,
      showMinimalisticState,
    ],
  );

  return (
    <BaseMessageInput
      onClickAway={onClickAway}
      onFocus={handleFocus}
      slotProps={slotProps}
      {...rest}
    />
  );
}

// used specifically to achieve an inline layout for the actions used in the case of Copilot.
function MessageInputCompact(props: MessageInputProps) {
  const { slotProps: _slotProps, extensions, ...rest } = props;

  const areLeftAlignedActionsEmpty = useMemo(
    () => !props.actions.some((action) => action.alignment === MessageInputActionAlignment.Start),
    [props.actions],
  );

  const slotProps = useMemo(
    () => ({
      textarea: _merge({}, MEDIUM_TEXTAREA, _slotProps?.textarea, { extensions }),
      startDecorator: _merge({}, DEFAULT_SLOT_PROPS.startDecorator, _slotProps?.startDecorator),
      endDecorator: _merge(
        {},
        areLeftAlignedActionsEmpty
          ? { className: 'mt-0 ms-auto flex-shrink-0' }
          : DEFAULT_SLOT_PROPS.endDecorator,
        _slotProps?.endDecorator,
      ),
      actions: _slotProps?.actions,
    }),
    [
      _slotProps?.actions,
      _slotProps?.endDecorator,
      _slotProps?.startDecorator,
      _slotProps?.textarea,
      areLeftAlignedActionsEmpty,
      extensions,
    ],
  );

  return <BaseMessageInput slotProps={slotProps} {...rest} />;
}

function MessageInputWrapper(props: MessageInputProps) {
  return (
    <UppyProvider>
      {props.mode === 'compact' ? <MessageInputCompact {...props} /> : <MessageInput {...props} />}
    </UppyProvider>
  );
}

export { MessageInputWrapper as MessageInput };
