import type { StoreApi, UseBoundStore } from 'zustand';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { BlockId } from '@unifyapps/defs/types/block';
import type { InterfaceStoreType } from './types';

export const createInterfaceStoreSelectors = (
  store: UseBoundStore<StoreApi<InterfaceStoreType>>,
) => {
  const storeSelectors = {
    mode: () => store((state) => state.mode),
    pageSettingDetails: () => store((state) => state.pageSettingDetails),
    client: () => store((state) => state.client),
    basePath: () => store((state) => state.basePath),
    activeInterfacePageId: () => store((state) => state.activeInterfacePageId),
    activeBlockId: () => store((state) => state.activeBlockId),
    pathToAddComponentPlaceholder: () => store((state) => state.pathToAddComponentPlaceholder),
    hoverBlockId: () => store((state) => state.hoverBlockId),
    touchedBlocks: () => store((state) => state.touchedBlocks),
    copiedBlockDetails: () => store((state) => state.copiedBlockDetails),
    touchedJavascriptEntities: () => store((state) => state.touchedJavascriptEntities),
    dirtyPages: () => store((state) => state.dirtyPages),
    propertyPanel: () => store((state) => state.propertyPanel),
    pages: () => store((state) => state.interfacePages),
    actions: () => store.getState().actions,
    isInteractiveModeEnabled: () => store((state) => state.isInteractiveModeEnabled),
    isPublic: () => store((state) => state.isPublic),
    isInterfaceRecordDirty: () => store((state) => state.isInterfaceRecordDirty),
  };

  const recordSelectors = {
    id: () => store((state) => state.interfaceRecord.id),
    name: () => store((state) => state.interfaceRecord.properties.name),
    details: () => store((state) => state.interfaceRecord),
    flags: () => store((state) => state.interfaceRecord.properties.flags),
    navigation: () => store((state) => state.interfaceRecord.properties.navigation),
    theme: () => store((state) => state.interfaceRecord.properties.theme),
    deviceDetails: () => store((state) => state.interfaceRecord.properties.deviceDetails),
    interfacePageIdVsSlugMap: () =>
      store((state) => state.interfaceRecord.properties.interfacePageIdVsSlugMap),
  };

  const pageSelectors = {
    name: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.name;
      });
    },
    componentType: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.componentType;
      });
    },
    visibility: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.visibility;
      });
    },
    inputSchema: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.inputSchema;
      });
    },
    interactions: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.interactions;
      });
    },
    output: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.output;
      });
    },
    details: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId];
      });
    },
    dataSources: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.dataSources;
      });
    },
    pageVariables: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.pageVariables;
      });
    },
    pageFunctions: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.pageFunctions;
      });
    },
    events: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.events;
      });
    },
    pageVariableById: (pageVariableId: string) => {
      const pageId = storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.pageVariables?.[pageVariableId];
      });
    },
    pageFunctionById: (pageFunctionId: string) => {
      const pageId = storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.pageFunctions?.[pageFunctionId];
      });
    },
    layout: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => {
        return state.interfacePages[pageId].properties.layout;
      });
    },
    interfacePageEl: () => store((state) => state.interfacePageEl),
    metadata: {
      flags: (interfacePageId?: string) => {
        const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
        return store((state) => {
          return state.interfacePages[pageId].properties.metadata?.flags;
        });
      },
      dependencies: (interfacePageId?: string) => {
        const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
        return store((state) => {
          return state.interfacePages[pageId].properties.metadata?.dependencies;
        });
      },
      dependencyById: (interfacePageId?: string) => {
        const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
        return store((state) => {
          return state.interfacePages[pageId].properties.metadata?.dependencyById;
        });
      },
    },
  };

  const blockSelectors = {
    fromBase: ({ blockId, interfacePageId }: { interfacePageId?: string; blockId: BlockId }) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      const _blockId = blockId || '';
      return store((state) => state.interfacePages[pageId].properties.blocks?.[_blockId]);
    },
    fromDevice: ({
      device,
      blockId,
      interfacePageId,
    }: {
      interfacePageId?: string;
      device: DeviceVariantType;
      blockId: BlockId;
    }) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      const _blockId = blockId || '';
      return store(
        (state) => state.interfacePages[pageId].properties.devices?.[device]?.blocks?.[_blockId],
      );
    },
  };

  const blocksSelectors = {
    fromBaseDevice: (interfacePageId?: string) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store((state) => state.interfacePages[pageId].properties.blocks);
    },
    fromDevice: ({
      deviceVariant,
      interfacePageId,
    }: {
      interfacePageId?: string;
      deviceVariant: DeviceVariantType;
    }) => {
      const pageId = interfacePageId || storeSelectors.activeInterfacePageId();
      return store(
        (state) => state.interfacePages[pageId].properties.devices?.[deviceVariant]?.blocks,
      );
    },
  };

  return Object.assign(store, {
    use: {
      ...storeSelectors,
      record: recordSelectors,
      page: pageSelectors,
      block: blockSelectors,
      blocks: blocksSelectors,
    },
  });
};
