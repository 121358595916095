import { produce } from 'immer';
import { invariant } from 'ts-invariant';
import type { PageFunction, PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { FUNCTION_PREFIX } from '../../../const';
import type { InterfaceStoreStateGetterAndSetter } from '../types';
import { getIdWithPrefix } from '../../../../utils/id';
import noCodeBuildDependencyGraph from '../../../graph/noCodeBuildDependencyGraph';
import { DependencyHelper } from '../../../../dependency/helpers/DependencyHelper';

export type PageFunctionActionProps =
  | {
      operation: 'CREATE';
      payload: {
        functionBody: string;
      };
    }
  | {
      operation: 'DELETE';
      payload: {
        pageFunctionId: string;
      };
    }
  | {
      operation: 'UPDATE';
      payload: {
        pageFunctionId: string;
        updatedPageFunction: PageFunction;
      };
    };

const getPageFunctionActions =
  (storeArgs: InterfaceStoreStateGetterAndSetter) =>
  ({ operation, payload }: PageFunctionActionProps) => {
    const { get, set, setPageFunctionState, getDependencyGraphActions, getDataSourceRecordsStore } =
      storeArgs;
    const state = get();
    const { incrementFunctionCounter } = state.actions;
    const activePageId = state.activeInterfacePageId;

    const getUsedDataSourceIds = (pageFunction: Omit<PageFunction, 'dataSourceIds'>) => {
      const { id } = pageFunction;
      const dataSources = getDataSourceRecordsStore().dataSources;
      const automaticDSIds = DataSourceHelper.filterAutomaticDataSourcesIds(dataSources);
      const dataSourceDepGraph = noCodeBuildDependencyGraph({
        objects: {
          [id]: pageFunction,
        },
        isValidEntity: (entityId) => automaticDSIds.includes(entityId),
      })[id];

      const dataSourceIds = DependencyHelper.getDependsOnIds(
        DependencyHelper.getDependsOn(dataSourceDepGraph),
      );

      return dataSourceIds ?? [];
    };

    switch (operation) {
      case 'CREATE': {
        const newFunctionId = getIdWithPrefix(FUNCTION_PREFIX);
        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          const count = currentPage.properties.metadata?._functionCounter;
          const newCount = (count ?? 0) + 1;

          const updatedPageFunction: Omit<PageFunction, 'dataSourceIds'> = {
            ...payload,
            id: newFunctionId,
            name: `Function_${newCount}`,
            createdTime: Number(new Date()),
          };

          const deps = getDependencyGraphActions().buildEntityDependency(
            updatedPageFunction,
            'pageFunctions',
          );

          const pageFncDeps = deps[newFunctionId];

          const dataSourceIds = getUsedDataSourceIds(updatedPageFunction);

          if (!currentPage.properties.pageFunctions) {
            currentPage.properties.pageFunctions = {
              [newFunctionId]: {
                ...updatedPageFunction,
                dataSourceIds,
                dpOn: DependencyHelper.getDependsOn(pageFncDeps),
              },
            };
          } else {
            currentPage.properties.pageFunctions[newFunctionId] = {
              ...updatedPageFunction,
              dataSourceIds,
              dpOn: DependencyHelper.getDependsOn(pageFncDeps),
            };
          }

          setPageFunctionState(newFunctionId, updatedPageFunction as PageFunctionState);

          getDependencyGraphActions().updateDependenciesAndNotify(deps);

          draftState.touchedJavascriptEntities.add(newFunctionId);
        });

        set(newState);
        incrementFunctionCounter();

        return newFunctionId;
      }
      case 'DELETE': {
        const { pageFunctionId } = payload;

        const newState = produce(state, (draftState) => {
          const currentPage = draftState.interfacePages[activePageId];
          invariant(currentPage, `Page with id ${activePageId} not found`);

          if (currentPage.properties.pageFunctions?.[pageFunctionId]) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- dynamic delete is required here
            delete currentPage.properties.pageFunctions[pageFunctionId];
          }

          setPageFunctionState(pageFunctionId, undefined);
          draftState.touchedJavascriptEntities.add(pageFunctionId);
        });

        set(newState);
        getDependencyGraphActions().deleteDependencies(pageFunctionId);

        break;
      }
      case 'UPDATE': {
        const { pageFunctionId, updatedPageFunction } = payload;

        const dependencies = getDependencyGraphActions().buildEntityDependency(
          updatedPageFunction,
          'pageFunctions',
        );

        const dataSourceIds = getUsedDataSourceIds(updatedPageFunction);

        const newState = produce(state, (draftState) => {
          const draftPageFunctions =
            draftState.interfacePages[activePageId].properties.pageFunctions;

          const finalUpdatedPageFunction: PageFunction = {
            ...updatedPageFunction,
            dataSourceIds,
          };

          if (!draftPageFunctions?.[pageFunctionId]) {
            return;
          }

          draftPageFunctions[pageFunctionId] = finalUpdatedPageFunction;

          const pageFncDeps = dependencies[pageFunctionId];

          draftPageFunctions[pageFunctionId].dpOn = DependencyHelper.getDependsOn(pageFncDeps);

          setPageFunctionState(pageFunctionId, finalUpdatedPageFunction);
          draftState.touchedJavascriptEntities.add(pageFunctionId);
        });

        set(newState);

        getDependencyGraphActions().updateDependenciesAndNotify(dependencies);

        break;
      }
    }
  };

export default getPageFunctionActions;
