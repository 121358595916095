import { RUN_TIME_BLOCK_KEY } from '../const';

// Breakdown of regex pattern:
// ^                           - Start of string
// (?:${RUN_TIME_BLOCK_KEY})  - Non-capturing group matching "runTimeBlockKey"
// _                          - Matches literal underscore
// (?<temp3>b_[^_]+)         - Named capture group "temp3":
//                             - Matches "b_" followed by one or more non-underscore chars
//                             - Example: "b_card1"
// (?:_[^[]*)?               - Optional non-capturing group:
//                             - Matches underscore followed by any chars except [
//                             - Example: "_primaryKey1_primaryKey2" or "_0_1"
// (?<temp1>(\\[.*?])+)?     - Optional named capture group "temp1":
//                             - Matches one or more bracket pairs [...]
//                             - .*? means non-greedy match of any chars
//                             - Example: "['content']['data']"
// $                          - End of string

// Examples of matching strings:
// "runTimeBlockKey_b_card1_primaryKey1_primaryKey2"          -> temp3: "b_card1", temp1: undefined
// "runTimeBlockKey_b_card1_0_1"                             -> temp3: "b_card1", temp1: undefined
// "runTimeBlockKey_b_card1_primaryKey1['content']"          -> temp3: "b_card1", temp1: "['content']"
// "runTimeBlockKey_b_card1_0_1['content']['data']"         -> temp3: "b_card1", temp1: "['content']['data']"

const RUNTIME_BLOCK_REGEX = new RegExp(
  `^(?:${RUN_TIME_BLOCK_KEY})_(?<temp3>b_[^_]+)(?:_[^[]*)?(?<temp1>(\\[.*?])+)?$`,
);

/**
 * Extracts components from a runtime block path ID
 */
export const parseRuntimeBlockPathId = (pathId: string) => {
  const match = RUNTIME_BLOCK_REGEX.exec(pathId);
  if (!match) return null;

  const { groups } = match;
  if (!groups) return null;

  return {
    targetBlockId: groups.temp3,
    remainingPathString: groups.temp1 || '',
  };
};
