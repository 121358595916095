import { useCallback, useMemo } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import File04 from '@unifyapps/icons/outline/File04';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import SvgTrash01 from '@unifyapps/icons/outline/Trash01';
import SvgImage from '@unifyapps/icons/colored/file/Image';
import SvgPdf from '@unifyapps/icons/colored/file/Pdf';
import useToggle from '@unifyapps/hooks/useToggle';
import { Divider } from '@unifyapps/ui/components/Divider';
import { CircularProgress } from '@unifyapps/ui/components/Loader/Loader';
import SvgXls from '@unifyapps/icons/colored/Xls';
import SvgCsv from '@unifyapps/icons/colored/Csv';
import SvgXlsx from '@unifyapps/icons/colored/Xlsx';
import SvgPptx from '@unifyapps/icons/colored/Pptx';
import SvgPpt from '@unifyapps/icons/colored/Ppt';
import SvgDoc from '@unifyapps/icons/colored/Doc';
import SvgDocx from '@unifyapps/icons/colored/Docx';
import SvgEpubs from '@unifyapps/icons/colored/Epubs';
import { Box } from '@unifyapps/ui/components/Box';
import clsx from 'clsx';
import type { UploadedFile } from '../../hooks/useUppy';
import { filesize } from '../FileInput';
import { formatPercent } from '../../utils/percent';
import MediaViewer from '../MediaViewer';
import type { MediaType } from '../MediaViewer/types';
import { adaptPrivateUrl } from '../MediaViewer/helpers/getPreviewUrl';
import { TypographyWithTooltip } from '../Typography/TypographyWithTooltip';

export const getIconFromFileType = (fileType: string) => {
  switch (true) {
    case fileType.includes('image'):
      return SvgImage;
    case fileType.includes('pdf'):
      return SvgPdf;
    case fileType.includes('excel'):
      return SvgXls;
    case fileType.includes('csv'):
      return SvgCsv;
    case fileType.includes('sheet'):
      return SvgXlsx;
    case fileType.includes('presentation'):
      return SvgPptx;
    case fileType.includes('powerpoint'):
      return SvgPpt;
    case fileType.includes('document'):
      return SvgDocx;
    case fileType.includes('msword'):
      return SvgDoc;
    case fileType.includes('epub'):
      return SvgEpubs;
    default:
      return File04;
  }
};

export type File = Pick<UploadedFile, 'url' | 'id' | 'fileProgress' | 'name' | 'size' | 'type'>;

const PROGRESS_SLOT_PROPS = { progress: { className: 'stroke-fg-brand-primary' } };
type Props = {
  onRemoveFile?: (fileId: string) => void;
  disabled?: boolean;
  allowDownload?: boolean;
  inlinePreview?: {
    enabled?: boolean;
    style?: string;
  };
};

function UploadStatus(
  props: {
    file: File;
    mediaItems: MediaType[];
    index: number;
  } & Props,
) {
  const { file, onRemoveFile, disabled, mediaItems, index, allowDownload, inlinePreview } = props;

  const isUploaded = Boolean(file.url);
  const [mediaViewerOpen, { on: openMediaViewer, off: closeMediaViewer }] = useToggle();
  const { name: fileName, url: fileUrl, id: fileId, type: fileType, fileProgress } = file;

  const onRemove = useCallback(() => {
    onRemoveFile?.(fileId);
  }, [fileId, onRemoveFile]);

  // for relative path
  const adaptedUrl = fileUrl ? adaptPrivateUrl(fileUrl) : fileUrl;

  const { enabled: showInlineImagePreview, style: inlinePreviewStyle } = inlinePreview ?? {};

  const showImagePreview = isUploaded && showInlineImagePreview && adaptedUrl;

  const ImagePreview = useMemo(() => {
    if (!showImagePreview) {
      return;
    }
    return (
      <Box className="border-tertiary p-xxs item-center flex justify-center border">
        <Box className={clsx('item-center flex justify-center', inlinePreviewStyle)}>
          <img alt={fileName} aria-hidden={!fileName} aria-label={fileName} src={adaptedUrl} />
        </Box>
      </Box>
    );
  }, [adaptedUrl, fileName, inlinePreviewStyle, showImagePreview]);

  const ProgressBar = useMemo(() => {
    if (typeof fileProgress !== 'number') {
      return;
    }
    return (
      <Stack alignItems="center" className="gap-md" direction="row">
        <CircularProgress
          determinate
          size="sm"
          slotProps={PROGRESS_SLOT_PROPS}
          sx={{
            '--CircularProgress-progressThickness': '4px',
            '--CircularProgress-trackThickness': '4px',
          }}
          value={fileProgress}
        />
        <Typography color="text-secondary" variant="text-sm" weight="medium">
          {formatPercent({ value: fileProgress })}
        </Typography>
      </Stack>
    );
  }, [fileProgress]);

  const DeleteButton = useMemo(() => {
    return (
      <IconButton
        Icon={SvgTrash01}
        className="!size-6"
        color="neutral"
        onClick={onRemove}
        size="sm"
        variant="soft"
      />
    );
  }, [onRemove]);

  const MediaViewerComponent = useMemo(() => {
    if (!mediaViewerOpen) {
      return;
    }
    return (
      <MediaViewer
        allowDownload={allowDownload}
        initialIndex={index}
        medias={mediaItems}
        onClose={closeMediaViewer}
      />
    );
  }, [allowDownload, closeMediaViewer, index, mediaItems, mediaViewerOpen]);

  if (showImagePreview) {
    return (
      <Stack
        alignItems="center"
        className="gap-x-lg relative min-w-0 justify-between"
        direction="row"
        onClick={openMediaViewer}
      >
        {ImagePreview}
        <Stack className="gap-md min-w-0" direction="row">
          {ProgressBar}
          {!disabled && onRemoveFile ? DeleteButton : null}
        </Stack>
        {MediaViewerComponent}
      </Stack>
    );
  }

  const Icon = getIconFromFileType(fileType ?? '');

  return (
    <Stack
      alignItems="center"
      className="gap-x-lg relative min-w-0"
      direction="row"
      onClick={isUploaded ? openMediaViewer : undefined}
    >
      <Icon className="h-6 w-6" />
      <Stack className="min-w-0 flex-1">
        <Stack
          alignItems="center"
          className="gap-md min-w-0 flex-1"
          direction="row"
          justifyContent="space-between"
        >
          <Stack className="gap-sm min-w-0" direction="row">
            <TypographyWithTooltip
              className="!break-all"
              color="text-secondary"
              maxNumberOfLines={1}
              variant="text-sm"
              weight="medium"
            >
              {fileName}
            </TypographyWithTooltip>

            <Typography className="min-w-20" color="text-tertiary" variant="text-sm">
              {file.size ? filesize(file.size) : null}
            </Typography>
          </Stack>
          {ProgressBar}
          {!disabled && isUploaded && onRemoveFile ? DeleteButton : null}
        </Stack>
      </Stack>
      {MediaViewerComponent}
    </Stack>
  );
}

function UploadStatusWrapper(
  props: {
    files: File[];
  } & Props,
) {
  const { files, onRemoveFile, disabled, allowDownload, inlinePreview } = props;

  const mediaItems = useMemo<MediaType[]>(
    () =>
      files.map((f) => ({
        name: f.name,
        url: f.url ?? '',
        id: f.id,
        mimeType: f.type,
      })),
    [files],
  );

  return (
    <Stack className="gap-md p-lg border-secondary rounded-xl border" direction="column">
      {files.map((f, index) => (
        <>
          <UploadStatus
            allowDownload={allowDownload}
            disabled={disabled}
            file={f}
            index={index}
            inlinePreview={inlinePreview}
            key={f.id}
            mediaItems={mediaItems}
            onRemoveFile={onRemoveFile}
          />
          {index === files.length - 1 ? null : <Divider />}
        </>
      ))}
    </Stack>
  );
}

export default UploadStatusWrapper;
