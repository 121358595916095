import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import {
  useGetDeployedEntitySnapshot,
  useSearchDeployedChildEntities,
} from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import {
  InterfacePageEntityComponentType,
  type InterfacePageEntity,
} from '@unifyapps/defs/types/page';
import _toNumber from 'lodash/toNumber';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import type { UseFetchResourcesHookType } from '../../standardEntities/data/useFetchInterfaceResources/type';
import { useDeployedInterfaceDetails } from '../../../../context/PublishedInterfaceDetails';
import { useFetchDeployedDataSourcesByComponentIdAndVersion } from './useFetchDeployedDataSourcesByComponentIdAndVersion';

const useFetchDeployedInterfaceResourcesByVersion: UseFetchResourcesHookType = ({
  interfaceId,
  interfaceComponent,
}) => {
  const { deployedVersion } = useDeployedInterfaceDetails();

  const {
    data: interfaceRecord,
    isLoading: isLoadingInterfaceRecord,
    error: errorInterfaceRecord,
  } = useGetDeployedEntitySnapshot<InterfaceEntity | undefined>(
    UAEntityTypes.Interface,
    interfaceId,
    {
      version: deployedVersion,
    },
  );

  const {
    data: pageEntities,
    isLoading: isLoadingPage,
    error: errorPage,
  } = useSearchDeployedChildEntities<InterfacePageEntity[]>({
    entityType: UAEntityTypes.Component,
    parentEntityId: interfaceId,
    parentEntityType: UAEntityTypes.Interface,
    parentVersion: _toNumber(deployedVersion),
  });

  const interfacePage = _filter(pageEntities, { id: interfaceComponent.id })[0] as
    | InterfacePageEntity
    | undefined;

  const { dataSources, errorDS, isLoadingDS } = useFetchDeployedDataSourcesByComponentIdAndVersion({
    interfaceComponentId: interfacePage?.id,
    interfaceComponentVersion: _toNumber(interfacePage?.version),
  });

  const interfaceModulesMap = _keyBy(
    _filter(
      pageEntities,
      (component) => component.properties.type === InterfacePageEntityComponentType.MODULE,
    ),
    'id',
  );

  const _isLoading = isLoadingInterfaceRecord || isLoadingDS || isLoadingPage;

  return {
    interfaceModulesMap,
    isLoading: _isLoading,
    error: errorInterfaceRecord || errorPage || errorDS,
    data: interfaceRecord,
    dataSources,
    interfacePage,
  };
};

export default useFetchDeployedInterfaceResourcesByVersion;
